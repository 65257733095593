/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import _ from 'lodash';
import intl from 'intl';
import {Form} from '../FormComponents';
import {MultilineInput} from '..';
import {PortUtils, GeneralUtils} from '../../utils';
import {getTrueValue, looseEqual} from '../../utils/GeneralUtils';

export default React.createClass({
  propTypes: {
    type: PropTypes.string,
    model: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      model: {},
      errorModel: {},
      initialModel: {},
      showHelpModel: {
        [intl('Services.Mixin.PortsAndProtocols')]: this.props.type !== 'dialog',
        [intl('Services.Mixin.PortsProtocolsAndProcesses')]: this.props.type !== 'dialog',
      },
    };
  },

  componentWillMount() {
    const editMode = Boolean(this.props.model && this.props.model.href);
    const generalFields = [
      {
        type: 'field',
        label: intl('Common.Name'),
        name: 'name',
        required: true,
        controls: [
          {
            type: 'text',
            maxlength: 255,
            name: 'name',
            placeholder: intl('Services.Mixin.Placeholder.ServiceName'),
          },
        ],
      },
      {
        type: 'field',
        label: intl('Common.Description'),
        name: 'description',
        controls: [
          {
            type: 'textarea',
            maxlength: 255,
            name: 'description',
            placeholder: intl('Services.Mixin.Placeholder.ServiceDescription'),
          },
        ],
      },
    ];
    const attributeFields = [
      {
        type: 'field',
        label: intl('Services.Mixin.Os.Title'),
        name: 'operating_system',
        required: true,
        disabled: editMode,
        hint: this.props.type === 'dialog' ? '' : intl('Services.Mixin.Os.Instructions'),
        controls: [
          {
            type: 'select',
            name: 'operating_system',
            options: [
              {
                value: 'all',
                label: intl('Services.Mixin.Os.All.Title'),
                sublabel: intl('Services.Mixin.Os.All.Subtitle'),
              },
              {
                value: 'windows',
                label: intl('Services.Mixin.Os.Windows.Title'),
                sublabel: intl('Services.Mixin.Os.Windows.Subtitle'),
              },
            ],
          },
        ],
      },
      {
        type: 'field',
        label: intl('Services.Mixin.PortsAndProtocols'),
        name: 'service_ports',
        required: true,
        hidden: model => model.operating_system !== 'all',
        hint: intl('Services.Mixin.Instructions'),
        help: (
          <span key="reform-field-help">
            <div className="ReForm-Field-Help-Content-Title">{intl('Port.ToAddPortProtocol')}:</div>
            <div className="ReForm-Field-Help-Content-Paragraph">
              80 {intl('Protocol.TCP')}
              <br />
              500 {intl('Protocol.UDP')}
              <br />
              1000-2000 {intl('Protocol.TCP')}
              <br />
              {intl('Protocol.GRE')}
              <br />
              {intl('Protocol.IPIP')}
              <br />
              {intl('Protocol.IGMP')}
              <br />
              {intl('Protocol.ICMP')}
              <br />
              {intl('Protocol.ICMPv6')}
              <br />8 {intl('Protocol.ICMP')}
              <br />
              3/2 {intl('Protocol.ICMP')}
              <br />
              133 {intl('Protocol.ICMPv6')}
              <br />
              {intl('Protocol.IPv4')}
              <br />
            </div>
          </span>
        ),
        controls: [
          {
            type: 'custom',
            name: 'service_ports',
            getRef: refs => refs.textarea,
            required: false, //required will be handled by custom validation function
            getControl: props => (
              <MultilineInput
                parse={PortUtils.parseExtendedPortString}
                stringify={PortUtils.stringifyPortObject}
                validate={PortUtils.validatePortsAndIpv4}
                readonly={false}
                showDiff={true}
                placeholder={intl('Services.Mixin.Placeholder.ServicePorts')}
                name={props.key}
                onChange={props.onChange}
                values={props.value || []}
                key={props.key}
                error={props.error}
                disabled={props.disabled}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                tid={props.tid}
                ref={props.ref}
                tabIndex={props.tabIndex}
              />
            ),
            validation: model => {
              let hasValue = false;
              let hasError = false;
              const servicePorts = model.service_ports || [];

              servicePorts.forEach(port => {
                if (port.error || port.duplicate) {
                  hasError = true;
                }

                if (typeof port.port === 'number' && !port.removed) {
                  hasValue = true;
                }

                if (port.text && port.text.toLocaleLowerCase() === intl('Protocol.IPv4').toLocaleLowerCase()) {
                  hasValue = true;
                }

                if (GeneralUtils.isNumberOrString(port.icmp_type) && !port.removed) {
                  hasValue = true;
                }

                if (PortUtils.getICMPAndPortlessProtocols().includes(port.protocol)) {
                  hasValue = true;
                }
              });

              if (hasError) {
                return {};
              }

              if (!hasValue) {
                return {
                  errorString: intl('Services.Mixin.AtLeastOnePort'),
                };
              }

              return true;
            },
          },
        ],
      },
      {
        type: 'field',
        label: intl('Services.Mixin.PortsProtocolsAndProcesses'),
        name: 'windows_services',
        required: true,
        hidden: model => model.operating_system !== 'windows',
        hint: intl('Services.Mixin.Instructions'),
        help: [
          <span key="reform-field-help-one">
            <div className="ReForm-Field-Help-Content-Title">{intl('Port.ToAddProcessAndWindowsService')}:</div>
            <div className="ReForm-Field-Help-Content-Paragraph">
              "{intl('Port.ProcessNamedSchedule')}"<br />"{intl('Port.WindowsServiceExample')}"<br />"
              {intl('Port.WindowsServiceExampleWithSystemEnvVariable')}"
            </div>
          </span>,
          <span key="reform-field-help-two">
            <div className="ReForm-Field-Help-Content-Title">{intl('Port.ToAddPortProtocolProcess')}:</div>
            <div className="ReForm-Field-Help-Content-Paragraph">
              540 {intl('Protocol.TCP')} "{intl('Port.WindowsServiceExample')}"<br />
              {intl('Protocol.GRE')} "my service"
              <br />
              80 {intl('Protocol.TCP')} "{intl('Port.WindowsServiceExample')}" "myservice"
            </div>
          </span>,
          <span key="reform-field-help-three">
            <div className="ReForm-Field-Help-Content-Title">{intl('Port.ToAddPortProtocol')}:</div>
            <div className="ReForm-Field-Help-Content-Paragraph">
              {intl('Protocol.IGMP')}
              <br />
              {intl('Protocol.ICMP')}
              <br />
              {intl('Protocol.ICMPv6')}
              <br />8 {intl('Protocol.ICMP')}
              <br />
              3/2 {intl('Protocol.ICMP')}
              <br />
              133 {intl('Protocol.ICMPv6')}
              <br />
              {intl('Protocol.IPv4')}
              <br />
            </div>
          </span>,
        ],
        controls: [
          {
            type: 'custom',
            name: 'windows_services',
            getRef: refs => refs.textarea,
            required: false, //required will be handled by custom validation function
            getControl: props => (
              <MultilineInput
                parse={PortUtils.parsePortProcessString}
                stringify={PortUtils.stringifyPortObject}
                validate={PortUtils.validatePortsAndIpv4}
                readonly={false}
                showDiff={true}
                placeholder={intl('Services.Mixin.Placeholder.ServicePorts')}
                name={props.key}
                onChange={props.onChange}
                values={props.value || []}
                key={props.key}
                error={props.error}
                disabled={props.disabled}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                tid={props.tid}
                ref={props.ref}
                tabIndex={props.tabIndex}
              />
            ),
            validation: model => {
              let hasValue = false;
              let hasError = false;
              const servicePorts = model.windows_services || [];

              servicePorts.forEach(port => {
                if (port.error || port.duplicate) {
                  hasError = true;
                }

                if ((typeof port.port === 'number' || port.process_name || port.service_name) && !port.removed) {
                  hasValue = true;
                }

                if (port.text && port.text.toLocaleLowerCase() === intl('Protocol.IPv4').toLocaleLowerCase()) {
                  hasValue = true;
                }

                if (GeneralUtils.isNumberOrString(port.icmp_type) && !port.removed) {
                  hasValue = true;
                }

                if (PortUtils.getICMPAndPortlessProtocols().includes(port.protocol)) {
                  hasValue = true;
                }
              });

              if (hasError) {
                return {};
              }

              if (!hasValue) {
                return {
                  errorString: intl('Services.Mixin.AtLeastOneProcess'),
                };
              }

              return true;
            },
          },
        ],
      },
    ];

    this.formFields =
      this.props.type === 'dialog'
        ? [...generalFields, ...attributeFields]
        : [
            {
              type: 'fieldset',
              name: 'general',
              title: intl('Common.General'),
              fields: generalFields,
            },
            {
              type: 'fieldset',
              name: 'attributes',
              title: intl('Common.Attributes'),
              fields: attributeFields,
            },
          ];
  },

  componentDidMount() {
    this.setInitialModel(this.props.model);
  },

  componentWillReceiveProps(nextProps) {
    if (this.areModelsDifferent(this.state.initialModel, this.getFiendlyModel(nextProps.model))) {
      this.setInitialModel(nextProps.model);
    }
  },

  getFiendlyModel(unfriendlyModel) {
    const friendlyModel = unfriendlyModel ? {...unfriendlyModel} : {};

    friendlyModel.operating_system = 'all';

    // The Service object returned from the API either has
    // 'service_ports' or 'windows_services' Array.
    // 'service_ports' means All OS Service, otherwise a Windows OS Service.
    // Both arrays might exist, in which case if the windows_services check if service_ports is empty
    // in which case it will be 'windows'
    if (
      Array.isArray(friendlyModel.windows_services) &&
      (!Array.isArray(friendlyModel.service_ports) || !friendlyModel.service_ports.length)
    ) {
      friendlyModel.operating_system = 'windows';
    }

    return friendlyModel;
  },

  setInitialModel(initialModel) {
    const model = this.getFiendlyModel(initialModel);

    this.setState({model, initialModel: model});
  },

  areModelsDifferent(original, compare) {
    if (
      !looseEqual(original.name, compare.name) ||
      !looseEqual(original.description, compare.description) ||
      !looseEqual(original.operating_system, compare.operating_system)
    ) {
      return true;
    }

    let originalPortRanges = [];
    let updatedPortRanges = [];

    if (compare.operating_system === 'all') {
      originalPortRanges = original.service_ports || [];
      updatedPortRanges = compare.service_ports || [];
    }

    if (compare.operating_system === 'windows') {
      originalPortRanges = original.windows_services || [];
      updatedPortRanges = compare.windows_services || [];
    }

    originalPortRanges = this.getTrueArray(originalPortRanges);
    updatedPortRanges = this.getTrueArray(updatedPortRanges);

    if (originalPortRanges.length !== updatedPortRanges.length) {
      return true;
    }

    let rangeChange = false;

    originalPortRanges.forEach((oldRange, index) => {
      if (!PortUtils.arePortRangesEqual(oldRange, updatedPortRanges[index])) {
        rangeChange = true;
      }
    });

    return rangeChange;
  },

  /**
   * Filter out the array with property is defined but should be ignored, mainly for the case that text property is an empty string.
   * @param {Array} arr The array
   * @param {string} prop The property to be used
   * @returns {Array}
   */
  getTrueArray(arr, prop = 'text') {
    return arr.filter(item => typeof item[prop] === 'undefined' || Boolean(getTrueValue(item[prop])));
  },

  handleChange(modelUpdates) {
    this.setState(previousState => ({model: {...previousState.model, ...modelUpdates}}));
  },

  handleErrorChange(errorModelUpdates) {
    this.setState(previousState => ({errorModel: {...previousState.errorModel, ...errorModelUpdates}}));
  },

  handleSubmit() {
    const model = {
      name: this.state.model.name,
    };

    if (this.state.model.description || this.state.model.description === '') {
      model.description = this.state.model.description.trim();
    }

    if (this.state.model.operating_system === 'all') {
      model.service_ports = this.state.model.service_ports
        .filter(range => !range.removed && (!_.isNil(range.port) || range.protocol))
        .map(range => {
          const updateRange = {};

          if (!_.isNil(range.port)) {
            updateRange.port = range.port;
          }

          if (range.protocol) {
            updateRange.protocol = range.protocol;
          }

          if (!_.isNil(range.to_port)) {
            updateRange.to_port = range.to_port;
          }

          if (GeneralUtils.isNumberOrString(range.icmp_type)) {
            updateRange.icmp_type = range.icmp_type;
          }

          if (GeneralUtils.isNumberOrString(range.icmp_code)) {
            updateRange.icmp_code = range.icmp_code;
          }

          return updateRange;
        });
    } else if (this.state.model.operating_system === 'windows') {
      model.windows_services = this.state.model.windows_services
        .filter(
          range =>
            !range.removed && (range.process_name || range.service_name || !_.isNil(range.port) || range.protocol),
        )
        .map(range => {
          const updateRange = {};

          if (!_.isNil(range.port)) {
            updateRange.port = range.port;
          }

          if (!_.isNil(range.to_port)) {
            updateRange.to_port = range.to_port;
          }

          if (range.protocol) {
            updateRange.protocol = range.protocol;
          }

          if (range.process_name) {
            updateRange.process_name = range.process_name;
          }

          if (range.service_name) {
            updateRange.service_name = range.service_name;
          }

          if (GeneralUtils.isNumberOrString(range.icmp_type)) {
            updateRange.icmp_type = range.icmp_type;
          }

          if (GeneralUtils.isNumberOrString(range.icmp_code)) {
            updateRange.icmp_code = range.icmp_code;
          }

          return updateRange;
        });
    }

    this.props.onSubmit(model);
  },

  hasChanged() {
    return this.areModelsDifferent(this.state.initialModel, this.state.model);
  },

  handleHelp(field) {
    const showHelpModel = {
      ...this.state.showHelpModel,
      [field.label]: !this.state.showHelpModel[field.label],
    };

    this.setState({showHelpModel});
  },

  render() {
    return (
      <Form
        type={this.props.type}
        fields={this.formFields}
        model={this.state.model}
        showHelpModel={this.state.showHelpModel}
        errorModel={this.state.errorModel}
        onChange={this.handleChange}
        onErrorChange={this.handleErrorChange}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
        onHelp={this.handleHelp}
      />
    );
  },
});
