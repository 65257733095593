/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import intl from 'intl';
import Icon from './Icon.jsx';
import RenderUtils from '../utils/RenderUtils';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import cx from 'classnames';

Vulnerability.propTypes = {
  // This can be a single vulnerability, or an aggregated vulnerability
  vulnerability: PropTypes.object,
  before: PropTypes.bool,
};

export default function Vulnerability(props) {
  const maxSeverity = props.vulnerability.hasOwnProperty('severity')
    ? props.vulnerability.severity
    : props.vulnerability.maxSeverity;
  const severity = RenderUtils.getVulnerabilityForRender(maxSeverity, 'severity');
  const severityValue = RenderUtils.getVulnerabilityForRender(maxSeverity, 'key');
  const severityFilter = TrafficFilterStore.getAll().vulnerabilitySeverity;
  const ves = props.vulnerability.vulnerabilityExposureScore;
  let vulnerabilityExposureScore = isNaN(ves)
    ? ves
    : RenderUtils.roundNumber(props.vulnerability.vulnerabilityExposureScore);
  const wideExposure =
    props.vulnerability.wideExposure &&
    (props.vulnerability.wideExposure === true ||
      Object.values(props.vulnerability.wideExposure).includes(true) ||
      props.vulnerability.wideExposure.internet ||
      props.vulnerability.wideExposure.ip_list);
  const classes = cx('Vulnerability', {
    'Vulnerability-LowOpacity': props.opacity && severityFilter > severityValue,
    'Vulnerability-Before': props.before,
  });

  if (vulnerabilityExposureScore === intl('Workloads.Status.Syncing')) {
    vulnerabilityExposureScore = (
      <span>
        <Icon size="large" name="syncing" />
      </span>
    );
  }

  return (
    <span className={classes}>
      <span
        className={`Vulnerability-Pill Vulnerability--${severity}${wideExposure ? ' Vulnerability-Wide' : ''}`}
        data-tid={`comp-vulnerability-${severity}`}
      >
        {vulnerabilityExposureScore}
      </span>
      {wideExposure ? (
        <div className="Vulnerability-wide-exposure">
          <Icon size="xlarge" name="internet" />
        </div>
      ) : null}
    </span>
  );
}
