/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import intl from 'intl';
import {Icon} from '..';
import SessionStore from '../../stores/SessionStore';

HelpCards.propTypes = {
  data: PropTypes.array.isRequired,
};

//This component maps data from HelpPopup into a list of HelpCards
//Each card contains a title, subtitle and a link to the support portal
export default function HelpCards(props) {
  let supportPortalURLBase;

  //If Version number is => 20 (Releases starting from 20.1)
  if (__ANTMAN__ || /[2-9]\d|\d\d/g.test(SessionStore.getHelpVersion().split('.')[0]) || SessionStore.isEdge()) {
    supportPortalURLBase = `${SessionStore.getHelpLink()}/${SessionStore.getHelpVersion()}/Search%20Results.htm?q=`;
  } else {
    supportPortalURLBase = `${SessionStore.getSupportLink()}/documentation/${SessionStore.getHelpVersion()}/Default.htm#search-`;
  }

  const helpCards = props.data.map(dataRow => {
    const helpLink = dataRow.title !== intl('Help.Title.NoHelp') && (
      <div className="HelpCards-support-link" data-tid="help-card-link">
        <a
          target="_blank"
          href={
            SessionStore.isEdge()
              ? supportPortalURLBase + dataRow.title + '&f=Illumio%20Edge'
              : supportPortalURLBase + dataRow.title
          }
        >
          {intl('Help.Menu.ShowMore')}
        </a>
        <div className="HelpCards-support-link-icon">
          <Icon styleClass="HelpPopupLink" name="external-link" />
        </div>
      </div>
    );

    return (
      <div className="HelpCards-card" data-tid="help-card">
        <div className="HelpCards-title" data-tid="help-card-title">
          {dataRow.title}
        </div>
        <div className="HelpCards-subtitle" data-tid="help-card-subtitle">
          {dataRow.subtitle}
        </div>
        {helpLink}
      </div>
    );
  });

  return <div className="HelpCards">{helpCards}</div>;
}
