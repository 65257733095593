/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import _ from 'lodash';
import intl from 'intl';
import {State, Navigation} from 'react-router';
import {SessionStore, TrafficStore} from '../../stores';
import {TabTo} from '../../components';
import {GroupDataUtils} from '../../utils';

export default React.createClass({
  mixins: [State, Navigation],
  propTypes: {
    active: PropTypes.oneOf(['members', 'rules', 'policyGenerator', 'vulnerabilities', 'map', 'explorer']),
  },

  defaultProps: {
    active: 'workloads',
  },

  componentWillMount() {
    if (!SessionStore.isIlluminationApiEnabled()) {
      this.replaceWith('landing');
    }
  },

  handleAppMapSelect() {
    const {route, params} = this.props.mapRoute;

    this.transitionTo(route, params);
  },

  handlePolicyGeneratorSelect() {
    const session = sessionStorage.getItem('ruleBuilderValues');
    const route = session && JSON.parse(session).route;
    const params = this.getParams();
    let routeName = 'appGroupPolicyGenerator';

    if (route && route.path && params && (params.previd || params.id) === route.id) {
      let path = route.path.split('/');

      if (path.length > 5) {
        path = path.slice(-3);
        routeName = path.reduce((result, item) => {
          if (item.includes('intra')) {
            result += 'IntraScope';
          } else if (item.includes('extra')) {
            result += 'ExtraScope';
          } else if (item.includes('iplist')) {
            result += 'IpList';
          } else {
            result += _.startCase(item);
          }

          return result;
        }, 'appGroup');
      }
    }

    this.transitionTo(routeName, {id: params.previd || params.id});
  },

  render() {
    const {active} = this.props;
    const id = this.getParams().previd || this.getParams().id;
    const appGroup = TrafficStore.getAppGroupNode(id);
    const trafficCaps = Boolean(appGroup && appGroup.caps.rulesets.includes('read'));
    const memberTabTo = GroupDataUtils.calculateMemberTabTo(appGroup);

    return (
      <div className="NavMenu" data-tid="comp-navmenu">
        {trafficCaps && (
          <div
            className={`NavMenu-item ${active === 'map' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-map"
            onClick={this.handleAppMapSelect}
          >
            <div className="TabTo">{intl('Common.Map')}</div>
          </div>
        )}
        <div
          className={`NavMenu-item ${active === 'members' ? 'NavMenu-item--active' : ''}`}
          data-tid="comp-navmenuitem-members"
        >
          <TabTo to={memberTabTo} params={{id}}>
            {intl('Common.Members')}
          </TabTo>
        </div>
        {trafficCaps && (
          <div
            className={`NavMenu-item ${active === 'rules' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-rules"
          >
            <TabTo to="appGroupRules" params={{id}}>
              {intl('Common.Rules')}
            </TabTo>
          </div>
        )}
        {trafficCaps && (
          <div
            className={`NavMenu-item ${active === 'policyGenerator' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-policygenerator"
            onClick={this.handlePolicyGeneratorSelect}
          >
            <div className="TabTo">{intl('PolicyGenerator.PolicyGenerator')}</div>
          </div>
        )}
        {SessionStore.areVulnerabilitiesEnabled() ? (
          <div
            className={`NavMenu-item ${active === 'vulnerabilities' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-vulnerabilities"
          >
            <TabTo to="appGroupVulnerabilities" params={{id}}>
              {intl('Common.Vulnerabilities')}
            </TabTo>
          </div>
        ) : null}
        {trafficCaps && (
          <div
            className={`NavMenu-item ${active === 'explorer' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-explorer"
          >
            <TabTo to="appGroupExplorer" params={{id}}>
              {intl('Common.Explorer')}
            </TabTo>
          </div>
        )}
      </div>
    );
  },
});
