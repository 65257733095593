/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import Select from '../Select';
import {getPolicyIntlByState} from 'intl/dynamic';

const policyStates = () => [
  {
    value: 'visibility',
    data: {mode: 'visibility', log_traffic: false, visibility_level: 'flow_summary'},
  },
  {
    value: 'selective',
    data: {mode: 'selective', log_traffic: true, visibility_level: 'flow_summary'},
  },
  {
    value: 'enforced',
    // TBD Re-add Visibility Level here, but it's better to show nothing rather than the wrong thing
    //visibility: 'flow_summary',
    data: {mode: 'enforced', log_traffic: true, visibility_level: 'flow_summary'},
  },
];

function getPolicyState(value) {
  return _.find(policyStates(), state => state.value === value);
}

export default React.createClass({
  getInitialState() {
    return {
      selectedState: getPolicyState(this.props.data.value),
    };
  },

  handleChange(selected) {
    const state = getPolicyState(selected.value);

    this.props.onChange(state);
    // Need to set the selected state so that
    // flow visibility can update accordingly.
    this.setState({
      selectedState: state,
    });
  },

  render() {
    // The different policy states are:
    // building, testing, enforced, mixed, or none
    const options = _.map(policyStates(), state => ({
      label: getPolicyIntlByState(state.value),
      value: state.value,
    }));
    const selected = this.state.selectedState && this.state.selectedState.value;

    return (
      <div className="PolicyStateSelect">
        <Select options={options} selected={selected} onSelect={this.handleChange} />
      </div>
    );
  },
});
