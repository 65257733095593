/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import PortUtils from './PortUtils';
import ServiceUtils from './ServiceUtils';
import {isValidNumber} from './GeneralUtils';

export const PORT_RANGE = 'portRange';
export const PORT_PROTOCOL = 'portProtocol';
export const PORT_PROTOCOL_REGEX = /(^t|tc|tcp|u|ud|udp)$/gi;
export const PORT_PROTOCOL_RANGE_REGEX = /(^t|tc|tcp|u|ud|udp-t|udp)$/gi;

/** Make sure to use func.call() method when invoking setPortProtocols to set the drodpownValues prop for <ObjectSelector>
 * e.g. this.setDropdownValues
 * e.g. PortProtocolsUtils.setPortProtocol.call(this, facet, query);
 * @param key
 * @param query
 */
export function setPortProtocol(key, query) {
  if (['windows_service', 'windows_process', 'note'].includes(key)) {
    if (!query.length) {
      this.setDropdownValues(key, query, {
        matches: [],
        num_matches: 0,
      });

      return;
    }

    this.setDropdownValues(key, query, {
      matches: [
        {
          key,
          name: query,
          value: query,
          href: query,
        },
      ],
      num_matches: 1,
    });

    return;
  }

  if (key !== PORT_RANGE && !isValidNumber(query) && !query.includes(' ')) {
    // Do not allow users to select just a protocol like 'TCP' or 'UDP'
    this.setDropdownValues(key, query, {
      matches: [],
      num_matches: 0,
    });

    return;
  }

  const regexPortProtocol = ServiceUtils.lookupRegexPortProtocol(PORT_PROTOCOL);
  const regexPortRangeProtocol = ServiceUtils.lookupRegexPortProtocol('portRangeProtocol');
  const regexProtocol = ServiceUtils.lookupRegexPortProtocol('protocolWithoutICMP');
  let portProtocol = query.toUpperCase();

  if (key !== PORT_RANGE && regexProtocol.test(portProtocol)) {
    portProtocol = portProtocol.includes('T') ? intl('Protocol.TCP') : intl('Protocol.UDP');

    this.setDropdownValues(key, query, {
      matches: [
        {
          key,
          name: portProtocol, // need for ServiceSelect::customListItem()
          value: portProtocol,
          href: portProtocol,
        },
      ],
      num_matches: 1,
    });

    return;
  }

  if ((key === PORT_PROTOCOL && validatePortProtocolValue(query)) || (key === PORT_RANGE && validatePortRange(query))) {
    const portProtoList = portProtocol.split(' ');

    if (
      (key === PORT_PROTOCOL && regexPortProtocol.test(portProtocol)) ||
      (key === PORT_RANGE && regexPortRangeProtocol.test(portProtocol))
    ) {
      const protocolValue = portProtoList[1].includes('T') ? intl('Protocol.TCP') : intl('Protocol.UDP');

      this.setDropdownValues(key, query, {
        matches: [
          {
            key,
            value: `${portProtoList[0]} ${protocolValue}`,
            href: `${portProtoList[0]} ${protocolValue}`,
            name: `${portProtoList[0]} ${protocolValue}`, // need for ServiceSelect::customListItem()
          },
        ],
        num_matches: 1,
      });

      return;
    }

    if ((portProtoList[1] && portProtoList[1].length <= 3) || !portProtoList[1]) {
      query = query.toUpperCase();

      const blank = ' ';

      if (key === 'portProtocol') {
        query = query.replace(/\s+.*/g, blank).replace(PORT_PROTOCOL_REGEX, blank);
      } else if (key === 'portRange') {
        query = query.replace(/\s+.*/g, blank).replace(PORT_PROTOCOL_RANGE_REGEX, blank);
      }

      portProtocol = portProtocol.replace(/\s/g, '').trim();

      this.setDropdownValues(key, query, {
        matches: portProtocol
          ? [
              {
                key,
                value: `${portProtocol} ${intl('Protocol.TCP')}`,
                href: `${portProtocol} ${intl('Protocol.TCP')}`,
                name: `${portProtocol} ${intl('Protocol.TCP')}`,
              },
              {
                key,
                value: `${portProtocol} ${intl('Protocol.UDP')}`,
                href: `${portProtocol} ${intl('Protocol.UDP')}`,
                name: `${portProtocol} ${intl('Protocol.UDP')}`, // need for ServiceSelect::customListItem()
              },
            ]
          : [],
        num_matches: portProtocol ? 2 : 0,
      });
    }
  } else {
    this.setDropdownValues(key, query, {
      matches: [],
      num_matches: 0,
    });
  }
}

export function validatePortProtocolValue(service) {
  const regexPort = ServiceUtils.lookupRegexPortProtocol('port');
  const regexProtocol = ServiceUtils.lookupRegexPortProtocol('protocol');
  let isPortValid = false;
  let numberSection;

  if (service.includes(' ')) {
    numberSection = service.slice(0, service.indexOf(' '));
  } else if (!isNaN(Number(service))) {
    numberSection = service;
  }

  if (!isNaN(Number(numberSection))) {
    numberSection = numberSection.trim();
    isPortValid = PortUtils.isValidPort(Number(numberSection));
  }

  let isValidService = false;

  // begining to end with number and one or zero spaces
  // '10 ' or '10'
  if (/^\d+\s?$/.test(service)) {
    isValidService = true;
  } else if (/^\d+\s(t|tc|tcp|u|ud|udp)$/gi.test(service)) {
    // beginning of one or more digit with a space following by TCP or UDP at the end of the string
    //e.g. '10 TCP' or 'TCP'
    isValidService = true;
  }

  if (isValidService && (regexProtocol.test(service) || (regexPort.test(numberSection) && isPortValid))) {
    return true;
  }

  return false;
}

export function validatePortRange(service) {
  const regexPortRange = ServiceUtils.lookupRegexPortProtocol('portRange');
  const numberSection = service.includes(' ') ? service.split(' ')[0] : service;
  const regexProtocol = ServiceUtils.lookupRegexPortProtocol('protocol');

  let isValidPortRange = false;

  //This section takes the portRange in the format 90-100 and validates the range to be in the format (N-N+1)
  //portRange: /(^[0-9]+)[-]([0-9]+)$/, numberSection: 90-100
  numberSection.replace(regexPortRange, match => {
    match = match.split('-');

    const lowerPortRange = match[0];
    const upperPortRange = match[1];

    if (lowerPortRange && upperPortRange) {
      const lowerPortRangeNum = Number(lowerPortRange.trim());
      const upperPortRangeNum = Number(upperPortRange.trim());

      // e.g. numberSection: 80-100
      // lowerPortRangeNum = 80, upperPortRangeNum = 100
      // Make sure port is valid port within a range of number 0 - 65535 and upperPortRangeNum > lowerPortRangeNum
      if (
        PortUtils.isValidPort(lowerPortRangeNum) &&
        PortUtils.isValidPort(upperPortRangeNum) &&
        upperPortRangeNum > lowerPortRangeNum
      ) {
        isValidPortRange = true;
      }
    }
  });

  if (isValidPortRange && !regexProtocol.test(service)) {
    return true;
  }

  return false;
}

export function getPortProtocol(portProtocols) {
  // portProtocols = '21 TCP' | '22 UDP'
  const regex = /^(\d+)\s+(tcp|udp)$/i;

  const portProtocolCombo = portProtocols.reduce((data, currentValue) => {
    if (!currentValue) {
      return data;
    }

    const portProtocolInfo = {};

    // e.g. portProtocols = '21 TCP'
    // p1 = 21, p2 = TCP
    currentValue.name.replace(regex, (match, p1, p2) => {
      // e.g p1 = 21
      // portProtocolInfo.port = 21
      if (p1) {
        // important to convert to Number for backend API
        portProtocolInfo.port = Number(p1);
      }

      if (p2) {
        // e.g. p2 = TCP
        // portProtocolInfo.proto = 6
        // // important to convert to Number for backend API
        portProtocolInfo.proto = Number(ServiceUtils.reverseLookupProtocol(p2));
      }
    });

    data.push(portProtocolInfo);

    return data;
  }, []);

  return portProtocolCombo;
}

export function getPortRange(portRange) {
  // portRange = '21-100 TCP' | '22-90 UDP'
  const regex = /^(\d+)-(\d+)\s*(tcp|udp)$/i;

  const portRangeCombo = portRange.reduce((data, currentValue) => {
    if (!currentValue) {
      return data;
    }

    const portRangeInfo = {};

    // e.g. portRange = '21-100 TCP'
    // p1 = 21, p2 = TCP
    currentValue.name.replace(regex, (match, p1, p2, p3) => {
      // e.g. p1 = 21
      // portPortRange.to_port = 21
      if (p1) {
        // important to convert to Number for backend API
        portRangeInfo.port = Number(p1);
      }

      // e.g. p2 = 100
      // portPortRange.to_port = 100
      if (p2) {
        // important to convert to Number for backend API
        portRangeInfo.to_port = Number(p2);
      }

      // e.g. p3 = TCP
      // portRangeInfo.proto = 6
      if (p3) {
        // important to convert to Number for backend API
        portRangeInfo.proto = Number(ServiceUtils.reverseLookupProtocol(p3));
      }
    });

    data.push(portRangeInfo);

    return data;
  }, []);

  return portRangeCombo;
}

/** Set the object into a facet format for <ServiceSelect>
 * @param facet
 * @param key
 */
export function setProperPortProtocolFacetItems(facet, key) {
  let text;

  // set the facet items
  if (facet.to_port) {
    text = `${facet.port}-${facet.to_port} ${ServiceUtils.lookupProtocol(facet.proto)}`;
  } else {
    text = `${facet.port ? facet.port : ''} ${ServiceUtils.lookupProtocol(facet.proto)}`;
  }

  return {name: text, href: text, value: text, key};
}

export default {
  validatePortRange,
  setPortProtocol,
  validatePortProtocolValue,
  getPortProtocol,
  getPortRange,
  setProperPortProtocolFacetItems,
  PORT_RANGE,
  PORT_PROTOCOL,
};
