/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import actionCreators from '../actions/actionCreators';
import ConfirmationDialog from '../components/ConfirmationDialog.jsx';

export default {
  statics: {
    willTransitionFrom(transition, component) {
      if (!component.ignoreChanges && component.hasChanged()) {
        transition.redirect(component.getRoutes()[1].name, component.getParams(), component.getQuery());

        if (!component.confirming) {
          component.confirming = true;
          component.onConfirmTransition(
            () => {
              component.ignoreChanges = true;
              transition.retry();
            },
            () => {
              component.confirming = false;
            },
          );
        }
      }
    },
  },

  onConfirmTransition(confirmCallback, cancelCallback) {
    actionCreators.openDialog(
      <ConfirmationDialog
        message={intl('Common.UnsavedPendingWarning')}
        onConfirm={_.isFunction(confirmCallback) ? confirmCallback : null}
        onCancel={_.isFunction(cancelCallback) ? cancelCallback : null}
      />,
    );
  },
};
