/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {Navigation, State} from 'react-router';
import {MapPageStore} from '../../stores';
import {Icon, ObjectSelector, Tooltip, Vulnerability} from '..';

const facetMap = {
  [intl('Common.Group')]: 'group',
};

export default React.createClass({
  mixins: [Navigation, State],

  getInitialState() {
    return {items: {}};
  },

  componentDidMount() {
    if (this.input) {
      // Autofocus does not auto-open the dropdown, so force a click
      this.input.refs.itemInput.click();
    }
  },

  componentDidUpdate(prevProps) {
    if (
      (prevProps.data.form.type !== this.props.data.form.type && this.input) ||
      (this.input && !this.input.state.showDropdown)
    ) {
      // This click needs to happen a little later to auto open the updated objectselector
      _.defer(() => this.input.refs.itemInput.click());
    }
  },

  handleMouseLeave() {
    if (this.state.unhoverCluster) {
      actionCreators.unhoverComponent([
        {
          type: 'group',
          href: this.state.unhoverCluster,
        },
      ]);
      this.setState({unhoverCluster: null});
    }
  },

  handleMouseOver(item) {
    if (MapPageStore.getMapType() !== 'app') {
      const href = this.props.data.form.singleValues[item.value].href;

      actionCreators.hoverComponent([
        {
          type: 'group',
          href,
        },
      ]);
      this.setState({unhoverCluster: href});
    }
  },

  gotoGroup(group) {
    const {type} = this.props.data.form;

    switch (type) {
      case 'findGroup':
        const level = {
          type: 'group',
          id: group.href,
        };

        actionCreators.selectComponent([
          {
            type: 'group',
            href: group.href,
            clusterId: group.clusterId,
          },
        ]);

        this.props.updateMapLevel(level);
        break;
      case 'findConnectedGroup':
        actionCreators.startSpinner();
        actionCreators.updateGraphCalculated();

        const focusedCluster = this.getParams().id;
        const expandedCluster = group.href;
        const data = [focusedCluster, expandedCluster];

        actionCreators.expandCluster(data);
        break;
      case 'findConsumingAppGroup':
      case 'findProvidingAppGroup':
        const mapRoute = MapPageStore.getMapRoute();
        const newMapLevel = {
          prevtype: 'focused',
          previd: mapRoute.previd || mapRoute.id,
          type: type.includes('Consuming') ? 'consuming' : 'providing',
          id: group.href,
        };

        actionCreators.selectComponent([
          {
            type: 'appGroup',
            href: group.href,
          },
        ]);
        this.props.updateMapLevel(newMapLevel);
        break;
    }
  },

  handleClose() {
    actionCreators.closeActionItem();
  },

  addItem(filter, value) {
    this.setState({items: {[filter]: value}});
    this.gotoGroup(value);
  },

  removeItem() {
    if (this.props.data.form.type === 'findConnectedGroup') {
      actionCreators.startSpinner();
      actionCreators.updateGraphCalculated();
      actionCreators.removeExpandedCluster();
    }

    this.setState({items: {}});
  },

  customListItem({props, item}) {
    if (!item) {
      return;
    }

    const group = this.props.data.form.singleValues[item];
    const vulnerabilityMap = MapPageStore.getAppMapVersion() === 'vulnerability';
    let vulnerability = null;

    if (vulnerabilityMap && group && group.vulnerability) {
      vulnerability = (
        <span className="FormPanel-FindGroup-Vulnerability">
          <Vulnerability vulnerability={group.vulnerability} />
        </span>
      );
    }

    if (group.truncatedName?.includes('...')) {
      return (
        <span title={props.name}>
          <li {...props}>
            {vulnerability}
            {group.truncatedName}
          </li>
        </span>
      );
    }

    return (
      <li {...props}>
        {vulnerability}
        {group.truncatedName}
      </li>
    );
  },

  render() {
    const {addItem, removeItem, customListItem, handleMouseOver, handleMouseLeave, handleClose} = this;
    const {info, form} = this.props.data;
    const {items} = this.state;

    const type = form.type;
    const findGroupLength = info.locationGroups && info.locationGroups.length;
    const findConnectedGroupLength = !_.isEmpty(info.connectedClusters);
    const selectedItem = items[Object.keys(items)[0]];

    let placeHolder;
    let title;

    switch (type) {
      case 'findGroup':
        placeHolder = intl('Map.SearchGroups');
        title = intl('Common.Group');
        break;
      case 'findConnectedGroup':
        placeHolder = intl('Map.SearchConnectedGroups');
        title = intl('Map.ConnectedGroup');
        break;
      case 'findConsumingAppGroup':
        placeHolder = intl('Map.SearchConsumingAppGroups');
        title = intl('Map.ConsumingAppGroups');
        break;
      case 'findProvidingAppGroup':
        placeHolder = intl('Map.SearchProvidingAppGroups');
        title = intl('Map.ProvidingAppGroups');
        break;
    }

    let groupSearch = null;

    if (
      (type === 'findGroup' && findGroupLength) ||
      (type === 'findConnectedGroup' && findConnectedGroupLength) ||
      _.size(form.singleValues)
    ) {
      const props = {
        items,
        addItem,
        removeItem,
        facetMap,
        customListItem,
        dropdownValues: {},
        initialValues: [],
        allowOne: true,
        ref: node => (this.input = node),
        autoFocus: true,
        onMouseOver: handleMouseOver,
        singleValues: form.singleValues,
        placeholder: _.isEmpty(items) ? placeHolder : '',
        getFacetValues: _.noop,
        returnValue: _.noop,
        alwaysOpen: true,
        maxResults: _.size(form.singleValues),
      };

      groupSearch = (
        <span onMouseLeave={handleMouseLeave}>
          <ObjectSelector {...props} />
        </span>
      );

      if (selectedItem && selectedItem.name.length > 40) {
        groupSearch = (
          <Tooltip content={selectedItem.name} width={400} position="formpanel" location="bottomright">
            {groupSearch}
          </Tooltip>
        );
      }
    }

    const policyVersion = MapPageStore.getPolicyVersion();
    const classes = cx('FormPanel-Title', {
      'FormPanel-Reported': policyVersion !== 'draft',
      'FormPanel-Draft': policyVersion === 'draft',
    });

    return (
      <div className="FormPanel">
        <div className={classes}>
          <Icon name="caret-left" size="xlarge" onClick={handleClose} data-tid="comp-icon-close" />
          <span data-tid="form-panel-title">{title}</span>
        </div>
        <div className="FormPanel-Search">
          <div className="FormPanel-Search-Icon">
            <Icon name="search" size="large" data-tid="comp-icon-search" />
          </div>
          {groupSearch}
        </div>
      </div>
    );
  },
});
