/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import cx from 'classnames';
import Schema from 'api/schema';
import {Button, Dialog, Grid, Notification, Spinner} from '../../components';
import intl from 'intl';
import {getId} from '../../utils/GeneralUtils';
import RestApiUtils from '../../utils/RestApiUtils';
import {QUERY_STATUS} from '../../utils/Explorer/ExplorerUtils';
import UserStore from '../../stores/UserStore';

const INDICATOR_ACTION_DELETE = '-actionDelete';
const INDICATOR_ACTION_CANCEL = '-actionCancel';

/**
 * Returns true if the query entry is completed and can be clicked
 *
 * @param {object} row The data of the row
 */
const isRowClickable = row => row.status === QUERY_STATUS.COMPLETED;

const AsyncQueryResultsDialog = React.createClass({
  getInitialState() {
    return {
      error: null,
      sorting: [
        {
          key: 'createdAtDate',
          direction: true,
        },
      ],
    };
  },

  componentDidMount() {
    // Make body unscrolable when component is shown
    document.body.style.overflow = 'hidden';
  },

  componentWillUnmount() {
    // Make body scrollable again on component close
    document.body.style.overflow = 'unset';
  },

  async handleOnDeleteActionClicked(event, data) {
    event.stopPropagation();

    const uuid = getId(data.href);

    this.setState({
      error: null,
    });

    this.props.indicatorAdd(data.href, INDICATOR_ACTION_DELETE);

    try {
      await RestApiUtils.trafficFlows.async.delete(uuid);
      await RestApiUtils.trafficFlows.async.getCollection();
    } catch (error) {
      this.setState({error: error.message});
    } finally {
      this.props.indicatorDelete(data.href, INDICATOR_ACTION_DELETE);
    }
  },

  async handleOnCancelActionClicked(event, data) {
    event.stopPropagation();

    const uuid = getId(data.href);

    this.setState({
      error: null,
    });

    this.props.indicatorAdd(data.href, INDICATOR_ACTION_CANCEL);

    // Currently, only queued queries can be canceled. Calling cancel on a working query
    // will result in a 405 error that we will temporarily ignore till BE is updated
    try {
      await RestApiUtils.trafficFlows.async.cancel(uuid);
      await RestApiUtils.trafficFlows.async.getCollection();
    } catch (error) {
      this.setState({error: error.message});
    } finally {
      this.props.indicatorDelete(data.href, INDICATOR_ACTION_CANCEL);
    }
  },

  handleOnExportActionClicked(event) {
    event.stopPropagation();
  },

  getActionLoadingIndicator() {
    return <Spinner color="dark" size="large" position="center" />;
  },

  /**
   * Returns the appropriate action buttons for each status
   *
   * @param {string} status The status of the result
   * @param {Object} data The data of the result
   */
  getActionsForStatus(status, data, indicatorCheck) {
    let actionsToReturn;

    switch (status) {
      case QUERY_STATUS.QUEUED:
      case QUERY_STATUS.WORKING:
        actionsToReturn = indicatorCheck(data.href, INDICATOR_ACTION_CANCEL) ? (
          this.getActionLoadingIndicator()
        ) : (
          <Button
            text={intl('Common.Cancel')}
            customClass="gridActionButton"
            type="secondary"
            onClick={event => this.handleOnCancelActionClicked(event, data)}
          />
        );
        break;

      case QUERY_STATUS.FAILED:
        actionsToReturn = indicatorCheck(data.href, INDICATOR_ACTION_DELETE) ? (
          this.getActionLoadingIndicator()
        ) : (
          <Button
            text={intl('Common.Delete')}
            customClass="gridActionButton"
            type="secondary"
            onClick={event => this.handleOnDeleteActionClicked(event, data)}
          />
        );
        break;
      case QUERY_STATUS.COMPLETED:
        actionsToReturn = (
          <div>
            {data?.flows_count === 0 ? (
              <Button text={intl('Common.Export')} customClass="gridActionButton" type="secondary" disabled />
            ) : (
              <a href={Schema.api_prefix + data.result}>
                <Button
                  text={intl('Common.Export')}
                  customClass="gridActionButton"
                  type="secondary"
                  disabled={data?.flows_count === 0}
                  onClick={this.handleOnExportActionClicked}
                />
              </a>
            )}

            {indicatorCheck(data.href, INDICATOR_ACTION_DELETE) ? (
              this.getActionLoadingIndicator()
            ) : (
              <Button
                text={intl('Common.Delete')}
                customClass="gridActionButton"
                type="secondary"
                onClick={event => this.handleOnDeleteActionClicked(event, data)}
              />
            )}
          </div>
        );
        break;

      default:
        actionsToReturn = <span />;
        break;
    }

    return actionsToReturn;
  },

  renderFlowColumn(value, rowData, explorerMaxResults) {
    const maxReached = rowData.matches_count > rowData.flows_count;
    const maxDisplayedReached = rowData.flows_count > explorerMaxResults;

    if (!Number.isInteger(value)) {
      return;
    }

    return (
      <div>
        {intl.num(value)}
        {maxReached && '*'}
        {maxDisplayedReached && (
          <div className="asyncQ-flow-subrow">
            {intl('Explorer.ConnectionsDisplayed', {connections: explorerMaxResults})}
          </div>
        )}
      </div>
    );
  },

  // Defines the columns to be used for the dialog
  getAsyncQueriesDataColumns(explorerMaxResults) {
    return [
      {
        key: 'name',
        label: intl('Common.Name'),
        format: (value, rowData) => (
          <div
            className={cx({
              'asyncQ-name': true,
              'asyncQ-name-active': rowData.status === QUERY_STATUS.COMPLETED,
            })}
          >
            {value}
          </div>
        ),
        sortable: true,
      },

      {
        key: 'flows_count',
        label: intl('Common.Connections'),
        format: (value, rowData) => this.renderFlowColumn(value, rowData, explorerMaxResults),
        style: 'asyncQ-flow',
      },
      {
        key: 'createdAtDate',
        label: intl('Explorer.RunAt'),
        format: value => intl.date(value, 'L_HH_mm'),
        style: 'asyncQ-created_at',
        sortable: true,
      },
      {
        key: 'status',
        label: intl('Common.Status'),
        format: value => _.capitalize(value.replaceAll('cancel_requested', intl('Common.CancelRequested'))),
        style: 'asyncQ-status',
      },
      {
        key: 'actions',
        label: intl('Common.Actions'),
        format: (value, rowData) => this.getActionsForStatus(rowData.status, rowData, this.props.indicatorCheck),
        style: 'asyncQ-actions',
      },
    ];
  },

  async handleOnRowClicked(data) {
    const uuid = getId(data.href);

    this.props.onLoadQuery();
    this.props.onClose();

    await RestApiUtils.trafficFlows.async.getResults(uuid);
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    this.setState({sorting});
  },

  render() {
    const {data, onClose, handleShowResultsSettingsClick} = this.props;
    const {error, sorting} = this.state;

    // Data with status killed already filtered out, transform date and order by date
    const parsedData = data
      .map(entry => ({...entry, createdAtDate: new Date(entry.created_at)}))
      .sort((a, b) => b.createdAtDate - a.createdAtDate);

    // Only display the label if it's true for at least one item
    const showNumOfMatchExceedsConfMax = parsedData.some(item => item.matches_count > item.flows_count);

    // Defines the modal actions
    const dialogActions = [
      {
        text: intl('Common.Close'),
        type: 'secondary',
        tid: 'close',
        ref: 'confirmButton',
        onClick: onClose,
      },
    ];

    return (
      <Dialog
        type="detail"
        title={intl('Explorer.Results')}
        className="AsyncQueryResultsDialog"
        actions={dialogActions}
        onClose={onClose}
      >
        <div className="ScrollableList">
          <Grid
            data={parsedData}
            onRowClick={this.handleOnRowClicked}
            rowClickable={isRowClickable}
            columns={this.getAsyncQueriesDataColumns(UserStore.getExplorerMaxResults())}
            sortable
            onSort={this.handleSort}
            sorting={sorting}
          />
        </div>

        {showNumOfMatchExceedsConfMax && (
          <div className="NumOfMatchExceedsConfMax">
            {intl('Explorer.NumOfMatchingFlowsExceedsConfMax')}
            <Button
              text={intl('Explorer.EditInParenthesis')}
              tid="asyncquery-edit-max-results"
              type="linky"
              icon={null}
              onClick={handleShowResultsSettingsClick}
            />
          </div>
        )}
        {error && <Notification type="error" title={intl('Common.Error')} message={<span>{error}</span>} />}
      </Dialog>
    );
  },
});

export default AsyncQueryResultsDialog;

AsyncQueryResultsDialog.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      flows_count: PropTypes.number,
      status: PropTypes.oneOf([
        QUERY_STATUS.WORKING,
        QUERY_STATUS.QUEUED,
        QUERY_STATUS.KILLED,
        QUERY_STATUS.COMPLETED,
        QUERY_STATUS.CANCEL_REQUESTED,
      ]).isRequired,
      query_parameters: PropTypes.shape({
        query_name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  indicatorAdd: PropTypes.func.isRequired,
  indicatorDelete: PropTypes.func.isRequired,
  indicatorCheck: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLoadQuery: PropTypes.func.isRequired,
  handleShowResultsSettingsClick: PropTypes.func.isRequired,
};

AsyncQueryResultsDialog.defaultProps = {
  data: [],
};
