/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';

const filters = JSON.parse(sessionStorage.getItem('filters'));
const selectorFilters = filters ? filters.selectorFilters : {};
let scopeFilters = filters ? filters.scopeFilters : [];

function addSelectorFilter(page, filter, value) {
  selectorFilters[page] = selectorFilters[page] || {};
  selectorFilters[page][filter] = value;
}

function removeSelectorFilter(page, filter) {
  if (selectorFilters[page]) {
    delete selectorFilters[page][filter];
  }
}

function addScopeFilter(filter, automatic) {
  if (automatic) {
    filter.forEach(fltr => {
      // automatic means filter is an array of labels and not an individual label
      scopeFilters.push({...fltr, automatic: true});
    });
  } else {
    scopeFilters.push(filter);
  }
}

function removeScopeFilter(filter) {
  scopeFilters = scopeFilters.filter(label => label.href !== filter.href);
}

function removeAutomaticScopeFilters() {
  scopeFilters = scopeFilters.filter(label => !label.automatic);
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.ADD_FILTERS:
        addScopeFilter(action.data, action.automatic);
        break;

      case Constants.ADD_API_FILTER:
        addSelectorFilter(action.data.page, action.data.filter, action.data.value);
        break;

      case Constants.REMOVE_FILTER:
        removeScopeFilter(action.data);
        break;

      case Constants.REMOVE_API_FILTER:
        removeSelectorFilter(action.data.page, action.data.filter);
        break;

      case Constants.REMOVE_AUTOMATIC_FILTERS:
        removeAutomaticScopeFilters();
        break;

      default:
        return true;
    }

    sessionStorage.setItem('filters', JSON.stringify({scopeFilters, selectorFilters}));
    this.emitChange();

    return true;
  },

  getSelectorFilters(page) {
    return selectorFilters[page] || {};
  },

  getScopeFilters() {
    return scopeFilters.filter(label => !label.partial);
  },

  getScopeFiltersNoRole() {
    return scopeFilters.filter(label => label.key !== 'role' && !label.partial && !label.automatic);
  },

  getAllScopeFiltersNoRole() {
    return scopeFilters.filter(label => label.key !== 'role');
  },

  getAllScopeFilters() {
    return scopeFilters;
  },
});
