/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getHrefParams} from 'api/apiUtils';
import intl from 'intl';
import JSONBig from 'json-bigint-keep-object-prototype-methods';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

/** href is the main field that is needed for parsing */
type HrefsArray = {href: string}[];

/** draft and active are type 'draft' and 'active' not a string */
interface HrefObjects {
  draft?: HrefsArray;
  active?: HrefsArray;
}

/** Nominal Types */
export type ContainerCluster = string & {_brand: 'container_clusters'};
export type ServiceAccount = string & {_brand: 'service_account'};
export type ActiveSecPolicyHref = string & {_brand: 'active_sec_policy'};

/** Use _brand for Nominal type checking */
/** example: /orgs/2/workloads/28201543-feb7-41e6-898e-36f58a147c3a
 *           /users/173
 * */
export type Href = string & {_brand: 'href'};

/** example: 28201543-feb7-41e6-898e-36f58a147c3a */
export type HrefId = string & {_brand: 'href_id'};

/** example: 173 **/
export type HrefIdNumber = (number | bigint) & {_brand: 'href_id_number'};

export const isProperHref = (href?: string): href is Href =>
  href !== undefined && href.length > 1 && href.startsWith('/');

const orgIdRegex = /\/orgs\/(\d+)/;
export const getOrgIdFromHref = (href: string): string | undefined => orgIdRegex.exec(href)?.[1];

/**
 * Takes id from last segment of href
 * If string is empty or last segment is empty, return undefined so default parameter usage on caller side is possible
 * https://jsperf.com/last-string-segment
 *
 * e.g. Usage
 *
 *  https://stackoverflow.com/questions/40081332/what-does-the-is-keyword-do-in-typescript
 *
 * function fetchId = (id: HrefId): void => {
 *   console.log('id', id);
 * };
 *
 * const id = getId('/users/173');
 * if (id) {  // A type guard check to ensure id exist then make a call to fetchId(id)
 *   fetchId(id);
 * }
 */
export function getId(href: Href): HrefId;
export function getId(href?: string): HrefId | undefined;
export function getId(href?: string | Href): HrefId | undefined {
  if (isProperHref(href)) {
    return href.substring(href.lastIndexOf('/') + 1) as HrefId;
  }
}

//Regex to test if value is number and not string
const regexId = /\d+/;

export const getIdNumber = (href?: string | Href): HrefIdNumber | undefined => {
  const id = getId(href);

  // return number of bigInt. (detect string if it is number of BigInt)
  // if Number return number, if BigInt return bigInt
  if (id && regexId.test(id)) {
    return JSONBigIntNative.parse(id) as HrefIdNumber;
  }
};

/* Used for sorting hrefs, which may contain number id,
   but we sort as strings to avoid converting ids bigger that MAX_SAFE_INTEGER to numbers*/
// Enable language sensitive string comparison. Using Intl.Collator compare method to ensure
// the strings are sorted according to the sort order of the specified locale.
export const collator = new Intl.Collator(intl.locale, {
  usage: 'sort',
  sensitivity: 'base',
  numeric: true,
  ignorePunctuation: false,
});

/** Move this method to Security Policy */
export const compareActiveHref = (activeHref: string, versionHref: string): activeHref is ActiveSecPolicyHref =>
  versionHref.replace(/\/sec_policy\/\d+/, '/sec_policy/active') ===
  activeHref.replace(/\/sec_policy\/\d+/, '/sec_policy/active');

// Takes a list of hrefs [A, B, C] and wraps them in object with href key [{href: A}, {href: B}, {href: C}] for API usage
export const wrapHrefs = (hrefs: {href: string}[]): {href: {href: string}}[] =>
  hrefs.map(singleHref => ({href: singleHref}));

export const findObjectByHref = (
  href: string,
  objects: HrefObjects,
  ignoreVersion: boolean,
): HrefsArray | undefined => {
  if (!href || !isProperHref(href) || _.isEmpty(objects)) {
    return;
  }

  /**
   * TypeScript is unaware of the return object from getHrefParams since it is right now Object,
   * thus implicitly telling TypeScript we
   * know that object by using Type Assertion {pversion: 'draft' | 'active'} , don't need TypeScript to infer.
   * 'draft' and 'active' field on HrefObjects is type: 'draft' or type: 'active' and not type: string which can
   * be confusing.
   **/
  const {pversion} = getHrefParams(href) as {pversion: 'draft' | 'active'};

  // handle unversioned objects too
  const source = !ignoreVersion && pversion !== undefined ? objects[pversion] : objects;

  return _.find(source, (o: {href: string}) =>
    pversion === 'active' ? compareActiveHref(href, o.href) : o.href === href,
  );
};

/**
 * Whether the href is a container cluster
 * @param href A href string
 * @returns A boolean value
 */
export const isContainerClusterHref = (href?: string): href is ContainerCluster =>
  typeof href === 'string' && href.includes('/container_clusters/');

/**
 * Whether the href is a service account
 * @param href
 * @returns {boolean}
 */
export const isServiceAccountHref = (href?: string): href is ServiceAccount =>
  typeof href === 'string' && href.includes('/service_accounts/');

export const getChangePassword = (): string => '/users/password/change';
