/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import React from 'react';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  getInitialState() {
    return {
      disableLoad: true,
      badAttempt: false,
      badFileType: false,
    };
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          actionCreators.closeDialog();
          this.props.onCancel();
        },
      },
      {
        text: intl('SegmentationTemplates.Load'),
        tid: 'upload',
        autoFocus: true,
        onClick: () => {
          this.handleLoad();
        },
      },
    ];
  },

  handleLoad() {
    this.handleClearErrors();

    const files = document.querySelector('#selectFiles').files;

    if (files.length <= 0) {
      this.setState({badAttempt: true});

      return false;
    }

    if (_.last(files[0].name.split('.')).toString().toLowerCase() !== 'json') {
      this.setState({badFileType: true});

      return false;
    }

    const fr = new FileReader();

    fr.addEventListener('load', evt => {
      const result = JSON.parse(evt.target.result);

      actionCreators.segmentationTemplateParseData(result);
    });
    fr.readAsText(files.item(0));
    actionCreators.closeDialog();
  },

  handleClearErrors() {
    this.setState({badAttempt: false, badFileType: false});
  },

  render() {
    const title = intl('SegmentationTemplates.SegmentationTemplates');

    return (
      <Dialog
        ref="dialog"
        type="detail"
        title={title}
        actions={this.getActions()}
        className="SegmentationTemplateModal"
      >
        <div className="SegmentationTemplateModal-text" data-tid="templates-upload-text1">
          {intl('SegmentationTemplates.UploadText1')}{' '}
        </div>
        <div className="SegmentationTemplateModal-text" data-tid="templates-upload-text2">
          {intl('SegmentationTemplates.UploadText2')}{' '}
        </div>
        <div className="SegmentationTemplateModal-text">
          <input type="file" id="selectFiles" onClick={this.handleClearErrors} data-tid="templates-upload-input" />
        </div>
        {this.state.badAttempt && (
          <div className="SegmentationTemplateUpload-error" data-tid="templates-upload-attempt-error">
            {intl('SegmentationTemplates.BadAttempt')}{' '}
          </div>
        )}
        {this.state.badFileType && (
          <div className="SegmentationTemplateUpload-error" data-tid="templates-upload-type-error">
            {intl('SegmentationTemplates.BadFileType')}{' '}
          </div>
        )}
      </Dialog>
    );
  },
});
