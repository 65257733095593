/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import APIError from './APIError';
import type {APIErrorOptions} from './APIError';
import {hrefUtils} from 'utils';
import type {Href, HrefIdNumber} from 'utils/href';

export type Data = {
  message: string;
  token: string;
  ids: HrefIdNumber[];
}[];

interface ProcessedAPIErrorOptions extends APIErrorOptions<Data> {
  hrefs?: Href[];
}

type ErrorsMap = Map<string, string | {message: string; hrefs: Href[]}>;

export default class ProcessedAPIError extends APIError<Data> {
  errorsMap: ErrorsMap;
  totalErrors: number;
  hrefsLeftToRemove: Href[];
  errorsHrefs: Href[];

  constructor(params: ProcessedAPIErrorOptions = {}) {
    const {hrefs = [], ...apiErrorParams} = params;

    super(apiErrorParams);
    this.name = 'ProcessedAPIError';

    let totalErrors = 0;
    const errorsMap: ErrorsMap = new Map();
    let errorsHrefs: Href[] = [];

    this.data?.forEach(({message, token, ids}) => {
      if (ids) {
        const messageErrorHrefs = hrefs.filter(href => {
          const idNum = hrefUtils.getIdNumber(href);

          return idNum && ids.includes(idNum);
        });

        errorsHrefs = [...errorsHrefs, ...messageErrorHrefs];
        totalErrors += messageErrorHrefs.length;
        errorsMap.set(token, {message, hrefs: messageErrorHrefs});
      } else {
        errorsMap.set(token, message);
        totalErrors += hrefs.length;
        errorsHrefs = [...errorsHrefs, ...hrefs];
      }
    });

    this.errorsMap = errorsMap;
    this.totalErrors = totalErrors;
    this.hrefsLeftToRemove = _.difference(hrefs, errorsHrefs);
    this.errorsHrefs = errorsHrefs;
  }
}

ProcessedAPIError.prototype.name = 'ProcessedAPIError';
