/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {RulesetFacetStore, FilterStore} from '../stores';
import StoreMixin from '../mixins/StoreMixin';
import {RestApiUtils} from '../utils';
import actionCreators from '../actions/actionCreators';

function getStateFromStores() {
  return {
    items: FilterStore.getSelectorFilters('rulesetList'),
    dropdownValues: RulesetFacetStore.getDropdownValues(),
  };
}

export default React.createClass({
  propTypes: {
    facetMap: PropTypes.object.isRequired,
    provisionStatusValues: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  mixins: [StoreMixin([RulesetFacetStore, FilterStore], getStateFromStores)],

  addItem(filter, value) {
    actionCreators.addApiFilter({page: 'rulesetList', filter, value});
  },

  removeItem(filter) {
    actionCreators.removeApiFilter({page: 'rulesetList', filter});
  },

  render() {
    const props = {
      addItem: this.addItem,
      dropdownValues: this.state.dropdownValues,
      facetMap: this.props.facetMap,
      getFacetValues: (facet, query) => {
        if (!facet) {
          return;
        }

        RestApiUtils.ruleSets.facets({facet, query});
      },
      hasTitle: [intl('Common.Description')],
      initialValues: Object.keys(this.props.facetMap).concat([intl('Provision.Status')]),
      items: this.state.items,
      onChange: this.props.onChange,
      partialItems: [intl('Common.Name'), intl('Common.Description')],
      placeholder: intl('Common.FilterView'),
      removeItem: this.removeItem,
      returnValue: value => value,
      statics: {
        [intl('Provision.Status')]: Object.keys(this.props.provisionStatusValues),
      },
      staticsKeys: [intl('Provision.Status')],
    };

    return <ObjectSelector {...props} />;
  },
});
