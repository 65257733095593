/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import ServiceUtils from './ServiceUtils';
import {isValidNumber, isNumberOrString} from './GeneralUtils';

export const isValidPort = port => {
  if (_.isNil(port)) {
    return false;
  }

  return isValidNumber(port, 0, 65_535);
};

const isValidIcmpTypeCode = type => isValidNumber(type, 0, 255);

// 6 - tcp
// 17 - udp
export const getProtocolsWithPorts = () => [6, 17];

// ICMP should be a part if this,
// but it is not as ICMP has type/code, which follow some of the protocol validation logic
// 47 - gre
// 94 - ipip
// 2 - igmp
export const getProtocolsWithoutPorts = () => [47, 94, 2];

//  1- icmp
// 58 - icmpv6
export const getICMPProtocols = () => [1, 58];

export const getICMPAndPortlessProtocols = () => [...getProtocolsWithoutPorts(), ...getICMPProtocols()];

export const getProtocols = () => [...getProtocolsWithPorts(), ...getProtocolsWithoutPorts(), ...getICMPProtocols()];

export const validatePort = (port, protocol) => {
  if (_.isString(protocol)) {
    protocol = ServiceUtils.reverseLookupProtocol(protocol.toUpperCase());
  }

  if (getProtocolsWithoutPorts().includes(protocol) && port === null) {
    return null;
  }

  if (isValidPort(port)) {
    return null;
  }

  if (port) {
    return intl('Port.InvalidPortValue');
  }

  return intl('Common.ProtocolInvalid');
};

export const validateProtocol = protocol => {
  if (_.isString(protocol)) {
    protocol = ServiceUtils.reverseLookupProtocol(protocol.toUpperCase());
  }

  if (getProtocols().includes(protocol)) {
    return null;
  }

  return intl('Common.ProtocolInvalid');
};

export const parsePort = (port, protocol) => {
  let error;

  if (getProtocolsWithPorts().includes(ServiceUtils.reverseLookupProtocol(protocol)) && (!port || port === '-1')) {
    return {error: intl('Port.Missing')};
  }

  if (getProtocolsWithoutPorts().includes(ServiceUtils.reverseLookupProtocol(protocol)) && port !== null) {
    return {error: intl('Port.ProtocolInvalid')};
  }

  if (!protocol) {
    return {error: intl('Common.ProtocolMissing')};
  }

  if (!getICMPAndPortlessProtocols().includes(ServiceUtils.reverseLookupProtocol(protocol)) && port !== '-1') {
    error = validatePort(port, protocol);
  }

  error = error || validateProtocol(protocol);

  if (!port) {
    return {
      protocol,
      error,
    };
  }

  return {
    port: error ? port.trim() : Math.trunc(Number(port.trim())),
    protocol,
    error,
  };
};

export const parseRangeOfPorts = (port, toPort, protocol) => {
  if (!protocol) {
    return {error: intl('Common.ProtocolMissing')};
  }

  let error = validatePort(port, protocol) || validatePort(toPort, protocol);

  if (Math.trunc(Number(port)) >= Math.trunc(Number(toPort))) {
    error = error || intl('Port.InvalidRange');
  }

  error = error || validateProtocol(protocol);

  return {
    port: error ? port : Math.trunc(Number(port)),
    to_port: error ? toPort : Math.trunc(Number(toPort)),
    protocol,
    error,
  };
};

// Ideally this logic should be done in "parsePortAndProcessString" function, but is cleaner here
const parseIcmpString = text => {
  const parts = text.split(' ');

  // Just "icmp" or "icmpv6"
  if (parts.length === 1) {
    return {
      text: parts[0],
      protocol: ServiceUtils.reverseLookupProtocol(parts[0]),
    };
  }

  const typeCodeParts = parts[0].split('/');
  const value = {
    text,
    protocol: ServiceUtils.reverseLookupProtocol(parts[1]),
  };

  if (typeCodeParts.length > 2) {
    value.error = intl('Port.InvalidTypeCodeValue');

    return value;
  }

  const [type, code] = typeCodeParts;

  if (isValidIcmpTypeCode(type)) {
    value.icmp_type = Number(type);
  } else {
    value.error = intl('Port.InvalidTypeValue');
  }

  if (!code) {
    return value;
  }

  // Check for 'code' as only 'type' can exist alone
  if (isValidIcmpTypeCode(code)) {
    value.icmp_code = Number(code);
  } else {
    value.error = intl('Port.InvalidCodeValue');
  }

  return value;
};

export const parsePortAndProcessString = (portString, noProcess) => {
  // icmp validations falls through here and gets validated inside "parsePortAndProcessString" function
  if (getICMPProtocols().includes(ServiceUtils.reverseLookupProtocol(portString.split(' ').pop()))) {
    return parseIcmpString(portString);
  }

  let value = {};
  const processParts = portString.split('"');
  let portProtocolParts = processParts[0].trim().replace(/\s+/g, ' ').split(' ');
  const portParts = portProtocolParts[0].split('-');
  let protocol = '';

  if (portProtocolParts.length > 1) {
    protocol = portProtocolParts[1].toLowerCase();
  } else if (String(Math.trunc(Number(portProtocolParts[0]))) !== portProtocolParts[0]) {
    protocol = portProtocolParts[0].toLowerCase();
  }

  portProtocolParts = _.filter(portProtocolParts, o => o !== '');

  // parse ports / protocol combination
  if (portProtocolParts.length === 2) {
    if (portParts.length > 2) {
      value.error = intl('Port.InvalidRange');
    } else if (portParts.length > 1) {
      value = parseRangeOfPorts(portParts[0], portParts[1], protocol);
    } else if (portParts.length === 1) {
      value = parsePort(portParts[0], protocol);
    }
    // parse protocol only
  } else if (portProtocolParts.length === 1) {
    value = parsePort(null, protocol);

    // too many values
  } else if (portProtocolParts.length !== 0) {
    if (noProcess) {
      value.error = intl('Port.ProtocolInvalid');
    } else if (processParts.length === 5) {
      value.error = intl('Port.ProtocolInvalid');
    } else {
      value.error = intl('Port.ProcessServiceRequiresQuotes');
    }
  }

  // parse process
  const processRegex = /^(.+\\)+(.+\.exe)$/i;

  if (processParts.length > 1 && !noProcess) {
    if (processParts.length === 3) {
      if (processParts[0] !== '' && !processParts[0].includes(' ', processParts[0].length - 1)) {
        value.error = intl('Port.InvalidValue');
      } else if (processParts[2].trim() !== '') {
        value.error = intl('Port.ServiceNameRequiresQuotes');
      } else if (processRegex.test(processParts[1])) {
        value.process_name = processParts[1];
      } else if (processParts[1].toUpperCase() === 'SYSTEM') {
        value.process_name = processParts[1].toUpperCase();
      } else if (processParts[1].trim() === '') {
        value.error = intl('Port.EmptyProcessorService');
      } else if (processParts[1].length > 255) {
        value.error = intl('Port.InvalidServiceName');
      } else {
        value.service_name = processParts[1];
      }
    } else if (processParts.length === 5) {
      value.process_name = processParts[1];

      if (processParts[0] !== '' && !processParts[0].includes(' ', processParts[0].length - 1)) {
        value.error = intl('Port.InvalidValue');
      }

      if (processParts[2].replace(/\s+/g, ' ') !== ' ' || processParts[4].trim() !== '') {
        value.error = intl('Port.InvalidValue');
      } else if (processParts[1].trim() === '') {
        value.error = intl('Port.EmptyProcessName');
      } else if (!processRegex.test(processParts[1])) {
        value.error = intl('Port.InvalidProcess');
      } else if (processParts[3].trim() === '') {
        value.error = intl('Port.EmptyProcessName');
      } else if (processParts[3].length > 255) {
        value.error = intl('Port.InvalidServiceName');
      }

      value.service_name = processParts[3];
    } else {
      value.error = intl('Port.InvalidValue');
    }
  } else if (processParts.length > 1 && noProcess) {
    value.error = intl('Port.ProtocolInvalid');
  }

  if (value.error) {
    delete value.port;
    delete value.to_port;
    delete value.protocol;
    delete value.process_name;
    delete value.service_name;
  }

  value.text = portString;

  if (value.protocol) {
    value.protocol = ServiceUtils.reverseLookupProtocol(value.protocol);
  }

  return value;
};

export const areICMPPortRangesOverlapping = (value, array) => {
  // ICMP type/code like 110/112, 110/113 can exist (same type, but different codes)
  // however exact same ICMP type/code cannot.
  // also exact same ICMP type/code can exist for different IP protocols (IPv4 and IPv6).
  // also checks for duplicate only "icmp" of "icmpv6" values
  const {protocol, icmp_type: type = null, icmp_code: code = null} = value;
  const valueIndex = array.indexOf(value);
  const icmpProtcolType = {};

  array.forEach((item, index) => {
    const {protocol, icmp_type: type = null, icmp_code: code = null} = item;

    if (index !== valueIndex && getICMPProtocols().includes(protocol)) {
      icmpProtcolType[`${protocol}${type}`] = code;
    }
  });

  return `${protocol}${type}` in icmpProtcolType && icmpProtcolType[`${protocol}${type}`] === code;
};
/* eslint-enable camelcase */

export const arePortRangesOverlapping = (oldRange, newRange) => {
  if (!oldRange || !newRange) {
    return false;
  }

  if (oldRange.protocol !== newRange.protocol) {
    return false;
  }

  const oldService = oldRange.service_name ? oldRange.service_name : null;
  const newService = newRange.service_name ? newRange.service_name : null;

  if (oldService !== newService) {
    return false;
  }

  const oldProcess = oldRange.process_name ? oldRange.process_name : null;
  const newProcess = newRange.process_name ? newRange.process_name : null;

  if (oldProcess !== newProcess) {
    return false;
  }

  if (
    !oldRange.protocol &&
    !oldService &&
    !oldProcess &&
    _.isNil(oldRange.port) &&
    _.isNil(oldRange.to_port) &&
    !oldRange.process_name &&
    !oldRange.service_name
  ) {
    return false;
  }

  if (oldRange.port === -1 || newRange.port === -1) {
    //-1 means all, so if the protocol, process, and service match its overlapping
    return true;
  }

  if (oldRange.port === undefined && newRange.port === undefined) {
    //if no port defined, then overlapping process name
    return true;
  }

  if (_.isNil(oldRange.to_port) && _.isNil(newRange.to_port)) {
    return oldRange.port === newRange.port;
  }

  if (_.isNil(oldRange.to_port)) {
    return oldRange.port >= newRange.port && oldRange.port <= newRange.to_port;
  }

  if (_.isNil(newRange.to_port)) {
    return newRange.port >= oldRange.port && newRange.port <= oldRange.to_port;
  }

  return oldRange.port <= newRange.to_port && newRange.port <= oldRange.to_port;
};

export const arePortRangesEqual = (oldRange, newRange) => {
  if (!oldRange && !newRange) {
    return true;
  }

  if (!oldRange || !newRange) {
    return false;
  }

  const compactOldRange = {...oldRange};
  const compactNewRange = {...newRange};

  for (const key in compactOldRange) {
    if (!compactOldRange[key]) {
      delete compactOldRange[key];
    }
  }

  for (const key in compactNewRange) {
    if (!compactNewRange[key]) {
      delete compactNewRange[key];
    }
  }

  const keysToCompare = ['port', 'to_port', 'icmp_type', 'icmp_code', 'proto', 'service_name', 'process_name'];

  for (const keyToCompare of keysToCompare) {
    let oldValue = compactOldRange[keyToCompare];
    let newValue = compactNewRange[keyToCompare];

    if (keyToCompare === 'port') {
      // If no port value is present, default all ports, i.e. -1.
      oldValue = oldValue || -1;
      newValue = newValue || -1;
    }

    if (oldValue !== newValue) {
      return false;
    }
  }

  return true;
};

// 1 => 00001; 123 => 00123
export const stringifyPortForSort = port => String(port).padStart(5, 0);

//os independent service
export const parseExtendedPortString = portString => parsePortAndProcessString(portString, true);

//windows service
export const parsePortProcessString = portString => parsePortAndProcessString(portString, false);

export const stringifyPortObjectSort = value => {
  let service = '';

  if (!_.isNil(value.port) && value.port !== -1) {
    service += stringifyPortForSort(value.port) + ' ';
  }

  if (!_.isNil(value.to_port)) {
    service += '- ' + stringifyPortForSort(value.to_port) + ' ';
  }

  if (value.protocol && value.protocol !== -1) {
    service += ServiceUtils.lookupProtocol(value.protocol);
  }

  if (value.process_name) {
    if (service !== '') {
      service += ' ';
    }

    service += value.process_name;
  }

  if (value.service_name) {
    if (service !== '') {
      service += ' ';
    }

    service += value.service_name;
  }

  return service;
};

export const stringifyPortObjectReadonly = value => {
  let service = '';

  if (isNumberOrString(value.icmp_type)) {
    service += value.icmp_type + (isNumberOrString(value.icmp_code) ? `/${value.icmp_code} ` : ' ');
  }

  if (!_.isNil(value.port) && value.port !== -1) {
    service += value.port + ' ';
  }

  if (!_.isNil(value.to_port)) {
    service += '- ' + value.to_port + ' ';
  }

  const protocol = value.protocol || value.proto;

  if (protocol && protocol !== -1) {
    service += ServiceUtils.lookupProtocol(protocol);
  }

  if (value.process_name) {
    if (service !== '') {
      service += ' ';
    }

    service += value.process_name;
  }

  if (value.service_name) {
    if (service !== '') {
      service += ' ';
    }

    service += value.service_name;
  }

  return service;
};

export const stringifyPortObject = value => {
  let service = '';

  if (isNumberOrString(value.icmp_type)) {
    service += value.icmp_type + (isNumberOrString(value.icmp_code) ? `/${value.icmp_code} ` : ' ');
  }

  if (!_.isNil(value.port) && value.port !== -1) {
    service += value.port;
  }

  if (!_.isNil(value.to_port)) {
    service += '-' + value.to_port + ' ';
  } else if (!_.isNil(value.port)) {
    service += ' ';
  }

  if (value.protocol && value.protocol !== -1) {
    service += ServiceUtils.lookupProtocol(value.protocol);
  }

  if (value.process_name) {
    if (service !== '') {
      service += ' ';
    }

    service += '"' + value.process_name + '"';
  }

  if (value.service_name) {
    if (service !== '') {
      service += ' ';
    }

    service += '"' + value.service_name + '"';
  }

  return service.trim();
};

export const validatePorts = (values, checkIpv4 = false) =>
  values.map((value, index, array) => {
    if (value) {
      const ipv4 = intl('Protocol.IPv4').toLocaleLowerCase();

      if (checkIpv4 && value.text && value.text.toLocaleLowerCase() === ipv4) {
        delete value.error;

        value.protocol = 4;
        value.duplicate = null;

        if (
          !value.removed &&
          values.reduce(
            (sum, value) => (!value.removed && value.text && value.text.toLowerCase() === ipv4 ? sum + 1 : sum),
            0,
          ) > 1
        ) {
          value.duplicate = intl('Services.OverlappingTypeCode');
        }

        return value;
      }

      if (getICMPProtocols().includes(value.protocol)) {
        value.duplicate = null;

        if (areICMPPortRangesOverlapping(value, values)) {
          value.duplicate = intl('Services.OverlappingTypeCode');
        }

        return value;
      }

      if (
        !value.error &&
        _.some(
          array,
          (currentValue, currentIndex) =>
            index !== currentIndex &&
            !currentValue.removed &&
            !value.removed &&
            arePortRangesOverlapping(value, currentValue),
        )
      ) {
        value.duplicate = intl('Services.OverlappingPorts');
      } else {
        value.duplicate = null;
      }

      if (arePortRangesEqual(value.original, value)) {
        value.type = value.type === 'updated' ? 'old' : value.type;
      } else {
        value.type = value.type === 'old' ? 'updated' : value.type;
      }
    }

    return value;
  });

export const validatePortsAndIpv4 = _.bind(validatePorts, null, _, true);

export const getPortProtocolProcessInstructions = process => {
  const portProtocolInstructions = (
    <div key="addAll" className="ServiceEdit-instructions-item">
      {intl('Port.ToAddPortProtocol')}:<div className="ServiceEdit-instructions-example">80 {intl('Protocol.TCP')}</div>
      <div className="ServiceEdit-instructions-example">500 {intl('Protocol.UDP')}</div>
      <div className="ServiceEdit-instructions-example">1000-2000 {intl('Protocol.TCP')}</div>
      <div className="ServiceEdit-instructions-example">{intl('Protocol.GRE')}</div>
      <div className="ServiceEdit-instructions-example">{intl('Protocol.IPIP')}</div>
      <div className="ServiceEdit-instructions-example">{intl('Protocol.ICMP')}</div>
      <div className="ServiceEdit-instructions-example">89/2 {intl('Protocol.ICMP')}</div>
      <div className="ServiceEdit-instructions-example">133 {intl('Protocol.ICMP')}</div>
      <div className="ServiceEdit-instructions-example">{intl('Protocol.ICMPv6')}</div>
      <div className="ServiceEdit-instructions-example">89/2 {intl('Protocol.ICMPv6')}</div>
      <div className="ServiceEdit-instructions-example">133 {intl('Protocol.ICMPv6')}</div>
      <div className="ServiceEdit-instructions-example">{intl('Protocol.IGMP')}</div>
    </div>
  );

  let processInstructions = null;

  if (process) {
    processInstructions = [
      <div key="addWindows" className="ServiceEdit-instructions-item">
        {intl('Port.ToAddProcessAndWindowsService')}:
        <div className="ServiceEdit-instructions-example">"{intl('Port.ProcessNamedSchedule')}"</div>
        <div className="ServiceEdit-instructions-example">"{intl('Port.WindowsServiceExample')}"</div>
      </div>,
      <div key="addWindows2" className="ServiceEdit-instructions-item">
        {intl('Port.ToAddPortProtocolProcess')}:
        <div className="ServiceEdit-instructions-example">
          540 {intl('Protocol.TCP')} "{intl('Port.WindowsServiceExample')}"
        </div>
        <div className="ServiceEdit-instructions-example">{intl('Protocol.GRE')} "my service"</div>
        <div className="ServiceEdit-instructions-example">
          80 {intl('Protocol.TCP')} "{intl('Port.WindowsServiceExample')}" "myservice"
        </div>
      </div>,
    ];
  }

  return [processInstructions, portProtocolInstructions];
};

export default {
  isValidPort,
  getProtocolsWithPorts,
  getProtocolsWithoutPorts,
  getICMPProtocols,
  getICMPAndPortlessProtocols,
  getProtocols,
  validateProtocol,
  parsePortAndProcessString,
  parsePort,
  parseRangeOfPorts,
  arePortRangesOverlapping,
  arePortRangesEqual,
  parseExtendedPortString,
  parsePortProcessString,
  stringifyPortObjectSort,
  stringifyPortObjectReadonly,
  stringifyPortObject,
  validatePorts,
  validatePortsAndIpv4,
  getPortProtocolProcessInstructions,
};
