/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {LabelGroupFacetStore} from '../stores';
import StoreMixin from '../mixins/StoreMixin';
import {RestApiUtils} from '../utils';
import intl from 'intl';

function getStateFromStores() {
  return {
    dropdownValues: LabelGroupFacetStore.getDropdownValues(),
  };
}

export default React.createClass({
  propTypes: {
    facetMap: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
  },

  mixins: [StoreMixin(LabelGroupFacetStore, getStateFromStores)],

  addFilter(filter, value) {
    const items = _.cloneDeep(this.props.items);

    items[filter] = value;
    this.props.onChange(items);
  },

  removeFilter(filter) {
    const items = _.cloneDeep(this.props.items);

    delete items[filter];
    this.props.onChange(items);
  },

  render() {
    const statics = {
      [intl('Provision.Status')]: Object.keys(this.props.provisionStatusValues),
      [intl('Common.Type')]: Object.keys(this.props.typeValues),
    };
    const props = {
      addItem: this.addFilter,
      dropdownValues: this.state.dropdownValues,
      facetMap: this.props.facetMap,
      getFacetValues: (facet, query) => {
        if (!facet) {
          return;
        }

        RestApiUtils.labelGroups.facets({facet, query}, 'draft');
      },
      hasTitle: [intl('Common.Description')],
      initialValues: Object.keys(this.props.facetMap).concat([intl('Provision.Status'), intl('Common.Type')]),
      items: this.props.items,
      partialItems: [intl('Common.Name'), intl('Common.Description')],
      placeholder: intl('Common.FilterView'),
      removeItem: this.removeFilter,
      returnValue: value => value,
      statics,
      staticsKeys: [intl('Provision.Status'), intl('Common.Type')],
    };

    return <ObjectSelector {...props} />;
  },
});
