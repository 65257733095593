/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import Fuzzy from 'fuzzy';
import Label from './Label.jsx';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-combobox';

export default React.createClass({
  propTypes: {
    results: PropTypes.array,
    selected: PropTypes.array,
    placeholder: PropTypes.string,
    maxResults: PropTypes.number,
    openOnEnter: PropTypes.bool,
    selectOnBlur: PropTypes.bool,
    clearOnBlur: PropTypes.bool,
    separators: PropTypes.string,
    allowEmptyQuery: PropTypes.bool,
    allowMultiSelect: PropTypes.bool,
    allowClear: PropTypes.bool,
    allowCreate: PropTypes.bool,
    allowCreateHint: PropTypes.string,
    fuzzyOptions: PropTypes.object,
    formatResult: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    formatSelection: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    getValue: PropTypes.func,
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
    waiting: PropTypes.bool,
    clearAfterSelect: PropTypes.bool,
    filterFn: PropTypes.func,
    searchFn: PropTypes.func,
    tid: PropTypes.string,
    autoFocus: PropTypes.bool,
    textNoResults: PropTypes.string,
    tabIndex: PropTypes.string,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      autoFocus: false,
      results: [],
      selected: [],
      maxResults: 10,
      openOnEnter: true,
      selectOnBlur: false,
      clearOnBlur: false,
      allowEmptyQuery: false,
      allowMultiSelect: true,
      allowClear: true,
      allowCreate: false,
      allowCreateHint: '',
      clearAfterSelect: true,
      fuzzyOptions: {
        caseSensitive: false,
        extract: o => o.value || o.label || '',
      },
      onFocus: _.noop,
      onChange: _.noop,
      onCreate: _.noop,
      waiting: false,
      formatResult: result => result.label,
      formatSelection: result => <Label text={result.label} />,
      getValue: result => result,
      textNoResults: 'No results found',
      tabIndex: '0',
      disabled: false,
    };
  },

  getInitialState() {
    return {
      activeQuery: '',
      activeResults: [],
      focusedResult: null,
      showResults: false,
    };
  },

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.selected, nextProps.selected) || this.props.waiting !== nextProps.waiting) {
      this.setState({
        activeResults: this.getResults(nextProps, this.state.activeQuery, nextProps.selected),
      });
    }
  },

  componentDidUpdate() {
    this.scrollToFocusedNode();
  },

  getResults(props, query, selected) {
    if (props.waiting) {
      return 'waiting';
    }

    let results = this.filterResults(this.search(query, props.results), selected);

    if (_.isFunction(this.props.filterFn)) {
      results = props.filterFn(results);
    }

    if (props.maxResults && props.maxResults !== 0) {
      results = _.take(results, props.maxResults);
    }

    return results;
  },

  handleChange(event) {
    event.stopPropagation();

    const activeQuery = event.target.value || '';
    const showResults = (!activeQuery && this.props.allowEmptyQuery) || Boolean(activeQuery);

    this.setState({
      activeQuery,
      showResults,
      focusedResult: activeQuery ? 0 : null,
      activeResults: this.getResults(this.props, activeQuery) || [],
    });
  },

  handleClick(event) {
    if (this.props.allowMultiSelect) {
      if (event.currentTarget === findDOMNode(this.refs.combobox)) {
        this.focusInput();
      }
    } else if (!this.state.showResults) {
      this.setState(
        {
          focusedResult: null,
          activeQuery: '',
        },
        () => {
          this.focusInput();
        },
      );
    }

    if (this.props.allowClear && event.target.classList.contains('Icon-Remove')) {
      const selection = event.target.parentElement;
      const idx = Array.prototype.indexOf.call(selection.parentElement.childNodes, selection);

      this.removeSelected(idx);
    }

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  },

  handleCreate(value) {
    const newState = {};

    newState.showResults = false;
    newState.focusedResult = null;
    newState.activeQuery = '';
    this.props.onCreate(value);
    this.setState(newState);
  },

  handleDropdownMouseDown() {
    this.disableBlur = true;
    _.defer(() => {
      this.disableBlur = false;
    });
  },

  handleInputBlur(evt) {
    if (this.disableBlur) {
      findDOMNode(this.refs.input).focus();

      return;
    }

    let activeQuery = _.cloneDeep(this.state.activeQuery);

    if (this.props.clearOnBlur) {
      activeQuery = '';
    }

    this.setState(
      {
        focused: false,
        focusedResult: null,
        showResults: false,
        activeQuery,
      },
      () => {
        if (this.props.onBlur) {
          this.props.onBlur(evt);
        }
      },
    );
  },

  handleInputFocus() {
    const newState = {
      focused: true,
    };

    if (this.state.activeQuery) {
      newState.showResults = true;
    }

    if (this.props.allowEmptyQuery) {
      newState.activeResults = this.getResults(this.props, this.state.activeQuery);
    }

    this.props.onFocus();
    this.setState(newState);
  },

  handleInputKeyDown(event) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }

    if (!event.target.value && event.key === 'Backspace') {
      this.removeLastSelected();
    }

    event.stopPropagation();

    const {activeResults, focusedResult} = this.state;

    if (activeResults === 'waiting') {
      return;
    }

    const filteredResults = this.filterResults(activeResults);
    let numResults = 0;

    filteredResults.forEach(result => {
      numResults += result.group ? result.items.length : 1;
    });

    const activeQuery = this.state.activeQuery.trim();

    if (activeQuery && this.props.allowCreate) {
      const resultLabels = this.props.results
        .map(result => this.props.getValue(result))
        .filter(result => _.isString(result))
        .map(result => result.trim());

      if (!resultLabels.includes(activeQuery)) {
        numResults++;
      }
    }

    const newState = {};

    switch (event.key) {
      case 'Escape':
        this.blurInput();
        break;

      case 'ArrowDown':
        if (this.state.showResults) {
          newState.showResults = true;

          if (focusedResult === null || focusedResult === numResults - 1) {
            newState.focusedResult = 0;
          } else {
            newState.focusedResult = focusedResult + 1;
          }
        } else {
          newState.activeResults = this.getResults(this.props);
          newState.showResults = true;
        }

        break;

      case 'ArrowUp':
        if (this.state.showResults) {
          newState.showResults = true;

          if (focusedResult === null || focusedResult === 0) {
            newState.focusedResult = numResults - 1;
          } else {
            newState.focusedResult = focusedResult - 1;
          }
        }

        break;

      case 'Enter':
        event.preventDefault();

        if (focusedResult !== null) {
          this.addResult(focusedResult);
        } else if (!this.state.showResults && this.props.openOnEnter) {
          newState.activeResults = this.getResults(this.props);
          newState.showResults = true;
        }

        break;

      case 'Tab':
        if (focusedResult !== null) {
          this.addResult(focusedResult);
        }

        break;
    }

    if (!_.isEqual(newState, {})) {
      this.setState(newState);
    }
  },

  handleMouseDown(event) {
    if (this.props.onMouseDown) {
      this.props.onMouseDown(event);
    }
  },

  handleTriggerClick(event) {
    event.stopPropagation();

    const showResults = !this.state.showResults;

    this.setState({
      showResults,
    });

    if (showResults) {
      this.focusInput();
    }
  },

  addResult(idx) {
    const newState = {};
    let removedResult = null;
    const filteredResults = this.filterResults(this.state.activeResults);
    let indexedResults = [];

    filteredResults.forEach(result => {
      if (result.group) {
        indexedResults = indexedResults.concat(result.items);
      } else {
        indexedResults.push(result);
      }
    });

    if (idx === indexedResults.length && this.state.activeQuery && this.props.allowCreate) {
      this.handleCreate(this.state.activeQuery);
    }

    const addedResult = indexedResults[idx];

    if (!addedResult) {
      return;
    }

    newState.showResults = false;
    newState.focusedResult = null;

    if (!this.props.allowMultiSelect) {
      newState.activeQuery = '';
      removedResult = this.removeLastSelected(true);
    } else if (this.props.clearAfterSelect) {
      newState.activeQuery = '';

      if (this.props.allowEmptyQuery) {
        newState.activeResults = this.getResults(this.props, this.state.activeQuery);
        newState.showResults = true;
      }
    } else {
      newState.activeQuery = addedResult.label;
    }

    this.props.onChange(addedResult, removedResult);
    this.setState(newState);
  },

  blurInput() {
    if (this.refs.input) {
      findDOMNode(this.refs.input).blur();
    }
  },

  filterResults(results, selected) {
    if (results === 'waiting') {
      return results;
    }

    const allowMultiple = this.props.allowMultiSelect;
    const filteredResults = [];

    selected = selected || this.props.selected;
    function isSelected(item, selection) {
      if (!selection.length) {
        return false;
      }

      if (allowMultiple) {
        return selection.some(select => _.isEqual(select, item));
      }

      return _.isEqual(selection[0], item);
    }
    results.forEach(result => {
      if (!result.group && !isSelected(result, selected)) {
        filteredResults.push(result);
      } else if (result.group) {
        const filteredGroupResults = [];

        result.items.forEach(item => {
          if (!isSelected(item, selected)) {
            filteredGroupResults.push(item);
          }
        });

        if (filteredGroupResults.length) {
          filteredResults.push({
            group: true,
            label: result.label,
            items: filteredGroupResults,
          });
        }
      }
    });

    return filteredResults;
  },

  focusInput() {
    if (this.refs.input) {
      findDOMNode(this.refs.input).focus();
    }
  },

  removeLastSelected(silent) {
    return this.removeSelected(this.props.selected.length - 1, silent);
  },

  removeSelected(idx, silent) {
    const removed = this.props.selected[idx];

    if (removed && !silent) {
      this.props.onChange(null, removed);
    }

    return removed;
  },

  scrollToFocusedNode() {
    // Necessary when keyboard nav focuses a node outside of the results window.
    if (this.refs.results && this.refs.focused) {
      const focusedNode = findDOMNode(this.refs.focused);
      const focusedRect = focusedNode.getBoundingClientRect();
      const resultsNode = findDOMNode(this.refs.results);
      const resultsRect = resultsNode.getBoundingClientRect();
      let newScrollTop;

      if (focusedRect.bottom > resultsRect.bottom) {
        newScrollTop = resultsNode.scrollTop + (focusedRect.bottom - resultsRect.bottom);
        resultsNode.scrollTop = newScrollTop;
      } else if (focusedRect.top < resultsRect.top) {
        newScrollTop = resultsNode.scrollTop - (resultsRect.top - focusedRect.top);
        resultsNode.scrollTop = newScrollTop;
      }
    }
  },

  search(query, results) {
    if (!query || !results || !results.length) {
      return results;
    }

    let searchResults = null;

    if (_.isFunction(this.props.searchFn)) {
      searchResults = this.props.searchFn(query, results);

      // If the searchResults returned are null, continue with the default search
      if (searchResults) {
        return searchResults;
      }
    }

    searchResults = [];

    // Search simple results
    const simpleResults = results.filter(result => !result.group);
    const filteredSimpleResults = Fuzzy.filter(query, simpleResults, this.props.fuzzyOptions).map(o => o.original);

    searchResults = searchResults.concat(filteredSimpleResults);

    // Search group results
    const groupResults = results.filter(result => result.group);
    const filteredGroupResults = _.compact(
      groupResults.map(group => {
        const filteredResults = Fuzzy.filter(query, group.items, this.props.fuzzyOptions).map(o => o.original);

        return filteredResults.length
          ? {
              group: true,
              label: group.label,
              items: filteredResults,
            }
          : null;
      }),
    );

    searchResults = searchResults.concat(filteredGroupResults);

    return searchResults;
  },

  render() {
    const activeResults = this.state.activeResults;
    const showResults = this.state.showResults;
    let resultIndex = 0;
    let groupIndex = 0;
    let activeResultItems = [];

    let placeholder = this.props.placeholder;

    if (!this.props.allowMultiSelect && this.props.selected.length) {
      placeholder = '';
    }

    if (activeResults !== 'waiting' && activeResults.length > 0) {
      activeResultItems = activeResults.map(result => {
        const currentIndex = resultIndex;
        const isFocused = resultIndex === this.state.focusedResult;
        const isGroup = Boolean(result.group);
        const resultItemClasses = cx({
          'Combobox-result': !isGroup,
          'Combobox-result-group': isGroup,
          'Combobox-result--focused': isFocused,
        });
        const onClick = () => this.addResult(currentIndex);
        const resultItemTid = 'comp-select-results-item';
        const resultGroupTid = 'comp-select-results-group';
        let resultItem;

        if (!isGroup) {
          resultItem = (
            <li
              ref={isFocused ? 'focused' : undefined}
              className={resultItemClasses}
              data-tid={resultItemTid}
              key={currentIndex}
              onClick={onClick}
              onMouseDown={evt => evt.preventDefault()}
              onMouseUp={evt => evt.preventDefault()}
            >
              {this.props.formatResult(result)}
            </li>
          );
          resultIndex++;
        } else {
          resultItem = (
            <li
              className={resultItemClasses}
              data-tid={resultGroupTid}
              key={'group' + groupIndex}
              onMouseDown={evt => evt.preventDefault()}
              onMouseUp={evt => evt.preventDefault()}
            >
              <div className="Combobox-result-group-label">{result.label}</div>
              <ul>
                {result.items.map(item => {
                  const currentIndex = resultIndex;
                  const onClick = () => this.addResult(currentIndex);
                  const isFocused = currentIndex === this.state.focusedResult;
                  const resultItemClasses = cx({
                    'Combobox-result': true,
                    'Combobox-result--focused': isFocused,
                  });

                  resultIndex++;

                  return (
                    <li
                      ref={isFocused ? 'focused' : undefined}
                      className={resultItemClasses}
                      data-tid={resultItemTid}
                      key={currentIndex}
                      onClick={onClick}
                    >
                      {this.props.formatResult(item)}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
          groupIndex++;
        }

        return resultItem;
      });
    }

    if (activeResults === 'waiting') {
      activeResultItems = null;
    } else if (activeResults.length === 0) {
      activeResultItems.push(
        <li key="no-results" className="Combobox-result-empty">
          {this.props.textNoResults}
        </li>,
      );
    }

    const totalLength = activeResults.reduce((count, cur) => count + (cur.items ? cur.items.length : 1), 0);
    const results = showResults ? (
      <div className="Combobox-results" onMouseDown={this.handleDropdownMouseDown}>
        <ul ref="results" className="Combobox-results-list" data-tid="comp-select-results-list">
          {activeResultItems}
        </ul>
        {this.props.allowCreate ? (
          <ul className="Combobox-results-list" data-tid="comp-select-create-list">
            <li
              key="create"
              data-tid="comp-combobox-create"
              className={cx({
                'fw-500': true,
                'Combobox-result': true,
                'Combobox-result-create': true,
                'Combobox-result--focused': this.state.focusedResult === totalLength,
              })}
              onMouseDown={evt => evt.preventDefault()}
              onMouseUp={evt => evt.preventDefault()}
              onClick={() => this.handleCreate(this.state.activeQuery)}
            >
              {this.state.activeQuery + (this.props.allowCreateHint ? ' ' + this.props.allowCreateHint : '')}
            </li>
          </ul>
        ) : null}
      </div>
    ) : null;
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);
    const comboClasses = cx({
      'Combobox': true,
      'Combobox--focus': this.state.focused === true,
      'Combobox--multiple': this.props.allowMultiSelect === true,
      'Combobox--single': this.props.allowMultiSelect === false,
      'Combobox--open': !this.props.disabled && showResults,
    });

    return (
      <div
        ref="combobox"
        className={comboClasses}
        data-tid={ComponentUtils.tidString(tids)}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
      >
        {this.props.selected.map((r, idx) => {
          const classes = cx({
            'Combobox-selected': true,
            'Combobox-selected--removable': this.props.allowClear === true,
          });

          return (
            <div className={classes} data-tid="comp-combobox-selected" key={idx}>
              {this.props.formatSelection(r)}
              {this.props.allowClear ? <Icon styleClass="Remove" name="close" tid="remove" /> : null}
            </div>
          );
        })}
        <div className="Combobox-selector">
          <input
            tabIndex={this.props.tabIndex}
            autoFocus={this.props.autoFocus}
            ref="input"
            className="Combobox-input"
            data-tid="comp-combobox-input"
            value={this.state.activeQuery}
            onBlur={this.handleInputBlur}
            onFocus={this.handleInputFocus}
            onChange={this.handleChange}
            onKeyDown={this.handleInputKeyDown}
            placeholder={placeholder}
            disabled={this.props.disabled}
          />
        </div>
        {!this.props.disabled ? (
          <span
            className="Combobox-chevron"
            ref="trigger"
            onMouseDown={evt => evt.preventDefault()}
            onMouseUp={evt => evt.preventDefault()}
            onClick={this.handleTriggerClick}
          >
            <Icon name={'caret-' + (showResults ? 'up' : 'down')} />
          </span>
        ) : null}
        {results}
      </div>
    );
  },
});
