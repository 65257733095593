/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import localeIdentify from 'locale';
import config from 'config';

declare global {
  interface Navigator {
    userLanguage: string;
  }
}

// Take client preferred language from browser navigator
// If navigator doesn't exist (for example in tests), take default language from config
const browserLocale =
  (typeof navigator === 'object' && (navigator.languages || navigator.userLanguage || navigator.language)) ||
  _.head(config.langs) ||
  'en';

const browserLocales = !Array.isArray(browserLocale) ? [browserLocale as string] : (browserLocale as readonly string[]);

const identifyUserLocale = (function () {
  // Locales we support
  const localesSupported = new localeIdentify.Locales(config.locales);
  // Default locale is the first one
  const localeDefault = config.locales[0];
  // Locales hashmap for check
  const localesHash = _.mapKeys(config.locales, locale => locale);

  return (localesArr: ConstructorParameters<typeof localeIdentify.Locales>[0]) => {
    const suggestedLocale = new localeIdentify.Locales(localesArr).best(localesSupported);

    return (
      localesHash[suggestedLocale.normalized] ||
      localesHash[suggestedLocale.code] ||
      localesHash[suggestedLocale.language] ||
      localeDefault
    );
  };
})();

export const locale = identifyUserLocale(browserLocales) || 'en'; // Result user's locale, for example, 'en-US'

export const lang = locale.split('-')[0]; // Result user's language, for example, 'en'
