/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import {DragLayer} from 'react-dnd';

const Types = {
  GRIDROW: 'gridrow',
};

function getItemStyles(props) {
  const {initialOffset, currentOffset} = props;

  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const {x, y} = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const dragLayerCollect = monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
});

//This component is used by react drag and drop to allow custom styling when a row is being dragged
const GridRowDragLayer = React.createClass({
  propTypes: {
    item: PropTypes.object,
    itemType: PropTypes.string,
    initialOffset: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    currentOffset: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    isDragging: PropTypes.bool,
  },

  renderItem(type, item) {
    switch (type) {
      case Types.GRIDROW:
        return (
          <table className="Grid">
            <tbody clasName="Grid-body">
              <tr className={item.className} data-tid={item['data-tid']}>
                {item.columns}
              </tr>
            </tbody>
          </table>
        );
      default:
        return null;
    }
  },

  render() {
    const {item, itemType, isDragging} = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div style={getItemStyles(this.props)}>
        <div className="RuleGrid GridRowDragLayer">{this.renderItem(itemType, item)}</div>
      </div>
    );
  },
});

export default DragLayer(dragLayerCollect)(GridRowDragLayer);
