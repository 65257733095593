/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {RestApiUtils, PolicyGeneratorUtils} from '..';
import {ExplorerBoundaryPolicyUtils, ExplorerBoundaryUtils} from '.';
import {ExplorerFilterStore} from '../../stores';
import {getId} from '../GeneralUtils';

function getExternalDataForBoundaryRuleset(id) {
  return {
    external_data_set: 'illumio_boundary_ruleset',
    external_data_reference: id,
  };
}

function getProposedBoundaryRulesetFromPath(pathname) {
  return pathname.toLowerCase().includes('/boundaries/proposedrules');
}

function getScopeForBoundaryRuleset(boundary) {
  const providerScopes = Object.values(
    (boundary?.providers || []).reduce(
      (result, item) => {
        const type = item.label ? 'label' : item.label_group ? 'label_group' : null;

        if (type && result[item[type].key]) {
          result[item[type].key].push(item);
        }

        return result;
      },
      {
        app: [],
        env: [],
        loc: [],
      },
    ),
  );

  // If there are more than one of any of the scope types, use the global scope for now
  if (providerScopes.some(scopeType => scopeType.length > 1)) {
    return [[]];
  }

  return [
    providerScopes.reduce((result, scopeType) => {
      if (scopeType.length) {
        result.push(scopeType[0]);
      }

      return result;
    }, []),
  ];
}

async function getBoundaryNewRulesetName(boundary) {
  let rulesetName;

  // If we didn't find a ruleset for this boundary
  // Make sure we choose a name for the ruleset that doesn't conflict with another ruleset
  for (let tries = 0; tries < 10; tries++) {
    rulesetName =
      tries === 0
        ? boundary.name
        : tries === 1
        ? `${boundary.name} Boundary Ruleset`
        : `${boundary.name} Boundary Ruleset ${tries}`;

    const response = await RestApiUtils.ruleSets.getCollection({name: rulesetName}, 'draft', true);

    if (!response.body.length) {
      tries = 10;
    }
  }

  return rulesetName;
}

async function loadBoundaryPolicyData(params) {
  const time = localStorage.getItem('boundary_time') || 'Last 24 Hours';

  const {boundary} = await ExplorerBoundaryUtils.getBoundaryData(
    params.id,
    params.pversion,
    time,
    this.setQueryPollId,
    true,
    true,
  );

  const responses = await Promise.all([
    RestApiUtils.services.getCollection({max_results: 100_000}),
    RestApiUtils.ipLists.getInstance('any'),
    RestApiUtils.ruleSets.getCollection(
      {
        representation: 'rule_set_services_labels_and_names',
        external_data_set: 'illumio_boundary_ruleset',
        external_data_reference: params.id,
      },
      'draft',
      true,
    ),
  ]);

  const scopes = ExplorerBoundaryPolicyUtils.getScopeForBoundaryRuleset(boundary);
  const boundaryRulesets = responses[2].body;

  if (!boundaryRulesets.length) {
    const name = await getBoundaryNewRulesetName(boundary);

    boundaryRulesets.push({name, scopes});
  }

  return {boundary, newScopes: scopes[0].map(scope => scope.label || scope.label_group), boundaryRulesets};
}

export async function removeBoundaryTags(boundaryRulesets) {
  return Promise.all(
    boundaryRulesets
      .filter(boundaryRuleset => boundaryRuleset?.href)
      .map(boundaryRuleset =>
        RestApiUtils.ruleSet.updateClean(getId(boundaryRuleset.href), {
          ...PolicyGeneratorUtils.getStrippedRuleset(boundaryRuleset),
          external_data_set: null,
          external_data_reference: null,
        }),
      ),
  );
}

function getLinks(params) {
  return ExplorerFilterStore.getAggregatedTableLinks(`boundaries${params.id}`) || [];
}

export default {
  getLinks,
  removeBoundaryTags,
  loadBoundaryPolicyData,
  getScopeForBoundaryRuleset,
  getExternalDataForBoundaryRuleset,
  getProposedBoundaryRulesetFromPath,
};
