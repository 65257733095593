/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Link} from 'react-router';
import {Icon, Label} from '..';
import Tooltip from '../Tooltip.jsx';
import {LabelUtils, RestApiUtils, RenderUtils} from '../../utils';
import UserMixin from '../../mixins/UserMixin.js';
import {MapPageStore} from '../../stores';
import AssignRolePanel from './AssignRolePanel';
import {isContainerClusterHref} from '../../utils/GeneralUtils';

export default React.createClass({
  getInitialState() {
    return {
      editPolicyState: false,
      editRole: false,
    };
  },

  componentDidMount() {
    const id = _.last(this.props.data.href.split('/'));

    _.defer(() => RestApiUtils.virtualServices.getInstance(id, 'draft', true));
  },

  renderRoleLabel(label) {
    const {caps, vs} = this.props.data;
    // TBD Raven: wait for final decisions on virtual service caps
    const vsWorkloadsIsWritable = caps.workloads.includes('write');

    const disableButton =
      !vsWorkloadsIsWritable ||
      vs.update_type === 'delete' || // deleted
      // created by container cluster and not deleted, handled by all region
      (vs.created_by && !vs.created_by.deleted && isContainerClusterHref(vs.created_by.href)) ||
      // created by container cluster and deleted, handled by all region
      !vs.created_by ||
      (vs.created_by.deleted && isContainerClusterHref(vs.created_by.href)) ||
      // not created by container cluster, handled by normal isUserReadOnly
      (vs.created_by &&
        !vs.created_by.deleted &&
        !isContainerClusterHref(vs.created_by.href) &&
        UserMixin.isUserReadOnly());

    const roleLabel = (
      <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
        {label ? <Label type={label.key} text={label.value} /> : intl('Common.NoRole')}
        {!disableButton && (
          <span className="Icon-Edit">
            <Icon name="edit" onClick={this.handleEditRole} tid="edit-role-label" />
          </span>
        )}
      </td>
    );

    const roleData = {
      href: this.props.data.href,
      labels: this.props.data.labels,
      currentRole: label && label.value,
      fullMap: MapPageStore.getMapType() === 'full',
    };

    const editRole = (
      <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
        <AssignRolePanel onClose={this.handleCloseRole} data={roleData} />
      </td>
    );

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          <div className={this.state.editRole ? 'MapInfoPanel-edit' : 'MapInfoPanel-role'}>
            {label && LabelUtils.typesName[label.key]}
          </div>
        </th>
        {!this.state.editRole && roleLabel}
        {this.state.editRole && editRole}
      </tr>
    );
  },

  handleEditRole() {
    this.setState({editRole: !this.state.editRole, editPolicyState: false});
  },

  handleCloseRole() {
    this.setState({editRole: false});
  },

  render() {
    const {data} = this.props;
    const {caps} = this.props.data;
    let group;

    // TBD Raven: wait for final decisions on virtual service caps
    const vsWorkloadsIsReadable = caps.workloads.includes('read');

    // Order label values into:
    // label:role label:app, label:env, label:loc (RAEL)
    const roleLabel = data.labels ? this.renderRoleLabel(data.labels.role) : null;

    if (data.group) {
      const groupParams = {id: data.group.href};
      let groupName = data.group.name;
      let tooltipContent;

      if (groupName === 'discovered' || data.group.discovered) {
        groupName = intl('Map.DiscoveredGroup');
      }

      if (groupName.length > 40) {
        tooltipContent = groupName;
      }

      let groupType = intl('Common.Group');

      let groupLink = (
        <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
          <Link to="groupWorkloads" className="MapInfoPanel-Link" params={groupParams}>
            {RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])}
          </Link>
        </div>
      );

      if (MapPageStore.getMapType() === 'app') {
        groupType = intl('Common.AppGroup');
        groupLink = (
          <div className="MapInfoPanel-Row-Group" data-tid="map-info-panel-row-value">
            {vsWorkloadsIsReadable ? (
              <Link to="appGroupWorkloads" className="MapInfoPanel-Link" params={groupParams}>
                {RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])}
              </Link>
            ) : (
              RenderUtils.truncateAppGroupName(groupName, 40, [20, 10, 10])
            )}
          </div>
        );
      }

      if (groupName) {
        group = (
          <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
            <th className="MapInfoPanel-Row-Label">
              <span data-tid="map-info-panel-row-label">{groupType}</span>
            </th>
            <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow">
              {tooltipContent ? (
                <Tooltip content={tooltipContent} position="workload" width={372} location="bottomright">
                  {groupLink}
                </Tooltip>
              ) : (
                groupLink
              )}
            </td>
          </tr>
        );
      }
    }

    let service;

    if (data.service && data.service.name) {
      service = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            <span data-tid="map-info-panel-row-label">{intl('Common.Service')}</span>
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            {data.service.name}
          </td>
        </tr>
      );
    }

    let boundWorkloads;
    let boundContainerWorkloads;

    let numBoundWorkloads = 0;
    let numBoundContainerWorkloads = 0;

    let policyState;

    if (data.boundWorkloads && data.boundWorkloads.length) {
      data.boundWorkloads.forEach(entity => {
        if (entity.href.includes('container_workloads')) {
          numBoundContainerWorkloads += 1;
        } else if (entity.href.includes('/workloads/')) {
          numBoundWorkloads += 1;
        }
      });

      if (numBoundWorkloads) {
        boundWorkloads = (
          <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
            <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              <span data-tid="map-info-panel-row-label">{intl('VirtualServices.BoundWorkloads')}</span>
            </th>
            <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
              {vsWorkloadsIsReadable ? (
                <Link
                  to="virtualServiceWorkloads"
                  className="MapInfoPanel-Link"
                  params={{id: data.id, pversion: 'draft', tab: 'workloads'}}
                  data-tid="map-info-panel-bound-workloads"
                >
                  {numBoundWorkloads}
                </Link>
              ) : (
                numBoundWorkloads
              )}
            </td>
          </tr>
        );
      }

      if (numBoundContainerWorkloads) {
        boundContainerWorkloads = (
          <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
            <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              <span data-tid="map-info-panel-row-label">{intl('VirtualServices.BoundContainerWorkloads')}</span>
            </th>
            <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
              <Link
                to="virtualServiceContainers"
                className="MapInfoPanel-Link"
                params={{id: data.id, pversion: 'draft', tab: 'containers'}}
              >
                {numBoundContainerWorkloads}
              </Link>
            </td>
          </tr>
        );
      }

      let policyStateLink = (
        <Link
          to="virtualServiceWorkloads"
          className="MapInfoPanel-Link"
          params={{id: data.id, pversion: 'draft', tab: 'workloads'}}
        >
          {intl('Map.ViewBoundWorkloads')}
        </Link>
      );

      if (!numBoundWorkloads && numBoundContainerWorkloads) {
        policyStateLink = (
          <Link
            to="virtualServiceContainers"
            className="MapInfoPanel-Link"
            params={{id: data.id, pversion: 'draft', tab: 'containers'}}
          >
            {intl('Map.ViewBoundContainerWorkloads')}
          </Link>
        );
      }

      policyState = vsWorkloadsIsReadable ? (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            <span data-tid="map-info-panel-row-label">{intl('Common.Enforcement')}</span>
          </th>
          <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            {policyStateLink}
          </td>
        </tr>
      ) : null;
    }

    let ipOverrides;
    let ipOverrideHeader;

    if (data.ipOverrides && data.ipOverrides.length) {
      ipOverrides = data.ipOverrides.map((ipList, index) => (
        <tr
          key={`Row-${index}`}
          className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
          data-tid="map-sub-info-panel-row"
        >
          <th colSpan="2" className="MapSubInfoPanel-Row-Label" key={ipList} data-tid="map-info-panel-row-value">
            <div className="MapInfoPanel-Container">{ipList}</div>
          </th>
        </tr>
      ));

      ipOverrideHeader = (
        <tr className="MapInfoPanel-Header">
          <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
            {intl('Common.IPOverrides', {count: data.ipOverrides ? data.ipOverrides.length : 0})}
          </th>
        </tr>
      );

      ipOverrides = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <div
              className="MapInfoPanel-Row--Scroll"
              ref={node => (this.scrollBox = node)}
              onScroll={this.handleScroll}
            >
              <table className="MapSubInfoPanel" ref={node => (this.row = node)}>
                <tbody>
                  {ipOverrideHeader}
                  {ipOverrides}
                </tbody>
              </table>
            </div>
            {this.state.scroll ? <div className="MapSubInfoPanel-More">...</div> : null}
          </td>
        </tr>
      );
    }

    return (
      <table className="MapInfoPanel">
        <tbody>
          {group}
          {roleLabel}
          {service}
          {boundWorkloads}
          {boundContainerWorkloads}
          {policyState}
          {ipOverrides}
        </tbody>
      </table>
    );
  },
});
