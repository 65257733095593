/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import _ from 'lodash';
import ParallelCoordinatesDimension from './ParallelCoordinatesDimension';
import ParallelCoordinatesLinks from './ParallelCoordinatesLinks';
import ParallelCoordinatesUtils from '../../../utils/Explorer/ParallelCoordinatesUtils';
import VizUtils from '../../../utils/Explorer/VizUtils';
import Tooltip from '../../../pages/Map/Tooltip.jsx';
import actionCreators from '../../../actions/actionCreators';
import intl from 'intl';

export default React.createClass({
  propTypes: {
    data: PropTypes.object,
    vizHighlighted: PropTypes.object,
    portSorting: PropTypes.string,
    isDataReady: PropTypes.bool,
  },

  getInitialState() {
    return {
      clickedTick: null,
    };
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(nextProps.data, this.props.data) ||
      !_.isEqual(nextProps.isDataReady, this.props.isDataReady) ||
      !_.isEqual(nextProps.vizHighlighted, this.props.vizHighlighted) ||
      !_.isEqual(nextProps.portSorting, this.props.portSorting) ||
      !_.isEqual(nextState.clickedTick, this.state.clickedTick)
    );
  },

  handleHighlight(name, dimension, evt) {
    let highlighted = {};
    const truncateThreshold = 30;

    if (name.length > truncateThreshold) {
      actionCreators.showMapTooltip({
        type: 'ExplorerLabel',
        tooltipInfo: {name},
        // Move the tooltip up so it doesn't fall off the bottom of the page
        location: {x: evt.pageX, y: evt.pageY - 100},
      });
    }

    _.forOwn(this.props.data.pcLinks, link => {
      if (link.data[dimension] === name) {
        highlighted = ParallelCoordinatesUtils.calculateHighlighted(highlighted, link, this.props.data.dimensions);
      }
    });
    this.props.onHighlight(highlighted);
  },

  handleUnHighlight() {
    actionCreators.hideMapTooltip();
    this.props.onHighlight({});
  },

  handleExpand(name, dimension) {
    if (dimension !== 'port') {
      //can't expand port dimension
      VizUtils.expandViz(dimension, name, this.props);
    }

    this.handleUnHighlight();
  },

  render() {
    const {width, height, selectedSource, selectedTarget, clickedTick, vizHighlighted, data} = this.props;
    const svgStyle = {width, height};
    const selected = {
      source: clickedTick.source
        ? clickedTick.source.clickedLabel === 'workload' && clickedTick.source.nextLabel === 'workload'
          ? intl('Common.Workloads')
          : clickedTick.source.nextLabel
        : selectedSource,
      target: clickedTick.target
        ? clickedTick.target.clickedLabel === 'workload' && clickedTick.target.nextLabel === 'workload'
          ? intl('Common.Workloads')
          : clickedTick.target.nextLabel
        : selectedTarget,
    };

    const pcLinks = (
      <ParallelCoordinatesLinks width={width} height={height} data={data} vizHighlighted={vizHighlighted} />
    );

    const pcDimensions = data.dimensions.map(dimension => (
      <ParallelCoordinatesDimension
        key={dimension}
        data={dimension}
        selected={selected}
        clickedTick={clickedTick}
        portSorting={this.props.portSorting}
        onUpdatePortSorting={this.props.onUpdatePortSorting}
        onUpdateProcessNameSorting={this.props.onUpdateProcessNameSorting}
        onUpdateOutboundProcessNameSorting={this.props.onUpdateOutboundProcessNameSorting}
        links={this.props.data.pcLinks}
        xCoord={this.props.data.xCoord}
        yCoord={this.props.data.yCoord}
        numTicks={this.props.data.numTicks}
        height={this.props.height}
        onClickTickText={this.handleExpand}
        onHoverTickText={this.handleHighlight}
        onUnHoverTickText={this.handleUnHighlight}
        vizHighlighted={this.props.vizHighlighted}
      />
    ));

    const pcSvg = (
      <svg className="PCSvg" style={svgStyle}>
        {pcDimensions}
      </svg>
    );

    return (
      <div className="ParallelCoordinates">
        {pcLinks}
        {pcSvg}
        <Tooltip />
      </div>
    );
  },
});
