/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import actionCreators from '../../actions/actionCreators';
import InstantSearch from './InstantSearch';
import {StoreMixin} from '../../mixins';
import {State} from 'react-router/lib';
import {KEY_ESCAPE, KEY_K} from 'keycode-js';
import {cmdOrCtrlPressed} from '../../utils/GeneralUtils';
import InstantSearchStore from '../../stores/InstantSearchStore';
import AnalyticsUtils from '../../utils/AnalyticsUtils';
import DataFormatUtils from '../../utils/DataFormatUtils';

function getStateFromStores() {
  return {
    isOpen: InstantSearchStore.isOpen(),
  };
}

export default React.createClass({
  mixins: [State, StoreMixin(InstantSearchStore, getStateFromStores)],
  getInitialState() {
    return {
      currentRoute: DataFormatUtils.pages.getRouteWithoutAppPrefix(
        this.getRoutes()
          .map(route => route.name)
          .join('.'),
      ),
    };
  },
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  },

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  },

  handleKeydown(evt) {
    // Escape key should close modal
    if (this.state.isOpen && evt.keyCode === KEY_ESCAPE) {
      actionCreators.closeInstantSearch();
    }

    // Open modal
    if (cmdOrCtrlPressed(evt) && evt.keyCode === KEY_K) {
      evt.preventDefault();
      evt.stopPropagation();

      if (!this.state.isOpen) {
        AnalyticsUtils.sendAnalyticsEvent('instantSearch.show', {
          routeName: this.state.currentRoute,
          method: 'shortcut',
          length: InstantSearchStore.getHistory()?.length,
        });

        actionCreators.openInstantSearch(<InstantSearch />);
      } else {
        actionCreators.closeInstantSearch();
      }

      return;
    }
  },

  render() {
    return null;
  },
});
