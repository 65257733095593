/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../actions/actionCreators';
import Dialog from '../components/Dialog.jsx';
import PortUtils from '../utils/PortUtils';

export default React.createClass({
  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        ref: 'confirmButton',
        onClick: this.handleClose,
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const {services} = this.props;
    const servicesList = [];

    services.forEach(ingressService => {
      const servicePorts = ingressService.service_ports || ingressService.windows_services;
      let className = 'IngressServiceItem';
      let serviceString = '';

      if (servicePorts) {
        serviceString = servicePorts
          .map(connection => PortUtils.stringifyPortObjectReadonly(connection, true))
          .join(', ');
      } else {
        serviceString = PortUtils.stringifyPortObjectReadonly(ingressService, true);
      }

      if (ingressService.created) {
        className += ' IngressServiceItem--created';
      }

      if (ingressService.deleted) {
        className += ' IngressServiceItem--deleted';
      }

      servicesList.push(
        <div className={className}>
          {ingressService.href ? (
            <div className="Service-Name-Dialog" data-tid="elem-service-name">
              <strong>{ingressService.name}</strong>
            </div>
          ) : null}
          <div className="Service-Name-Dialog-String" data-tid="elem-service-connections">
            {serviceString}
          </div>
        </div>,
      );
    });

    return (
      <Dialog ref="dialog" type="alert" alert={true} actions={this.getActions()} title={intl('Common.Services')}>
        <div className="RuleGridServiceDialog PolicyGeneratorGrid-port-protocol-list">{servicesList}</div>
      </Dialog>
    );
  },
});
