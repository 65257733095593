/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import GraphTransformStore from '../stores/GraphTransformStore';

export default {
  size: 40,
  hoverPadding: 4,
  corner: 2,
  padding: {top: 5, left: 5},
  paddingRadius: 4,
  textSize: 13,
  policySize: 42,
  duration: 750, //750, remove the transition duration for zoom to fit
  showLabelSize: 15, // the size to show role label,

  enter(selection) {
    this.position(selection, 0);
    this.addTitle(selection);
  },

  update(selection) {
    const roleRadius = this.size / 2;
    const scale = GraphTransformStore.getTransform().scale;
    const semanticScale = Math.pow(GraphTransformStore.getTransform().scale, 0.5);
    const enforcementPadding =
      selection.datum().policyState === 'enforced' || selection.datum().policyState === 'selective' ? 7 : 0;
    const roleFontSize =
      50 * scale < this.showLabelSize ? this.textSize / semanticScale - 2 : this.textSize / semanticScale + 2;

    selection.call(this.selectRole);
    selection.call(this.hoverRole);

    selection
      .select('.il-role-policyState')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', d => {
        return d.isLegend ? d.policySize / 2 + d.paddingRadius : this.policySize / 2 + this.paddingRadius;
      })
      .attr('stroke-width', 3)
      .attr('stroke', '#435e69')
      .attr('stroke-dasharray', d => {
        if (d.policyState === 'selective') {
          return d.isLegend
            ? [(Math.PI * d.policySize) / 6, (Math.PI * d.policySize) / 10]
            : [(Math.PI * this.policySize) / 12, (Math.PI * this.policySize) / 20];
        }
      })
      .attr('transform', d => (d.type === 'virtualServer' ? 'rotate(45)' : ''));

    selection
      .select('.il-role-rect')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', d => {
        return d.isLegend ? d.size : roleRadius;
      });

    selection
      .select('.il-role-workloadsNum')
      .attr('font-size', d => {
        const numLength = d.entityCounts.toString().length;

        if (numLength > 3) {
          return `${10 - 2 * (numLength - 4)}px`;
        }
      })
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .attr('fill', '#fff')
      .style('font-weight', 600)
      .style('pointer-events', 'none');

    selection
      .select('.il-role-text')
      .attr('dy', '.35em')
      .attr('font-size', this.textSize / scale)
      .attr('y', this.policySize / 2 + this.padding.top * 2)
      .attr('display', () => (45 * scale < this.showLabelSize ? 'none' : 'block'))
      .style({'pointer-events': 'fill', 'padding-left': '10px'});

    selection
      .select('.il-role-text-name')
      .attr('text-anchor', 'start')
      .attr('x', (-selection.select('.il-role-text-name').text().length * roleFontSize * 2) / 9)
      .attr('dy', '.35em')
      .attr('font-size', roleFontSize)
      .attr('y', this.policySize / 2 + enforcementPadding + (this.padding.top * 2) / semanticScale)
      .attr('display', () => (45 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style({'pointer-events': 'fill', 'padding-left': '10px'});

    selection
      .select('.il-role-text-vulnerability')
      .attr('text-anchor', 'end')
      .attr('x', (-(selection.select('.il-role-text-name').text().length + 3) * roleFontSize * 1) / 4.5)
      .attr('dy', '.35em')
      .attr('font-size', roleFontSize)
      .attr('stroke-width', 1)
      .attr('y', this.policySize / 2 + enforcementPadding + (this.padding.top * 2) / semanticScale)
      .attr('display', () => (45 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style({'pointer-events': 'fill', 'padding-left': '10px'});

    selection
      .select('.il-role-vulnerability')
      .attr('dx', '.35em')
      .attr('dy', '.35em')
      .attr('font-size', this.textSize / semanticScale)
      .attr('display', () => (45 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style({'pointer-events': 'fill', 'font-weight': 400 / semanticScale});

    selection
      .select('.il-role-internet')
      .attr('font-family', 'illumio-icons')
      .attr('text-anchor', 'middle')
      .attr('dy', '.46em')
      .attr('fill', '#3b4881') // @blue
      .attr('y', -this.policySize / 2.5)
      .attr('x', this.policySize / 2.5)
      .style('font-size', parseInt(this.size + 4, 10) + 'px')
      .text('\uE620');

    selection
      .select('.il-role-syncing')
      .attr('font-family', 'illumio-icons')
      .attr('text-anchor', 'end')
      .attr('x', (-selection.select('.il-role-text-name').text().length * roleFontSize * 1) / 4.5)
      .attr('dy', '.35em')
      .text('\uE660')
      .attr('font-size', roleFontSize + 10)
      .attr('stroke-width', 1)
      .attr('y', this.policySize / 2 + enforcementPadding + (this.padding.top * 3) / semanticScale)
      .attr('display', () => (45 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style({'pointer-events': 'fill', 'padding-left': '10px'});

    this.position(selection, this.duration);
    this.addTitle(selection);
  },

  position(selection, duration) {
    if (duration) {
      selection = selection.transition().duration(d => (d.drag ? 0 : duration));
    }

    selection.attr('transform', d => 'translate(' + d.x.toFixed(2) + ',' + d.y.toFixed(2) + ')');
  },

  addTitle(selection) {
    selection
      .select('.il-role-title')
      .attr('x', -this.policySize + this.padding.left * 8)
      .text(d => d.name);
  },

  selectRole(selection) {
    const policyState = selection.select('.il-role-policyState');
    const roleRect = selection.select('.il-role-rect');

    // If the policy state rect exists, clean role rect filter then apply filter on policyState
    // If not, apply filter on the role rect
    if (!policyState.empty()) {
      roleRect.attr('filter', 'none');
      policyState.attr('filter', d => (d.selected ? 'url(#selectFilter)' : 'none'));
    } else {
      roleRect.attr('filter', d => (d.selected ? 'url(#selectFilter)' : 'none'));
    }
  },

  hoverRole(selection) {
    const selectedRole = selection.select('.il-role-policyState').empty()
      ? selection.select('.il-role-rect')
      : selection.select('.il-role-policyState');

    selectedRole.attr('filter', d => {
      if (d.selected || d.selfHovered) {
        return 'url(#selectFilter)';
      }

      return 'none';
    });

    selection.select('.il-role-rect').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-role-policyState').style('stroke-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-role-text').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection
      .select('.il-role-vulnerability')
      .style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1))
      .style('stroke-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-role-internet').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));
  },

  dragRole(selection, drag, dragend) {
    selection.call(
      d3.behavior
        .drag()
        .on('dragstart', () => {
          d3.event.sourceEvent.stopPropagation();
          document.dispatchEvent(new Event('mousedown')); // for triggering menu-drop down close
        })
        .on('drag', () => {
          drag(d3.event.x, d3.event.y);
        })
        .on('dragend', () => {
          d3.event.sourceEvent.stopPropagation();
          dragend();
        }),
    );
  },
};
