/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import Field from '../components/Form/Field.jsx';
import actionCreators from '../actions/actionCreators';
import {Notification, OSLabelSelect, SpinnerOverlay} from '../components';
import {MapPageStore, TrafficFilterStore} from '../stores';
import {LabelUtils, RestApiUtils, GraphDataUtils} from '../utils';
import {VirtualServiceDialog} from 'scripts/modals';

export default React.createClass({
  propTypes: {
    workloads: PropTypes.array,
    onCancel: PropTypes.func,
    onComplete: PropTypes.func,
    saveWorkloads: PropTypes.bool,
    type: PropTypes.string,
  },

  getDefaultProps() {
    return {
      workloads: [],
      onCancel: _.noop,
      onComplete: _.noop,
      returnWorkloads: false,
      type: 'workload',
    };
  },

  getInitialState() {
    const roleLabels = [];
    const appLabels = [];
    const envLabels = [];
    const locLabels = [];
    let roleLabelsHrefs = [];
    let appLabelsHrefs = [];
    let envLabelsHrefs = [];
    let locLabelsHrefs = [];

    _.forEach(this.props.workloads, workload => {
      const workloadLabels = LabelUtils.buildLabelTypeObject(workload.labels);

      roleLabels.push(workloadLabels.role);
      appLabels.push(workloadLabels.app);
      envLabels.push(workloadLabels.env);
      locLabels.push(workloadLabels.loc);
    });

    roleLabelsHrefs = _.uniq(_.map(roleLabels, 'href'));
    appLabelsHrefs = _.uniq(_.map(appLabels, 'href'));
    envLabelsHrefs = _.uniq(_.map(envLabels, 'href'));
    locLabelsHrefs = _.uniq(_.map(locLabels, 'href'));

    const initialState = {
      roleLabel: roleLabelsHrefs.length === 1 ? roleLabels[0] : 'MIXED',
      appLabel: appLabelsHrefs.length === 1 ? appLabels[0] : 'MIXED',
      envLabel: envLabelsHrefs.length === 1 ? envLabels[0] : 'MIXED',
      locLabel: locLabelsHrefs.length === 1 ? locLabels[0] : 'MIXED',
    };

    return {
      roleLabel: initialState.roleLabel,
      appLabel: initialState.appLabel,
      envLabel: initialState.envLabel,
      locLabel: initialState.locLabel,
      loading: false,
      outOfScope: false,
      initialState,
    };
  },

  getActions() {
    const actionsWorkload = [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: this.handleCancel,
      },
      {
        text: intl('Common.OK'),
        type: 'primary',
        tid: 'ok',
        onClick: this.handleConfirm,
        disabled: !this.hasChanged(),
      },
    ];

    const actionsVS = [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: this.handleCancel,
      },
      {
        text: intl('Common.Next'),
        type: 'primary',
        tid: 'next',
        onClick: this.handleConfirm,
        disabled: !this.hasChanged() && this.props.AssignForUnmanagedWorkloads,
      },
    ];

    return this.props.type === 'workload' ? actionsWorkload : actionsVS;
  },

  handleCancel() {
    this.props.onCancel();
    this.handleClose();
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  handleOnComplete() {
    this.props.onComplete();
  },

  async handleConfirm() {
    const {roleLabel, appLabel, envLabel, locLabel} = this.state;
    const workloads = this.props.goBack ? this.props.changedWorkloads : this.props.workloads;
    const {changedWorkloads, changedLabels, deletedLabels} = this.updateWorkloads(
      workloads,
      roleLabel,
      appLabel,
      envLabel,
      locLabel,
    );

    if (this.props.type === 'virtualService') {
      actionCreators.openDialog(
        <VirtualServiceDialog
          virtualServices={workloads.map(virtualService => ({...virtualService, labels: changedLabels}))}
          onClose={this.handleClose}
          onComplete={this.handleOnComplete}
          closeHandler={actionCreators}
        />,
      );

      return;
    }

    this.setState({loading: true});

    if (this.props.returnWorkloads === true) {
      this.handleClose();
      this.props.onComplete(workloads, changedWorkloads);

      return;
    }

    try {
      const errResult = await RestApiUtils.workloads.setLabels(changedWorkloads, changedLabels, deletedLabels);

      if (
        errResult &&
        errResult.length &&
        errResult[0].token === 'rbac_cannot_operate_on_resource_with_broader_scope'
      ) {
        this.setState({outOfScope: true});
        this.handleClose();

        const actions = [
          {
            text: intl('Common.OK'),
            type: 'primary',
            tid: 'ok',
            ref: 'confirmButton',
            onClick: () => {
              actionCreators.closeDialog();
            },
          },
        ];

        actionCreators.openDialog(
          <Dialog type="detail" className="IncreaseVenUpdate" title={intl('Labels.Edit')} actions={actions}>
            <Notification type="error" message={<span>{errResult[0].message}</span>} />
          </Dialog>,
        );
      }

      if (MapPageStore.getMapType() === 'app' || MapPageStore.getMapType() === 'loc') {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Add a delay so as to allow time to recompute the location summary.
        await GraphDataUtils.loadLevelTraffic('rebuild', TrafficFilterStore.getTransmissionFilters());
      }
    } finally {
      if (!this.state.outOfScope) {
        this.handleClose();
        this.props.onComplete();
      }
    }
  },

  handleLabelAppClear() {
    this.handleLabelSelect('app');
  },

  handleLabelAppSelect(added, removed) {
    this.handleLabelSelect('app', added, removed);
  },

  handleLabelAppUndo() {
    this.handleLabelSelect('app', this.state.initialState.appLabel);
  },

  handleLabelEnvClear() {
    this.handleLabelSelect('env');
  },

  handleLabelEnvSelect(added, removed) {
    this.handleLabelSelect('env', added, removed);
  },

  handleLabelEnvUndo() {
    this.handleLabelSelect('env', this.state.initialState.envLabel);
  },

  handleLabelLocClear() {
    this.handleLabelSelect('loc');
  },

  handleLabelLocSelect(added, removed) {
    this.handleLabelSelect('loc', added, removed);
  },

  handleLabelLocUndo() {
    this.handleLabelSelect('loc', this.state.initialState.locLabel);
  },

  handleLabelRoleClear() {
    this.handleLabelSelect('role');
  },

  handleLabelRoleSelect(added, removed) {
    this.handleLabelSelect('role', added, removed);
  },

  handleLabelRoleUndo() {
    this.handleLabelSelect('role', this.state.initialState.roleLabel);
  },

  handleLabelSelect(type, added) {
    const newState = _.cloneDeep(this.state);

    if (added) {
      newState[type + 'Label'] = added;
    } else {
      delete newState[type + 'Label'];
    }

    this.replaceState(newState);
  },

  hasChanged() {
    return (
      this.labelHasChanged('role') ||
      this.labelHasChanged('app') ||
      this.labelHasChanged('env') ||
      this.labelHasChanged('loc')
    );
  },

  labelHasChanged(type) {
    let originalValue = this.state.initialState[type + 'Label'];
    let newValue = this.state[type + 'Label'];

    if (originalValue && originalValue !== 'MIXED') {
      originalValue = originalValue.href;
    }

    if (newValue && newValue !== 'MIXED') {
      newValue = newValue.href;
    }

    return originalValue !== newValue;
  },

  updateWorkloads(workloads, role, app, env, loc) {
    const changedWorkloads = [];
    const changedLabels = [];
    const deletedLabels = [];

    if (!role) {
      deletedLabels.push('role');
    }

    if (!app) {
      deletedLabels.push('app');
    }

    if (!env) {
      deletedLabels.push('env');
    }

    if (!loc) {
      deletedLabels.push('loc');
    }

    if (role && role !== 'MIXED' && this.labelHasChanged('role')) {
      changedLabels.push(role);
    }

    if (app && app !== 'MIXED' && this.labelHasChanged('app')) {
      changedLabels.push(app);
    }

    if (env && env !== 'MIXED' && this.labelHasChanged('env')) {
      changedLabels.push(env);
    }

    if (loc && loc !== 'MIXED' && this.labelHasChanged('loc')) {
      changedLabels.push(loc);
    }

    _.forEach(workloads, w => {
      const labels = LabelUtils.buildLabelTypeObject(w.labels);

      const newLabels = [];

      if (role === 'MIXED') {
        if (labels.role) {
          newLabels.push(labels.role);
        }
      } else if (role) {
        newLabels.push(role);
      }

      if (app === 'MIXED') {
        if (labels.app) {
          newLabels.push(labels.app);
        }
      } else if (app) {
        newLabels.push(app);
      }

      if (env === 'MIXED') {
        if (labels.env) {
          newLabels.push(labels.env);
        }
      } else if (env) {
        newLabels.push(env);
      }

      if (loc === 'MIXED') {
        if (labels.loc) {
          newLabels.push(labels.loc);
        }
      } else if (loc) {
        newLabels.push(loc);
      }

      changedWorkloads.push({
        href: w.href,
        labels: newLabels,
      });
    });

    return {changedWorkloads, changedLabels, deletedLabels};
  },

  render() {
    const workloadsCount = this.props.workloads.length;

    const clearedLabels = [];

    if (this.state.initialState.roleLabel && !this.state.roleLabel) {
      clearedLabels.push('role');
    }

    if (this.state.initialState.appLabel && !this.state.appLabel) {
      clearedLabels.push('app');
    }

    if (this.state.initialState.envLabel && !this.state.envLabel) {
      clearedLabels.push('env');
    }

    if (this.state.initialState.locLabel && !this.state.locLabel) {
      clearedLabels.push('loc');
    }

    let clearMessage = null;

    if (clearedLabels.length) {
      const list = clearedLabels.map(LabelUtils.getLabelTypeName);
      const names = intl.list(list);

      clearMessage = (
        <Notification
          type="warning"
          sidebar="false"
          message={intl(
            'Labels.ClearMessage',
            {
              labelNames: names,
              count: workloadsCount,
            },
            {html: true},
          )}
        />
      );
    }

    const title =
      this.props.type === 'workload'
        ? this.props.AssignForUnmanagedWorkloads
          ? intl('Labels.Assign')
          : intl('Labels.Edit')
        : intl('Labels.AssignVS');

    return (
      <Dialog ref="dialog" className="EditLabels" title={title} actions={this.getActions()}>
        {this.state.loading ? <SpinnerOverlay /> : null}
        <div className="Edit-notification">{clearMessage}</div>
        <div className="EditLabelsDialog">
          {this.props.type === 'workload' ? (
            <div className="EditLabels-Instructions">
              {intl(
                this.props.AssignForUnmanagedWorkloads
                  ? 'Labels.ModifyForUnmanagedWorkloads'
                  : 'Labels.ModifyForWorkloads',
                {
                  count: workloadsCount,
                },
                {html: true},
              )}
            </div>
          ) : null}

          <Field
            label={LabelUtils.getLabelTypeName('role')}
            tid="labelrole"
            hint={() => {
              if (this.labelHasChanged('role')) {
                return (
                  <div className="EditLabels-Undo" onClick={this.handleLabelRoleUndo}>
                    Undo
                  </div>
                );
              }
            }}
            control={
              this.state.roleLabel === 'MIXED' ? (
                <div onClick={this.handleLabelRoleClear} className="EditLabels-Mixed">
                  Multiple Role Labels. Click to edit.
                </div>
              ) : (
                <OSLabelSelect
                  onChange={this.handleLabelRoleSelect}
                  type="role"
                  selected={this.state.roleLabel ? [this.state.roleLabel] : []}
                  allowMultiSelect={false}
                  tid="rolelabel"
                />
              )
            }
          />

          <Field
            label={LabelUtils.getLabelTypeName('app')}
            tid="labelapp"
            hint={() => {
              if (this.labelHasChanged('app')) {
                return (
                  <div className="EditLabels-Undo" onClick={this.handleLabelAppUndo}>
                    Undo
                  </div>
                );
              }
            }}
            control={
              this.state.appLabel === 'MIXED' ? (
                <div onClick={this.handleLabelAppClear} className="EditLabels-Mixed">
                  Multiple Application Labels. Click to edit.
                </div>
              ) : (
                <OSLabelSelect
                  onChange={this.handleLabelAppSelect}
                  type="app"
                  selected={this.state.appLabel ? [this.state.appLabel] : []}
                  allowMultiSelect={false}
                  tid="applabel"
                />
              )
            }
          />

          <Field
            label={LabelUtils.getLabelTypeName('env')}
            tid="labelenv"
            hint={() => {
              if (this.labelHasChanged('env')) {
                return (
                  <div className="EditLabels-Undo" onClick={this.handleLabelEnvUndo}>
                    Undo
                  </div>
                );
              }
            }}
            control={
              this.state.envLabel === 'MIXED' ? (
                <div onClick={this.handleLabelEnvClear} className="EditLabels-Mixed">
                  Multiple Environment Labels. Click to edit.
                </div>
              ) : (
                <OSLabelSelect
                  onChange={this.handleLabelEnvSelect}
                  type="env"
                  selected={this.state.envLabel ? [this.state.envLabel] : []}
                  allowMultiSelect={false}
                  tid="envlabel"
                />
              )
            }
          />

          <Field
            label={LabelUtils.getLabelTypeName('loc')}
            tid="labelloc"
            hint={() => {
              if (this.labelHasChanged('loc')) {
                return (
                  <div className="EditLabels-Undo" onClick={this.handleLabelLocUndo}>
                    Undo
                  </div>
                );
              }
            }}
            control={
              this.state.locLabel === 'MIXED' ? (
                <div onClick={this.handleLabelLocClear} className="EditLabels-Mixed">
                  Multiple Location Labels. Click to edit.
                </div>
              ) : (
                <OSLabelSelect
                  onChange={this.handleLabelLocSelect}
                  type="loc"
                  selected={this.state.locLabel ? [this.state.locLabel] : []}
                  allowMultiSelect={false}
                  tid="loclabel"
                />
              )
            }
          />
        </div>
      </Dialog>
    );
  },
});
