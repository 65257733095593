/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createStore} from '../lib/store';
import Constants from '../constants';
import dispatcher from '../actions/dispatcher';
import ActionBarUtils from '../utils/ActionBarUtils';
import RenderUtils from '../utils/RenderUtils';
import intl from 'intl';
import {FilterStore, MapPageStore, TrafficFilterStore, TrafficStore} from '.';

let fullSearch = {};
let locationSearch = {};
let appGroupSearch = {};
let policyStateFilters;

function calculateFullMapSearch() {
  const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();

  let groups = _.transform(
    TrafficStore.getGroupSearch(),
    (result, group) => {
      const totalPolicyStateFiltered = RenderUtils.getPolicyStateGroupWorkloads(group, policyStateFilters);

      if (group.entityCounts > totalPolicyStateFiltered) {
        result[intl('Map.Search.Group', {group: group.name})] = {
          ...group,
          workloads: group.workloadCounts - totalPolicyStateFiltered,
        };
      }
    },
    {},
  );

  groups = getMappedGroupSearchData(groups);

  const workloads = _.transform(
    TrafficStore.getAllWorkloadNodes(),
    (result, workload) => {
      if (workload && workload.href && workload.href.includes('/workloads/')) {
        const role = workload.labels && workload.labels.find(label => label.key === 'role');

        if (
          !policyStateFilters.includes(
            (RenderUtils.getPolicyState(workload, 'workload') || 'unmanaged').split('ing')[0],
          )
        ) {
          if (role) {
            result[intl('Map.Search.WorkloadWithRole', {workload: workload.name, role: role.value})] = workload;
          } else {
            result[intl('Map.Search.Workload', {workload: workload.name})] = workload;
          }
        }
      } else if (workload && workload.href && workload.href.includes('virtual_services')) {
        result[intl('Map.Search.VirtualService', {virtualService: workload.name})] = workload;
      } else if (workload && workload.href && workload.href.includes('virtual_servers')) {
        result[intl('Map.Search.VirtualServer', {virtualServer: workload.name})] = workload;
      } else if (workload && workload.href && workload.href.includes('container')) {
        result[intl('Map.Search.ContainerWorkload', {workload: workload.name})] = workload;
      }
    },
    {},
  );

  fullSearch = {
    options: {...groups, ...workloads},
    workloadCount: workloads ? Object.keys(workloads).length : 0,
    groupCount: groups ? Object.keys(groups).length : 0,
  };
}

function calculateLocationSearch() {
  const locationFilteredWorkloads = {};
  const filteredLocations = {};

  if (!policyStateFilters) {
    policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();
  }

  let groups = _.transform(
    TrafficStore.getGroupSearch(),
    (result, group) => {
      const nodeLabelHrefs = group.labels.map(label => label.href);

      // Insure the groups are in the filtered labels
      if (FilterStore.getScopeFiltersNoRole().every(filterLabel => nodeLabelHrefs.includes(filterLabel.href))) {
        const location = group.labels.find(label => label.key === 'loc');
        const totalPolicyStateFiltered = RenderUtils.getPolicyStateGroupWorkloads(group, policyStateFilters);

        if (locationFilteredWorkloads[location.href]) {
          locationFilteredWorkloads[location.href] += totalPolicyStateFiltered;
        } else {
          locationFilteredWorkloads[location.href] = totalPolicyStateFiltered;
        }

        group.workloads = group.workloadCounts - totalPolicyStateFiltered;

        if (group.entityCounts > totalPolicyStateFiltered) {
          if (filteredLocations[location.href]) {
            filteredLocations[location.href].groups += 1;
          } else {
            filteredLocations[location.href] = {name: location.name, groups: 1};
          }

          result[intl('Map.Search.Group', {group: group.name})] = group;
        }
      }
    },
    {},
  );

  groups = getMappedGroupSearchData(groups);

  // shouldn't show discovered and no_location locations in location search panel in any level
  const locations = _.transform(
    TrafficStore.getLocations(),
    (result, location) => {
      // Make sure the location is in the label filtered set of locations
      if (filteredLocations[location.href]) {
        result[intl('Map.Search.Location', {location: location.name})] = {
          ...location,
          groups: filteredLocations[location.href].groups,
          workloads: location.workloads - locationFilteredWorkloads[location.href],
        };
      }
    },
    {},
  );

  locationSearch = {
    options: {...locations, ...groups},
    locationCount: locations ? Object.keys(locations).length : 0,
    groupCount: groups ? Object.keys(groups).length : 0,
  };
}

function calculateAppGroupSearch() {
  const appGroups = _.transform(
    TrafficStore.getTrafficAppGroupNodes().sort((a, b) => {
      // We don't calculate traffic for nodes over 5000, so we want to put them at the end
      // But this is configurable, so how do we get this number?
      if (a.workloads > 5000) {
        return 1;
      }

      if (b.workloads > 5000) {
        return -1;
      }

      return b.workloads - a.workloads;
    }),
    (result, appGroup) => {
      result[appGroup.name] = appGroup;
    },
    {},
  );

  appGroupSearch = {
    options: appGroups,
    appGroupCount: appGroups ? Object.keys(appGroups).length : 0,
  };
}

function getMappedGroupSearchData(options) {
  return ActionBarUtils.getGroupActionValues(options, 35, [15, 10, 10], `${intl('Common.Group')}: `);
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.UPDATE_MAP_ROUTE:
      case Constants.SET_APP_MAP_VERSION:
      case Constants.UPDATE_MAP_TYPE:
      case Constants.USERS_LOGIN_SUCCESS:
        dispatcher.waitFor([MapPageStore.dispatchToken]);
        this.emitChange();

        return true;

      case Constants.SET_TOTAL_WORKLOADS:
      case Constants.UPDATE_TRAFFIC_PARAMETERS:
        dispatcher.waitFor([MapPageStore.dispatchToken]);
        break;

      case Constants.RESET_DEFAULT_FILTERS:
      case Constants.SELECT_TRAFFIC_FILTERS:
        dispatcher.waitFor([TrafficFilterStore.dispatchToken]);

        if (_.isEqual(policyStateFilters, TrafficFilterStore.getHiddenPolicyStates())) {
          // Do not recalculate if nothing has changed;
          return;
        }

        policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();
        break;

      case Constants.ADD_FILTERS:
      case Constants.REMOVE_FILTER:
        dispatcher.waitFor([FilterStore.dispatchToken]);
        break;

      case Constants.NETWORK_TRAFFIC_GET_SUCCESS:
      case Constants.LOCATION_SUMMARY_GET_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
      case Constants.APP_GROUPS_OBSERVED_RULE_COVERAGE_SUCCESS:
      case Constants.APP_GROUP_OBSERVED_RULE_COVERAGE_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      default:
        return true;
    }

    if (MapPageStore.getLocMapType() === 'full') {
      calculateFullMapSearch();
    } else {
      calculateLocationSearch();
    }

    calculateAppGroupSearch();

    this.emitChange();

    return true;
  },

  getSearch() {
    if (MapPageStore.getMapType() === 'app') {
      return appGroupSearch;
    }

    if (MapPageStore.getMapLevel() === 'full') {
      return fullSearch;
    }

    return locationSearch;
  },
});
