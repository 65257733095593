/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {Navbar} from '../../components';
import {RouterMixin, PolicyGeneratorMixin} from '../../mixins';
import {RBSuccessMessage} from '../../components/PolicyGenerator';
import {GroupDataUtils, RenderUtils} from '../../utils';
import {AppGroupTabs} from '../Groups';

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin],

  componentWillMount() {
    const state = this.getIntraSuccessInfo();

    if (!state.appGroupName && !(this.getParams() && this.getParams().id)) {
      this.transitionTo('policygenerator');
    }

    // getSuccessInfo returns an obj with following keys: appGroupName, newRules, newServices, rulesetId
    this.setState(state);
  },

  onContinue() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupPolicyGenerator', {id});
    } else {
      this.transitionTo('policygenerator');
    }
  },

  onSelectAnother() {
    this.setAppGroup();
    this.transitionTo('policygenerator');
  },

  onGoToRule() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupRules', {id});
    } else {
      this.transitionTo('rulesets.item', {id: this.state.rulesetId, pversion: 'draft', tab: 'intrascope'});
    }
  },

  onGoToAppGroupMap() {
    actionCreators.setMapPolicyState('draft');
    this.transitionTo('appMapLevel', {
      type: 'focused',
      id: this.state.appGroupHref,
    });
  },

  render() {
    const {appGroupName, newRules, newServices, appGroup} = this.state;
    const id = this.getParams() && this.getParams().id;

    let title = intl('Common.Success');
    let appGroupTabs = null;

    if (id) {
      const mapRoute = GroupDataUtils.getMapRoute(appGroup, id, this.mapLevel, 'appgroups');

      title = RenderUtils.truncateAppGroupName(appGroup.name, 45, [30, 15, 10]);
      appGroupTabs = (
        <div className="GroupBar">
          <AppGroupTabs active="policyGenerator" mapRoute={mapRoute} />
        </div>
      );
    }

    return (
      <div className="RBSuccess">
        <Navbar title={title} />
        {appGroupTabs}
        <RBSuccessMessage
          name={appGroupName}
          newRules={newRules}
          newServices={newServices}
          type="intra"
          continue={this.onContinue}
          selectAnother={id ? null : this.onSelectAnother}
          goToRule={this.onGoToRule}
          goToAppGroupMap={this.onGoToAppGroupMap}
        />
      </div>
    );
  },
});
