/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import Icon from './Icon.jsx';

export default React.createClass({
  propTypes: {
    data: PropTypes.object.isRequired,
    showContent: PropTypes.bool,
    onToggle: PropTypes.func,
    index: PropTypes.number,
    after: PropTypes.bool,
    tid: PropTypes.string,
    caretHighlight: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      index: 0,
      onToggle: _.noop,
    };
  },

  getInitialState() {
    return {showContent: false};
  },

  componentWillMount() {
    document.addEventListener('click', this.handleDocumentClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  },

  isContentVisible() {
    return this.props.showContent !== undefined ? this.props.showContent : this.state.showContent;
  },

  handleToggle(evt) {
    evt.stopPropagation();

    if (this.isContentVisible()) {
      this.props.onToggle(false, this.props.index);
      this.setState({showContent: false});
    } else {
      this.props.onToggle(true, this.props.index);
      this.setState({showContent: true});
    }
  },

  handleDocumentClick(evt) {
    if (!this.element.contains(evt.target)) {
      this.setState({showContent: false});
    }
  },

  handleElementRef(element) {
    this.element = element;
  },

  render() {
    return (
      <div className="ToggleDropdown" ref={this.handleElementRef}>
        {this.isContentVisible() && this.props.center ? (
          <div className="ToggleDropdown-Content ToggleDropdown-Hidden">{this.props.data.content}</div>
        ) : null}
        <div className="ToggleDropdown-Title" onClick={this.handleToggle} data-tid={this.props.tid}>
          {this.props.after ? this.props.data.title : null}
          <span className={this.props.caretHighlight ? 'ToggleDropdown-Chevron-Selected' : 'ToggleDropdown-Chevron'}>
            <Icon name={this.isContentVisible() || this.props.after ? 'caret-down' : 'caret-right'} position="before" />
          </span>
          {!this.props.after ? this.props.data.title : null}
        </div>
        {this.isContentVisible() ? <div className="ToggleDropdown-Content">{this.props.data.content}</div> : null}
      </div>
    );
  },
});
