/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import RestApiUtils from './RestApiUtils';
import _ from 'lodash';
import {webStorageUtils} from 'utils';
import intl from 'intl';
import {IpListStore} from '../stores';
import {setProperPortProtocolFacetItems} from './PortProtocolsUtils';

// Extract the proper scopes from Selective Enforcement
// Make the data structure for tesse react similar to legacy for selective enforcement
const getLabels = objectLabels => {
  const labels = ['providers', 'consumers'].reduce(
    (newCur, cur) => {
      objectLabels[cur].forEach(item => {
        if (item?.label) {
          // label
          newCur[cur].push(item);
        }

        if (item?.label_group) {
          // label groups
          newCur[cur].push(item);
        }

        if (item?.ip_list) {
          // ip list
          newCur[cur].push({ip_list: {href: item.ip_list.href, name: item.ip_list.name}});
        }

        if (item?.actors) {
          // all workloads
          newCur[cur].push({actors: {allWorkloads: true}});
        }
      });

      return newCur;
    },
    {providers: [], consumers: []},
  );

  return labels;
};

/**
 *  Get the providers and consumers
 * @param enforcement
 * @returns {{ipLists: *[], labelGroups: *[], allWorkloads: *[], labels: *[]}}
 */
const getScopeEnforcementLabels = enforcement => {
  const data = {
    labels: [],
    labelGroups: [],
    ipLists: [],
    allWorkloads: [],
  };

  let anyIpList = false;

  enforcement.forEach(label => {
    if (label.label) {
      data.labels.push(label.label);
    } else if (label.label_group) {
      data.labelGroups.push(label.label_group);
    } else if (label.ip_list) {
      if (label.ip_list.href !== IpListStore.getAnyIpList()?.href) {
        data.ipLists.push(label.ip_list);
      } else {
        anyIpList = true;
      }
    }
  });

  if (anyIpList) {
    data.ipLists = [];
  }

  return data;
};

const getIngressServices = services => {
  const data = {
    [intl('Port.Port')]: [],
    [intl('Port.PortRange')]: [],
    [intl('Common.Protocol')]: [],
    [intl('RuleSearch.WindowsProcessName')]: [],
    [intl('RuleSearch.WindowsServiceName')]: [],
  };
  let allServices = false;

  services.forEach(item => {
    if (!item.port) {
      // Ingress Services
      if (item.name === intl('Common.AllServices')) {
        // All Services
        allServices = true;
      } else {
        (item.service_ports || item.windows_services).forEach(service => {
          const {port, proto, to_port, process_name, service_name} = service;
          const {name} = setProperPortProtocolFacetItems(service);

          if (to_port) {
            data[intl('Port.PortRange')].push({key: 'portRange', name, href: name, value: name});
          } else if (port && proto) {
            data[intl('Port.Port')].push({key: 'portProtocol', name, href: name, value: name});
          } else if (proto) {
            data[intl('Common.Protocol')].push(name);
          }

          if (process_name) {
            data[intl('RuleSearch.WindowsProcessName')].push({
              key: 'process_name',
              name: process_name,
              href: process_name,
              value: process_name,
            });
          }

          if (service_name) {
            data[intl('RuleSearch.WindowsServiceName')].push({
              key: 'service_name',
              name: service_name,
              href: service_name,
              value: service_name,
            });
          }
        });
      }
    } else if (item.to_port && item.proto && item.port) {
      // Range
      const {name} = setProperPortProtocolFacetItems(item);

      data[intl('Port.PortRange')].push({key: 'portRange', name, href: name, value: name});
    } else {
      // Port
      const {name} = setProperPortProtocolFacetItems(item);

      data[intl('Port.Port')].push({key: 'portProtocol', name, href: name, value: name});
    }
  });

  if (allServices) {
    // Do not scope the rule search by any service
    return {
      [intl('Port.Port')]: [],
      [intl('Port.PortRange')]: [],
      [intl('RuleSearch.WindowsProcessName')]: [],
      [intl('RuleSearch.WindowsServiceName')]: [],
      [intl('Common.Protocol')]: [],
    };
  }

  return data;
};

// e.g. Explorer(legacy)-> Rules(tesse react), Rules(tesse react)->Explorer(legacy)
// Make the data structure for tesse react similar to legacy for selective enforcement
const getSegmentationLabels = (versions, id, setSession = false) => {
  const {pversionObj, prevPversionObj} = versions;

  let prevPversionObjLabels;

  // Active version
  if (prevPversionObj) {
    // Note: scope will have both label and label_group
    prevPversionObjLabels = getLabels(prevPversionObj);
  }

  // Draft version
  // Note: scope will have both label and label_group
  const pversionObjLabels = getLabels(pversionObj);
  const scopeLabels = {};

  if (prevPversionObj) {
    // When active exist then there is a draft version and active version
    scopeLabels.active = {
      ...prevPversionObjLabels,
      ingress_services: prevPversionObj.ingress_services,
      href: prevPversionObj.href,
    };
    scopeLabels.draft = {...pversionObjLabels, ingress_services: pversionObj.ingress_services, href: pversionObj.href};
  } else {
    // When active doesn't exist then only pversionObj then set both active and draft are the same
    scopeLabels.active = {...pversionObjLabels, ingress_services: pversionObj.ingress_services, href: pversionObj.href};
    scopeLabels.draft = {...pversionObjLabels, ingress_services: pversionObj.ingress_services, href: pversionObj.href};
  }

  // scopeLabels are the labels/label groups specific selective rule instance user has
  if (setSession) {
    webStorageUtils.setSessionItem('selectiveInstanceLabels', {scopeLabels, id});
  }

  return {scopeLabels, id};
};

// This method has same logic as Tesse React "EnforcementBoundariesItemSaga.fetchEnforcementBoundariesItem"
// to get all versions.
// Make the data structure for tesse react similar to legacy for selective enforcement
async function handleEnforcementBoundariesLists(pversion, id) {
  /*
    This is needed when the user is logged out of Selective Enforcement Rules tab.
    Then when they relogin, need to retrieve the proper labels.
   */

  const version = Number(pversion);
  // pversion is numeric when navigating from policy version thus check for numeric
  // e.g. #/versions
  // When looking at /draft, /active then pversion is non-numeric
  const isOldVersion = !_.isNaN(pversion) && pversion > 0;

  const enforcementBoundaryId = id;

  const promises = [
    RestApiUtils.enforcementBoundaries
      .get(enforcementBoundaryId, 'active', true, {representation: 'labels_workloads_count'})
      .catch(err => {
        if (err.status !== 404) {
          // Mute 404 on this resource
          throw err;
        }
      }),
    RestApiUtils.enforcementBoundaries
      .get(enforcementBoundaryId, 'draft', true, {representation: 'labels_workloads_count'})
      .catch(err => {
        if (err.status !== 404) {
          // Mute 404 on this resource
          throw err;
        }
      }),
    ...(isOldVersion
      ? RestApiUtils.enforcementBoundary
          .get(enforcementBoundaryId, version, true, {representation: 'labels_workloads_count'})
          .catch(err => {
            if (err.status !== 404) {
              // Mute 404 on this resource
              throw err;
            }
          })
      : []),
    ...(isOldVersion && pversion > 1
      ? RestApiUtils.enforcementBoundary
          .get(enforcementBoundaryId, version - 1, true, {representation: 'labels_workloads_count'})
          .catch(err => {
            if (err.status !== 404) {
              // Mute 404 on this resource
              throw err;
            }
          })
      : []),
  ];

  const [active, draft, oldPversionObj, oldPrevPversionObj] = await Promise.all(promises);

  const data = {
    active: active?.body,
    draft: draft?.body,
    ...(oldPversionObj ? {oldPversionObj: oldPversionObj?.body} : {}),
    ...(oldPrevPversionObj ? {oldPrevPversionObj: oldPrevPversionObj?.body} : {}),
  };

  // If both draft and active are not invalid, redirect to the list page
  // draft and active is undefined for deleted policy object
  // Redirect to list page if pversion is invalid
  const validItem = oldPversionObj || oldPrevPversionObj || draft || active;
  const validPversion = isOldVersion || ['draft', 'active'].includes(pversion);

  if (!validItem || !validPversion || (isOldVersion && !oldPversionObj && !oldPrevPversionObj)) {
    throw new Error('Invalid Version');
  }

  return data;
}

// Note: This mirror Tesse React ProvisioningUtils
// Make the data structure for tesse react similar to legacy for selective enforcement
export const getSelectivePolicyVersions = (detail = {}, pversion) => {
  // When isOldVersion is true then the pversion is numeric
  const isOldVersion = !_.isNaN(Number(pversion));
  const {active, draft, oldPversionObj, oldPrevPversionObj} = detail;

  // Scenario to describe oldPversionObj.
  // When viewing page: https://devtest201:8443/#/iplists/136/941 an API call to
  // 'https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136' is invoked to get 'oldPversionObj'.

  //  941 - is the pversion on uri: https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136
  //  136 - is the ip_lists id on uri: https://devtest201:8443/api/v2/orgs/1/sec_policy/941/ip_lists/136
  // When 'oldPversionObj', !draft , and !active are undefined then this pversion is DELETED (aka deleted policy object).
  // When 'oldPversionObj' is undefined, draft and active both exist then this pversion has been RESTORED (aka restored policy object).

  const pversionObjIsDeleted = !oldPversionObj && Boolean(oldPrevPversionObj);

  const versions = {draft, active};

  if (pversionObjIsDeleted) {
    // show content of last valid version for deleted policy object and restored policy object
    versions.pversionObj = oldPrevPversionObj;
  } else if (isOldVersion) {
    versions.pversionObj = oldPversionObj;
    versions.prevPversionObj = oldPrevPversionObj;
  } else if (pversion === 'draft') {
    versions.pversionObj = draft;
    versions.prevPversionObj = active;
  } else {
    versions.pversionObj = active;
  }

  return {versions, isOldVersion, pversionObjIsDeleted};
};

export const getEnforcementRulesets = async ({enforcementExternals = {}}) => {
  const data = await RestApiUtils.ruleSets.getCollection(
    {
      representation: 'rule_set_services_labels_and_names',
      ...enforcementExternals,
    },
    // Always get the draft enforcement rules
    'draft',
    true,
  );

  return data;
};

export default {
  getLabels,
  getSegmentationLabels,
  handleEnforcementBoundariesLists,
  getSelectivePolicyVersions,
  getScopeEnforcementLabels,
  getIngressServices,
  getEnforcementRulesets,
};
