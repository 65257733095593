/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import ExplorerActions from '../../actions/ExplorerActions';
import ParallelCoordinatesUtils from './ParallelCoordinatesUtils';
import OrgStore from '../../stores/OrgStore';
import intl from 'intl';
import {SessionStore} from '../../stores';

const ipLists = {};
const initAxisSort = {
  'Labels': 1,
  'FQDNs': 2,
  'IP List': 3,
  'IP Address': 4,
};
let multiSrcTypes = [];
let multiDstTypes = [];
export default {
  calculateAndPositionViz,
  expandViz,
  sortingLinksByPorts,
  sortingLinksByProcessName,
  sortingLinksByOutboundProcessName,
  getCurentWorkloadType,
};

export function calculateAndPositionViz(
  vizProps,
  portSorting,
  processNameSorting,
  outBoundProcessNameSorting,
  width,
  height,
) {
  const filteredLinks = calculateVizLinks(vizProps);
  const viz = calculateAndPositionVizLinks(
    filteredLinks,
    portSorting,
    processNameSorting,
    outBoundProcessNameSorting,
    width,
    height,
    vizProps.clickedTick,
  );

  if (
    (vizProps.clickedTick && vizProps.clickedTick.source && vizProps.clickedTick.source.clickedLabel === 'ip_list') ||
    (vizProps.clickedTick && vizProps.clickedTick.target && vizProps.clickedTick.target.clickedLabel === 'ip_list')
  ) {
    filteredLinks.forEach(link => {
      if (vizProps.clickedTick.source && vizProps.clickedTick.source.clickedLabel === 'ip_list') {
        link.source = link.sourceIP;
      } else if (vizProps.clickedTick.target && vizProps.clickedTick.target.clickedLabel === 'ip_list') {
        link.target = link.targetIP;
      }
    });
  }

  return {filteredLinks, viz};
}

function getDimensionHeight(height) {
  return height * 0.85;
}

function calculateVizLinks(vizProps) {
  const {links, clickedTick, clickedTickPath, selectedSource, selectedTarget} = vizProps;
  let clickedSrcKey;
  let clickedDstKey;
  let sKey;
  let tKey;
  let sType;
  let tType;
  let ipListType;
  let ipType;
  let filteredLinks = [];
  const isIPListSelected =
    (clickedTick.source && clickedTick.source.clickedLabel === 'ip_list') ||
    (clickedTick.target && clickedTick.target.clickedLabel === 'ip_list');

  if (_.isEmpty(clickedTick.source)) {
    sKey = 'src_' + selectedSource;
    sType = selectedSource;
  } else {
    clickedSrcKey = 'src_' + clickedTick.source.clickedLabel;
    sKey = _.isEmpty(clickedTick.source.nextLabel)
      ? clickedTick.source.clickedLabel === 'ip_list'
        ? clickedTick.source.clickedValue
        : clickedSrcKey
      : 'src_' + clickedTick.source.nextLabel;
    sType = clickedTick.source.clickedLabel === 'ip_list' ? 'ip' : clickedTick.source.nextLabel;
  }

  if (_.isEmpty(clickedTick.target)) {
    tKey = 'dst_' + selectedTarget;
    tType = selectedTarget;
  } else {
    clickedDstKey = 'dst_' + clickedTick.target.clickedLabel;
    tKey = _.isEmpty(clickedTick.target.nextLabel)
      ? clickedTick.target.clickedLabel === 'ip_list'
        ? clickedTick.target.clickedValue
        : clickedDstKey
      : 'dst_' + clickedTick.target.nextLabel;
    tType = clickedTick.target.clickedLabel === 'ip_list' ? 'ip' : clickedTick.target.nextLabel;
  }

  if (
    _.isEmpty(clickedTick) ||
    (_.isEmpty(clickedTick.source) && _.isEmpty(clickedTick.target)) ||
    (clickedTick &&
      ((clickedTick.source && clickedTick.source.clickedLabel !== 'ip_list') ||
        (clickedTick.target && clickedTick.target.clickedLabel !== 'ip_list')))
  ) {
    filteredLinks = _.filter(links, link => {
      let isSource = true;
      let isTarget = true;

      if (!_.isEmpty(clickedTick.source) && !_.isEmpty(clickedTickPath)) {
        isSource = _.every(
          clickedTickPath.source,
          n =>
            n.clickedValue ===
            link[
              'src_' +
                (n.nextLabel === 'virtual_service' || n.nextLabel === 'virtual_server' || n.nextLabel === 'workload'
                  ? 'role'
                  : n.clickedLabel)
            ],
        );
      }

      if (!_.isEmpty(clickedTick.target) && !_.isEmpty(clickedTickPath)) {
        isTarget = _.every(
          clickedTickPath.target,
          n =>
            n.clickedValue ===
            link[
              'dst_' +
                (n.nextLabel === 'virtual_service' || n.nextLabel === 'virtual_server' || n.nextLabel === 'workload'
                  ? 'role'
                  : n.clickedLabel)
            ],
        );
      }

      return isSource && isTarget;
    });
  }

  // The code below is specific to IPList. Currently the backend does not send the name of the iplist for each ipaddress entry(link entry) in some cases.
  // So when no IPList name is associated with the ipaddress entry, find the first iplist in the list of iplists, if one exits and add these ipaddress entries into
  // the first entry. This is a hack until the backend starts figuring out a way to send the iplist name associated with each ipaddress entry.
  if (
    selectedSource === 'ip_list' ||
    selectedTarget === 'ip_list' ||
    (clickedTick &&
      ((clickedTick.source && clickedTick.source.clickedLabel === 'ip_list') ||
        (clickedTick.target && clickedTick.target.clickedLabel === 'ip_list')))
  ) {
    if (!filteredLinks.length && isIPListSelected) {
      filteredLinks = links;
    }

    if (selectedSource === 'ip_list' || (clickedTick.source && clickedTick.source.clickedLabel === 'ip_list')) {
      filteredLinks = filteredLinks.map(link => ({
        ...link,
        src_ip_list: link.src_ip_list ? link.src_ip_list : {name: intl('IPLists.IPAddresses')},
      }));
    }

    if (selectedTarget === 'ip_list' || (clickedTick.target && clickedTick.target.clickedLabel === 'ip_list')) {
      filteredLinks = filteredLinks.map(link => ({
        ...link,
        dst_ip_list: link.dst_ip_list ? link.dst_ip_list : {name: intl('IPLists.IPAddresses')},
      }));
    }
  }

  if (
    clickedTick &&
    ((clickedTick.source &&
      (clickedTick.source.clickedLabel === 'workload' ||
        clickedTick.source.clickedLabel === 'virtual_server' ||
        clickedTick.source.clickedLabel === 'virtual_service')) ||
      (clickedTick.target &&
        (clickedTick.target.clickedLabel === 'workload' ||
          clickedTick.target.clickedLabel === 'virtual_server' ||
          clickedTick.target.clickedLabel === 'virtual_service')) ||
      (clickedTick.source &&
        (clickedTick.source.clickedValue === 'IP List' || clickedTick.source.clickedValue === 'IP Range')) ||
      (clickedTick.target &&
        (clickedTick.target.clickedValue === 'IP List' || clickedTick.target.clickedValue === 'IP Range')) ||
      isIPListSelected)
  ) {
    filteredLinks = _.filter(!filteredLinks.length && isIPListSelected ? links : filteredLinks, link => {
      let isSource = true;
      let isTarget = true;

      if (!_.isEmpty(clickedTick.source)) {
        if (clickedTick.source.clickedValue === 'IP List' || clickedTick.source.clickedLabel === 'ip_list') {
          isSource =
            !_.isEmpty(link.src_ip_list) &&
            !_.isEmpty(link.src_ip_list.name) &&
            _.every(
              clickedTickPath.source,
              n =>
                n.clickedValue ===
                (n.clickedLabel === 'ip_list' ? link.src_ip_list.name : link['src_' + n.clickedLabel]),
            );
        } else {
          isSource = link.hasOwnProperty(`src_${clickedTick.source.nextLabel}`);
        }
      }

      if (!_.isEmpty(clickedTick.target)) {
        if (clickedTick.target.clickedValue === 'IP List' || clickedTick.target.clickedLabel === 'ip_list') {
          isTarget =
            !_.isEmpty(link.dst_ip_list) &&
            !_.isEmpty(link.dst_ip_list.name) &&
            _.every(
              clickedTickPath.target,
              n =>
                n.clickedValue ===
                (n.clickedLabel === 'ip_list' ? link.dst_ip_list.name : link['dst_' + n.clickedLabel]),
            );
        } else {
          isTarget = link.hasOwnProperty(`dst_${clickedTick.target.nextLabel}`);
        }
      }

      return isSource && isTarget;
    });
  }

  if (
    clickedTick &&
    ((clickedTick.source && clickedTick.source.clickedValue === 'IP List') ||
      (clickedTick.target && clickedTick.target.clickedValue === 'IP List') ||
      (clickedTick.source && clickedTick.source.clickedLabel === 'ip_list') ||
      (clickedTick.target && clickedTick.target.clickedLabel === 'ip_list'))
  ) {
    if (
      clickedTick.source &&
      (clickedTick.source.clickedLabel === 'ip_list' || clickedTick.source.clickedValue === 'IP List')
    ) {
      ipListType = 'src_ip_list';
      ipType = 'src_ip';
    } else if (
      clickedTick.target &&
      (clickedTick.target.clickedLabel === 'ip_list' || clickedTick.target.clickedValue === 'IP List')
    ) {
      ipListType = 'dst_ip_list';
      ipType = 'dst_ip';
    }

    filteredLinks.forEach(link => {
      if (link[ipListType]) {
        if (!ipLists.hasOwnProperty(link[ipListType].name)) {
          ipLists[link[ipListType].name] = [];
        }

        ipLists[link[ipListType].name].push(link[ipType]);
      }
    });
  }

  // calculating how many workload types the current links have for both consumers & providers
  // later will show 'types' if there are more than 1 type, show workloads/VS otherwise
  if (!_.isEmpty(clickedTick.target) && clickedTick.target.clickedLabel === 'role') {
    multiSrcTypes = Array.from(new Set(filteredLinks.map(link => link.src_type)));
    multiDstTypes = Array.from(new Set(filteredLinks.map(link => link.dst_type)));
  }

  const edge = SessionStore.isEdge();

  return _.map(filteredLinks, link => {
    let source;
    let target;
    let process;
    let processServiceName;

    if (link.src_type === 'I') {
      source = link.src_ip;
    } else if (!_.isEmpty(clickedTick.source) && clickedTick.source.clickedLabel === 'role') {
      let typeKey = sKey;

      if (link.src_type === 'Workloads') {
        typeKey = 'workload';
      } else if (link.src_type === 'Virtual Services') {
        typeKey = 'virtual_service';
      } else if (link.src_type === 'Virtual Servers') {
        typeKey = 'virtual_server';
      }

      source = multiSrcTypes.length > 1 ? link.src_type : link[`src_${typeKey}`];
    } else if (
      (!_.isEmpty(clickedTick.source) &&
        (clickedTick.source.clickedValue === 'IP List' || clickedTick.source.clickedLabel === 'ip_list')) ||
      (_.isEmpty(clickedTick.source) && selectedSource === 'ip_list')
    ) {
      source = link.src_ip_list ? link.src_ip_list.name : null;
    } else {
      source = link[sKey];
    }

    if (link.dst_type === 'I') {
      target = link.dst_ip;
    } else if (!_.isEmpty(clickedTick.target) && clickedTick.target.clickedLabel === 'role') {
      let typeKey = tKey;

      if (link.dst_type === 'Workloads') {
        typeKey = 'workload';
      } else if (link.dst_type === 'Virtual Services') {
        typeKey = 'virtual_service';
      } else if (link.dst_type === 'Virtual Servers') {
        typeKey = 'virtual_server';
      }

      target = multiDstTypes.length > 1 ? link.dst_type : link[`dst_${typeKey}`];
    } else if (
      (!_.isEmpty(clickedTick.target) &&
        (clickedTick.target.clickedValue === 'IP List' || clickedTick.target.clickedLabel === 'ip_list')) ||
      (_.isEmpty(clickedTick.target) && selectedTarget === 'ip_list')
    ) {
      target = link.dst_ip_list ? link.dst_ip_list.name : null;
    } else {
      target = link[tKey];
    }

    if (!link.windowsService && !link.processName && link.protocol === 'ICMP') {
      link.windowsService = 'N/A';
    }

    if (edge) {
      const reportedByWindows =
        link.flow_direction === 'outbound'
          ? link.src?.workload?.os_type === 'windows'
          : link.dst?.workload?.os_type === 'windows';

      if (link.windowsService && link.processName) {
        processServiceName = [link.windowsService, link.processName].join(', ');
      } else if (link.processName) {
        processServiceName = reportedByWindows ? link.processName?.toLocaleLowerCase() : link.processName;
      } else {
        processServiceName = reportedByWindows ? link.windowsService?.toLocaleLowerCase() : link.windowsService;
      }
    } else if (!link.windowsService && link.processName) {
      process = link.processName;
    } else {
      process = link.windowsService;
    }

    return {
      source,
      target,
      sourceType: sType,
      targetType: tType,
      sourceIP: clickedTick && clickedTick.source && clickedTick.source.clickedLabel === 'ip_list' ? link.src_ip : '',
      targetIP: clickedTick && clickedTick.target && clickedTick.target.clickedLabel === 'ip_list' ? link.dst_ip : '',
      port: edge
        ? link.protocol.includes(intl('Protocol.TCP')) || link.protocol.includes(intl('Protocol.UDP'))
          ? link.port
          : link.protocol
        : link.protocol.includes(intl('Protocol.ICMP'))
        ? link.protocol
        : link.port,
      processName: link.flow_direction === 'inbound' ? (edge ? processServiceName : process) : '',
      outboundProcessName: link.flow_direction === 'outbound' ? (edge ? processServiceName : process) : '',
      protocol: link.protocol,
      count: link.count,
    };
  });
}

function calculateAndPositionVizLinks(
  links,
  portSorting,
  processNameSorting,
  outBoundProcessNameSorting,
  width,
  height,
  clickedTick,
) {
  const line = d3.svg.line();
  const forcedNodes = {};
  const vizLinks = {};
  const {xCoord, yCoord, dimensions, numTicks} = positionParallelCoordinates(
    links,
    portSorting,
    processNameSorting,
    outBoundProcessNameSorting,
    width,
    height,
    clickedTick,
  );

  links.forEach((link, i) => {
    const newLine = line(
      dimensions.map(d => {
        if (link[`${d}IP`] && Object.keys(link[`${d}IP`]).length) {
          return [xCoord[d], yCoord[d](link[`${d}IP`])];
        }

        return [xCoord[d], yCoord[d](link[d])];
      }),
    );

    forcedNodes[link.source] = {
      name:
        clickedTick && clickedTick.source && clickedTick.source.clickedLabel === 'ip_list'
          ? link.sourceIP
          : link.source,
      type: link.sourceType,
      dimension: 'source',
    };
    forcedNodes[link.target] = {
      name:
        clickedTick && clickedTick.target && clickedTick.target.clickedLabel === 'ip_list'
          ? link.targetIP
          : link.target,
      type: link.targetType,
      dimension: 'target',
    };

    let key;

    const linkForIPList = {...link};

    if (
      clickedTick &&
      clickedTick.source &&
      clickedTick.source.clickedLabel === 'ip_list' &&
      clickedTick &&
      clickedTick.target &&
      clickedTick.target.clickedLabel === 'ip_list'
    ) {
      key =
        link.sourceIP + ',' + link.targetIP + ',' + link.port + ',' + link.processName + ',' + link.outboundProcessName;
      linkForIPList.source = linkForIPList.sourceIP;
      linkForIPList.target = linkForIPList.targetIP;
    } else if (clickedTick && clickedTick.source && clickedTick.source.clickedLabel === 'ip_list') {
      key =
        link.sourceIP + ',' + link.target + ',' + link.port + ',' + link.processName + ',' + link.outboundProcessName;
      linkForIPList.source = linkForIPList.sourceIP;
    } else if (clickedTick && clickedTick.target && clickedTick.target.clickedLabel === 'ip_list') {
      key =
        link.source + ',' + link.targetIP + ',' + link.port + ',' + link.processName + ',' + link.outboundProcessName;
      linkForIPList.target = linkForIPList.targetIP;
    } else {
      key = link.source + ',' + link.target + ',' + link.port + ',' + link.processName + ',' + link.outboundProcessName;
    }

    vizLinks[key] = {
      id: i,
      key,
      line: newLine,
      source: forcedNodes[link.source],
      target: forcedNodes[link.target],
      data:
        clickedTick &&
        ((clickedTick.source && clickedTick.source.clickedLabel === 'ip_list') ||
          (clickedTick.target && clickedTick.target.clickedLabel === 'ip_list'))
          ? linkForIPList
          : link,
    };
  });

  const pc = {
    pcLinks: vizLinks,
    dimensions,
    xCoord,
    yCoord,
    numTicks,
  };

  return {pc};
}

function positionParallelCoordinates(
  links,
  portSorting,
  processNameSorting,
  outBoundProcessNameSorting,
  width,
  height,
  clickedTick,
) {
  const xCoord = {};
  const yCoord = {};
  const yHeight = getDimensionHeight(height);
  const dimensions = links.length
    ? OrgStore.reverseProviderConsumer()
      ? ['outboundProcessName', 'source', 'target', 'port', 'processName']
      : ['processName', 'port', 'target', 'source', 'outboundProcessName']
    : [];
  const numTicks = {};

  // create scale to calculate x coordinates of links
  const x = d3.scale.ordinal().domain(dimensions).rangePoints([0, width], 1);

  // calculate xCoord and yCoord of the links
  dimensions.forEach(d => {
    xCoord[d] = x(d);

    if (d === 'source' || d === 'target') {
      let sortedLinks = [];

      if (_.isEmpty(clickedTick) || !clickedTick[d]) {
        sortedLinks = links
          .map(link => link[d])
          .sort((a, b) => (initAxisSort[a] > initAxisSort[b] ? -1 : initAxisSort[a] < initAxisSort[b] ? 1 : 0));
      } else if (clickedTick[d].nextLabel === 'domain') {
        // sort domain names in reverse order of each subdomain names
        sortedLinks = links
          .map(link => link[d])
          .sort((a, b) => {
            const [str1, str2] = [a.split('.').reverse().join(''), b.split('.').reverse().join('')];

            return str1 > str2 ? -1 : str1 < str2 ? 1 : 0;
          });
      } else if (clickedTick[d].nextLabel === 'ip') {
        // sort ip names
        sortedLinks = links
          .map(link => link[d])
          .sort((a, b) => {
            const [arr1, arr2] = [a.split('.').map(Number), b.split('.').map(Number)];

            for (const [i, item] of arr1.entries()) {
              if (item > arr2[i]) {
                return -1;
              }

              if (item < arr2[i]) {
                return 1;
              }
            }

            return 0;
          });
      } else {
        sortedLinks = links.map(link => link[d]).sort((a, b) => (a > b ? -1 : a < b ? 1 : 0));
      }

      const linkTypes = {
        'Workloads': false,
        'Virtual Services': false,
        'Virtual Servers': false,
      };

      sortedLinks.forEach(sortedLink => {
        if (sortedLink === 'Workloads') {
          linkTypes.Workloads = true;
        } else if (sortedLink === 'Virtual Services') {
          linkTypes['Virtual Services'] = true;
        } else if (sortedLink === 'Virtual Servers') {
          linkTypes['Virtual Servers'] = true;
        }
      });

      if (
        (!_.isEmpty(clickedTick) &&
          d === 'source' &&
          clickedTick.source &&
          clickedTick.source.clickedLabel === 'ip_list') ||
        (d === 'target' && clickedTick.target && clickedTick.target.clickedLabel === 'ip_list')
      ) {
        const filteredLinks = links.filter(link => {
          if (d === 'source') {
            return ipLists[clickedTick[d].clickedValue].includes(link.sourceIP);
          }

          return ipLists[clickedTick[d].clickedValue].includes(link.targetIP);
        });

        const sortedIpLinks = filteredLinks.map(link => link[`${d}IP`]).sort((a, b) => (a > b ? -1 : a < b ? 1 : 0));

        yCoord[d] = d3.scale.ordinal().domain(sortedIpLinks).rangePoints([yHeight, 0]);
        numTicks[d] = yCoord[d].range().length;
      } else if (
        (!_.isEmpty(clickedTick) &&
          d === 'source' &&
          clickedTick.source &&
          clickedTick.source.clickedValue === 'IP List') ||
        (d === 'target' && clickedTick.target && clickedTick.target.clickedValue === 'IP List')
      ) {
        const ipListSet = new Set(links.map(link => link[d]));
        const ipListNames = [...ipListSet];

        yCoord[d] = d3.scale.ordinal().domain(ipListNames).rangePoints([yHeight, 0]);
        numTicks[d] = yCoord[d].range().length;
      } else if (
        (!_.isEmpty(clickedTick) &&
          d === 'source' &&
          clickedTick.source &&
          clickedTick.source.clickedLabel === 'role' &&
          clickedTick.source.nextLabel === 'workload' &&
          multiSrcTypes.length > 1) ||
        (d === 'target' &&
          clickedTick.target &&
          clickedTick.target.clickedLabel === 'role' &&
          clickedTick.target.nextLabel === 'workload' &&
          multiDstTypes.length > 1)
      ) {
        const existingTypes = ['Workloads', 'Virtual Servers', 'Virtual Services'].filter(type => linkTypes[type]);

        yCoord[d] = d3.scale.ordinal().domain(existingTypes).rangePoints([yHeight, 0]);
        numTicks[d] = yCoord[d].range().length;
      } else {
        yCoord[d] = d3.scale.ordinal().domain(sortedLinks).rangePoints([yHeight, 0]);
        numTicks[d] = yCoord[d].range().length;
      }
    } else if (d === 'port') {
      const {length, portCoord} = sortingLinksByPorts(portSorting, links, height);

      yCoord[d] = portCoord;
      numTicks[d] = length;
    } else if (d === 'processName') {
      const {length, processNameCoord} = sortingLinksByProcessName(processNameSorting, links, height);

      yCoord[d] = processNameCoord;
      numTicks[d] = length;
    } else if (d === 'outboundProcessName') {
      const {length, outboundProcessNameCoord} = sortingLinksByOutboundProcessName(
        outBoundProcessNameSorting,
        links,
        height,
      );

      yCoord[d] = outboundProcessNameCoord;
      numTicks[d] = length;
    } else if (d === 'count') {
      yCoord[d] = d3.scale
        .linear()
        .domain(d3.extent(links, link => Number(link[d])))
        .range([yHeight, 0]);
    }
  });

  return {xCoord, yCoord, dimensions, numTicks};
}

export function expandViz(dimension, value, data) {
  const clickedTick = _.cloneDeep(data.clickedTick);
  let clickedLabel;

  if (
    (dimension === 'source' &&
      (data.selectedSource === 'workload' ||
        data.selectedSource === 'virtual_server' ||
        data.selectedSource === 'virtual_service')) ||
    (dimension === 'target' &&
      (data.selectedTarget === 'workload' ||
        data.selectedTarget === 'virtual_server' ||
        data.selectedTarget === 'virtual_service'))
  ) {
    const filteredLinks = _.filter(data.data.pcLinks, link => {
      let isSource = true;
      let isTarget = true;

      if (dimension === 'source') {
        isSource = link.data.source === value;
      }

      if (dimension === 'target') {
        isTarget = link.data.target === value;
      }

      return isSource && isTarget;
    });

    if (!filteredLinks.length) {
      return;
    }
  }

  // if (dimension === 'source' && data.selectedSource === 'ip' || dimension === 'target' && data.selectedTarget === 'ip') {
  if (
    (dimension === 'source' && data.selectedSource === 'ip') ||
    (dimension === 'target' && data.selectedTarget === 'ip') ||
    (dimension === 'source' && data.selectedSource === 'virtual_server') ||
    (dimension === 'target' && data.selectedTarget === 'virtual_server') ||
    (dimension === 'source' && data.selectedSource === 'virtual_service') ||
    (dimension === 'target' && data.selectedTarget === 'virtual_service')
  ) {
    //cannot expand workload or IP
    return;
  }

  if (_.isEmpty(clickedTick[dimension])) {
    clickedLabel = dimension === 'source' ? data.selectedSource : data.selectedTarget;
  } else {
    clickedLabel = _.isNull(clickedTick[dimension].nextLabel)
      ? clickedTick[dimension].clickedLabel
      : clickedTick[dimension].nextLabel;
  }

  clickedTick[dimension] = {
    clickedLabel,
    clickedValue:
      value === 'Virtual Servers' || value === 'Virtual Services' || value === 'Workloads'
        ? clickedTick[dimension].clickedValue
        : value?.name || value,
    nextLabel: ParallelCoordinatesUtils.calculateNextLabel(clickedLabel, value),
  };

  const clickedTickPath = ParallelCoordinatesUtils.calculateClickedTickPath(
    data.clickedTickPath,
    clickedTick,
    dimension,
  );

  ExplorerActions.clickTick(clickedTick, clickedTickPath);
}

export function sortingLinksByPorts(portSorting, links, height) {
  let sortedLinks;
  const yHeight = getDimensionHeight(height);

  if (portSorting === 'flowNumber') {
    const portList = _.countBy(links, 'port');

    sortedLinks = links
      .map(link => link.port)
      .sort((a, b) => (portList[a] > portList[b] ? 1 : portList[a] < portList[b] ? -1 : 0));
  } else if (portSorting === 'portNumber') {
    sortedLinks = links.map(link => link.port).sort((a, b) => (isNaN(a) ? 1 : isNaN(b) ? -1 : b - a));
  }

  const portCoord = d3.scale.ordinal().domain(sortedLinks).rangePoints([yHeight, 0]);

  return {portCoord, length: portCoord.range().length};
}

export function sortingLinksByProcessName(processNameSorting, links, height) {
  let sortedLinks;
  const yHeight = getDimensionHeight(height);

  if (processNameSorting === 'flowNumber') {
    const processList = _.countBy(links, 'processName');

    sortedLinks = links
      .map(link => link.processName)
      .sort((a, b) => (processList[a] > processList[b] ? 1 : processList[a] < processList[b] ? -1 : 0));
  } else if (processNameSorting === 'processOrServiceName') {
    sortedLinks = links
      .map(link => link.processName)
      .sort((a, b) => b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'}));
  }

  const processNameCoord = d3.scale.ordinal().domain(sortedLinks).rangePoints([yHeight, 0]);

  return {processNameCoord, length: processNameCoord.range().length};
}

export function sortingLinksByOutboundProcessName(outBoundProcessNameSorting, links, height) {
  let sortedLinks;
  const yHeight = getDimensionHeight(height);

  if (outBoundProcessNameSorting === 'flowNumber') {
    const processList = _.countBy(links, 'outboundProcessName');

    sortedLinks = links
      .map(link => link.outboundProcessName)
      .sort((a, b) => (processList[a] > processList[b] ? 1 : processList[a] < processList[b] ? -1 : 0));
  } else if (outBoundProcessNameSorting === 'outboundProcessOrServiceName') {
    sortedLinks = links
      .map(link => link.outboundProcessName)
      .sort((a, b) => b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'}));
  }

  const outboundProcessNameCoord = d3.scale.ordinal().domain(sortedLinks).rangePoints([yHeight, 0]);

  return {outboundProcessNameCoord, length: outboundProcessNameCoord.range().length};
}

export function getCurentWorkloadType() {
  return {source: multiSrcTypes, target: multiDstTypes};
}
