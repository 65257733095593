/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import {Navigation, State} from 'react-router';
import intl from 'intl';
import Icon from '../Icon.jsx';
import Tooltip from '../Tooltip.jsx';
import RenderUtils from '../../utils/RenderUtils';
import actionCreators from '../../actions/actionCreators';
import MapPageStore from '../../stores/MapPageStore';
import GraphStore from '../../stores/GraphStore';

const networkEntity = () => ({
  containerWorkload: intl('Common.ContainerWorkload'),
  workload: intl('Common.Workload'),
  multiSelect: 'Multiple Selection',
  virtualServer: intl('Common.VirtualServer'),
  virtualService: intl('Common.VirtualService'),
  role: intl('Common.Role'),
  location: intl('Common.Location'),
  group: intl('Common.Group'),
  internet: intl('Common.Internet'),
  ipList: intl('Common.IPLists'),
  fqdn: intl('Common.Fqdns'),
});

const iconShapes = {
  containerWorkload: 'hexagon',
  virtualService: 'diamond',
  virtualServer: 'diamond',
  workload: 'square',
  role: 'square',
  group: 'oval',
  internet: 'internet',
  ipList: 'iplist',
  fqdn: 'fqdn',
};

const titleBarBackground = {
  reported: 'CommandPanelReported',
  draft: 'CommandPanelDraft',
  vulnerability: 'CommandPanelReported',
};

export default React.createClass({
  mixins: [Navigation, State],

  handleClose() {
    actionCreators.unselectComponent();
  },

  transformData() {
    const {info, type} = this.props.data;
    let labels;
    let targetName;
    let sourceName;

    if (type === 'group') {
      const {groups} = this.props.data.info;

      if (groups instanceof Object && groups[info.href]) {
        const {name} = groups[info.href];

        if (name) {
          labels = name;
        }
      }

      if (!labels && info.labels && (info.labels.app || info.labels.env || info.labels.loc)) {
        labels = _.compact([
          info.labels.app && info.labels.app.value,
          info.labels.env && info.labels.env.value,
          info.labels.loc && info.labels.loc.value,
        ]).join(' | ');
      }
    } else if (type === 'traffic') {
      targetName = info.targetName;
      sourceName = info.sourceName;

      labels = {
        sourceName,
        targetName,
      };
    }

    return labels;
  },

  handleBack() {
    // do something to go back to multiple selections
    const prevSelections = GraphStore.getPrevSelections();

    actionCreators.updateComponentSelection(prevSelections);
    actionCreators.updateMultipleSelection([]);
  },

  handleExternalLink() {
    const {info} = this.props.data;
    const {type, subType} = this.props.data;

    if (type === 'group' && info.href) {
      this.transitionTo('groupWorkloads', {id: info.href});
    } else if (type === 'workload' && info.subType === 'container' && info.href) {
      this.transitionTo('containerWorkload', {id: info.href.split('/').pop()});
    } else if (type === 'workload' && info.href) {
      this.transitionTo('workloads.item', {id: info.href.split('/').pop()});
    } else if (type === 'virtualService' && info.href && subType === 'virtualServer') {
      this.transitionTo('virtualServerTab', {id: info.href.split('/').pop(), tab: 'summary', pversion: 'draft'});
    } else if (type === 'virtualService' && info.href) {
      this.transitionTo('virtualService', {id: info.href.split('/').pop(), pversion: 'draft'});
    }
  },

  render() {
    const {info} = this.props.data;
    let {type, subType} = this.props.data;
    const mapPolicyVersion = MapPageStore.getPolicyVersion();
    const labels = this.transformData();
    let tooltipInfoData;
    let tooltipTrafficSourceName;
    let tooltipTrafficTargetName;
    let tooltipTrafficName;
    const multiSelected = GraphStore.getPrevSelections().length !== 0;
    const workloadsIsReadable =
      (type === 'workload' || type === 'virtualService') && info.caps.workloads.includes('read');

    if (info.subType === 'container') {
      type = 'containerWorkload';
    } else if (type === 'virtualService' && subType === 'virtualServer') {
      type = 'virtualServer';
    }

    if (
      info &&
      info.name &&
      type !== 'internet' &&
      type !== 'ipList' &&
      type !== 'group' &&
      RenderUtils.truncateAppGroupName(info.name, 40, [40]).includes('...')
    ) {
      tooltipInfoData = info.name;
    } else if (type === 'traffic' && labels) {
      if (labels.sourceName && RenderUtils.truncateAppGroupName(labels.sourceName, 40, [20, 10, 10]).includes('...')) {
        tooltipTrafficSourceName = labels.sourceName;
      }

      if (labels.targetName && RenderUtils.truncateAppGroupName(labels.targetName, 40, [20, 10, 10]).includes('...')) {
        tooltipTrafficTargetName = labels.targetName;
      }
    } else if (
      type === 'group' &&
      !_.isEmpty(info.labels) &&
      labels &&
      RenderUtils.truncateAppGroupName(labels, 40, [20, 10, 10]).includes('...')
    ) {
      tooltipTrafficName = labels;
    }

    return (
      <div className={`CommandPanelTitleBar ${titleBarBackground[mapPolicyVersion]}`}>
        <div
          className={`CommandPanelTitle-Back ${multiSelected ? 'displayBlock' : 'displayNone'}`}
          onClick={evt => this.handleBack(evt)}
        >
          <Icon name="caret-left" size="xlarge" data-tid="comp-icon-close" />
        </div>
        <div
          className={`CommandPanelContentBar ${multiSelected ? 'clickable' : null}`}
          onClick={multiSelected ? evt => this.handleBack(evt) : null}
        >
          <div className="CommandPanelTitle" data-tid={`title-bar-${type}`}>
            <div>
              {type === 'traffic'
                ? intl('Common.SourceToTarget', {
                    source: networkEntity()[info.sources.linkType],
                    target: networkEntity()[info.targets.linkType],
                  })
                : networkEntity()[type]}
            </div>
          </div>
          {type === 'group' ? (
            _.isEmpty(info.labels) ? (
              <div className="CommandPanelNoTooltipSubTitle CommandPanelTitle-Link" onClick={this.handleExternalLink}>
                {intl('Map.DiscoveredGroup')}
              </div>
            ) : (
              <Tooltip content={tooltipTrafficName} width={372} position="group" location="bottomright">
                <div className="CommandPanelSubTitle">
                  <div
                    className={info.href ? 'CommandPanelTitle-Link' : 'CommandPanelTitle-Name'}
                    onClick={this.handleExternalLink}
                    data-tid="nav-to-group-details"
                  >
                    {RenderUtils.truncateAppGroupName(labels, 40, [20, 10, 10])}
                  </div>
                </div>
              </Tooltip>
            )
          ) : info.name && type !== 'internet' && type !== 'ipList' && type !== 'fqdn' ? (
            <Tooltip content={tooltipInfoData} width={372} position="group" location="bottomright">
              <div className="CommandPanelSubTitle">
                <div
                  className={
                    (this.props.data.type === 'workload' || this.props.data.type === 'virtualService') &&
                    info.href &&
                    workloadsIsReadable
                      ? 'CommandPanelTitle-Link'
                      : 'CommandPanelTitle-Name'
                  }
                  onClick={workloadsIsReadable ? this.handleExternalLink : null}
                  data-tid={
                    (this.props.data.type === 'workload' || this.props.data.type === 'virtualService') && info.href
                      ? 'nav-to-workload-details'
                      : type
                  }
                >
                  {RenderUtils.truncateAppGroupName(info.name, 50, [50])}
                </div>
              </div>
            </Tooltip>
          ) : null}

          {type === 'traffic' ? (
            <div className="CommandPanelTraffic">
              <div className="CommandPanelArrowPane">
                {info.sources.linkType !== 'ipList' && info.sources.linkType !== 'internet' ? (
                  <div className={iconShapes[info.sources.linkType]} />
                ) : null}
                {info.sources.linkType === 'ipList' || info.sources.linkType === 'internet' ? (
                  <div className="Icon-position">
                    <Icon
                      name={iconShapes[info.sources.linkType]}
                      styleClass={info.sources.linkType.toLowerCase()}
                      position="inbetween"
                    />
                  </div>
                ) : null}
                {info.sourceName && info.targetName ? <Icon name="arrow-right" styleClass="arrowDown" /> : null}
                {info.targets.linkType !== 'ipList' ||
                info.targets.linkType !== 'internet' ||
                info.targets.linkType !== 'fqdn' ? (
                  <div className={iconShapes[info.targets.linkType]} />
                ) : null}
                {info.targets.linkType === 'ipList' ||
                info.targets.linkType === 'internet' ||
                info.targets.linkType === 'fqdn' ? (
                  <div>
                    <Icon
                      name={iconShapes[info.targets.linkType]}
                      styleClass={info.targets.linkType.toLowerCase()}
                      position="inbetween"
                    />
                  </div>
                ) : null}
              </div>
              <div className="CommandPanelContent">
                <Tooltip content={tooltipTrafficSourceName} position="toptraffic" width={372} location="bottomright">
                  <div className="CommandPanelTopTraffic" data-tid="traffic-source">
                    {RenderUtils.truncateAppGroupName(labels.sourceName, 40, [20, 10, 10])}
                  </div>
                </Tooltip>
                <Tooltip content={tooltipTrafficTargetName} position="bottomtraffic" width={372} location="bottomright">
                  <div className="CommandPanelBottomTraffic" data-tid="traffic-target">
                    {RenderUtils.truncateAppGroupName(labels.targetName, 40, [20, 10, 10])}
                  </div>
                </Tooltip>
              </div>
            </div>
          ) : null}

          {type === 'multiSelect' ? (
            <div className="CommandPanelTraffic CommandPanelSubTitle">{`${info.length} Traffic Links`}</div>
          ) : null}
        </div>
        <div className="CommandPanelTitleBarClose" onClick={this.handleClose}>
          <Icon name="close" />
        </div>
      </div>
    );
  },
});
