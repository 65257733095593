/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import {State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import {Grid, Navbar, SpinnerOverlay, Icon, Tally, Tooltip} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import Constants from '../../constants';
import RouterMixin from '../../mixins/RouterMixin';
import StoreMixin from '../../mixins/StoreMixin';
import {GeneralStore, TrafficStore, SessionStore} from '../../stores';
import {GridDataUtils, RestApiUtils, RenderUtils, GraphDataUtils, GroupDataUtils} from '../../utils';
import WorkloadUtils from '../../utils/WorkloadUtils';
import {AppGroupTabs} from '.';

function getStateFromStores() {
  const {group} = this.getGroupLabelHrefs();

  return {
    group,
  };
}

export default React.createClass({
  mixins: [State, RouterMixin, StoreMixin(TrafficStore, getStateFromStores)],
  getInitialState() {
    const {groupHref, groupLabelHrefs} = this.getGroupLabelHrefs();
    const selectionObject = GeneralStore.getSelection('groupVulnerabilities');
    const sorting = GeneralStore.getSorting('groupVulnerabilities') || [
      {key: 'vulnerabilityExposureScore', direction: true},
    ];
    const filter = GeneralStore.getFilter('groupVulnerabilities');
    const type = GroupDataUtils.getType(this.getPathname());
    let selection = [];

    if (selectionObject && selectionObject.selection && selectionObject.id === this.getParams().id) {
      selection = selectionObject.selection;
    }

    return {
      type,
      groupHref,
      groupLabelHrefs,
      selection,
      sorting: sorting || [{key: 'name', direction: false}],
      filter: filter || 'all',
      groupExists: true,
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    this.getGroup(this.state.group, this.state.groupHref);
    this.getVulnerabilities(this.state.groupHref, this.state.groupLabelHrefs);
    this.getWorkloads(this.state.group);

    const response = await RestApiUtils.workloads.getCollection({max_results: 1}, true);
    const totalWorkloads = Number(response.headers.get('x-total-count'));

    this.mapLevel = totalWorkloads > (localStorage.getItem('location_view') || 50) ? 'loc' : 'full';
  },

  componentDidUpdate() {
    if (this.getParams().id !== this.state.groupHref) {
      const {groupHref, groupLabelHrefs, group} = this.getGroupLabelHrefs();

      this.getGroup(group, groupHref);
      this.getVulnerabilities(groupHref, groupLabelHrefs);
      this.getWorkloads(group);

      this.setState({
        group,
        groupHref,
        groupLabelHrefs,
        selection: [],
      });
    }
  },

  async getGroup(group, groupHref) {
    if (!group) {
      if (!GraphDataUtils.isSummaryDataLoaded('requested')) {
        await Promise.all([
          GraphDataUtils.getTraffic('location', {route: 'groups'}),
          GraphDataUtils.getTraffic('appGroup', {route: 'groups'}),
        ]);
      }

      group = TrafficStore.getNode(groupHref);

      this.setState({groupExists: !_.isEmpty(group)});
    }

    this.getWorkloads(group);
  },

  getGroupLabelHrefs() {
    const groupHref = this.getParams().id;
    const groupLabelHrefs = groupHref.split('x').reduce((result, labelId) => {
      const labelHref = getSessionUri(getInstanceUri('labels'), {label_id: labelId});

      result.push(labelHref);

      return result;
    }, []);

    return {groupHref, groupLabelHrefs, group: TrafficStore.getNode(groupHref)};
  },

  getWorkloads(group) {
    // The full map will have 'discovered' set to true for discovery groups based on traffic
    // The leveled map will have 'discovered' as the href, and we will load all workloads without labels
    if (group && group.labels && !group.discovered) {
      const hrefPrefix = getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1);
      // Start with an empty set of labels
      let labels =
        this.state.type === 'appgroups'
          ? {}
          : {
              app: `${hrefPrefix}?key=app&exists=false`,
              env: `${hrefPrefix}?key=env&exists=false`,
              loc: `${hrefPrefix}?key=loc&exists=false`,
            };

      // Replace with all the labels we have for this group
      labels = JSON.stringify([
        Object.values(group.labels.reduce((result, label) => ({...result, [label.key]: label.href}), labels)),
      ]);

      _.defer(async () => {
        const result = await RestApiUtils.workloads.getCollection(
          {
            labels,
            representation: this.state.vulnerabilitiesEnabled
              ? 'workload_labels_vulnerabilities'
              : 'workload_labels_services',
            max_results: 500,
          },
          true,
        );

        if (result.body) {
          const exposureApplicable = result.body.reduce((result, workload) => {
            const policyState = WorkloadUtils.policyState(workload);

            return result && policyState && policyState !== 'idle' && policyState !== 'unmanaged';
          }, true);

          this.setState({exposureApplicable});
        }
      });
    }
  },

  async getVulnerabilities(groupHref, groupLabelHrefs) {
    this.setState({status: Constants.STATUS_BUSY});

    try {
      await RestApiUtils.vulnerabilityInstances.getAggregateVulnerabilityInstances(
        JSON.stringify(groupLabelHrefs),
        groupHref,
      );

      this.setState({vulnerabilities: TrafficStore.getNodeVulnerabilityByHref(groupHref) || {}});
    } catch (error) {
      if (error.status === 404) {
        this.replaceWith('resourceNotFound');
      }
    }

    this.setState({status: Constants.STATUS_IDLE});
  },

  render() {
    let title;

    if (this.state.group) {
      title = RenderUtils.truncateAppGroupName(
        _.sortBy(this.state.group.labels, 'key')
          .map(label => label.value)
          .join(' | '),
        45,
        [30, 15, 10],
      );
    }

    const columns = [
      {
        key: 'vulnerabilityExposureScore',
        label: intl('Vulnerability.VEScore'),
        formatHeader: value => (
          <Tooltip content={intl('Help.Desc.VEScore')} position="location-group" width={400} location="fixed">
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability',
        format: (value, row) =>
          GridDataUtils.formatVulnerability({
            ...row,
            policyState: this.state.exposureApplicable || row.vulnerabilityExposureScore ? 'applicable' : 'unmanaged',
          }),
        sortFunction: (rowA, rowB) => GridDataUtils.sortVulnerability(rowA, rowB),
        sortable: true,
      },
      {
        key: 'vulnerabilityScore',
        label: intl('Vulnerability.Score'),
        formatHeader: value => (
          <Tooltip
            content={intl('Help.Desc.VulnerabilityScore')}
            position="location-group"
            width={400}
            location="fixed"
          >
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-score',
        sortable: true,
        format: value => Math.round(value * 10) / 10,
      },
      {
        key: 'vulnerablePortExposure',
        label: intl('Vulnerability.EWExposure'),
        formatHeader: value => (
          <Tooltip content={intl('Help.Desc.EWExposure')} position="location-group" width={400} location="fixed">
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-exposure',
        sortable: true,
        format: value => (value === null ? intl('Common.NA') : value),
      },
      {
        key: 'wideExposure',
        label: intl('Vulnerability.InternetExposure'),
        formatHeader: value => (
          <Tooltip content={intl('Help.Desc.InternetExposure')} position="location-group" width={400} location="fixed">
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-exposure',
        sortable: true,
        format: value =>
          value && (value.internet || value.ip_list) ? <Icon name="internet" size="medium" /> : intl('Common.None'),
      },
      {
        key: 'numWorkloads',
        label: intl('Common.Workloads'),
        formatHeader: value => (
          <Tooltip content={intl('Help.Desc.WorkloadCount')} position="location-group" width={400} location="fixed">
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-score',
        sortable: true,
        format: value => value,
      },
      {
        key: 'port',
        label: intl('Port.Protocol'),
        formatHeader: value => (
          <Tooltip
            content={intl('Help.Desc.VulnerabilityPortProtocol')}
            position="location-group"
            width={400}
            location="fixed"
          >
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-port',
        type: 'string',
        sortable: true,
        sortValue: value => value,
        format: (value, row) => GridDataUtils.formatPortProtocol(row),
      },
      {
        key: 'protocol', // Unique key for CVE id, which is in the details object
        label: intl('Vulnerability.CVEIds'),
        formatHeader: value => (
          <Tooltip content={intl('Help.Desc.CVEIds')} position="location-group" width={350} location="fixed">
            {value.label}
          </Tooltip>
        ),
        style: 'cve',
        sortable: true,
        sortValue: (value, row) => row.details.cve_ids && row.details.cve_ids.join(','),
        format: (value, row) => row.details.cve_ids && row.details.cve_ids.map(id => <div>{id}</div>),
      },
      {
        key: 'details',
        label: intl('Common.Name'),
        formatHeader: value => (
          <Tooltip content={intl('Help.Desc.VulnerabilityName')} position="location-group" width={250} location="fixed">
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-title',
        sortable: true,
        sortValue: value => value.name,
        format: value => value.name,
      },
    ];

    const onSort = (key, direction) => {
      const sorting = [];

      if (key) {
        sorting.push({key, direction});
      }

      actionCreators.updateGeneralSorting('groupVulnerability', sorting);
      this.setState({sorting});
    };

    let gridData;
    const vulnerabilities = this.state.vulnerabilities;

    if (this.state.vulnerabilities) {
      gridData = _.isEmpty(vulnerabilities) ? [] : Object.values(vulnerabilities.instances).flat();
    }

    let ves;

    if (!_.isEmpty(this.state.vulnerabilities)) {
      ves = RenderUtils.roundNumber(this.state.vulnerabilities.aggregatedValues.vulnerabilityExposureScore);
    }

    const {group, groupHref} = this.state;
    const mapRoute = GroupDataUtils.getMapRoute(group, groupHref, this.mapLevel, 'appgroups');
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    return (
      <div className="GroupWorkloads ListPage" data-tid="page-appcontainer-workloads">
        <Navbar title={title} type="detail" up={listPage} />
        <div className="GroupBar">
          <AppGroupTabs active="vulnerabilities" mapRoute={mapRoute} />
        </div>
        {!this.state.vulnerabilities || !this.state || this.state.status === Constants.STATUS_BUSY ? (
          <SpinnerOverlay />
        ) : (
          [
            <ToolBar>
              <ToolGroup />
              <ToolGroup>
                <Tally label={intl('Vulnerability.TotalVEScore')} count={ves === null ? intl('Common.NA') : ves} />
              </ToolGroup>
            </ToolBar>,
            <Grid
              columns={columns}
              data={gridData}
              sorting={this.state.sorting}
              sortable={true}
              selectable={false}
              onSort={onSort}
            />,
          ]
        )}
      </div>
    );
  },
});
