/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from 'intl';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';
import {Field, Form} from '../components/Form';

export default React.createClass({
  propTypes: {
    title: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      secret: '',
    };
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: this.handleClose,
      },
      {
        text: intl('Common.Verify'),
        type: 'primary',
        tid: 'verify',
        disabled: !this.state.secret,
        onClick: () => {
          this.props.onConfirm(this.state.secret, this.props.row);
          this.handleClose();
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  handleSecretChange(evt) {
    this.setState({
      secret: evt.target.value,
    });
  },

  render() {
    return (
      <Dialog
        ref="dialog"
        className="RadiusTestSharedSecret"
        type="detail"
        title={this.props.title}
        actions={this.getActions()}
      >
        <div className="RadiusTestSharedSecret-form">
          <Form>
            <Field
              type="password"
              label={intl('Common.Secret')}
              value={this.state.secret}
              onChange={this.handleSecretChange}
              noHint={true}
            />
          </Form>
        </div>
      </Dialog>
    );
  },
});
