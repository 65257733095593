/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React from 'react';
import actionCreators from '../actions/actionCreators';
import {RoutesMap} from '../Routes.jsx';
import {BroadcastChannel} from 'broadcast-channel';
import {State, Navigation} from 'react-router';
import StoreMixin from '../mixins/StoreMixin';
import SessionStore from '../stores/SessionStore';
import {Button, Dialog} from '.';

const secondIntl = new Intl.NumberFormat(intl.locale, {
  notation: 'standard',
  style: 'unit',
  unit: 'second',
  unitDisplay: 'long',
});

function getStateFromStores() {
  return {
    sessionTimeout: SessionStore.getSessionTimeout(),
  };
}

export default React.createClass({
  mixins: [State, Navigation, StoreMixin([SessionStore], getStateFromStores)],

  getInitialState() {
    // Show alert on 'gray' browsers, that are not bad, not supported and this alert was not closed before
    return {
      show: false,
      seconds: null,
      timeout: null,
      logout: false,
    };
  },

  componentDidMount() {
    try {
      // Create channel to broadcast message that the current user is active to the other tabs to prevent them from calling logout
      const activityChannel = new BroadcastChannel('userActivityChannel', {webWorkerSupport: false});

      this.broadcastActivity = () => {
        try {
          activityChannel.postMessage('Active');
        } catch (error) {
          console.error('Could not broadcast a message', error);
        }
      };

      // When other tab writes to activity channel restart logout countdown
      activityChannel.onmessage = event => {
        if (event === 'Active') {
          if (this.state.show) {
            this.continueSession();
          }

          this.countdownShow();
        }
      };

      this.logoutTimeout = 1000 * Math.max(15, Math.min(60, Math.floor(0.1 * 60 * this.state.sessionTimeout)));

      this.showModalTimeout = 60_000 * this.state.sessionTimeout - this.logoutTimeout;

      // Method to dispatch logout action when user is inactive
      // Logout after 10min of user inactivity (after last mouse movement) in production, except routes that have 'noAutoLogout' flag
      this.countdownShow = _.debounce(this.showExpirationPopup, this.showModalTimeout);

      // When user performs some action, restart logout countdown and send a message to other tabs
      this.updateUserActivity = _.throttle(
        () => {
          try {
            this.countdownShow();
            this.broadcastActivity();
          } catch (error) {
            console.error('Could not broadcast a message', error);
          }
        },
        1000,
        {trailing: false},
      );

      // Start listening to events that will call updateUserActivity
      this.addEvents();

      // Start countdown to automatically logout after debounce time, even if user is not making any action
      // And notify other tabs to reset their session
      this.updateUserActivity();
    } catch (error) {
      console.error('During BroadcastChannel creation', error);
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeName !== prevProps.routeName) {
      this.updateUserActivity();
    }

    if (prevState.show && !this.state.show) {
      this.addEvents();
    }
  },

  handleContinueClick() {
    this.broadcastActivity();
    this.continueSession();
    this.countdownShow();
  },

  handleLogoutClick() {
    this.logout();
  },

  continueSession() {
    clearTimeout(this.state.timeout);
    this.setState(this.getInitialState());
  },

  addEvents() {
    // Use capture to avoid being prevented by stopPropagation call from children handlers
    // User is active when mouse is moved or mouse/touch is down.
    document.body.addEventListener('mousemove', this.updateUserActivity, {capture: true});
    document.body.addEventListener('mousedown', this.updateUserActivity, {capture: true});
    document.body.addEventListener('keydown', this.updateUserActivity, {capture: true});
    window.addEventListener('scroll', this.updateUserActivity, {passive: true});
  },

  removeEvents() {
    document.body.removeEventListener('mousemove', this.updateUserActivity, {capture: true});
    document.body.removeEventListener('mousedown', this.updateUserActivity, {capture: true});
    document.body.removeEventListener('keydown', this.updateUserActivity, {capture: true});
    window.removeEventListener('scroll', this.updateUserActivity, {passive: true});
  },

  countdown() {
    this.setState(state => {
      let seconds;

      if (state.show) {
        seconds = state.seconds - 1;

        if (!seconds) {
          this.logout();

          return null;
        }
      } else {
        seconds = this.logoutTimeout / 1000;
      }

      return {show: true, seconds, timeout: setTimeout(this.countdown, 1000)};
    });
  },

  logout() {
    actionCreators.logout();
  },

  showExpirationPopup() {
    if (__DEV__) {
      // Never show countdown in development
      return;
    }

    try {
      const currentRouteName = this.getRoutes()
        .map(route => route.name)
        .join('.');
      const route = RoutesMap.get(currentRouteName);

      if (route && !route.noAutoLogout) {
        this.removeEvents();
        this.countdown();
      }
    } catch (error) {
      console.error('Could not show session expiration countdown', error);
    }
  },

  render() {
    const {show, seconds} = this.state;

    if (!show) {
      return null;
    }

    return (
      <Dialog className="SessionExpiration" tid="session-expiration">
        <div className="SessionExpiration-timer">
          <div className="SessionExpiration-number SessionExpiration-tnum">{seconds}</div>
          <div className="SessionExpiration-unit">
            {secondIntl.formatToParts(seconds).find(item => item.type === 'unit')?.value}
          </div>
        </div>
        <div className="SessionExpiration-body">
          <div className="SessionExpiration-text">
            <div className="SessionExpiration-title">{intl('PasswordPolicy.SessionTimeoutWarning')}</div>
            <div>
              {intl(
                'PasswordPolicy.LogoutWarningDescription',
                {
                  seconds,
                  number: <span className="SessionExpiration-tnum">{intl.num(seconds)}</span>,
                },
                {jsx: true},
              )}
            </div>
          </div>
          <div className="SessionExpiration-buttons">
            <Button type="nofill" tid="logout" text={intl('PasswordPolicy.Logout')} onClick={this.handleLogoutClick} />
            <Button
              type="primary"
              tid="continue"
              text={intl('PasswordPolicy.ContinueSession')}
              onClick={this.handleContinueClick}
            />
          </div>
        </div>
      </Dialog>
    );
  },
});
