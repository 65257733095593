/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {RestApiUtils} from '../utils';
import {LabelsAutocompleteStore, SessionStore} from '../stores';

export default React.createClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      items: {},
      dropdownValues: {},
      labels: {},
      facetMap: {
        [intl('Common.Group')]: 'labels-role',
        [intl('Workloads.WorkloadName')]: 'name',
        [intl('Common.Role')]: 'labels-role',
        [intl('Common.Application')]: 'labels-app',
        [intl('Common.Environment')]: 'labels-env',
        [intl('Common.Location')]: 'labels-loc',
      },
    };
  },

  getFacetValues(facet, query = '') {
    if (!facet) {
      return;
    }

    if (facet.includes('labels')) {
      const key = facet.split('-').pop();

      RestApiUtils.labels
        .autocomplete(undefined, {
          key,
          query,
          max_results: 25,
        })
        .then(() => {
          this.setState({dropdownValues: LabelsAutocompleteStore.getDropdownValues()});
        });
    }

    if (facet === 'name') {
      RestApiUtils.workloads
        .autocomplete('draft', {
          query,
          max_results: 25,
        })
        .then(response => {
          const dropdownValues = {...this.state.dropdownValues, ['name-' + query]: response.body};

          this.setState({dropdownValues});
        });
    }
  },

  addItem(item, value) {
    const items = {...this.state.items};
    const labels = {...this.state.labels};

    if (item === 'Blocked Traffic') {
      items[item] = value;
    } else if (!value && !items[item]) {
      items[item] = null;
    } else if (
      (item === 'Role' && !SessionStore.isEdge()) ||
      (item === 'Group' && SessionStore.isEdge()) ||
      item === 'Application' ||
      item === 'Environment' ||
      item === 'Location'
    ) {
      items[item] = [value.value];

      if (!labels[item]) {
        labels[item] = value.href;
      }
    } else if (value) {
      items[item] = items[item] || [];
      items[item].push(value);
    }

    this.props.onChange(items, labels);
    this.setState({items, labels});
  },

  removeItem(item) {
    const items = {...this.state.items};
    const labels = {...this.state.labels};

    delete items[item];
    delete labels[item];

    this.props.onChange(items, labels);
    this.setState({items, labels});
  },

  removeMulti(item, singleItem) {
    const items = {...this.state.items};

    if (singleItem) {
      items[item] = items[item].filter(item => item.href !== singleItem.href);
    } else if (items[item]) {
      items[item].pop();
    }

    if (items[item] && !items[item].length) {
      delete items[item];
    }

    this.props.onChange(items);
    this.setState({items});
  },

  render() {
    const {facetMap} = this.state;
    let initialValues = SessionStore.isEdge()
      ? [intl('Common.Group'), intl('BlockedTraffic.TrafficStatus'), intl('Workloads.WorkloadName')]
      : [
          intl('Common.Role'),
          intl('Common.Application'),
          intl('Common.Environment'),
          intl('Common.Location'),
          intl('BlockedTraffic.TrafficStatus'),
          intl('Workloads.WorkloadName'),
        ];

    if (this.state.items.Name) {
      initialValues = [intl('BlockedTraffic.TrafficStatus'), intl('Workloads.WorkloadName')];
    }

    if (!_.isEmpty(this.state.labels)) {
      if (SessionStore.isEdge()) {
        initialValues = [intl('Common.Group'), intl('BlockedTraffic.TrafficStatus')];
      } else {
        initialValues = [
          intl('Common.Role'),
          intl('Common.Application'),
          intl('Common.Environment'),
          intl('Common.Location'),
          intl('BlockedTraffic.TrafficStatus'),
        ];
      }
    }

    return (
      <ObjectSelector
        addItem={this.addItem}
        dropdownValues={this.state.dropdownValues}
        facetMap={facetMap}
        getFacetValues={this.getFacetValues}
        initialValues={initialValues}
        items={this.state.items}
        multiItems={[intl('Workloads.WorkloadName')]}
        onChange={this.props.onChange}
        placeholder={intl('Common.FilterView')}
        removeItem={this.removeItem}
        removeMulti={this.removeMulti}
        returnValue={value => value && (value.name || value.hostname || value.value || value)}
        statics={{[intl('BlockedTraffic.TrafficStatus')]: [intl('Common.Blocked'), intl('Common.PotentiallyBlocked')]}}
        staticsKeys={[intl('BlockedTraffic.TrafficStatus')]}
      />
    );
  },
});
