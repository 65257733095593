/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import Constants from '../constants';
import AlertDialog from '../components/AlertDialog.jsx';
import ObjectLimitReached from '../components/ObjectLimitReached.jsx';
import _ from 'lodash';

const intlizedStatuses = {
  500: intl('StatusCodes.InternalServerError'),
  501: intl('StatusCodes.NotImplemented'),
  502: intl('StatusCodes.BadGateway'),
  503: intl('StatusCodes.ServiceUnavailable'),
  504: intl('StatusCodes.GatewayTimeout'),
  505: intl('StatusCodes.HttpVersionNotSupported'),
  506: intl('StatusCodes.VariantAlsoNegotiates'),
  507: intl('StatusCodes.InsufficientStorage'),
  508: intl('StatusCodes.LoopDetected'),
  510: intl('StatusCodes.NotExtended'),
  511: intl('StatusCodes.NetworkAuthenticationRequired'),
};

export default {
  getErrorAlert(type, token, message, errorMessage, requestId, status, error) {
    let content = null;
    const newToken = token || _.get(error, 'parsedBody.token');
    const newMessage = message || _.get(error, 'parsedBody.message');

    switch (type) {
      case Constants.API_FORBIDDEN_FAIL:
        content = intl('ErrorsAPI.err_ui:403:message'); // eslint-disable-line rulesdir/intl-call-verify
        break;

      case Constants.API_NOT_ACCEPTABLE_FAIL:
        if (newToken) {
          content = intl(`ErrorsAPI.err:${newToken}`);
        } else if (newMessage) {
          content = String(newMessage);
        } else if (errorMessage) {
          content = String(errorMessage);
        } else {
          content = intl('ErrorsAPI.err_ui:general'); // eslint-disable-line rulesdir/intl-call-verify
        }

        break;

      case Constants.API_TOO_MANY_REQUESTS_FAIL:
        content = intl('ErrorsAPI.err_ui:throttled'); // eslint-disable-line rulesdir/intl-call-verify
        break;

      case Constants.API_ERROR_FAIL:
        content = intl('ErrorsAPI.err_ui:exception'); // eslint-disable-line rulesdir/intl-call-verify
        break;

      case Constants.API_TIMEOUT_FAIL:
        content = intl('ErrorsAPI.err_ui:timeout'); // eslint-disable-line rulesdir/intl-call-verify
        break;

      case Constants.API_SERVER_CONNECTION_FAIL:
        content = intl('ErrorsAPI.err_ui:connection_error'); // eslint-disable-line rulesdir/intl-call-verify
        break;

      case Constants.API_SERVICE_UNAVAILABLE:
        content = intl('ErrorsAPI.err_ui:service_unavailable'); // eslint-disable-line rulesdir/intl-call-verify
        break;

      case Constants.API_BAD_GATEWAY_FAIL:
        if (errorMessage && errorMessage.includes('facets')) {
          content = intl('ErrorsAPI.err_ui:general'); // eslint-disable-line rulesdir/intl-call-verify
        } else {
          content = intl('ErrorsAPI.err:bad_gateway'); // eslint-disable-line rulesdir/intl-call-verify
        }

        break;

      case Constants.API_FAIL:
        if (errorMessage && errorMessage.toLocaleLowerCase().includes('timeout')) {
          content = intl('ErrorsAPI.err_ui:timeout'); // eslint-disable-line rulesdir/intl-call-verify
        } else {
          console.error('Error Message:', error);

          return;
        }

        break;

      default:
        content = errorMessage;
    }

    if (/%s/i.test(content)) {
      content = message;
    }

    // If a requestId is passed to this function, display it on the second line of the Alert Dialog
    if (requestId) {
      content = [content, intl('NavigationAlert.ProvideRequestId') + ':', requestId];
    }

    return <AlertDialog title={intlizedStatuses[status]} message={content} />;
  },

  getObjectLimitError(type, requestId) {
    let object = intl('Common.Object');

    switch (type) {
      case Constants.LABELS_CREATE_FAIL:
        object = intl('Common.Labels');
        break;

      case Constants.LABEL_GROUPS_CREATE_FAIL:
      case Constants.LABEL_GROUP_UPDATE_FAIL:
        object = intl('Labels.Groups');
        break;

      case Constants.WORKLOADS_CREATE_FAIL:
        object = intl('Common.Workloads');
        break;

      case Constants.PAIRING_PROFILE_PAIRING_KEY_FAIL:
        object = intl('PairingProfiles.PairingKeys');
        break;

      case Constants.PAIRING_PROFILES_CREATE_FAIL:
        object = intl('PairingProfiles.Profiles');
        break;

      case Constants.SERVICES_CREATE_FAIL:
      case Constants.SERVICE_UPDATE_FAIL:
        object = intl('Common.Services');
        break;

      case Constants.ORG_AUTH_SECURITY_PRINCIPALS_CREATE_FAIL:
      case Constants.ORG_PERMISSIONS_CREATE_FAIL:
        object = intl('Users.User');
        break;

      case Constants.IP_LISTS_CREATE_FAIL:
        object = intl('Common.IPLists');
        break;

      case Constants.SECURITY_PRINCIPALS_CREATE_FAIL:
        object = intl('Common.AUSUsers');
        break;

      case Constants.SERVICE_BINDINGS_CREATE_FAIL:
        object = intl('Common.ServiceBindings');
        break;

      case Constants.RULE_SETS_CREATE_FAIL:
      case Constants.RULE_SET_UPDATE_FAIL:
      case Constants.SEC_RULES_CREATE_FAIL:
      case Constants.SEC_RULE_UPDATE_FAIL:
        object = intl('Common.Rules');
        break;

      case Constants.IP_TABLES_RULES_CREATE_FAIL:
      case Constants.IP_TABLES_RULE_UPDATE_FAIL:
        object = intl('Common.IpTables');
        break;
    }

    return <ObjectLimitReached object={object} id={requestId} />;
  },

  networkTrafficTimeout() {
    return <AlertDialog message={intl('Error.NetworkTimeout')} />;
  },

  appGroupTimeout() {
    return <AlertDialog message={intl('Error.AppGroupTimeout')} />;
  },

  DataStoreUnavailable() {
    return <AlertDialog message={intl('Error.DataStoreUnavailable')} />;
  },
};
