/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {RestApiUtils, WorkloadUtils} from '../utils';

export default React.createClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    allowPartial: PropTypes.bool,
  },

  getInitialState() {
    return {
      items: {},
      dropdownValues: {},
    };
  },

  componentDidMount() {
    _.defer(() => {
      this.getFacetValues();
    });
  },

  getFacetValues(facet, query, maxResults = 25) {
    if (this.state.dropdownValues['workload-' + query]) {
      return;
    }

    query = query || '';
    RestApiUtils.workloads
      .autocomplete(undefined, {
        query,
        max_results: maxResults,
      })
      .then(response => {
        const dropdownValues = _.cloneDeep(this.state.dropdownValues);

        dropdownValues['workload-' + query] = response.body;
        this.setState({dropdownValues});
      });
  },

  addItem(item, value) {
    const items = _.cloneDeep(this.state.items);

    items[item] = value;
    this.props.onChange(value);
    this.setState({items});
  },

  removeItem(item) {
    const items = _.cloneDeep(this.state.items);

    this.props.onChange(undefined, items[item]);
    delete items[item];
    this.setState({items});
  },

  removeMulti(item, singleItem) {
    const items = _.cloneDeep(this.state.items);

    if (singleItem) {
      this.props.onChange(undefined, singleItem);

      const deletedIndex = items[item].findIndex(item => singleItem.href === item.href);

      items[item].splice(deletedIndex, 1);
    } else if (items[item]) {
      this.props.onChange(undefined, items[item].pop());
    }

    if (items[item] && !items[item].length) {
      delete items[item];
    }

    this.setState({items});
  },

  returnValue(value) {
    if (typeof value === 'string') {
      return value;
    }

    return WorkloadUtils.friendlyName(value);
  },

  render() {
    let placeholder = this.props.placeholder || 'Select Workloads';

    if (this.state.items.Workload) {
      placeholder = '';
    }

    const {addItem, getFacetValues, removeItem, removeMulti, returnValue} = this;
    const props = {
      dropdownValues: this.state.dropdownValues,
      items: this.state.items,
      facetMap: {
        [intl('Common.Workload')]: 'workload',
      },
      initialValues: [intl('Common.Workload')],
      onChange: _.noop,
      singleSelect: intl('Common.Workload'),
      addItem,
      getFacetValues,
      placeholder,
      removeItem,
      removeMulti,
      returnValue,
    };

    if (this.props.allowPartial) {
      props.partialItems = [intl('Common.Workload')];
      props.partialOnZeroMatches = true;
    }

    props.allowOne = true;

    return <ObjectSelector {...props} />;
  },
});
