/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Link, State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import Constants from '../../constants';
import {Button, Grid, Icon, Navbar, SpinnerOverlay, Pagination, ExpandableGridDataList} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin} from '../../mixins';
import {
  SessionStore,
  GeneralStore,
  LabelStore,
  VirtualServerStore,
  WorkloadStore,
  VirtualServiceStore,
  ContainerWorkloadStore,
  PairingProfileStore,
} from '../../stores';
import {GraphDataUtils, GridDataUtils, GroupDataUtils, RenderUtils, RestApiUtils, WorkloadUtils} from '../../utils';
import {enforcementStateMap} from '../../utils/RenderUtils';
import {GroupTabs, AppGroupTabs} from '.';
import GroupMemberPanel from './GroupMemberSelect';

const MAX_RESULTS_PER_PAGE = 50;

function getStateFromStores() {
  const {groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

  const virtualServers = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServerStore.getAll());
  const pairingProfiles = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, PairingProfileStore.getAll());
  let containerWorkloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, ContainerWorkloadStore.getAll());
  let virtualServices = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServiceStore.getAll().draft);

  // Find the workloads in a discovery group defined by the traffic
  if (group && group.discovered) {
    containerWorkloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const containerWorkload = ContainerWorkloadStore.getSpecified(href);

        if (containerWorkload) {
          result.push(containerWorkload);
        }
      },
      [],
    );

    virtualServices = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualService = VirtualServiceStore.getSpecified(href);

        if (virtualService) {
          result.push(virtualService);
        }
      },
      [],
    );
  }

  return {
    group,
    virtualServices,
    virtualServers,
    containerWorkloads,
    pairingProfiles,
    count: VirtualServiceStore.getCount(),
    status: [LabelStore.getStatus(), VirtualServerStore.getStatus(), VirtualServiceStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    UserMixin,
    StoreMixin(
      [LabelStore, ContainerWorkloadStore, PairingProfileStore, VirtualServiceStore, WorkloadStore],
      getStateFromStores,
    ),
  ],

  getInitialState() {
    const {groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);
    const selectionObject = GeneralStore.getSelection('groupVirtualServices');
    const sorting = GeneralStore.getSorting('groupVirtualServices');
    const filter = GeneralStore.getFilter('groupVirtualServices');
    const type = GroupDataUtils.getType(this.getPathname());
    const vulnerabilitiesEnabled = SessionStore.areVulnerabilitiesEnabled() && type === 'appgroups';
    let selection = [];

    if (selectionObject && selectionObject.selection && selectionObject.id === this.getParams().id) {
      selection = selectionObject.selection;
    }

    return {
      type,
      groupHref,
      groupLabelHrefs,
      selection,
      sorting:
        sorting || vulnerabilitiesEnabled
          ? [{key: 'vulnerability_summary', direction: true}]
          : [{key: 'name', direction: false}],
      filter: filter || 'all',
      expandedRow: null,
      currentPage: 1,
      groupExists: true,
      vulnerabilitiesEnabled,
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);

    this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();

    const {groupHref, type, vulnerabilitiesEnabled} = this.state;
    const {group, caps} = await GroupDataUtils.getGroup(
      this.state.group,
      groupHref,
      type,
      vulnerabilitiesEnabled,
      true,
    );

    this.setState({groupExists: !_.isEmpty(group), caps});

    if (group && group.discovered) {
      this.setState(() => {
        /* Set the proper count for "Discovered" group since the API doesn't return a count header. */
        const count = {...this.state.count, matched: group.nodesHrefs.length};

        return {count};
      });
    }
  },

  componentWillReceiveProps() {
    const type = GroupDataUtils.getType(this.getPathname());

    if (this.getParams().id !== this.state.groupHref || type !== this.state.type) {
      const {groupHref, groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

      GroupDataUtils.getGroup(group, groupHref, type, this.state.vulnerabilitiesEnabled, true);

      this.setState({
        group,
        groupHref,
        groupLabelHrefs,
        selection: [],
      });
    }
  },

  handleExpandCollapse(data) {
    if (this.state.expandedRow && this.state.expandedRow === data) {
      this.setState({expandedRow: null});
    } else {
      this.setState({expandedRow: data});
    }
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
    });
  },

  handleRowClick(row) {
    const url = row.href.split('/');
    const id = url[url.length - 1];

    this.transitionTo('virtualService', {id, pversion: 'draft'});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('groupVirtualServices', sorting);
    this.setState({sorting});
  },

  handleAddVirtualService() {
    this.transitionTo('virtualServices.create');
  },

  render() {
    const {type} = this.state;
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    if (!this.state.groupExists) {
      // if we can't find the group, it must be because it's a discovered group
      this.replaceWith(type === 'appgroups' ? listPage.route : 'map');
    } else if (!this.state.group) {
      return <SpinnerOverlay />;
    }

    const columns = [
      {
        key: 'update_type',
        label: intl('Provision.Status'),
        style: 'provision',
        sortable: true,
        format(value) {
          return GridDataUtils.formatUpdatedType(value);
        },
      },
      {
        key: 'name',
        label: intl('Common.Name'),
        sortable: true,
        format(value, row) {
          return (
            <Link
              to="virtualService"
              className="Grid-link"
              params={{id: GridDataUtils.getIdFromHref(row.href), pversion: 'draft'}}
            >
              {WorkloadUtils.friendlyName(row)}
            </Link>
          );
        },
        sortValue(value, row) {
          return WorkloadUtils.friendlyName(row).toLowerCase();
        },
      },
      {
        key: 'service',
        label: intl('Common.Services'),
        sortable: true,
        style: 'wrap',
        format: value => (value && value.name ? value.name : null),
        sortValue: value => value?.name || '',
      },
      {
        key: 'role',
        label: intl('Common.Role'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValue,
        sortValue: (value, row) => {
          const role = row.labels.find(label => label.key === 'role');

          return role ? role.value : null;
        },
        sortable: true,
      },
      {
        key: 'bound_workloads',
        label: intl('VirtualServices.BoundWorkloads'),
        format: (value, row) => {
          let boundWorkloads = [];

          if (value) {
            boundWorkloads = _.compact(value).map(bw => (
              <div className="BoundWorkloads-item">
                <Icon
                  size="medium"
                  name={bw.online ? 'online' : 'offline'}
                  styleClass={bw.online ? 'Online' : 'Offline'}
                />
                <Link
                  to={bw.href.includes('container') ? 'containerWorkload' : 'workloads.item'}
                  className="Grid-link"
                  params={{id: GridDataUtils.getIdFromHref(bw.href)}}
                >
                  {bw.name && bw.name.length ? bw.name : bw.hostname}
                </Link>
              </div>
            ));
          }

          return (
            <ExpandableGridDataList
              data={boundWorkloads}
              href={row.href}
              expanded={row.href === this.state.expandedRow}
              tid="bound-workloads"
              onExpandCollapse={this.handleExpandCollapse}
              collapseMessage={intl('VirtualServices.ShowFewer')}
              expandMessage={intl('VirtualServices.SeeAll', {count: boundWorkloads.length})}
            />
          );
        },
      },
      {
        key: 'workloadPolicyState',
        label: intl('Common.Enforcement'),
        format: (value, row) => {
          const enforcementMode = _.compact(row.bound_workloads)?.map(bw => (
            <div className="BoundWorkloads-items">{enforcementStateMap[bw.enforcement_mode] || <br />}</div>
          ));

          return (
            <ExpandableGridDataList
              href={row.href}
              data={enforcementMode}
              expanded={row.href === this.state.expandedRow}
              tid="policy-state"
              onExpandCollapse={this.handleExpandCollapse}
            />
          );
        },
      },
    ];

    let title;

    if (this.state.group) {
      title = RenderUtils.truncateAppGroupName(
        _.sortBy(this.state.group.labels, 'key')
          .map(label => label.value)
          .join(' | '),
        45,
        [30, 15, 10],
      );
    }

    const gridData = this.state.virtualServices;

    const {group, groupHref} = this.state;
    const mapRoute = GroupDataUtils.getMapRoute(group, groupHref, this.mapLevel, type);
    const tabs = GroupDataUtils.getTabs(this.state);
    const appGroups = this.state.type === 'appgroups';

    return (
      <div className="GroupVirtualServices ListPage" data-tid="page-appcontainer-virtual-services">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar title={title} type="detail" up={appGroups || !mapRoute ? listPage : mapRoute} />
        <div className="GroupBar">
          {appGroups ? (
            <AppGroupTabs active="members" mapRoute={mapRoute} />
          ) : (
            <GroupTabs active="virtualServices" tabs={tabs} group={group} />
          )}
        </div>
        {appGroups ? <GroupMemberPanel active="virtualServices" items={tabs} /> : null}

        <ToolBar>
          <ToolGroup>
            <Button
              autoFocus={true}
              text={intl('Common.Add')}
              onClick={this.handleAddVirtualService}
              tid="pair"
              disabled={(!UserMixin.isUserAdmin() && !UserMixin.isUserOwner()) || SessionStore.isSuperclusterMember()}
            />
            {this.state.selection.length > 0 &&
              intl(
                'Common.SelectedCount',
                {className: 'Count', count: this.state.selection.length},
                {
                  html: true,
                  htmlProps: {
                    'className': 'ListPage-Count-Selection',
                    'data-tid': 'elem-count-selection',
                  },
                },
              )}
          </ToolGroup>
          {gridData.length ? (
            <ToolGroup tid="pagination">
              <Pagination
                page={this.state.currentPage}
                totalRows={gridData.length}
                pageLength={MAX_RESULTS_PER_PAGE}
                onPageChange={this.handlePageChange}
                isFiltered
                count={this.state.count}
              />
            </ToolGroup>
          ) : null}
        </ToolBar>

        <Grid
          columns={columns}
          data={gridData}
          sorting={this.state.sorting}
          sortable
          idField="href"
          onSort={this.handleSort}
          onRowClick={this.handleRowClick}
          resultsPerPage={MAX_RESULTS_PER_PAGE}
          currentPage={this.state.currentPage}
        />
      </div>
    );
  },
});
