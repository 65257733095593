/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {UGFacetStore, FilterStore} from '../stores';
import StoreMixin from '../mixins/StoreMixin';
import {RestApiUtils} from '../utils';
import actionCreators from '../actions/actionCreators';

function getStateFromStores() {
  return {
    items: FilterStore.getSelectorFilters('userGroupList'),
    dropdownValues: UGFacetStore.getDropdownValues(),
  };
}

export default React.createClass({
  propTypes: {
    facetMap: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  },

  mixins: [StoreMixin([UGFacetStore, FilterStore], getStateFromStores)],

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  },

  getFacetValues(facet, query = '') {
    if (!facet) {
      return;
    }

    RestApiUtils.securityPrincipals.facets({facet, query});
  },

  removeItem(filter) {
    actionCreators.removeApiFilter({page: 'userGroupList', filter});
  },

  handleClick(evt) {
    if (findDOMNode(this).contains(evt.target)) {
      return;
    }

    actionCreators.resetFacetValues('userGroupList');
  },

  addItem(filter, value) {
    actionCreators.addApiFilter({page: 'userGroupList', filter, value});
  },

  render() {
    const props = {
      addItem: this.addItem,
      dropdownValues: this.state.dropdownValues,
      facetMap: this.props.facetMap,
      getFacetValues: this.getFacetValues,
      initialValues: Object.keys(this.props.facetMap),
      items: this.state.items,
      onChange: this.props.onChange,
      partialItems: [intl('Common.Name'), intl('UserGroups.SID')],
      placeholder: intl('Common.FilterView'),
      removeItem: this.removeItem,
      returnValue: value => value,
    };

    return <ObjectSelector {...props} />;
  },
});
