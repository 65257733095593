/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from 'intl';
import DayPicker from 'react-day-picker';

const shortWeekdays = () => [
  intl('DateTimeInput.ShortDays.SunSat'),
  intl('DateTimeInput.ShortDays.Mon'),
  intl('DateTimeInput.ShortDays.TuesThurs'),
  intl('DateTimeInput.ShortDays.Wed'),
  intl('DateTimeInput.ShortDays.TuesThurs'),
  intl('DateTimeInput.ShortDays.Fri'),
  intl('DateTimeInput.ShortDays.SunSat'),
];

export default React.createClass({
  propTypes: {
    date: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['from', 'to']).isRequired,
  },

  handleDayClick(day, {disabled, selected}) {
    if (disabled) {
      return;
    }

    const selectedDay = selected ? undefined : day;

    this.props.onDateChange(selectedDay, this.props.type);
  },

  render() {
    return (
      <DayPicker
        onDayClick={this.handleDayClick}
        selectedDays={this.props.date}
        weekdaysShort={shortWeekdays()}
        modifiers={{
          weekdaysShort: shortWeekdays(),
        }}
        disabledDays={{
          after: new Date(),
        }}
      />
    );
  },
});
