/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import {State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import {Grid, Navbar, SpinnerOverlay, TabTo, Icon, Tally, Tooltip} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import Constants from '../../constants';
import RouterMixin from '../../mixins/RouterMixin';
import StoreMixin from '../../mixins/StoreMixin';
import {
  GeneralStore,
  TrafficStore,
  WorkloadStore,
  ServiceBindingsStore,
  SessionStore,
  TrafficFilterStore,
} from '../../stores';
import {GridDataUtils, RestApiUtils, WorkloadUtils, RenderUtils, GraphDataUtils} from '../../utils';

function getStateFromStores() {
  const href = getSessionUri(getInstanceUri('workloads'), {
    workload_id: this.getParams().id,
  });

  return {
    workloadServiceBindings: ServiceBindingsStore.getAll(href),
    workload: WorkloadStore.getSpecified(href),
    rulesetCaps: this.state && this.state.rulesetCaps,
    status: [TrafficStore.getStatus(), WorkloadStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [State, RouterMixin, StoreMixin([TrafficStore, WorkloadStore, ServiceBindingsStore], getStateFromStores)],

  getInitialState() {
    const sorting = GeneralStore.getSorting('workloadVulnerabilities') || [
      {key: 'vulnerabilityExposureScore', direction: true},
    ];

    return {sorting};
  },

  componentDidMount() {
    if (!SessionStore.areVulnerabilitiesEnabled()) {
      this.replaceWith('resourceNotFound');

      return;
    }

    this.getWorkload();
  },

  componentDidUpdate() {
    if (this.workloadId && this.getParams().id !== this.workloadId) {
      this.getWorkload();
    }
  },

  handleClose() {
    this.transitionTo('workloads.list');
  },

  async getWorkload() {
    this.workloadId = this.getParams().id;

    const href = getSessionUri(getInstanceUri('workloads'), {
      workload_id: this.workloadId,
    });

    this.setState({status: [Constants.STATUS_BUSY]});

    RestApiUtils.serviceBindings.getCollection({
      workload: href,
      representation: 'expanded_virtual_services_resolved_service_ports',
    });

    try {
      const response = await Promise.all([
        RestApiUtils.vulnerabilityInstances.getVulnerabilityInstances(href),
        RestApiUtils.workloads.getInstance(this.workloadId, {representation: 'workload_labels'}, true, true),
      ]);
      const workload = response[1].body;

      if (workload) {
        // API Calls for Workload RulesetCaps
        const clusterId = RenderUtils.getWorkloadClusterParent(workload);
        const clusterCapsResponse =
          clusterId && (await RestApiUtils.agentTraffic.caps('cluster_keys', JSON.stringify([clusterId])));
        const clusterCaps = clusterCapsResponse.body;

        if (clusterCaps) {
          const rulesetCaps = clusterCaps[clusterId]?.caps.rule_sets?.includes('read');

          this.setState({rulesetCaps});

          if (rulesetCaps && SessionStore.isIlluminationApiEnabled()) {
            const transmissionFilters = TrafficFilterStore.getTransmissionFilters();
            const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];
            const requests = [];

            transmissionFilters.forEach((transmissionFilter, i) => {
              if (transmissionFilter) {
                requests.push(
                  GraphDataUtils.getTraffic(
                    {workloads: JSON.stringify([href]), traffic_class: trafficClasses[i]},
                    {route: 'workloads'},
                  ),
                );
              }
            });

            await Promise.all(requests);
            this.setState({traffic: TrafficStore.getNode(href)?.consumingTraffic.byPort});
          }
        }
      }

      this.setState({vulnerabilities: TrafficStore.getNodeVulnerabilityByHref(href)});
    } catch (error) {
      if (error.status === 404) {
        this.replaceWith('resourceNotFound');
      }
    }

    this.setState({status: [Constants.STATUS_IDLE]});
  },

  render() {
    const title = this.state.workload && WorkloadUtils.friendlyName(this.state.workload);
    const isUnmanaged = this.state.workload && !this.state.workload.agent.status;
    const isIdle =
      this.state.workload && this.state.workload.agent.config && this.state.workload.agent.config.mode === 'idle';
    const ves =
      this.state.vulnerabilities &&
      RenderUtils.roundNumber(this.state.vulnerabilities.aggregatedValues.vulnerabilityExposureScore);
    const {traffic, rulesetCaps} = this.state;

    if (rulesetCaps === null && !SessionStore.isEdge()) {
      return this.state.status === Constants.STATUS_BUSY ? <SpinnerOverlay /> : null;
    }

    let label = null;

    if (isUnmanaged) {
      label = intl('Common.UnmanagedWorkload');
    } else if (isIdle) {
      label = intl('Common.IdleWorkload');
    }

    const vulnerabilityExposureScore = {
      key: 'vulnerabilityExposureScore',
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.VEScore')}
          position="table-header-medium"
          width={400}
          location="fixed-top"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      label: intl('Vulnerability.VEScore'),
      style: 'vulnerability',
      format: (value, row) =>
        GridDataUtils.formatVulnerability({...row, policyState: isUnmanaged || isIdle ? 'unmanaged' : 'applicable'}),
      sortFunction: (rowA, rowB) => GridDataUtils.sortVulnerability(rowA, rowB),
      sortable: true,
    };

    const vulnerabilityScore = {
      key: 'vulnerabilityScore',
      label: intl('Vulnerability.Score'),
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.VulnerabilityScore')}
          position="table-header-tall"
          width={400}
          location="fixed-top"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      style: 'vulnerability-score',
      sortable: true,
      format: value => value,
    };

    const vulnerablePortExposure = {
      key: 'vulnerablePortExposure',
      label: intl('Vulnerability.EWExposure'),
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.EWExposure')}
          position="table-header-tall"
          width={400}
          location="fixed-top"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      style: 'vulnerability-exposure',
      sortable: true,
      format: value => (value === null ? intl('Common.NA') : value),
    };

    const wideExposure = {
      key: 'wideExposure',
      label: intl('Vulnerability.InternetExposure'),
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.InternetExposure')}
          position="table-header-tall"
          width={400}
          location="fixed-top"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      style: 'vulnerability-exposure',
      sortable: true,
      sortValue: value => value && (value.internet || value.ip_list),
      format: value => {
        if (value && value.any === null) {
          return intl('Common.NA');
        }

        return value && (value.any || value.ip_list) ? <Icon name="internet" size="medium" /> : intl('Common.None');
      },
    };

    let trafficColumn;

    if (SessionStore.isIlluminationApiEnabled()) {
      trafficColumn = {
        key: 'traffic',
        label: intl('Vulnerability.ProvidedTraffic'),
        formatHeader: value => (
          <Tooltip
            content={intl('Help.Desc.ProvidedTraffic')}
            position="table-header-medium"
            width={400}
            location="fixed-top"
            isHeader={true}
          >
            {value.label}
          </Tooltip>
        ),
        style: 'vulnerability-traffic',
        sortValue: value => GridDataUtils.formatVulnerabilityTraffic(value),
        format: value => GridDataUtils.formatVulnerabilityTraffic(value),
        sortable: true,
      };
    }

    const port = {
      key: 'port',
      label: intl('Port.Protocol'),
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.VulnerabilityPortProtocol')}
          position="table-header-short"
          width={400}
          location="fixed-top"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      style: 'vulnerability-port',
      type: 'string',
      sortable: true,
      sortValue: value => value,
      format: (value, row) => GridDataUtils.formatPortProtocol(row, true),
    };

    const protocol = {
      key: 'protocol', // Unique key for CVE id, which is in the details object
      label: intl('Vulnerability.CVEIds'),
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.CVEIds')}
          position="table-header-right-tall"
          width={350}
          location="fixed-top-right"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      style: 'cve',
      sortable: true,
      sortValue: (value, row) => (row.details.cve_ids && row.details.cve_ids[0]) || null,
      format: (value, row) => row.details.cve_ids && row.details.cve_ids.map(id => <div>{id}</div>),
    };

    const details = {
      key: 'details',
      label: intl('Common.Name'),
      formatHeader: value => (
        <Tooltip
          content={intl('Help.Desc.VulnerabilityName')}
          position="table-header-right-short"
          width={250}
          location="fixed-top-right"
          isHeader={true}
        >
          {value.label}
        </Tooltip>
      ),
      style: 'vulnerability-title',
      sortable: true,
      sortValue: value => value.name,
      format: value => value.name,
    };

    const columns = [];

    columns.push(vulnerabilityExposureScore, vulnerabilityScore, vulnerablePortExposure, wideExposure);

    if (rulesetCaps) {
      columns.push(trafficColumn);
    }

    columns.push(port, protocol, details);

    const onSort = (key, direction) => {
      const sorting = [];

      if (key) {
        sorting.push({key, direction});
      }

      actionCreators.updateGeneralSorting('workloadVulnerability', sorting);
      this.setState({sorting});
    };

    const gridData = this.state.vulnerabilities
      ? Object.values(this.state.vulnerabilities.instances)
          .flat()
          .map(vulnerability => ({
            ...vulnerability,
            traffic: traffic ? traffic[[vulnerability.port, vulnerability.protocol].join(',')] : intl('Common.Loading'),
          }))
      : [];

    return (
      <div className="WorkloadServices" data-tid="page-workloads-services">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar title={title} label={label} type="detail" onUp={this.handleClose} />
        <div className="NavMenu" data-tid="comp-navmenu">
          <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-summary">
            <TabTo to="workloads.item" params={{id: this.getParams().id}}>
              {intl('Common.Summary')}
            </TabTo>
          </div>
          {this.state.workloadServiceBindings && this.state.workloadServiceBindings.length && !SessionStore.isEdge() ? (
            <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-virtual-services">
              <TabTo to="workloadVirtualServices" params={{id: this.getParams().id}}>
                {intl('Common.VirtualServices')}
              </TabTo>
            </div>
          ) : null}
          <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-services">
            <TabTo to="workloadServices" params={{id: this.getParams().id}}>
              {intl('Common.Processes')}
            </TabTo>
          </div>
          {!SessionStore.isEdge() && !SessionStore.isUserWithReducedScope() && !isUnmanaged && rulesetCaps ? (
            <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-rules">
              <TabTo to="workloadRules" params={{id: this.getParams().id}}>
                {intl('Common.Rules')}
              </TabTo>
            </div>
          ) : null}
          {!SessionStore.isEdge() && !isUnmanaged && (
            <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-boundaries">
              <TabTo to="workloadBoundaries" params={{id: this.getParams().id}}>
                {intl('Workloads.EnforcementBoundaries')}
              </TabTo>
            </div>
          )}
          {this.state.workload && WorkloadUtils.showWorkloadReport(this.state.workload) && (
            <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-report">
              <TabTo to="workloadReport" params={{id: this.getParams().id}}>
                {intl('Common.CompatibilityReport')}
              </TabTo>
            </div>
          )}
          {SessionStore.isExplorerEnabled() && rulesetCaps && (
            <div className="NavMenu-item" data-tid="comp-tab comp-tab-comp-navmenuitem-blockedtraffic">
              <TabTo to="workloadBlockedTraffic" params={{id: this.getParams().id}}>
                {intl('BlockedTraffic.Name')}
              </TabTo>
            </div>
          )}
          {!SessionStore.isEdge() && SessionStore.areVulnerabilitiesEnabled() ? (
            <div
              className="NavMenu-item NavMenu-item--active"
              data-tid="comp-tab comp-tab-comp-navmenuitem-vulnerabilities"
            >
              <TabTo to="workloadVulnerabilities" params={{id: this.getParams().id}}>
                {intl('Common.Vulnerabilities')}
              </TabTo>
            </div>
          ) : null}
        </div>
        <ToolBar>
          <ToolGroup>
            {this.state.reportDate && intl.utils.format.dateAtTimeBy(this.state.reportDate.updated_at)}
          </ToolGroup>
          <ToolGroup>
            <Tally
              label={intl('Vulnerability.TotalVEScore')}
              count={!isUnmanaged && !isIdle ? ves : intl('Common.NA')}
            />
          </ToolGroup>
        </ToolBar>
        <Grid
          columns={columns}
          data={gridData}
          sorting={this.state.sorting}
          sortable={true}
          selectable={false}
          onSort={onSort}
        />
      </div>
    );
  },
});
