/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import update from 'react-addons-update';
import Dialog from '../Dialog.jsx';
import Label from '../Label.jsx';
import MapPageStore from '../../stores/MapPageStore';
import OSLabelSelect from '../OSLabelSelect.jsx';
import actionCreators from '../../actions/actionCreators';
import {GridDataUtils, GraphDataUtils, LabelUtils, RenderUtils, RestApiUtils} from '../../utils';
import {Notification} from '..';

export default React.createClass({
  getInitialState() {
    if (this.props.data.isDiscovery || this.props.data.isReadonly) {
      return {};
    }

    const role = this.props.data.node.labels.role;
    let labelsForAdd = this.props.data.cluster && this.props.data.cluster.labels;

    labelsForAdd = labelsForAdd || {};
    labelsForAdd = update(labelsForAdd, {
      $merge: {role},
    });

    for (const label in labelsForAdd) {
      if (_.isEmpty(labelsForAdd[label])) {
        delete labelsForAdd[label];
      }
    }

    // if there is no location label from the cluster (appgroup)
    // but there is a location label on the node being moved
    if (!labelsForAdd.loc && this.props.data.node.labels.loc && MapPageStore.getMapType() === 'app') {
      labelsForAdd.loc = this.props.data.node.labels.loc;
    }

    const labelsForRemove = {role};

    return {
      currentRole: role,
      currentLoc: labelsForAdd.loc,
      labelsForAdd,
      labelsForRemove,
    };
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  async handleConfirm() {
    const {type, subType} = this.props.data.node;
    let labels = this.props.data.cluster ? this.state.labelsForAdd : this.state.labelsForRemove;

    labels = _.filter(labels);

    if (type === 'role') {
      const xxxlabels = [RenderUtils.constructxxxLabels(this.props.data.node.labels)];

      const response = await RestApiUtils.workloads.getCollection(
        {
          xxxlabels,
          max_results: 500,
        },
        true,
      );

      const data = response.body.map(workload => ({href: workload.href, labels}));

      await RestApiUtils.workloads.setLabels(data, []);
    } else {
      const id = GridDataUtils.getIdFromHref(this.props.data.node.href);

      if (type === 'workload') {
        if (subType === 'container') {
          return;
        }

        await RestApiUtils.workload.update(id, {labels}, true);
      } else if (type === 'virtualService') {
        if (subType === 'virtual_server') {
          const oldLabels = this.props.data.node.labels;

          await RestApiUtils.virtualServer.update(id, {}, 'draft', {labels, oldLabels});
        } else {
          const virtualServiceLabels = labels ? LabelUtils.stripExtraneousFromObject(labels) : [];

          await RestApiUtils.virtualService.update(id, {labels: virtualServiceLabels}, 'draft');
        }
      }

      GraphDataUtils.loadTraffic('rebuild');
    }
  },

  handleLocationChange(added) {
    const state = {};

    state.currentLoc = added;
    state.labelsForAdd = update(this.state.labelsForAdd, {
      $merge: {loc: added},
    });

    this.setState(state);
  },

  handleRoleChange(added) {
    const state = {};

    state.currentRole = added;
    state.labelsForAdd = update(this.state.labelsForAdd, {
      $merge: {role: added},
    });

    this.setState(state);
  },

  renderLabels() {
    // only show the table of labels if the cluster is not discovered
    // and the user is not readonly
    if (!this.props.data.isDiscovery && !this.props.data.isReadonly && this.props.data.node.subType !== 'container') {
      const className = cx({
        'DragWorkloadModal-Table': true,
        'RemoveWorkload': !this.props.data.addWorkload,
      });

      let labels;
      let locationLabelPresent = true;

      if (this.props.data.addWorkload) {
        labels = this.props.data.cluster && this.props.data.cluster.labels;

        // The workload is moved to a group with no location label.
        if (_.isEmpty(labels.loc) || !labels.loc) {
          locationLabelPresent = false;
        }

        labels = labels ? Object.values(labels) : [];
      } else {
        // only show app/env/loc labels
        labels = _.filter(this.props.data.node.labels, (label, key) => key !== 'role');
      }

      const selectedLoc = this.state.currentLoc ? [this.state.currentLoc] : [];

      labels = labels
        .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0))
        .reduce((result, label) => {
          if (!_.isEmpty(label)) {
            result.push(
              <tr className="DragWorkloadModal-Row">
                <td className="DragWorkloadModal-Row-Label">{LabelUtils.typesName[label.key]}</td>
                <td className="DragWorkloadModal-Row-Value">
                  <Label type={label.key} text={label.value} />
                </td>
              </tr>,
            );
          }

          // only in appMap let the location label be editable.
          if (!locationLabelPresent && MapPageStore.getMapType() === 'app') {
            locationLabelPresent = true;
            result.push(
              <tr className="DragWorkloadModal-Row">
                <td className="DragWorkloadModal-Row-Label">Location</td>
                <td className="DragWorkloadModal-Row-Value">
                  <OSLabelSelect
                    type="loc"
                    selected={selectedLoc}
                    onChange={this.handleLocationChange}
                    allowMultiSelect={false}
                    tid="loclabel"
                  />
                </td>
              </tr>,
            );
          }

          return result;
        }, []);

      let addRole = null;

      if (this.props.data.addWorkload || this.props.data.node.type === 'role') {
        const selectedRole = this.state.currentRole ? [this.state.currentRole] : [];

        addRole = (
          <tr className="DragWorkloadModal-Row">
            <td className="DragWorkloadModal-Row-Label">Role</td>
            <td className="DragWorkloadModal-Row-Value">
              <OSLabelSelect
                type="role"
                selected={selectedRole}
                onChange={this.handleRoleChange}
                allowMultiSelect={false}
                tid="rolelabel"
              />
            </td>
          </tr>
        );
      }

      return (
        <div>
          <table className={className}>
            <tbody>{labels}</tbody>
          </table>
          <table className="DragWorkloadModal-Table">
            <tbody>{addRole}</tbody>
          </table>
        </div>
      );
    }

    return null;
  },

  render() {
    let title;
    let message;
    let workloadsAffected;
    let optionalMessage;
    const {
      data,
      data: {isReadonly, addWorkload, isDiscovery, node},
    } = this.props;

    if (isReadonly) {
      title = intl('Map.CantPerformOperation');
      message = intl('Map.CantPerformOperationMessage');
    } else if (node.subType === 'container') {
      title = intl('Map.CantPerformOperation');
      message = intl('Map.CantChangeLabelsOnContainers');
    } else if (isDiscovery && addWorkload) {
      title = intl('Map.Workloads.CantDiscovery');
      message = intl('Map.Workloads.CantDiscoveryMessage');
    } else if (isDiscovery && !addWorkload) {
      title = intl('Map.Workloads.CantDiscovery');
      message = intl('Map.Workloads.CantDiscoveryMessage');
    } else if (addWorkload && node.type === 'virtualServer') {
      title = intl('Map.Workloads.AddVirtualServerToGroup');
      message = intl('Map.Workloads.AddVirtualServerToGroupMessage');
    } else if (addWorkload && node.type === 'virtualService') {
      title = intl('Map.Workloads.AddVirtualServiceToGroup');
      message = intl('Map.Workloads.AddVirtualServerToGroupMessage');
    } else if (addWorkload) {
      title = node.type === 'role' ? intl('Map.Workloads.AddToOtherGroup') : intl('Map.Workloads.AddToGroup');
      message =
        node.type === 'role' ? intl('Map.Workloads.AddToOtherGroupMessage') : intl('Map.Workloads.AddToGroupMessage');
    } else if (!addWorkload && node.subType === 'virtualServer') {
      title = intl('Map.Workloads.RemoveVirtualServerFromGroup');
      message = intl('Map.Workloads.RemoveVirtualServerFromGroupMessage');
    } else if (!addWorkload && node.type === 'virtualService') {
      title = intl('Map.Workloads.RemoveVirtualServiceFromGroup');
      message = intl('Map.Workloads.RemoveVirtualServiceFromGroupMessage');
    } else if (!data.addWorkload) {
      title = node.type === 'role' ? intl('Map.Workloads.RemoveFromGroup') : intl('Map.Workloads.RemoveOneFromGroup');
      message =
        node.type === 'role'
          ? intl('Map.Workloads.RemoveFromGroupMessage')
          : intl('Map.Workloads.RemoveOneFromGroupMessage');
    }

    if (node && node.type === 'role') {
      workloadsAffected = intl('Map.Workloads.AffectedCount', {count: _.size(this.props.data.node.children)});
      optionalMessage = intl('Map.Workloads.RemoveFromGroupNewRole');
    }

    let actions;

    if (!isDiscovery && !isReadonly) {
      actions = [
        {
          text: intl('Common.Cancel'),
          tid: 'cancel',
          type: 'nofill',
          onClick: () => {
            this.handleClose();
          },
        },
        {
          text: intl('Common.OK'),
          tid: 'ok',
          type: 'primary',
          ref: 'confirmButton',
          onClick: () => {
            this.handleConfirm();
            this.handleClose();
          },
        },
      ];
    } else {
      actions = [
        {
          text: intl('Common.OK'),
          tid: 'ok',
          ref: 'confirmButton',
          onClick: () => {
            this.handleClose();
          },
        },
      ];
    }

    return (
      <Dialog type="detail" className="DragWorkloadDialog" title={title} actions={actions}>
        <div className="DragWorkloadModal">
          {data.disableWarning && (
            <div className="DragWorkloadModal-Message">
              {' '}
              <Notification type="warning" message={intl('RBAC.ManageWarning')} />{' '}
            </div>
          )}
          <div className="DragWorkloadModal-Message">{message}</div>
          {node && node.type === 'role' && <div className="DragWorkloadModal-Message">{optionalMessage}</div>}
          {node && node.type === 'role' && <div className="DragWorkloadModal-WorkloadsNum">{workloadsAffected}</div>}
          {this.renderLabels()}
        </div>
      </Dialog>
    );
  },
});
