/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import Icon from '../components/Icon.jsx';
import {HealthStore} from '../stores';

const iconProps = {
  red: {
    name: 'close',
    helpText: intl('Common.Error'),
  },
  yellow: {
    name: 'warning',
    helpText: intl('Common.Warning'),
  },
  green: {
    name: 'check',
    helpText: intl('Common.OK'),
  },
};

const HealthUtils = {};

// 'Error', 'Degraded', 'Normal' is not intlized and is returned by the API
HealthUtils.status = {
  normal: intl('Health.Normal'),
  warning: intl('Common.Warning'),
  critical: intl('Common.Critical'),
  unknown: intl('Common.Unknown'),
};

// 'Error', 'Degraded', 'Normal' corresponding icon colors
HealthUtils.statusIcons = {
  normal: 'green',
  warning: 'yellow',
  critical: 'red',
};

// 'Leader', 'Member' is not intlized and is returned by the API
HealthUtils.pceType = () => ({
  leader: intl('Health.Leader'),
  member: intl('Health.Member'),
});

/**
 * Convert seconds into Weeks, Days, Hours, Minutes and Seconds
 *
 * @param valueInSeconds
 * @returns {{weeks: number, days: number, hours: number, minutes: number, seconds: number}}
 */
HealthUtils.secondsToWDHMS = valueInSeconds => {
  const num = Number(valueInSeconds);
  const weeks = Math.floor(num / (3600 * 24 * 7));
  const days = Math.floor(num / (3600 * 24));
  const hours = Math.floor((num / 3600) % 24);
  const minutes = Math.floor((num % 3600) / 60);
  const seconds = Math.floor((num % 3600) % 60);

  return {weeks, days, hours, minutes, seconds};
};

/**
 * Return hours minutes seconds in string format
 * Show only minutes if <= 59 minutes
 * Show only hours if <= 23 hours
 * Show only days otherwise
 *
 * @param value       Seconds
 * @returns {string}  Formatted seconds
 */
HealthUtils.getPceUptime = value => {
  const uptime = {days: 0, hours: 0, minutes: 0, seconds: 0};
  const {days, hours, minutes} = HealthUtils.secondsToWDHMS(value);

  if (days) {
    return intl('Health.DaysHrsMinSec', {...uptime, days});
  }

  if (hours) {
    return intl('Health.DaysHrsMinSec', {...uptime, hours});
  }

  if (minutes) {
    return intl('Health.DaysHrsMinSec', {...uptime, minutes});
  }

  return intl('Health.DaysHrsMinSec', uptime);
};

/**
 * Return Red, Yellow or Green icon used in PCE Health
 *
 * @param type      Color, one of 'red', 'yellow', 'green'
 * @returns {XML}   React Component
 */
HealthUtils.getIcon = type => {
  const classnames = cx('HealthIcon', {
    'HealthIcon--Red': type === 'red',
    'HealthIcon--Yellow': type === 'yellow',
    'HealthIcon--Green': type === 'green',
  });

  return (
    <div className={classnames}>
      <Icon size="small" {...iconProps[type]} />
    </div>
  );
};

/**
 * Give a PCE Health, return the Intlized string with icon
 *
 * @param status    One of 'Error', 'Degraded', 'Normal'
 * @param cluster   cluster object JSON data
 * @returns {XML}   React Element
 */
HealthUtils.getPceHealth = (status, cluster) => {
  // When is member is in rolling upgrade cluster and status is critical
  if (HealthUtils.isRollingUpgradeUnknown(cluster)) {
    return intl('Common.Unavailable');
  }

  return (
    <span className="HealthIconWithString">
      {HealthUtils.getIcon(HealthUtils.statusIcons[status])}
      {HealthUtils.status[status]}
    </span>
  );
};

/**
 * Give a cluster data, determine if member is in a rolling upgrade with specific status
 *
 * @param cluster      cluster object JSON data
 * @param status       cluster status: critical, normal, warning
 * @returns {Boolean}  true - user is member in rolling upgrade, false - user is not a member and not in rolling upgrade
 */
HealthUtils.isRollingUpgradeUnknown = (cluster, status = 'critical') =>
  cluster && cluster.type === 'unknown' && cluster.status === status && HealthStore.getInRollingUpgrade();

/**
 * Give a PCE listen only timestamp string, return the Intlized string with icon
 *
 * @param status    Timestamp string or undefined
 * @returns {XML}   React Element
 */
HealthUtils.getListenOnly = enabledAt => (
  <span className="HealthIconWithString">
    {HealthUtils.getIcon(enabledAt ? 'yellow' : 'green')}
    {enabledAt ? intl('Common.Enabled') : intl('Common.Disabled')}
  </span>
);

/**
 * Give a PCE rolling upgrade status, return the Intlized string with icon
 *
 * @param status    bool
 * @returns {XML}   React Element
 */
HealthUtils.getUpgradeStatus = status => (
  <span className="HealthIconWithString">
    {HealthUtils.getIcon(status ? 'yellow' : 'green')}
    {status ? intl('Common.Pending') : intl('Health.Complete')}
  </span>
);

/**
 * Convert backend returned non intlized node status to intlized string
 *
 * @param string      Status of the node
 * @return {string}   Intlized Status with icon
 */
/* eslint-disable no-unreachable */
HealthUtils.getNodeStatus = string => {
  switch (string) {
    case 'STOPPED':
      return (
        <>
          {HealthUtils.getIcon('red')}
          {intl('Common.Stopped')}
        </>
      );
    case 'RUNNING':
      return (
        <>
          {HealthUtils.getIcon('green')}
          {intl('Common.Running')}
        </>
      );
    case 'PARTIAL':
      return (
        <>
          {HealthUtils.getIcon('yellow')}
          {intl('Health.Partial')}
        </>
      );
    case 'UNKNOWN':
      return (
        <>
          {HealthUtils.getIcon('yellow')}
          {intl('Common.Unknown')}
        </>
      );
    case 'ERROR: Unable to get node status':
      return (
        <>
          {HealthUtils.getIcon('yellow')}
          {intl('Health.ErrorUnableToGetToNodeStatus')}
        </>
      );
    default:
      return _.upperFirst(string.toLocaleLowerCase());
  }
};

/**
 * Convert backend returned non intlized service status to intlized string
 *
 * @param string      Status of the service
 * @return {string}   Intlized Status
 */
HealthUtils.getServiceStatus = string => {
  switch (string) {
    case 'running':
      return intl('Common.Running');
      break;
    case 'not_running':
      return intl('Health.NotRunning');
    case 'partial':
      return intl('Health.Partial');
    case 'optional':
      return intl('Common.Optional');
    case 'unknown':
      return intl('Common.Unknown');
    default:
      // The status is empty if the service doesn't run on the specific node
      return '';
  }
};
/* eslint-enable no-unreachable */

/**
 * Backend returns this in the format of "24 days 5:45 hours" or "24 days 56 min"
 * Untested: "5:45 hours" or "56 min"
 * The UI needs to display the time format in a consistent manner with other places
 * TODO: try to get the response in correct format
 *
 * Show only minutes if <= 59 minutes
 * Show only hours if <= 23 hours
 * Show only days otherwise
 *
 * @param string  Time string
 * @return {string}  React Element
 */
HealthUtils.formatNodeUptime = string => {
  if (!string) {
    return <span>&nbsp;</span>;
  }

  let days = 0;
  let hours = 0;
  let minutes = 0;

  if (string.includes(':')) {
    const data = string.split(':').map(s => s.split(' '));

    if (data[0].length === 1) {
      hours = data[0];
    } else {
      days = data[0][0];
    }
  } else {
    const data = string.split(' ');

    if (data.length === 2) {
      // If string is like "56 min" (untested)
      minutes = data[0];
    } else {
      days = data[0];
    }
  }

  return intl('Health.DaysHrsMinSec', {days, hours, minutes});
};

/**
 * Return an array of Health messages (or notifications) to be showed in
 * the Health Detail page
 *
 * @param pceData   Object
 */
HealthUtils.generateMessages = pceData => {
  // pceData Object contains multiple fields with the key "status".
  // For the fields whose "status" is not normal, synthetically add a
  // message (or notification) for UI display purposes.
  //
  // The API only returns notifications which cannot be inferred
  // from the response (like time drift between nodes,
  // incorrect cluster type configuration, etc.).
  // The purpose of this method is to generate an overall summary of all
  // the PCE attributes which are in bad state. The overall summary is
  // generated in the form of English messages which are then displayed in
  // the PCE Health detail page — along with the notifications returned by the API.
  const additionalNotifications = [];
  const normalStatus = {status: 'normal'};
  // Not all pceData Objects will have the network key
  const networkReplication = pceData.network && pceData.network.replication;

  if (_.get(pceData, 'nodes', []).length) {
    pceData.nodes.forEach(node => {
      const {cpu = normalStatus, disk = [{value: normalStatus}], memory = normalStatus, services = normalStatus} = node;
      const nodeHostname = _.upperFirst(node.hostname);

      if (node.runlevel !== 5) {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeRunlevelNotFive', {
            node: nodeHostname,
          }),
        });
      }

      if (cpu.status !== 'normal') {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeCpuStatus', {
            node: nodeHostname,
            status: HealthUtils.status[cpu.status],
          }),
        });
      }

      if (disk.some(({value = {}}) => value.status !== 'normal')) {
        let diskStatus = '';

        disk.forEach(({value = {}}) => {
          if (value.status === 'warning') {
            diskStatus = 'warning';
          }

          if (value.status === 'critical') {
            diskStatus = 'critical';
          }
        });

        additionalNotifications.push({
          message: intl('Health.Notifications.NodeDiskStatus', {
            node: nodeHostname,
            status: HealthUtils.status[diskStatus],
          }),
        });
      }

      if (memory.status !== 'normal') {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeMemoryStatus', {
            node: nodeHostname,
            status: HealthUtils.status[memory.status],
          }),
        });
      }

      if (services.status !== 'normal') {
        additionalNotifications.push({
          message: intl('Health.Notifications.NodeServicesStatus', {
            node: nodeHostname,
            status: HealthUtils.status[services.status],
          }),
        });
      }
    });
  }

  if (networkReplication && networkReplication.some(({value = {}}) => value.status !== 'normal')) {
    let replicationStatus = '';

    networkReplication.forEach(({value = {}}) => {
      if (value.status === 'warning') {
        replicationStatus = 'warning';
      }

      if (value.status === 'critical') {
        replicationStatus = 'critical';
      }
    });

    additionalNotifications.push({
      message: intl('Health.Notifications.NodeNetworkReplicationStatus', {
        status: HealthUtils.status[replicationStatus],
      }),
    });
  }

  return additionalNotifications;
};

/**
 * Return appropriate CPU, Memory and Disk Usage badge
 *
 * @param value       Object
 * @return {XML}      React Element
 */
HealthUtils.getUsageBadge = value => {
  if (!value) {
    return <span>&nbsp;</span>;
  }

  const {status, percent} = value;
  const color = HealthUtils.statusIcons[status];

  return (
    <>
      {HealthUtils.getIcon(color)}
      {intl('Health.Percent', {val: percent / 100})}
    </>
  );
};

/**
 * Return appropriate Database Replication badge
 *
 * @param seconds     number
 * @return {XML}      React Element
 */
HealthUtils.getDatabaseReplicationlag = value => {
  if (!value) {
    return (
      <>
        {HealthUtils.getIcon('yellow')}
        {intl('Common.Unknown')}
      </>
    );
  }

  const {status, lag_seconds: seconds} = value;
  const color = HealthUtils.statusIcons[status];

  return (
    <>
      {HealthUtils.getIcon(color)}
      {intl('Health.Seconds', {seconds})}
    </>
  );
};

/**
 * Based on the timestamp of the last successful sync,
 * return how long ago that was
 *
 * @param timestamp   Timestamp in string
 * @return {string}   String of timestamp
 */
HealthUtils.getIllumincationSync = timestamp => {
  if (!timestamp) {
    return intl('Common.Never');
  }

  const dateObj = new Date(timestamp);
  const currentDate = new Date();
  const differenceInSeconds = (currentDate - dateObj) / 1000;

  // If timestamp is before a week ago, show the timestamp as is in a certain format
  if (dateObj < intl.utils.subtractTime(currentDate, 'd', 7)) {
    return intl.date(dateObj, intl.formats.date.L_HH_mm);
  }

  // Show 'Just Now' for less than sixty seconds ago
  if (differenceInSeconds < 60) {
    return intl('Map.JustNow');
  }

  return intl('Health.Ago', {
    time: HealthUtils.getPceUptime(differenceInSeconds),
  });
};

/**
 * Based on a timestamp
 * return how long ago that was
 *
 * @param time String of timestamp
 * @return {time: integer, unit: string}  Object time and units
 */
HealthUtils.getDuration = time => {
  const differenceInSeconds = (Date.now() - new Date(time).getTime()) / 1000;
  const {weeks, days, hours, minutes, seconds} = HealthUtils.secondsToWDHMS(differenceInSeconds);

  if (weeks) {
    return {time: weeks, unit: intl('Health.Weeks', {weeks})};
  }

  if (days) {
    return {time: days, unit: intl('Health.Days', {days})};
  }

  if (hours) {
    return {time: hours, unit: intl('Health.Hours', {hours})};
  }

  if (minutes) {
    return {time: minutes, unit: intl('Health.Minutes', {minutes})};
  }

  if (seconds) {
    return {time: seconds, unit: intl('Health.Secs', {seconds})};
  }
};

/**
 * Format friendly date
 *
 * @param date String of ISO date
 * @return date  String of friendly date
 */
HealthUtils.formatDate = date => intl.date(date, intl.formats.date.l_h_mm);

export default HealthUtils;
