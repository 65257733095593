/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import update from 'react-addons-update';
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {Form} from '../FormComponents';
import {FormComponentsDialogForm} from '.';
import {Button, Dialog, Grid, MultilineInput, OSLabelSelect} from '..';
import {ToolBar, ToolGroup} from '../ToolBar';
import {GridDataUtils, IpUtils} from '../../utils';

const onGridAddEditCancel = () => {
  actionCreators.closeDialog();
};

export default React.createClass({
  propTypes: {
    type: PropTypes.string,
    model: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  },

  getInitialState() {
    return {
      model: {},
      errorModel: {},
      initialModel: {},
      gridSelection: [],
      gridSorting: [],
    };
  },

  componentWillMount() {
    this.formFields = [
      {
        type: 'fieldset',
        title: intl('Common.General'),
        name: 'general',
        fields: [
          {
            type: 'field',
            label: intl('Common.Name'),
            name: 'name',
            required: true,
            controls: [
              {
                type: 'text',
                maxlength: 20,
                name: 'name',
                placeholder: 'Enter a unique name no more than 20 characters',
              },
            ],
          },
          {
            type: 'field',
            label: intl('Common.Description'),
            name: 'description',
            required: true,
            controls: [
              {
                type: 'textarea',
                maxlength: 255,
                name: 'description',
                placeholder: 'Enter a description no more than 255 characters',
              },
            ],
          },
          {
            type: 'field',
            label: 'Display Disabled Example',
            name: 'visible',
            required: true,
            controls: [
              {
                type: 'radiogroup',
                name: 'visible',
                display: 'horizontal',
                options: [
                  {
                    label: 'Hidden',
                    value: '0',
                  },
                  {
                    label: 'Visible',
                    value: '1',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Enable Disabled Example',
            name: 'enabled',
            required: true,
            hidden: model => model.visible !== '1',
            controls: [
              {
                type: 'radiogroup',
                name: 'enabled',
                display: 'horizontal',
                options: [
                  {
                    label: 'Disabled',
                    value: '0',
                  },
                  {
                    label: 'Enabled',
                    value: '1',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'fieldset',
        title: 'Disable Example',
        subtitle:
          'This shows how visiblity and disabled is inherited. Fieldsets have no disabled styles themselves, but pass down the disabled property to all children.',
        name: 'disabled',
        disabled: model => model.enabled !== '1',
        hidden: model => model.visible !== '1',
        fields: [
          {
            type: 'field',
            label: 'Radios',
            name: 'radio0',
            required: true,
            controls: [
              {
                type: 'radiogroup',
                name: 'radio0',
                display: 'horizontal',
                options: [
                  {
                    label: 'Radio 1',
                    value: '1',
                  },
                  {
                    label: 'Radio 2',
                    value: '2',
                  },
                  {
                    label: 'Radio 3',
                    value: '3',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Enabled Options',
            name: 'enabledcomplex',
            required: true,
            controls: [
              {
                type: 'checkboxgroup',
                name: 'enabledcomplex',
                display: 'horizontal',
                options: [
                  {
                    label: 'Display Field',
                    value: '1',
                  },
                  {
                    label: 'Enable Field',
                    value: '1.1',
                  },
                  {
                    label: 'Display First Control',
                    value: '2',
                  },
                  {
                    label: 'Enable First Control',
                    value: '2.1',
                  },
                  {
                    label: 'Display Second Control',
                    value: '3',
                  },
                  {
                    label: 'Enable Second Control',
                    value: '3.1',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Disabled Example',
            name: 'firstandsecond',
            hidden: model => !model.enabledcomplex || !model.enabledcomplex.includes('1'),
            disabled: model => !model.enabledcomplex || !model.enabledcomplex.includes('1.1'),
            required: true,
            controls: [
              {
                type: 'text',
                maxlength: 20,
                name: 'first',
                hidden: model => !model.enabledcomplex || !model.enabledcomplex.includes('2'),
                disabled: model => !model.enabledcomplex || !model.enabledcomplex.includes('2.1'),
              },
              {
                type: 'select',
                name: 'second',
                placeholder: 'Select an option',
                options: [
                  {
                    label: 'Role',
                    value: 'role',
                  },
                  {
                    label: 'Application',
                    value: 'application',
                  },
                  {
                    label: 'Environment',
                    value: 'environment',
                  },
                  {
                    label: 'Location',
                    value: 'location',
                  },
                ],
                hidden: model => !model.enabledcomplex || !model.enabledcomplex.includes('3'),
                disabled: model => !model.enabledcomplex || !model.enabledcomplex.includes('3.1'),
              },
            ],
          },
        ],
      },
      {
        type: 'fieldset',
        title: 'Other Examples',
        name: 'attributes',
        fields: [
          {
            type: 'field',
            label: 'Horizontal Radios',
            name: 'radio1',
            required: true,
            controls: [
              {
                type: 'radiogroup',
                name: 'radio1',
                display: 'horizontal',
                options: [
                  {
                    label: 'Radio 1',
                    value: '1',
                  },
                  {
                    label: 'Radio 2',
                    value: '2',
                  },
                  {
                    label: 'Radio 3',
                    value: '3',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Vertical Radios',
            name: 'radio2',
            required: true,
            visible: model => model.radio1 === '1',
            controls: [
              {
                type: 'radiogroup',
                name: 'radio2',
                display: 'vertical',
                options: [
                  {
                    label: 'Radio 1 '.repeat(20),
                    sublabel: 'Sub Radio 1 '.repeat(20),
                    value: '1',
                  },
                  {
                    label: 'Radio 2',
                    value: '2',
                  },
                  {
                    label: 'Radio 3',
                    sublabel: 'Sub Radio 3',
                    value: '3',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Horizontal Checkboxes',
            name: 'checkbox1',
            required: true,
            controls: [
              {
                type: 'checkboxgroup',
                name: 'checkbox1',
                display: 'horizontal',
                options: [
                  {
                    label: 'Checkbox 1',
                    value: '1',
                  },
                  {
                    label: 'Checkbox 2',
                    value: '2',
                  },
                  {
                    label: 'Checkbox 3',
                    value: '3',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Vertical Checkboxes',
            name: 'checkbox2',
            required: true,
            controls: [
              {
                type: 'checkboxgroup',
                name: 'checkbox2',
                display: 'vertical',
                options: [
                  {
                    label: 'Checkbox 1',
                    sublabel: 'Sub Checkbox 1',
                    value: '1',
                  },
                  {
                    label: 'Checkbox 2',
                    value: '2',
                  },
                  {
                    label: 'Checkbox 3',
                    sublabel: 'Sub Checkbox 3',
                    value: '3',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Select Example',
            name: 'select1',
            required: true,
            controls: [
              {
                type: 'select',
                name: 'select1',
                placeholder: 'Select an option',
                options: [
                  {
                    label: 'Option 1',
                    sublabel: 'Sub Option 1',
                    value: '1',
                  },
                  {
                    label: 'Option 2',
                    sublabel: 'Sub Option 2',
                    value: '2',
                  },
                  {
                    label: 'Option 3',
                    value: '3',
                  },
                  {
                    label: 'Option 4',
                    sublabel: 'Sub Option 4',
                    value: '4',
                  },
                  {
                    label: 'Option 5',
                    sublabel: 'Sub Option 5',
                    value: '5',
                  },
                  {
                    label: 'Option 6 '.repeat(20),
                    sublabel: 'Sub Option 6 '.repeat(20),
                    value: '6',
                  },
                  {
                    label: 'Option 7',
                    sublabel: 'Sub Option 7',
                    value: '7',
                  },
                  {
                    label: 'Option 8',
                    sublabel: 'Sub Option 8',
                    value: '8',
                  },
                  {
                    label: 'Option 9',
                    sublabel: 'Sub Option 9',
                    value: '9',
                  },
                  {
                    label: 'Option 10',
                    sublabel: 'Sub Option 10',
                    value: '10',
                  },
                  {
                    label: 'Option 11',
                    sublabel: 'Sub Option 11',
                    value: '11',
                  },
                  {
                    label: 'Option 12',
                    sublabel: 'Sub Option 12',
                    value: '12',
                  },
                  {
                    label: 'Option 13',
                    sublabel: 'Sub Option 13',
                    value: '13',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'fieldset',
        title: 'Custom Controls',
        name: 'custom',
        fields: [
          {
            type: 'field',
            label: 'Display Custom Fields',
            name: 'customvisible',
            required: true,
            controls: [
              {
                type: 'radiogroup',
                name: 'customvisible',
                display: 'horizontal',
                options: [
                  {
                    label: 'Hidden',
                    value: '0',
                  },
                  {
                    label: 'Visible',
                    value: '1',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Enable Custom Fields',
            name: 'customenabled',
            required: true,
            hidden: model => model.customvisible !== '1',
            controls: [
              {
                type: 'radiogroup',
                name: 'customenabled',
                display: 'horizontal',
                options: [
                  {
                    label: 'Disabled',
                    value: '0',
                  },
                  {
                    label: 'Enabled',
                    value: '1',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            label: 'Label Select',
            name: 'labelSelect',
            required: true,
            disabled: model => model.customenabled !== '1',
            hidden: model => model.customvisible !== '1',
            controls: [
              {
                type: 'custom',
                name: 'location',
                getRef: refs => refs.objectSelector.refs.itemInput,
                getControl: props => (
                  <OSLabelSelect
                    onChange={(added, removed) => {
                      if (removed) {
                        props.onChange();
                      } else {
                        props.onChange(added);
                      }
                    }}
                    type="loc"
                    allowMultiSelect={false}
                    selected={props.value ? [props.value] : []}
                    key={props.key}
                    error={props.error}
                    disabled={props.disabled}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    tid={props.tid}
                    ref={props.ref}
                    tabIndex={props.tabIndex}
                  />
                ),
              },
            ],
          },
          {
            type: 'field',
            label: 'IPs',
            name: 'ips',
            required: true,
            disabled: model => model.customenabled !== '1',
            hidden: model => model.customvisible !== '1',
            controls: [
              {
                type: 'custom',
                name: 'ips',
                getRef: refs => refs.textarea,
                getControl: props => (
                  <MultilineInput
                    parse={IpUtils.parseAddressLine}
                    stringify={IpUtils.stringifyAddressObject}
                    validate={IpUtils.validateAddresses}
                    readonly={false}
                    showDiff={true}
                    placeholder={intl('IPLists.TypeOrPasteAndEnter')}
                    name={props.key}
                    onChange={props.onChange}
                    values={props.value || []}
                    key={props.key}
                    error={props.error}
                    disabled={props.disabled}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    tid={props.tid}
                    ref={props.ref}
                    tabIndex={props.tabIndex}
                  />
                ),
                validation: model => {
                  let hasValue = false;
                  let hasError = false;

                  _.forEach(model.ips || [], ip => {
                    if (ip.error || ip.duplicate) {
                      hasError = true;
                    }

                    if (ip.text) {
                      hasValue = true;
                    }
                  });

                  if (hasError) {
                    return {
                      errorString: 'Correct the errors highlighted in red',
                    };
                  }

                  if (!hasValue) {
                    return {
                      errorString: 'Must enter at least one value',
                    };
                  }

                  return true;
                },
              },
            ],
          },
        ],
      },
      {
        type: 'fieldset',
        title: 'Grid Example',
        name: 'grid',
        fields: [
          {
            type: 'field',
            label: 'Enable Grid Below',
            name: 'enablegrid',
            required: true,
            controls: [
              {
                type: 'radiogroup',
                name: 'enablegrid',
                display: 'horizontal',
                options: [
                  {
                    label: 'Disabled',
                    value: '0',
                  },
                  {
                    label: 'Enabled',
                    value: '1',
                  },
                ],
              },
            ],
          },
          {
            type: 'field',
            name: 'grid',
            required: true,
            customStyle: 'grid',
            label: 'Complex Grid Example',
            hint: 'Enter at least one and most 3 grid rows below',
            hidden: model => model.enablegrid !== '1',
            controls: [
              {
                type: 'custom',
                name: 'grid',
                validation: model => {
                  if (model.grid && model.grid.length > 3) {
                    return {
                      errorString: 'Too many items',
                    };
                  }

                  return true;
                },
                getRef: refs => refs.button,
                getControl: props => {
                  const value = props.value || [];

                  const onGridAddSubmit = data => {
                    let newGridData;

                    if (value.length) {
                      data.id = _.maxBy(value, row => row.id).id + 1;
                      newGridData = update(value, {$push: [data]});
                    } else {
                      data.id = 1;
                      newGridData = [data];
                    }

                    props.onChange(newGridData);
                    actionCreators.closeDialog();
                  };

                  const onGridAdd = () => {
                    actionCreators.openDialog(
                      <Dialog type="form" title="Add Row">
                        <FormComponentsDialogForm
                          type="dialog"
                          onSubmit={onGridAddSubmit}
                          onCancel={onGridAddEditCancel}
                        />
                      </Dialog>,
                    );
                  };

                  const onGridEditSubmit = data => {
                    const index = _.findIndex(value, row => row.id === data.id);

                    if (index !== -1) {
                      const newGridData = _.clone(value);

                      newGridData[index] = data;
                      props.onChange(newGridData);
                    }

                    actionCreators.closeDialog();
                  };

                  const onGridEdit = data => {
                    actionCreators.openDialog(
                      <Dialog type="form" title="Edit Row">
                        <FormComponentsDialogForm
                          type="dialog"
                          model={data}
                          onSubmit={onGridEditSubmit}
                          onCancel={onGridAddEditCancel}
                        />
                      </Dialog>,
                    );
                  };

                  const onGridRemove = () => {
                    const newGridData = _.filter(value, row => !this.state.gridSelection.includes(row.id));

                    props.onChange(newGridData);
                    this.setState({gridSelection: []});
                  };

                  const classes = cx({
                    'ReForm-Grid': true,
                    'ReForm-Grid--error': props.error,
                  });

                  return (
                    <div key={props.key} data-tid={props.tid} className={classes}>
                      <ToolBar>
                        <ToolGroup>
                          <Button
                            text={intl('Common.Add')}
                            onClick={onGridAdd}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            tabIndex={props.tabIndex}
                            tid="add"
                            ref={props.ref}
                          />
                          <Button
                            text={intl('Common.Remove')}
                            disabled={!this.state.gridSelection.length}
                            onClick={onGridRemove}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            tid="remove"
                          />
                        </ToolGroup>
                      </ToolBar>
                      {value.length ? (
                        <Grid
                          sorting={this.state.gridSorting}
                          selection={this.state.gridSelection}
                          sortable={true}
                          selectable={true}
                          idField="id"
                          onSort={this.handleGridSort}
                          onRowSelectToggle={this.handleGridSelect}
                          columns={[
                            {
                              key: 'name',
                              label: 'Name',
                              sortable: true,
                              sortValue(value) {
                                return value.toLowerCase();
                              },
                            },
                            {
                              key: 'name2',
                              label: 'Name 2',
                              sortable: true,
                              sortValue(value) {
                                return value.toLowerCase();
                              },
                            },
                            {
                              key: 'actions',
                              style: 'actions',
                              format: (value, row) => (
                                <Button
                                  icon="edit"
                                  content="icon-only"
                                  onClick={() => {
                                    onGridEdit(row);
                                  }}
                                  onFocus={props.onFocus}
                                  onBlur={props.onBlur}
                                  tid="edit"
                                />
                              ),
                            },
                          ]}
                          data={value}
                        />
                      ) : null}
                    </div>
                  );
                },
              },
            ],
          },
        ],
      },
      {
        type: 'fieldset',
        title: 'Advanced Examples',
        subtitle:
          'These examples should probably be avoided at all costs, but are presented here to show they are possible if needed',
        name: 'advanced',
        fields: [
          {
            type: 'field',
            name: 'complexcheckbox',
            label: 'Complex Checkbox',
            splitStyle: 'vertical',
            required: true,
            controls: [
              {
                type: 'checkbox',
                name: 'complexcheckbox',
                label: 'Checkbox 1',
                sublabel: 'Sub Checkbox 1 '.repeat(10),
                value: '1',
              },
              {
                type: 'text',
                name: 'complexcheckboxsubfield1',
                placeholder: 'Enter a value for option 1',
                hidden: model => !model.complexcheckbox || !model.complexcheckbox.includes('1'),
              },
              {
                type: 'checkbox',
                name: 'complexcheckbox',
                label: 'Checkbox 2',
                sublabel: 'Sub Checkbox 2 '.repeat(10),
                value: '2',
              },
              {
                type: 'text',
                name: 'complexcheckboxsubfield2',
                placeholder: 'Enter a value for option 2',
                hidden: model => !model.complexcheckbox || !model.complexcheckbox.includes('2'),
              },
              {
                type: 'checkbox',
                name: 'complexcheckbox',
                label: 'Checkbox 3',
                sublabel: 'Sub Checkbox 3 '.repeat(10),
                value: '3',
              },
              {
                type: 'text',
                name: 'complexcheckboxsubfield3',
                placeholder: 'Enter a value for option 3',
                hidden: model => !model.complexcheckbox || !model.complexcheckbox.includes('3'),
              },
            ],
          },
          {
            type: 'field',
            name: 'complexradio',
            label: 'Complex Radio',
            required: true,
            controls: [
              {
                type: 'radio',
                name: 'complexradio',
                label: 'Radio 1',
                sublabel: 'Sub Radio 1 '.repeat(4),
                value: '1',
              },
              {
                type: 'text',
                name: 'complexradiosubfield1',
                placeholder: 'Enter a value for option 1',
                hidden: model => model.complexradio !== '1',
              },
              {
                type: 'radio',
                name: 'complexradio',
                label: 'Radio 2',
                sublabel: 'Sub Radio 2 '.repeat(4),
                value: '2',
              },
              {
                type: 'text',
                name: 'complexradiosubfield2',
                placeholder: 'Enter a value for option 2',
                hidden: model => model.complexradio !== '2',
              },
              {
                type: 'radio',
                name: 'complexradio',
                label: 'Radio 3',
                sublabel: 'Sub Radio 3 '.repeat(4),
                value: '3',
              },
              {
                type: 'text',
                name: 'complexradiosubfield3',
                placeholder: 'Enter a value for option 3',
                hidden: model => model.complexradio !== '3',
              },
            ],
          },
        ],
      },
    ];
  },

  componentDidMount() {
    this.setInitialModel(this.props.model);
  },

  componentWillReceiveProps(nextProps) {
    if (this.areModelsDifferent(this.state.initialModel, this.getFriendlyModel(nextProps.model))) {
      this.setInitialModel(nextProps.model);
    }
  },

  getFriendlyModel(unfriendlyModel) {
    return unfriendlyModel || {};
  },

  setInitialModel(initialModel) {
    const model = this.getFriendlyModel(initialModel);

    this.setState({model, initialModel: model});
  },

  handleChange(modelUpdates) {
    this.setState(previousState => ({model: {...previousState.model, ...modelUpdates}}));
  },

  handleErrorChange(errorModelUpdates) {
    this.setState(previousState => ({errorModel: {...previousState.errorModel, ...errorModelUpdates}}));
  },

  handleSubmit() {
    this.props.onSubmit(this.state.model);
  },

  handleGridSort(key, direction) {
    this.setState({gridSorting: key ? [{key, direction}] : []});
  },

  handleGridSelect(selection) {
    this.setState({gridSelection: GridDataUtils.selectToggle(this.state.gridSelection, selection)});
  },

  areModelsDifferent(original, compare) {
    if (original.name !== compare.name) {
      return true;
    }

    return false;
  },

  hasChanged() {
    return this.areModelsDifferent(this.state.initialModel, this.state.model);
  },

  render() {
    return (
      <Form
        type={this.props.type}
        fields={this.formFields}
        model={this.state.model}
        errorModel={this.state.errorModel}
        onChange={this.handleChange}
        onErrorChange={this.handleErrorChange}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
      />
    );
  },
});
