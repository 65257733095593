/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import Draggable from 'react-draggable';
import intl from 'intl';
import Popout from 'react-popout';
import HelpPopup from './HelpPopup.jsx';
import {State, Navigation} from 'react-router';
import {Icon} from '..';
import HelpPopupUtils from '../../utils/HelpPopupUtils';

//The HelpPopupLauncher is the parent component which launches the HelpPopup and controls state for the HelpPopup.
//HelpPopup state determines whether the HelpPopup is open or closed and whether it is expanded (in a pop up window) or in the same window.
export default React.createClass({
  mixins: [State, Navigation],

  getInitialState() {
    return {
      open: false,
      expand: false,
      expandRouteKey: '',
    };
  },

  handleExpand() {
    if (this.state.expand) {
      this.setState({expand: false, open: true, expandRoute: ''});
    } else {
      this.setState({
        expand: true,
        open: true,
        expandRouteKey: HelpPopupUtils.getHelpPopupKeyFromRoute(this.getRoutes()[1].name, this.props.title),
      });
    }
  },

  handleHelpClick() {
    this.setState({open: !this.state.open});
  },

  handlePopoutClose() {
    if (this.state.expand) {
      this.setState({expand: false, open: false, expandRouteKey: ''});
    }
  },

  render() {
    const helpPopupKey = HelpPopupUtils.getHelpPopupKeyFromRoute(this.getRoutes()[1].name, this.props.title);

    //Popout will create a new popup window containing just the helpPopup component
    const popoutHelp = this.state.open && this.state.expand && (
      <Popout
        url="./#/helpmenuold/"
        options={{scrollbars: 'no', width: '360px', height: '600px'}}
        title={intl('Help.Menu.HelpMenu')}
        onClosing={this.handlePopoutClose}
      >
        <HelpPopup
          data={HelpPopupUtils.getHelpPopupItems(this.state.expandRouteKey)}
          onExpand={this.handleExpand}
          poppedOut={this.state.expand}
          canExpand={true}
          canClose={false}
        />
      </Popout>
    );

    //Draggable make the Help Menu draggable on the title bar
    const sidebarHelp = this.state.open && !this.state.expand && (
      <Draggable handle=".HelpPopup-title-bar" bounds="body">
        <div className="HelpPopup-wrapper">
          <HelpPopup
            data={HelpPopupUtils.getHelpPopupItems(helpPopupKey)}
            onClose={this.handleHelpClick}
            onExpand={this.handleExpand}
            canExpand={true}
            canClose={true}
          />
        </div>
      </Draggable>
    );

    return (
      <div className="HelpPopupLauncher" data-tid="comp-navbar-contextualhelp">
        <div className="HelpPopupLauncher-button" onClick={this.handleHelpClick}>
          <Icon styleClass="HelpPopupLauncher" name="help-menu" />
        </div>
        {sidebarHelp}
        {popoutHelp}
      </div>
    );
  },
});
