/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import {minmaxFor} from './GeneralUtils';
import update from 'react-addons-update';
import RenderUtils from './RenderUtils';

// For nodes, have a standard width for text and then use ellipse (talk to Roy)
const margin = {top: 100, left: 100};
const padding = {top: 0, left: 75};
const locationPadding = {top: 130};
const clusterMargin = {top: 50, left: 50};
const clusterPadding = {top: 180, left: 400};
const nodePadding = {top: 80, left: 160};
const circlePadding = 40;
const internetX = 90;
const internetY = 60;

// if locations display on screen is different from locations saved in position,
// location layout need to be recalculated
const areLocationsIdentical = (locations, positions) => Object.keys(locations).every(key => positions.locations[key]);

// note: This function has unit test
// if you are going to change this function, please update unit test as well
export function positionLocationGraph(locations, positions, mapLevel, filters) {
  // if current locations are different from the locations in localStorage
  // recalculate locatons layout
  const isSame = areLocationsIdentical(locations, positions, filters);

  if (_.isEmpty(positions.locations) || !isSame) {
    // initially calculate the location layout by circle packing layout algorithm
    locations = calculateCirclePackingLayout(locations, mapLevel);
  } else {
    // The scale for location circle radius based on location entityCounts
    const sizeScale = calculateLocationCircleScale(locations);

    // if the location already there, then use its previous position values
    locations = _.map(locations, (location, key) => {
      if (_.isEmpty(positions.locations[key])) {
        // the random number is to reduce circle overlapping when majority of positions.locations not equal to locations
        location.x = (window.innerWidth || document.documentElement.clientWidth) / 2 + (Math.random() * 1000 + 1);
        location.y = (window.innerHeight || document.documentElement.clientHeight) / 2 + (Math.random() * 1000 + 1);
        location.r = sizeScale(location.entityCounts);
      } else {
        location.x = positions.locations[key].x;
        location.y = positions.locations[key].y;
        location.r = positions.locations[key].r;
      }

      return location;
    });
  }

  // remember location positions
  if (_.isEmpty(filters) || !isSame) {
    if (!isSame) {
      positions.locations = {};
    }

    _.each(locations, location => {
      if (_.isEmpty(positions.locations[location.href])) {
        positions.locations[location.href] = {
          href: location.href,
          x: location.x,
          y: location.y,
          r: location.r,
        };
      } else {
        positions.locations[location.href] = update(positions.locations[location.href], {
          $merge: {
            href: location.href,
            x: location.x,
            y: location.y,
            r: location.r,
          },
        });
      }
    });
  }
}

// note: This function has unit test
// if you are going to change this function, please update unit test as well
export function positionClusterGraph(clusters, locations, positions, mapRoute, filters, truncated) {
  const centeredCluster = _.maxBy(_.toArray(clusters), 'entityCounts');
  const tokenClusters = _.omitBy(clusters, (cluster, key) => key === centeredCluster.href);

  if (!areLocationsIdentical(locations, positions)) {
    positionLocationGraph(locations, positions, 'location', filters);
  }

  const centeredLocation = positions.locations[centeredCluster.labels.loc.href];

  if ((filters && filters.length) || truncated) {
    // if filters exist, don't save positions into localStorage
    // recalculate the filtered clusters layout
    // dragging is not available when filtering
    positionInitialClusterGraph(clusters, centeredCluster, tokenClusters);
  } else {
    // if filters do not exist, compare the clusters in localStorage and the clusters in current data.
    // If there are new clusters in current data, recalculate the clusters layout
    // otherwise, using the positions in localStorage
    const centeredLocationKeys = _.keys(centeredLocation.clusters);
    const clusterKeys = _.keys(clusters);
    const isDiff = _.difference(clusterKeys, centeredLocationKeys).length;

    if (_.isEmpty(centeredLocation.clusters) || isDiff) {
      positionInitialClusterGraph(clusters, centeredCluster, tokenClusters);
    } else {
      positionsClusterGraphWithCollisionDetection(clusters, centeredLocation.clusters);
    }

    updateClusterPositions(clusters, centeredLocation, mapRoute);
  }

  // calculate location bubble
  if (!_.isEmpty(locations)) {
    positionLocationInOtherGraph(centeredLocation, locations, clusters, positions, 'group');
  }
}

export function positionSweetSpotGraph(
  locations,
  clusters,
  centeredCluster,
  expandedClusters,
  positions,
  isConnectedLocations,
  filters,
) {
  // Firstly, position nodes in each cluster
  _.each(clusters, cluster => {
    if (cluster.displayType === 'full') {
      positionClusterNodes(cluster);
      applyClusterNodePositions(cluster, positions);
      calculateClusterSize(cluster);
      positionClusterInternets(cluster);
    } else {
      calculateClusterSize(cluster);
    }
  });

  // Group clusters by location labels
  const locationGroup = _.groupBy(clusters, cluster => {
    const labels = cluster.labels;

    if (labels && labels.loc && labels.loc.href) {
      return labels.loc.href;
    }

    if (_.isEmpty(labels)) {
      return 'discovered';
    }

    return 'no_location';
  });

  if (!areLocationsIdentical(locations, positions)) {
    positionLocationGraph(locations, positions, 'location', filters);
  }

  // if only show connected locations, the locations' position need to be recalculated.
  const rememberedPositions = {};

  rememberedPositions.locations = _.cloneDeep(positions.locations);

  if (isConnectedLocations) {
    const recalculateLocationPositions = calculateCirclePackingLayout(locations, 'location');

    rememberedPositions.locations = _.cloneDeep(recalculateLocationPositions);
    rememberedPositions.locations[centeredCluster.labels.loc.href].clusters =
      positions.locations[centeredCluster.labels.loc.href].clusters;
  }

  const centeredLocation = centeredCluster.labels.loc.href
    ? rememberedPositions.locations[centeredCluster.labels.loc.href]
    : rememberedPositions.locations.no_location;

  // Position clusters in each location
  _.each(locationGroup, (clustersGroup, key) => {
    const isCenteredLocation = key === centeredLocation.href;

    positionClustersInSweetSpotView(
      clustersGroup,
      centeredLocation,
      expandedClusters,
      centeredCluster,
      isCenteredLocation,
    );

    // Check for collisions after positioning the clusters
    collisionDetection(Object.values(clustersGroup), 0.3);
  });

  // calculate locations' positions
  positionLocationInOtherGraph(centeredLocation, locations, locationGroup, rememberedPositions, 'workload');

  // remember the centered location positions before collision detection
  const oldLocationX = locations[centeredLocation.href].x;
  const oldLocationY = locations[centeredLocation.href].y;

  collisionDetection(Object.values(locations), 0.5);
  // Lastly, adjust clusters in each location
  _.each(locationGroup, (clustersGroup, key) => {
    locations[key].expanded = true;

    if (key === centeredLocation.href) {
      locations[key].focused = true;
      _.each(clustersGroup, c => {
        c.x += locations[key].x - oldLocationX;
        c.y += locations[key].y - oldLocationY;
      });

      return;
    }

    const clusterGroupCenters = calculateGraphCenters(clustersGroup, 'group');

    _.each(clustersGroup, c => {
      c.x += locations[key].x - clusterGroupCenters.x;
      c.y += locations[key].y - clusterGroupCenters.y;
    });
  });
}

export function positionFullGraph(nodes, clusters, positions) {
  // First position nodes in each cluster
  _.each(clusters, cluster => {
    positionClusterNodes(cluster);
    applyClusterNodePositions(cluster, positions);
    calculateClusterSize(cluster);
    positionClusterInternets(cluster);
  });

  _.each(nodes, node => {
    calculateNodeSize(node);
    positionNodeInternets(node);
  });

  const unpositionedClusters = [];

  applyPositions(clusters, positions.clusters, unpositionedClusters);
  applyPositions(nodes, positions.nodes);
  positionNodesAndClusters(nodes, unpositionedClusters, positions);
}

export function positionAppGroupGraph(locations, clusters, positions) {
  // First position nodes in each cluster
  _.each(clusters, cluster => {
    positionClusterNodes(cluster);
    applyClusterNodePositions(cluster, positions);
    calculateClusterSize(cluster);
    positionClusterInternets(cluster);
  });

  const focusedGroup = _.find(clusters, cluster => cluster.focused);
  // super appGroups's position is depend on the size of the focused cluster
  const groupDiagonal = focusedGroup ? (focusedGroup.width + focusedGroup.height) / 4 : 100;
  // parameters to decide how far the super appGroups to the focused appGroup
  const locDisToFocusedGroupX = 450;
  const locDisToFocusedGroupY = 250;
  // parameter to decide how far the connected appGroup to the focused appGroup
  const appGroupDisToFocusedGroup = 180;

  // position super appGroups
  _.forOwn(locations, (location, key) => {
    location.r = 100;
    location.x = (groupDiagonal + locDisToFocusedGroupX + location.r) * Math.cos(Math.PI / 7.5);
    location.y = (groupDiagonal + locDisToFocusedGroupY + location.r) * Math.sin(Math.PI / 7.5);

    if (key === 'consuming') {
      location.y = -location.y;
    }
  });

  // position appGroups
  _.forOwn(clusters, cluster => {
    cluster.x = 0;

    if (cluster.focused) {
      cluster.y = 0;
    } else if (cluster.connectionType === 'consuming') {
      cluster.y = -(focusedGroup.height / 2 + cluster.height / 2 + appGroupDisToFocusedGroup);
    } else if (cluster.connectionType === 'providing') {
      cluster.y = focusedGroup.height / 2 + cluster.height / 2 + appGroupDisToFocusedGroup;
    }

    // get cluster previous positions for animation in d3
    if (!cluster.focused) {
      const location = locations[cluster.connectionType];

      cluster.px = location ? location.x : cluster.x;
      cluster.py = location ? location.y : cluster.y;
      cluster.tween = true; // if the cluster need a tween animation, return true
    }
  });
}

// helper functions

export function positionLocationInOtherGraph(centeredLocation, locations, clusters, positions, mapLevel) {
  let ratio; // the ratio of location radius change
  let centeredRatio; // the ratio of the centered location radius change
  let centers; // the dimension of the centered location

  if (mapLevel === 'group') {
    // calculate selected location positions
    centers = calculateGraphCenters(clusters, 'group');
    locations[centeredLocation.href].x = centers.x;
    locations[centeredLocation.href].y = centers.y;
    locations[centeredLocation.href].r = centers.r + 100;
    ratio = locations[centeredLocation.href].r / centeredLocation.r;
  } else if (mapLevel === 'workload') {
    const locationPadding = 150;

    // clusters are grouped by location
    // calculate centered location's positions and each location's radius if it contains clusters
    _.each(clusters, (clusterGroup, key) => {
      centers = calculateGraphCenters(clusterGroup, 'group');

      if (_.isEmpty(locations[key])) {
        locations[key] = {};
      }

      locations[key].r = centers.r + locationPadding;

      if (key === centeredLocation.href) {
        centeredRatio = locations[key].r / positions.locations[key].r;
        locations[key].x = centers.x;
        locations[key].y = centers.y;
      }

      const newRatio = locations[key].r / positions.locations[key].r;

      if (!ratio) {
        ratio = newRatio;
      } else if (ratio < newRatio) {
        // get the largest ratio to avoid overlap
        ratio = newRatio;
      }
    });
  }

  // calculate locations' positions
  // the principle is to scale ratio of distance between two locations
  const selectedLocation = locations[centeredLocation.href];

  _.each(positions.locations, (location, key) => {
    if (_.isEmpty(locations[key]) || key === centeredLocation.href) {
      return;
    }

    const diffX = Math.abs(location.x - centeredLocation.x);
    const diffY = Math.abs(location.y - centeredLocation.y);
    const theta = Math.atan2(diffY, diffX);
    let distance = Math.pow(diffX, 2) + Math.pow(diffY, 2);

    distance = Math.sqrt(distance);

    let diff = distance - location.r - centeredLocation.r;

    diff *= ratio; // this is important to keep the layout have the same scale as previous layout

    if (mapLevel === 'group') {
      locations[key].r = location.r * ratio;
    } else if (!locations[key].r) {
      locations[key].r = location.r;

      if (ratio < 1) {
        // if the ratio is less then 1,
        // the location without clusters is based on centered location ratio
        locations[key].r = location.r * centeredRatio;
      }
    }

    const disX = Math.cos(theta) * (locations[key].r + selectedLocation.r + diff);
    // Add some extra in the y direction to account for the labels
    const disY = Math.sin(theta) * (locations[key].r + selectedLocation.r + diff) + 120;

    if (location.x > centeredLocation.x) {
      locations[key].x = selectedLocation.x + disX;
    } else if (location.x < centeredLocation.x) {
      locations[key].x = selectedLocation.x - disX;
    } else {
      locations[key].x = selectedLocation.x;
    }

    if (location.y > centeredLocation.y) {
      locations[key].y = selectedLocation.y + disY;
    } else if (location.y < centeredLocation.y) {
      locations[key].y = selectedLocation.y - disY;
    } else {
      locations[key].y = selectedLocation.y;
    }
  });
}

export function positionClustersInSweetSpotView(
  clusters,
  centeredLocation,
  expandedClusters,
  centeredCluster,
  isCenteredLocation,
) {
  const recalculatePositions = Object.values(clusters).some(
    cluster => cluster?.labels?.loc?.href === centeredLocation.href && (isNaN(cluster.x) || isNaN(cluster.y)),
  );

  if (!centeredLocation.clusters || !isCenteredLocation || recalculatePositions) {
    // calculate clusters' positions in non-centered location
    let middle;
    let others;

    if (clusters.length === 1) {
      middle = clusters[0];
      others = [];
    } else {
      middle = _.maxBy(_.toArray(clusters), 'entityCounts');
      others = _.reject(clusters, c => c === middle);
    }

    positionInitialClusterGraph(clusters, middle, others);
  }

  if ((!isCenteredLocation && clusters.length > 1) || isCenteredLocation) {
    // calculate the average extended x and y if any expanded clusters
    let expandRadiusX = 0;
    let expandRadiusY = 0;

    _.each(expandedClusters, cluster => {
      if (cluster.labels && cluster.labels.loc && cluster.labels.loc.href === centeredLocation.href) {
        expandRadiusX += (cluster.width * 3) / 4;
        expandRadiusY += (cluster.height * 3) / 4;
      }
    });

    // adjust the other clusters' positions if any expanded clusters
    // todo: can be improved if need open multiple clusters in the future.
    const originCluster =
      centeredLocation.clusters && centeredLocation.clusters[centeredCluster.href]
        ? centeredLocation.clusters[centeredCluster.href]
        : centeredCluster;

    _.each(clusters, cluster => {
      const currentCluster =
        centeredLocation.clusters && centeredLocation.clusters[cluster.href]
          ? centeredLocation.clusters[cluster.href]
          : cluster;

      if (cluster.href === centeredCluster.href) {
        cluster.x = currentCluster.x;
        cluster.y = currentCluster.y;
      } else if (currentCluster.x < originCluster.x && currentCluster.y < originCluster.y) {
        cluster.x = currentCluster.x - expandRadiusX;
        cluster.y = currentCluster.y - expandRadiusY;
      } else if (currentCluster.x > originCluster.x && currentCluster.y < originCluster.y) {
        cluster.x = currentCluster.x + expandRadiusX;
        cluster.y = currentCluster.y - expandRadiusY;
      } else if (currentCluster.x < originCluster.x && currentCluster.y > originCluster.y) {
        cluster.x = currentCluster.x - expandRadiusX;
        cluster.y = currentCluster.y + expandRadiusY;
      } else if (currentCluster.x > originCluster.x && currentCluster.y > originCluster.y) {
        cluster.x = currentCluster.x + expandRadiusX;
        cluster.y = currentCluster.y + expandRadiusY;
      }
    });
  }
}

// calculate group view initially
export function positionInitialClusterGraph(clusters, centeredCluster, tokenClusters) {
  const length = Array.isArray(clusters) ? clusters.length : Object.keys(clusters).length;

  _.each(clusters, cluster => {
    calculateClusterSize(cluster, length);

    if (_.isEmpty(cluster.labels)) {
      cluster.focus = 'discovered';
    } else if (!cluster.labels.loc || !cluster.labels.loc.href) {
      cluster.focus = 'nolocation';
    } else {
      cluster.focus = cluster.labels.loc.href;
    }
  });

  calculateCirclesLayout(clusters, centeredCluster, null, tokenClusters, 2 * Math.PI);
}

export function positionsClusterGraphWithCollisionDetection(clusters, positionClusters) {
  const length = Array.isArray(clusters) ? clusters.length : Object.keys(clusters).length;

  clusters = _.map(clusters, (cluster, key) => {
    if (_.isEmpty(positionClusters[key])) {
      cluster.x = 50;
      cluster.y = 50;
    } else {
      cluster.x = positionClusters[key].x;
      cluster.y = positionClusters[key].y;
    }

    calculateClusterSize(cluster, length);

    return cluster;
  });

  // still useful for dragging
  collisionDetection(Object.values(clusters), 0.3);
}

export function updateClusterPositions(clusters, positionClusters, mapRoute) {
  if (_.isEmpty(mapRoute)) {
    _.each(clusters, cluster => {
      positionClusters[cluster.href] = {
        href: cluster.href,
        x: cluster.x,
        y: cluster.y,
      };
    });
  } else {
    _.each(clusters, cluster => {
      if (!positionClusters.clusters) {
        positionClusters.clusters = {};
      }

      positionClusters.clusters[cluster.href] = {
        href: cluster.href,
        locHref:
          cluster.displayType === 'summary' ? cluster.labels && cluster.labels.loc && cluster.labels.loc.href : null,
        x: cluster.x,
        y: cluster.y,
      };
    });
  }
}

export function collisionDetection(elements, alpha) {
  const quadtree = d3.geom.quadtree(elements);
  const padding = 16;

  _.each(elements, element => {
    // Defining Bounding Box [(nx1,ny1), (nx2,ny2)] To Test Elements Colliding Utilising QuadTree Partitions
    const dis = element.r || Math.sqrt(element.width * element.width + element.height * element.height);
    const rb = dis + padding;
    const nx1 = element.x - rb;
    const nx2 = element.x + rb;
    const ny1 = element.y - rb;
    const ny2 = element.y + rb;

    quadtree.visit((quad, x1, y1, x2, y2) => {
      if (quad.point && quad.point !== element) {
        let x = element.x - quad.point.x || 1;
        let y = element.y - quad.point.y || 1;
        let l = Math.sqrt(x * x + y * y); // Current Distance (distance algorithm) Between the Centers of the Colliding Elements
        const r = element.r && quad.point.r ? element.r + quad.point.r + padding : rb; // Ideal Distance ( sum of the radius + padding ) Between the Centers of the Colliding Elements

        //If current distance is lesser than the ideal distance move the Elements along x and y.
        if (l < r) {
          // Alpha should be 0.5 when circular elements.
          // l is the variable we use to calculate the amount the elements move in the x (l * offset in x ) ,y ( l * offset in y) axis
          l = ((l - r) / l) * alpha;
          element.x -= x *= l;
          element.y -= y *= l;
          quad.point.x += x;
          quad.point.y += y;
        }
      }

      return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
    });
  });
}

export function calculateCirclesLayout(clusters, centeredCluster, expandedCluster, tokenClusters, radianRange) {
  if (_.isPlainObject(clusters)) {
    clusters = Object.values(clusters);
  }

  if (_.isPlainObject(tokenClusters)) {
    tokenClusters = Object.values(tokenClusters);
  }

  // To keep every tokens staying in the same positions when expending tokens
  let tokens = _.isEmpty(expandedCluster) ? tokenClusters : _.union(tokenClusters, [expandedCluster]);

  tokens = _.sortBy(tokens, 'href');

  // the selected cluster is in the center and assume the center is (50, 50)
  centeredCluster.x = 50;
  centeredCluster.y = 50;

  // todo(Xianlin): add comments later. Really don't want to add comments now!!!
  let startRadius;

  if (radianRange !== 2 * Math.PI) {
    const virtualHeight = 0.3 * centeredCluster.width;

    startRadius = virtualHeight / 2 / Math.sin(Math.PI / 12);

    if (startRadius < 200) {
      startRadius = 200;
    }
  } else if (radianRange === 2 * Math.PI && centeredCluster.width < 200) {
    startRadius = 200;
  } else {
    startRadius = centeredCluster.width;
  }

  const basicRadius = radianRange === 2 * Math.PI ? startRadius : startRadius / 2;
  const basicCircle = 6;
  const basicTokens = radianRange === 2 * Math.PI ? 6 : 3;
  let currentCircle;
  let circleIndex;
  let sumIndex = 0;
  // the radian between two neighboring tokens
  let neighborRadian;
  let neighborNum;
  let clusterRadius;

  // calculate the token clusters' position
  // the position of current token is based on the previous token position
  _.each(tokens, (cluster, i) => {
    // the first token's position is fixed at the right side of the centeredCluster
    if (i === 0) {
      currentCircle = tokens.length < basicCircle ? tokens.length : basicCircle;
      circleIndex = 0;
      sumIndex = currentCircle;
      neighborNum = radianRange === Math.PI * 2 ? currentCircle : currentCircle - 1 || 1;
      neighborRadian = radianRange / neighborNum;
      clusterRadius = startRadius;
      cluster.x = centeredCluster.x + clusterRadius * Math.cos(Math.PI / 12);
      cluster.y = centeredCluster.y - clusterRadius * Math.sin(Math.PI / 12);

      return;
    }

    if (i === sumIndex) {
      cluster.x = centeredCluster.x + clusterRadius * Math.cos(Math.PI / 12);
      cluster.y = centeredCluster.y - clusterRadius * Math.sin(Math.PI / 12);

      const index = currentCircle / basicTokens;

      sumIndex =
        radianRange === 2 * Math.PI
          ? (basicTokens * index * (index + 1)) / 2
          : basicTokens * ((index * (index + 1)) / 2 - 1);

      return;
    }

    // clusterRadius is different for odd and even tokens
    clusterRadius = i % 2 === 0 ? clusterRadius - 20 : clusterRadius + 20;

    // theta is the radian between the previous token and x axix
    const theta = Math.atan2(tokens[i - 1].y - centeredCluster.y, tokens[i - 1].x - centeredCluster.x);
    // diffTheta is the radian between the current token and x axix
    const diffTheta = theta - neighborRadian;

    // calculate the position of current token
    cluster.x = centeredCluster.x + clusterRadius * Math.cos(diffTheta);
    cluster.y = centeredCluster.y + clusterRadius * Math.sin(diffTheta);

    if (i === sumIndex - 1) {
      currentCircle += basicTokens;

      const remainNum = tokens.length - (i + 1);

      currentCircle = remainNum < currentCircle ? remainNum : currentCircle;
      neighborNum = radianRange === Math.PI * 2 ? currentCircle : currentCircle - 1 || 1;
      neighborRadian = radianRange / neighborNum;
      circleIndex += 1;
      clusterRadius = startRadius + basicRadius * circleIndex;
    }
  });

  // calculate the expanded cluster's position
  if (expandedCluster) {
    const expandedDistance = centeredCluster.height / 2 + expandedCluster.height / 2 + basicRadius;

    expandedCluster.x = centeredCluster.x;
    expandedCluster.y = centeredCluster.y + expandedDistance;
  }
}

export function calculateCirclePackingLayout(data, mapLevel) {
  const root = {};

  root.name = mapLevel;
  root.children = Object.values(data);

  const diameter = 1000;
  const circleMargin = 20;

  // the min and max workload numbers for all locations
  const sizeScale = calculateLocationCircleScale(data);

  const pack = d3.layout
    .pack()
    .padding(circlePadding)
    .size([diameter - circleMargin, diameter - circleMargin])
    .value(d => sizeScale(d.entityCounts))
    .sort(d => {
      if (d.href === 'discovered' || d.href === 'no_location') {
        return Infinity;
      }

      return 0;
    })
    .radius(d => d);

  const nodes = pack.nodes(root);
  const circleNodes = {};

  _.each(nodes, node => {
    if (node.depth !== 0) {
      circleNodes[node.href] = node;
    }
  });

  return circleNodes;
}

export function applyPositions(nodes, positions, unpositionedClusters) {
  // first apply positions if they already exist
  _.each(nodes, node => {
    const position = positions[node.href];

    if (!position || !position.x || !position.y) {
      if (node.type === 'group') {
        unpositionedClusters.push(node);
      }

      return;
    }

    node.x = position.x;
    node.y = position.y;
  });
}

export function applyClusterNodePositions(cluster, positions) {
  const positionCluster = positions.clusters[cluster.href];

  if (!positionCluster || !positionCluster.nodes) {
    return;
  }

  _.each(cluster.nodes, node => {
    const positionNode = positionCluster.nodes[node.href];

    if (!positionNode || !positionNode.x || !positionNode.y) {
      return;
    }

    node.x = positionNode.x;
    node.y = positionNode.y;
  });

  const dimension = calculateDimensions(cluster);
  const centerX = (dimension.maxX + dimension.minX) / 2;
  const centerY = (dimension.maxY + dimension.minY) / 2;

  _.each(cluster.nodes, node => {
    node.x -= centerX;
    node.y -= centerY;

    const positionNode = positionCluster.nodes[node.href];

    if (!positionNode || !positionNode.x || !positionNode.y) {
      return;
    }

    positionNode.x = node.x;
    positionNode.y = node.y;
  });
}

export function positionClusterNodes(cluster) {
  // Assign tier for all nodes in this cluster
  cluster = calculateNodesLayout(cluster);

  // if the number of workloads is larger than 10 in one tier
  // wrap it into several rows
  let addedIndex = 0;
  // Make the width double the height of a large grid of nodes
  const numInEachRow = cluster.nodes.length > 24 ? Math.round(Math.sqrt(cluster.nodes.length * 2)) : 8;
  const nodesTiers = _.groupBy(cluster.nodes, 'tier');

  _.each(nodesTiers, nodes => {
    nodes.forEach((node, i) => {
      node.tier = Math.floor(i / numInEachRow) + addedIndex;
    });
    addedIndex = minmaxFor(nodes, 'tier').max + 1;
  });

  // Based on tier to calculate x and y for each node
  const tiers = _.map(cluster.nodes, 'tier');
  const nodeSize = 16; // Only need here

  // Calculate y position for each node
  // Locate different tier evenly in a cluster
  const nodeTiers = new Map();

  cluster.nodes.forEach(node => {
    const length = tiers.length;
    const yPadding = nodeSize + nodePadding.top;

    node.y = yPadding * (node.tier - length / 2);

    if (nodeTiers.has(node.tier)) {
      nodeTiers.get(node.tier).push(node);
    } else {
      nodeTiers.set(node.tier, [node]);
    }
  });

  // Calculate x position for each node
  // Locate nodes in the same tier evenly
  for (const nodeTier of nodeTiers.values()) {
    const length = nodeTier.length - 1;

    _.sortBy(nodeTier, 'x').forEach((node, i) => {
      node.x = nodePadding.left * (i - length / 2);
    });
  }

  const dimension = calculateDimensions(cluster);
  const centerX = (dimension.maxX + dimension.minX) / 2;
  const centerY = (dimension.maxY + dimension.minY) / 2;

  cluster.nodes.forEach(node => {
    node.x -= centerX;
    node.y -= centerY;
  });
}

function positionNodesAndClusters(nodes, clusters, positions) {
  let maxY = 0;

  if (!_.isEmpty(positions.clusters)) {
    maxY = minmaxFor(positions.clusters, 'y').max + 150;
  }

  let x = padding.left;
  let y = padding.top;
  let height = 0;

  const sortedClusters = clusters.sort((a, b) => {
    if (a.discovered && !b.discovered) {
      return 1;
    }

    if (!a.discovered && b.discovered) {
      return -1;
    }

    // Sort the biggest clusters to the top
    return b.nodes.length > a.nodes.length ? 1 : b.nodes.length < a.nodes.length ? -1 : 0;
  });

  //This whole section is for intra cluster/group positioning
  const clusterRows = Math.ceil(Math.sqrt(clusters.length));
  const nodeRows = Math.ceil(Math.sqrt(Object.keys(nodes).length));

  // Break the clusters into rows
  sortedClusters.forEach((cluster, i) => {
    if (i % clusterRows === 0) {
      // When we get to next row
      x = padding.left;
      y += height + margin.top;
      height = 0;
    }

    cluster.x = x + cluster.width / 2;
    cluster.y = y + cluster.height / 2 + (maxY || 0);

    x += cluster.width + margin.left;
    height = Math.max(height, cluster.height);

    //Applying cluster positions
    positions.clusters[cluster.href] = {
      href: cluster.href,
      x: cluster.x,
      y: cluster.y,
    };
  });

  // Break the nodes into rows
  Object.values(nodes).forEach((node, i) => {
    if (i % nodeRows === 0) {
      // When we get to next row
      x = padding.left;
      y += height + margin.top;
      height = 0;
    }

    node.x = x + node.width;
    node.y = y + node.height / 2 + (maxY || 0);

    x += node.width + margin.left + 50; // add extra space between single nodes on full map
    height = Math.max(height, node.height);
  });
}

// Get min/max of x and y for cluster based on all the nodes
function calculateDimensions(cluster) {
  const {
    x: {min: minX = 0, max: maxX = 0},
    y: {min: minY = 0, max: maxY = 0},
  } = minmaxFor(cluster.nodes, 'x', 'y');
  const isCornerNodePresent = cluster.nodes.some(
    node => (node.x === minX || node.x === maxX) && (node.y === minY || node.y === maxY),
  );
  let width = isCornerNodePresent ? (maxX - minX) * 1.3 : maxX - minX;
  let height = maxY - minY;

  // If there's only one node in cluster
  // then just use their x & y position as the width and height
  if (cluster.nodes.length === 1) {
    width = Math.abs(maxX);
    height = Math.abs(maxY);
  }

  return {minX, minY, maxX, maxY, width: width || 0, height: height || 0};
}

export function calculateClusterSize(cluster, length) {
  if (cluster.displayType === 'token' || cluster.displayType === 'summary') {
    cluster.width = 36;
    cluster.height = 24;

    // if there are too many clusters, the cluster size is smaller
    if (length > 800) {
      cluster.width = 22;
      cluster.height = 10;
    }
  } else if (RenderUtils.isEmptyCluster(cluster)) {
    cluster.width = 200;
    cluster.height = 150;
  } else {
    const dimension = calculateDimensions(cluster);
    let width = dimension.width + clusterPadding.left;
    const height = dimension.height + clusterPadding.top;

    // Make height and width in ellipse shape with rate 3:4
    if ((height * 4) / 3 > width) {
      width = (4 * height) / 3;
    }

    cluster.width = width;
    cluster.height = height;
  }
}

function calculateNodeSize(node) {
  // For now just default to 50x75
  // In the future do something more involved.
  node.width = 50;
  node.height = 75;
}

export function calculateGraphCenters(clusters, mapLevel) {
  const graphDimension = calculateGraphDimensions(clusters, mapLevel);
  const graphMinX = graphDimension.graphMinX;
  const graphMaxX = graphDimension.graphMaxX;
  const graphMinY = graphDimension.graphMinY;
  const graphMaxY = graphDimension.graphMaxY;
  const graphX = graphMaxX - (graphMaxX - graphMinX) / 2;
  const graphY = graphMaxY - (graphMaxY - graphMinY) / 2;
  let graphR = Math.pow((graphMaxX - graphMinX) / 2, 2) + Math.pow((graphMaxY - graphMinY) / 2, 2);

  graphR = Math.sqrt(graphR);

  if (graphR < 300) {
    graphR = 300;
  }

  return {
    x: graphX,
    y: graphY,
    r: graphR,
  };
}

export function calculateGraphDimensions(data, mapLevel) {
  const {
    0: {min: graphMinX},
    1: {max: graphMaxX},
    2: {min: graphMinY},
    3: {max: graphMaxY},
  } = mapLevel === 'location'
    ? minmaxFor(
        data,
        d => d.x - d.r,
        d => d.x + d.r,
        d => d.y - d.r,
        d => d.y + d.r,
      )
    : minmaxFor(
        data,
        d => d.x - d.width / 2,
        d => d.x + d.width / 2,
        d => d.y - d.height / 2,
        d => d.y + d.height / 2,
      );

  return {graphMinX, graphMaxX, graphMinY, graphMaxY};
}

function positionClusterInternets(cluster) {
  _.each(cluster.internets, internet => {
    if (internet.type === 'fqdn') {
      internet.x = -internetX;
    } else if (internet.type === 'internet') {
      internet.x = internetX;
    } else {
      // IP List
      internet.x = 0;
    }

    internet.y = -(cluster.height / 2);
  });
}

function positionNodeInternets(node) {
  _.each(node.internets, internet => {
    if (internet.type === 'fqdn') {
      internet.x = (-internetX * 3) / 4;
    } else if (internet.type === 'internet') {
      internet.x = (internetX * 3) / 4;
    } else {
      // IP List
      internet.x = 0;
    }

    internet.y = -internetY;
  });
}

// The min and max radius of location circle
const minRadius = 300;
const maxRadius = 600;

export function calculateLocationCircleScale(locations) {
  // The min and max workload numbers for all locations
  const {
    0: {min, max},
  } = minmaxFor(locations, 'entityCounts');
  const sizeScale = d3.scale.linear().domain([min, max]).range([minRadius, maxRadius]);

  return sizeScale;
}

// Helpers

function isTopToBottomLinkFun(sourceTier, targetTier, tierGroup) {
  // If source tier is from the previous group but the target tier is undefined
  // Then the link is defined as TopToBottom link
  return _.isUndefined(targetTier) && sourceTier === tierGroup - 1;
}

function calculateNodesTiers(workloadLinks, internetLinks, tier, hasNoTierNodes, hasInternetLinks) {
  // TierGroup is a group of tier of workloads
  // with traffic from the same group of sources but from different ports
  let tierGroup = tier;
  const isTopToBottomLink = isTopToBottomLinkFun;

  // Stop loop if all target nodes have tier
  while (hasNoTierNodes) {
    // Get links for the current calculating tier
    // Tier 0 use internetLinks
    // Tier 0 without internetLinks use links
    // The other tiers use links from top tier to bottom tier
    let currentTierLinks;

    if (tier === 0 && !hasInternetLinks) {
      currentTierLinks = workloadLinks;
    } else if (tier === 0 && hasInternetLinks) {
      currentTierLinks = internetLinks;
    } else if (tier !== 0) {
      currentTierLinks = workloadLinks.filter(link => isTopToBottomLink(link.source.tier, link.target.tier, tierGroup));
    }

    if (currentTierLinks.length === 0) {
      hasNoTierNodes = workloadLinks.some(link => !link.target.tier);

      break;
    }

    // Get nodes for the current calculating tier,
    // which is the target nodes for current tier links
    const currentTierNodes = _.transform(
      currentTierLinks,
      (result, link) => {
        if (!link.target.tier && !result.includes(link.target)) {
          result.push(link.target);
        }
      },
      [],
    );

    if (currentTierNodes.length === 0) {
      hasNoTierNodes = workloadLinks.some(link => !link.target.tier);

      break;
    }

    // Group targets into different groups
    // based on port with the heaviest traffic
    currentTierLinks.forEach(link => {
      const targetNode = link.target;

      link.connections.forEach(connection => {
        if (connection.sessions > targetNode.serviceFlows) {
          targetNode.heaviestPort = connection.port;
          targetNode.serviceFlows = connection.sessions;
        }
      });
    });

    const tierTotalFlow = {};

    currentTierNodes.forEach(node => {
      if (!tierTotalFlow[node.heaviestPort]) {
        tierTotalFlow[node.heaviestPort] = {
          heaviestPort: node.heaviestPort,
          sumOfFlow: 0,
        };
      }

      tierTotalFlow[node.heaviestPort].sumOfFlow += node.serviceFlows;
    });

    const sortedServiceFlows = _.orderBy(tierTotalFlow, 'sumOfFlow', 'desc').map(eachTier => eachTier.heaviestPort);

    // If there is no internet links, the first tier is the tier with smallest serviceFlows
    if (tier === 0 && !hasInternetLinks) {
      const smallestService = sortedServiceFlows.pop();

      sortedServiceFlows.unshift(smallestService);
    }

    currentTierNodes.forEach(eachNode => {
      eachNode.tier = tierGroup;
      eachNode.heaviestPort = tier + sortedServiceFlows.indexOf(eachNode.heaviestPort);
    });

    tier += sortedServiceFlows.length;
    tierGroup++;
    hasNoTierNodes = !workloadLinks.length || workloadLinks.some(link => !link.target.tier);
  }
}

// If one link has two connection both has the same port and protocol,
// in calculation, we need group these two connections as one.
// For example, link = {connections: [port: 22, protocol: 6, sessions: 5], [port:22, protocol: 6, sessions: 10]} .
// It should be link = {connections: [port: 22, protocol: 6, sessions: 15]}
// todo: not sure this one is better
const groupConnections = connections => {
  const newConnections = _.transform(
    connections,
    (result, connection) => {
      const key = `${connection.port},${connection.protocol}`;

      result[key] ??= {
        port: connection.port,
        protocol: connection.protocol,
        service: connection.service,
        sessions: 0,
      };

      result[key].sessions += connection.sessions;
    },
    {},
  );

  return Object.values(newConnections);
};

const calMaxMinTier = (nodes, type) => {
  if (!nodes.length) {
    return 0;
  }

  const tiers = nodes.reduce((result, node) => {
    if (_.isNumber(node.tier)) {
      result.push(node.tier);
    }

    return result;
  }, []);

  if (type === 'max') {
    return _.max(tiers);
  }

  return _.min(tiers);
};

export function calculateNodesLayout(cluster) {
  const nodes = cluster.nodes;

  // Define serviceFlows, heaviestPort and tier
  // node.serviceFlows is to sessions to the node
  // node.heaviestPort is used to categorize workloads based on their port and traffic
  // node.tier is the index to indicate which tier the nodes should be in the layout
  nodes.forEach(eachNode => {
    eachNode.serviceFlows = 0;
    eachNode.heaviestPort = undefined;
    eachNode.tier = undefined;
  });

  // if no links inside cluster, every node will be in the same row
  if (!cluster.links.length) {
    nodes.forEach(node => {
      node.tier = 0;
    });

    return cluster;
  }

  // use internetLinks to calculate the first tier
  // workloadLinks to calculate the rest tiers
  const internetLinks = [];
  const workloadLinks = [];

  // Separate links between workloads and internetLinks
  cluster.links.forEach(link => {
    const linkGroup = {};

    // sum the connections' sessions if they have the same port and protocol
    linkGroup.connections = groupConnections(link.connections);
    linkGroup.source = link.source;
    linkGroup.target = link.target;

    if (!link.internetLinks) {
      workloadLinks.push(linkGroup);
    } else if (!RenderUtils.isOutboundTraffic(link) && !link.exposureTraffic) {
      internetLinks.push(linkGroup);
    }
  });

  const hasInternetLinks = internetLinks.length > 0;
  let tier = 0;
  // Return true if there are nodes still without tier
  const hasNoTierNodes = true;

  // Get three special nodes:
  // noTrafficIn: the nodes are always sources never targets
  // onlyInternet: the nodes only connect with internet
  // noTraffic: the nodes are unconnected
  const sourceNodes = {};
  const targetNodes = {};

  workloadLinks.forEach(link => {
    sourceNodes[link.source.href] = link.source;
    targetNodes[link.target.href] = link.target;
  });

  nodes.forEach(node => {
    const isLinkToInternet = internetLinks.some(link => link.target.href === node.href);

    if (!isLinkToInternet && !targetNodes[node.href] && sourceNodes[node.href]) {
      node.tier = 'noTrafficIn';
    } else if (!sourceNodes[node.href] && !targetNodes[node.href]) {
      if (isLinkToInternet) {
        node.tier = 'onlyInternet';
      } else {
        node.tier = 'noTraffic';
      }
    }
  });

  // Assign tier to nodes based on their connections
  calculateNodesTiers(workloadLinks, internetLinks, tier, hasNoTierNodes, hasInternetLinks);

  const maxIndex = calMaxMinTier(nodes, 'max');

  tier = maxIndex === -Infinity ? 0 : maxIndex;

  const noTrafficInTier = tier + 1;
  const noTrafficTier = tier + 2;

  // Locate noTrafficIn/noTraffic/onlyInternet nodes with tier number
  nodes.forEach(node => {
    if (node.tier === 'onlyInternet') {
      node.tier = -1;
    } else if (node.tier === 'noTrafficIn') {
      node.tier = noTrafficInTier;
    } else if (node.tier === 'noTraffic') {
      node.tier = noTrafficTier;
    }
  });

  // Call maxtier to prepare for the non-tiered nodes
  tier = calMaxMinTier(nodes, 'max');

  // Nodes without tier
  let nonTieredNodes = nodes.filter(node => isNaN(node.tier));
  let nonTieredNodesCount;

  // Loop ended if all nodes has been assigned tier
  // Protect against an infinite loop
  while (nonTieredNodes.length && nonTieredNodes.length !== nonTieredNodesCount) {
    const indexedNodes = nodes.filter(node => _.isNumber(node.tier));
    // linksFromIndex: links from indexedNodes to nonTiered nodes
    // linksToIndex: link from nonTiered nodes to indexedNodes
    // linksBetween: the number of links between indexed and nonTiered nodes
    const linksFromIndexedNodes = workloadLinks.filter(
      link => indexedNodes.includes(link.source) && nonTieredNodes.includes(link.target),
    );
    const linksToIndexedNodes = workloadLinks.filter(
      link => indexedNodes.includes(link.target) && nonTieredNodes.includes(link.source),
    );
    const linksBetween = linksFromIndexedNodes.length + linksToIndexedNodes.length;

    nonTieredNodesCount = nonTieredNodes.length;

    // If no links between indexed and nonTiered nodes, assign tier by calculateNodesTier algorithm,
    // else assign tier based on the linksFromIndex and LinksToIndex
    if (linksBetween === 0) {
      const nonTieredLinks = workloadLinks.filter(
        link => nonTieredNodes.includes(link.source) && nonTieredNodes.includes(link.target),
      );

      calculateNodesTiers(nonTieredLinks, [], 0, true, true);

      nonTieredNodes = nonTieredNodes.reduce((result, node) => {
        if (_.isNumber(node.tier)) {
          node.tier += tier + 1;
          result.push(node);
        }

        return result;
      }, []);
    } else {
      linksFromIndexedNodes.forEach(link => {
        const targetNode = link.target;
        const sourceNode = link.source;

        link.connections.forEach(connection => {
          if (connection.sessions > targetNode.serviceFlows) {
            targetNode.heaviestPort = connection.port;
            targetNode.serviceFlows = connection.session;
            targetNode.tier = sourceNode.tier + 1;
          }
        });
      });

      linksToIndexedNodes.forEach(link => {
        const targetNode = link.target;
        const sourceNode = link.source;

        link.connections.forEach(connection => {
          if (connection.sessions > sourceNode.serviceFlows) {
            sourceNode.heaviestPort = connection.port;
            sourceNode.serviceFlows = connection.sessions;
            sourceNode.tier = targetNode.tier - 1;
          }
        });
      });
    }

    nonTieredNodes = nodes.filter(node => isNaN(node.tier));
    tier = calMaxMinTier(nodes, 'max');
  }

  // Make sure onlyInternet has the smallest tier number
  const minTier = calMaxMinTier(nodes, 'min');

  nodes.forEach(node => {
    if (node.tier === 'onlyInternet') {
      node.tier = minTier - 1;
    }
  });

  //If nodes are less than or equal to 3, have only one tier.
  if (nodes.length <= 3) {
    nodes.forEach(node => (node.tier = 0));
  } else {
    // Sort tier and make sure it is from 0
    const tierSort = nodes.map(node => node.tier).sort((a, b) => a - b);

    nodes.forEach(node => (node.tier = tierSort.indexOf(node.tier)));
  }

  return cluster;
}

export function collideCircles(node) {
  const r = node.radius + locationPadding.top;
  const nx1 = node.x - r;
  const nx2 = node.x + r;
  const ny1 = node.y - r;
  const ny2 = node.y + r;

  return function (quad, x1, y1, x2, y2) {
    if (quad.point && quad.point !== node) {
      let x = node.x - quad.point.x;
      let y = node.y - quad.point.y;
      let l = Math.sqrt(x * x + y * y);
      const r = node.radius + quad.point.radius;

      if (l < r) {
        l = ((l - r) / l) * 0.5;
        node.x -= x *= l;
        node.y -= y *= l;
        quad.point.x += x;
        quad.point.y += y;
      }
    }

    return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
  };
}

export const collideRectangles = node => {
  const nx1 = node.x - (node.width / 2 + clusterMargin.left);
  const nx2 = node.x + (node.width / 2 + clusterMargin.left);
  const ny1 = node.y - (node.height / 2 + clusterMargin.top);
  const ny2 = node.y + (node.height / 2 + clusterMargin.top);
  const k = 1;
  let overlapX;
  let overlapY;

  return (quad, x1, y1, x2, y2) => {
    if (quad.point && quad.point !== node) {
      const px1 = quad.point.x - (quad.point.width / 2 + clusterMargin.left);
      const px2 = quad.point.x + (quad.point.width / 2 + clusterMargin.left);
      const py1 = quad.point.y - (quad.point.height / 2 + clusterMargin.top);
      const py2 = quad.point.y + (quad.point.height / 2 + clusterMargin.top);

      if (!(nx2 < px1 || ny2 < py1)) {
        // y
        const collideY = func => {
          if (ny1 < py1 && ny2 > py1) {
            overlapY = (ny2 - py1) / 2;

            if (overlapX < overlapY) {
              func();
            } else {
              node.y -= overlapY * k;
              quad.point.y += overlapY * k;
            }
          } else if (py1 < ny1 && py2 > ny1) {
            overlapY = (py2 - ny1) / 2;

            if (overlapX < overlapY) {
              func();
            } else {
              quad.point.y -= overlapY * k;
              node.y += overlapY * k;
            }
          } else if (ny1 < py1 && py2 < ny2) {
            overlapY = (py2 - ny1) / 2;

            if (overlapX < overlapY) {
              func();
            } else {
              node.y -= overlapY * k;
              quad.point.y += overlapY * k;
            }
          } else if (py1 < ny1 && ny2 < py2) {
            overlapY = (ny2 - py1) / 2;

            if (overlapX < overlapY) {
              func();
            } else {
              quad.point.y -= overlapY * k;
              node.y += overlapY * k;
            }
          }
        };

        // x
        if (nx1 < px1 && nx2 > px1) {
          overlapX = (nx2 - px1) / 2;
          collideY(() => {
            node.x -= overlapX * k;
            quad.point.x += overlapX * k;
          });
        } else if (px1 < nx1 && px2 > nx1) {
          overlapX = (px2 - nx1) / 2;
          collideY(() => {
            quad.point.x -= overlapX * k;
            node.x += overlapX * k;
          });
        } else if (nx1 < px1 && px2 < nx2) {
          overlapX = (px2 - nx1) / 2;
          collideY(() => {
            node.x -= overlapX * k;
            quad.point.x += overlapX * k;
          });
        } else if (px1 < nx1 && nx2 < px2) {
          overlapX = (nx2 - px1) / 2;
          collideY(() => {
            quad.point.x -= overlapX * k;
            node.x += overlapX * k;
          });
        }
      }
    }

    return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
  };
};

export default {
  positionLocationGraph,
  positionClusterGraph,
  positionSweetSpotGraph,
  positionFullGraph,
  positionAppGroupGraph,
  positionLocationInOtherGraph,
  positionClustersInSweetSpotView,
  positionInitialClusterGraph,
  positionsClusterGraphWithCollisionDetection,
  updateClusterPositions,
  collisionDetection,
  calculateCirclesLayout,
  calculateCirclePackingLayout,
  applyPositions,
  applyClusterNodePositions,
  positionClusterNodes,
  calculateClusterSize,
  calculateGraphCenters,
  calculateGraphDimensions,
  calculateLocationCircleScale,
  calculateNodesLayout,
  collideCircles,
  collideRectangles,
};
