/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import OrgStore from './OrgStore';
import {createApiStore} from '../lib/store';
import {StoreUtils} from '../utils';
import Constants from '../constants';
import NetworkStore from './NetworkStore';

const virtualServices = {};
const isLoaded = {};
const expandQueue = [];
const count = {};

function setCount(data) {
  Object.assign(count, data);
}

function setVirtualServices(data, version) {
  const usersLoaded = OrgStore.areUsersLoaded();

  if (usersLoaded) {
    data.map(fillUserInfo);
  } else {
    expandQueue.push(version);
  }

  return data.map(enhanceWithLabelKeys);
}

function lazyExpand() {
  expandQueue.forEach(version => {
    virtualServices[version].forEach(fillUserInfo);
  });
}

function enhanceWithLabelKeys(virtualService) {
  const labelKeys = {};

  virtualService.labels.forEach(label => {
    if (label.key) {
      labelKeys[label.key] = label;
    }
  });

  return Object.assign(virtualService, labelKeys);
}

function fillUserInfo(virtualService) {
  const updatedByUser = virtualService.updated_by && OrgStore.getUserFromHref(virtualService.updated_by.href);
  const createdByUser = virtualService.created_by && OrgStore.getUserFromHref(virtualService.created_by.href);

  return Object.assign(virtualService, {
    updated_by_user: (updatedByUser && updatedByUser.full_name) || '--',
    created_by_user: (createdByUser && createdByUser.full_name) || '--',
  });
}

function setVirtualService(data, version) {
  let enhancedVirtualService = enhanceWithLabelKeys(data);

  if (OrgStore.areUsersLoaded()) {
    enhancedVirtualService = fillUserInfo(enhancedVirtualService);
  } else {
    expandQueue.push(version);
  }

  StoreUtils.setObjectByVersion(enhancedVirtualService, version, virtualServices);
}

function deleteVirtualService(href) {
  const draftIndex =
    virtualServices.draft && virtualServices.draft.findIndex(virtualService => virtualService.href === href);
  const lvIndex =
    virtualServices.listView && virtualServices.listView.findIndex(virtualService => virtualService.href === href);

  if (draftIndex > -1) {
    virtualServices.draft.splice(draftIndex, 1);
  }

  if (lvIndex > -1) {
    virtualServices.listView.splice(lvIndex, 1);
  }
}

export default createApiStore(['USERS_', 'VIRTUAL_SERVICE_', 'VIRTUAL_SERVICES_'], {
  dispatchHandler(action) {
    const isList =
      action.options &&
      action.options.query &&
      action.options.query.max_results &&
      action.options.query.max_results === 500;
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    virtualServices[version] = virtualServices[version] || [];

    switch (action.type) {
      case Constants.USERS_GET_COLLECTION_SUCCESS:
        if (expandQueue.length) {
          lazyExpand();
        }

        break;

      case Constants.VIRTUAL_SERVICES_GET_COLLECTION_SUCCESS:
        const filledVirtualServices = setVirtualServices(action.data, version);

        if (isList && version === 'draft') {
          virtualServices.listView = filledVirtualServices;
        }

        setCount(action.count);
        StoreUtils.setObjects(virtualServices, isLoaded, filledVirtualServices, version);
        break;

      case Constants.VIRTUAL_SERVICES_GET_INSTANCE_SUCCESS:
        setVirtualService(action.data, version);
        break;

      case Constants.VIRTUAL_SERVICE_STORE_ADD:
        setVirtualService(action.data, 'draft');
        break;

      case Constants.VIRTUAL_SERVICE_STORE_DELETE:
        deleteVirtualService(action.data);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSpecified(href) {
    return StoreUtils.findObjectByHref(href, virtualServices);
  },

  getSpecifiedCombo(href) {
    return generateIpRanges(StoreUtils.findObjectByHref(href, virtualServices));
  },

  getAll() {
    return virtualServices;
  },

  isLoaded(version = 'draft') {
    return isLoaded[version];
  },

  getCount() {
    return count;
  },
});

export function generateIpRanges(virtualService) {
  if (virtualService && !virtualService.ipRanges) {
    const ipValues = Array.isArray(virtualService.ip_overrides) ? virtualService.ip_overrides.slice(0) : [];
    const brnValues = [];
    const fqdnValues = [];

    if (Array.isArray(virtualService.service_addresses)) {
      virtualService.service_addresses.forEach(value => {
        if (value.network) {
          const brn = NetworkStore.getAll().find(brn => brn.href === value.network.href);

          if (brn) {
            brnValues.push(`${value.ip} "${brn.name}"`);
          }
        } else if (value.fqdn) {
          if (value.description) {
            fqdnValues.push(`${value.fqdn} "${value.description}"`);
          } else {
            fqdnValues.push(value.fqdn);
          }
        }
      });
    }

    virtualService.ipRanges = [
      ...new Set(ipValues.sort()),
      ...new Set(brnValues.sort()),
      ...new Set(fqdnValues.sort()),
    ]; // remove duplicates from the backend data
  }

  return virtualService;
}
