/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Button, Icon} from '..';
import {PropTypes} from 'react';

RBSuccessMessage.propTypes = {
  name: PropTypes.string.isRequired,
  newRules: PropTypes.number,
  newServices: PropTypes.number,
  newScopes: PropTypes.number,
  type: PropTypes.oneOf(['intra', 'extra', 'iplist', 'ringfence']).isRequired,
  continue: PropTypes.func.isRequired,
  selectAnother: PropTypes.func,
  goToRule: PropTypes.func.isRequired,
  goToAppGroupMap: PropTypes.func.isRequired,
};

export default function RBSuccessMessage(props) {
  return (
    <div className="RBSuccessMessage">
      <div className="RBSuccessMessage-Header">
        <Icon name="check" size="large" styleClass="RBSuccessMessage" />
        <h1 className="RBSuccessMessage-Header-Title">{intl('RBSuccessMessage.PolicyGeneratorSuccessful')}</h1>
      </div>
      <div className="RBSuccessMessage-Body">
        <h2 className="RBSuccessMessage-Body-Title">{intl('RBSuccessMessage.AddedToDraftRules')}</h2>
        <h3 className="RBSuccessMessage-Body-AppGroup">{props.name}</h3>
        <p className="RBSuccessMessage-Body-Count">
          {props.type !== 'ringfence' && intl('PolicyGenerator.NewRules', {count: props.newRules}, {html: true})}
          <br />
          {props.type !== 'ringfence' && intl('PolicyGenerator.NewServices', {count: props.newServices}, {html: true})}
          {props.type === 'ringfence' && intl('PolicyGenerator.NewRulesets', {count: props.newScopes}, {html: true})}
        </p>
        <p className="RBSuccessMessage-Body-Desc">{intl('RBSuccessMessage.Desc')}</p>
      </div>
      <div className="RBSuccessMessage-Buttons">
        {/* eslint-disable react/jsx-handler-names */}
        <div>
          {props.type !== 'ringfence' && (
            <Button text={intl('RBSuccessMessage.ContinueWithGroup')} onClick={props.continue} tid="configure" />
          )}
          {props.selectAnother ? (
            <Button
              text={
                props.type === 'ringfence'
                  ? intl('RBSuccessMessage.SelectAnAppGroup')
                  : intl('RBSuccessMessage.SelectAnotherAppGroup')
              }
              type="secondary"
              onClick={props.selectAnother}
              tid="another-group"
            />
          ) : null}
        </div>
        <div>
          <div className="RBSuccessMessage-Link" onClick={props.goToRule} data-tid="goto-rules">
            {intl('RBSuccessMessage.GoToDraftRules')}
          </div>
          <div className="RBSuccessMessage-Link" onClick={props.goToAppGroupMap} data-tid="goto-rules">
            {intl('RBSuccessMessage.GoToAppGroupMap')}
          </div>
        </div>
        {/* eslint-enable react/jsx-handler-names */}
      </div>
    </div>
  );
}
