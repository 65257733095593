/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from 'intl';
import update from 'react-addons-update';
import {OSLabelSelect, Button, Spinner} from '..';
import TrafficStore from '../../stores/TrafficStore';
import TrafficFilterStore from '../../stores/TrafficFilterStore';
import actionCreators from '../../actions/actionCreators';
import {RestApiUtils, GraphDataUtils, GridDataUtils} from '../../utils';

export default React.createClass({
  getInitialState() {
    return {
      currentRole: {value: this.props.data.currentRole},
      labels: this.props.data.labels,
    };
  },

  handleCancel() {
    this.props.onClose();
  },

  handleChange(added) {
    const state = {};

    state.currentRole = {value: added ? added.value : ''};

    if (added) {
      // if a new label is chosen, need to mutate the labels object from props,
      // so use update to make a copy and then merge the role value with it
      state.labels = update(this.state.labels, {
        $merge: {role: added},
      });
    }

    this.setState(state);
  },

  setStateAsync(newState) {
    return new Promise(resolve => {
      this.setState(newState, resolve);
    });
  },

  async handleSubmit() {
    const id = GridDataUtils.getIdFromHref(this.props.data.href);
    const newLabels = this.state.labels;
    let labels = Object.values(newLabels);
    const group = _.compact([
      newLabels.app && newLabels.app.href,
      newLabels.env && newLabels.env.href,
      newLabels.loc && newLabels.loc.href,
    ])
      .map(href => href.split('/').pop())
      .sort((a, b) => a - b)
      .join('x');

    const oldRole =
      this.props.data.labels.role &&
      TrafficStore.getNode(`${group}-${this.props.data.labels.role.href.split('/').pop()}`);
    const oldCount = oldRole ? oldRole.workloadCounts : 0;
    const newRole = `${group}-${newLabels.role.href.split('/').pop()}`;

    await this.setStateAsync({loading: true});

    if (this.props.data.href.includes('virtual_services')) {
      labels = labels.map(label => ({href: label.href}));
      await RestApiUtils.virtualService.update(id, {labels}, 'draft', true);
    } else {
      await RestApiUtils.workload.update(id, {labels}, true);
    }

    // Wait 2 seconds before making any traffic rebuild
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Do not rerender until the role data has been reloaded
    if (oldRole) {
      actionCreators.clearLoadedRole(oldRole.href);
    }

    actionCreators.clearLoadedRole(newRole);

    await GraphDataUtils.loadTraffic('rebuild');

    // Rebuild the workload traffic for the new role, even though it won't be currently exanded
    const transmissionFitlers = TrafficFilterStore.getTransmissionFilters();
    const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];
    const requests = [];

    transmissionFitlers.forEach((transmissionFitler, i) => {
      if (transmissionFitler) {
        requests.push(
          GraphDataUtils.getTraffic(
            {role_keys: JSON.stringify([newRole]), traffic_class: trafficClasses[i]},
            {type: 'rebuild'},
          ),
        );
      }
    });

    await Promise.all(requests);

    await this.setStateAsync({loading: false});
    this.handleCancel();

    if (oldCount === 1 && oldRole.href !== newRole) {
      actionCreators.collapseRole([oldRole.href]);
    }
  },

  render() {
    const selected = this.state.currentRole.value ? [this.state.currentRole] : [];

    if (this.state.loading) {
      return (
        <div className="SetPolicyState-Spinner">
          <Spinner size="small" />
        </div>
      );
    }

    return (
      <div className="AssignRole">
        <OSLabelSelect
          type="role"
          selected={selected}
          onChange={this.handleChange}
          allowMultiSelect={false}
          tid="rolelabel"
          maxResults={5}
          placeholder={intl('Role.ClickToSelect')}
        />
        {!this.props.data.fullMap && (
          <div className="MapActionBar-FormPanel-Msg">{intl('Map.AssignRoleOnLeveledMap')}</div>
        )}
        <div className="MapActionBar-FormPanel-Buttons">
          <Button text={intl('Common.Cancel')} type="secondary" onClick={this.handleCancel} tid="cancel" />
          <Button
            text={intl('Common.Save')}
            onClick={this.handleSubmit}
            disabled={!this.state.currentRole.value}
            tid="save"
          />
        </div>
      </div>
    );
  },
});
