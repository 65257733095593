/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import {Navigation, State} from 'react-router';
import intl from 'intl';
import cx from 'classnames';
import Icon from '../Icon.jsx';
import Tooltip from '../Tooltip.jsx';
import MapPageStore from '../../stores/MapPageStore';
import actionCreators from '../../actions/actionCreators';
import RenderUtils from '../../utils/RenderUtils';
import RestApiUtils from '../../utils/RestApiUtils';

export default React.createClass({
  mixins: [Navigation, State],

  getInitialState() {
    return this.getCurrentItems(this.props.data.info);
  },

  componentDidMount() {
    if (this.state.focused) {
      this.getRulesets(this.state.focused);
    }
  },

  componentWillReceiveProps(nextProps) {
    const nextState = this.getCurrentItems(nextProps.data.info);

    if (nextState.focused && !_.isEqual(nextState.focused, this.state.focused)) {
      this.getRulesets(nextState.focused);
    }

    this.setState(nextState);
  },

  getRulesets() {
    const labels =
      this.state.focused &&
      this.state.focused.labels.reduce((result, label) => {
        result[label.key] = label.href;

        return result;
      }, {});

    if (labels) {
      _.defer(() => {
        RestApiUtils.ruleSets.getGroupCollection(labels, 'rule_set_services_labels_and_names');
      });
    }
  },

  handleExternalLink(connected) {
    const params = this.getParams();

    this.transitionTo('appMapLevel', {type: 'focused', id: connected ? params.id : params.previd || params.id});
  },

  getCurrentItems(info) {
    const params = this.getParams();

    // Find the focused and connected items
    const items = info.searchData.reduce(
      (result, item) => {
        const itemId = item.href && item.href.split('/').pop();

        if (
          (params.type === 'focused' && itemId === params.id) ||
          (params.prevtype === 'focused' && itemId === params.previd)
        ) {
          result.focused = item;
        } else if (params.prevtype === 'focused' && itemId === params.id) {
          result.connected = item;
        }

        return result;
      },
      {connected: null},
    );

    const connectedSearchData = info.connectedSearchData;

    // Find the connected items
    if (params.prevtype === 'focused' && connectedSearchData) {
      items.connectedArray = Object.values(connectedSearchData);

      const length = items.connectedArray.length;
      const index = items.connectedArray.findIndex(data => data.href === params.id);

      items.nextIndex = index + 1 === length ? 0 : index + 1;
      items.prevIndex = index === 0 ? length - 1 : index - 1;
    }

    if (!connectedSearchData) {
      items.nextIndex = undefined;
      items.prevIndex = undefined;
      items.connectedArray = undefined;
    }

    return items;
  },

  handleClose() {
    actionCreators.unselectComponent();
  },

  handleCloseConnected() {
    const data = this.props.data;

    if (data) {
      let level = {};

      if (data.info.connectedType) {
        const focusedId = _.last(this.state.focused.href.split('/'));

        level = {
          type: 'focused',
          id: focusedId,
        };
        actionCreators.selectComponent([
          {
            type: 'appGroup',
            href: focusedId,
          },
        ]);
      }

      this.props.updateMapLevel(level);
    }
  },

  handleGoToPrev() {
    this.gotoNextConnectedGroup(this.state.connectedArray[this.state.prevIndex].href);
  },

  handleGoToNext() {
    this.gotoNextConnectedGroup(this.state.connectedArray[this.state.nextIndex].href);
  },

  gotoNextConnectedGroup(id) {
    const level = {
      prevtype: 'focused',
      previd: _.last(this.state.focused.href.split('/')),
      type: this.props.data.info.connectedType,
      id,
    };

    actionCreators.selectComponent([
      {
        type: 'appGroup',
        href: id,
      },
    ]);
    this.props.updateMapLevel(level);
  },

  render() {
    const {connectedType} = this.props.data.info;
    const {focused, connected, nextIndex} = this.state;

    if (!focused) {
      return null;
    }

    const focusedGroup = [
      <div className="CommandPanelTitle">{intl('Common.AppGroup')}</div>,
      <Tooltip
        content={focused.name.length > 42 ? focused.name : null}
        location="bottomright"
        position="group"
        width={372}
      >
        <div className="CommandPanelSubTitle CommandPanelTitle-Name" data-tid="nav-to-group-details">
          {RenderUtils.truncateAppGroupName(focused.name, 42, [20, 12, 10])}
        </div>
      </Tooltip>,
    ];

    let connectedGroups;

    if (connected) {
      const connectedGroup = {
        tooltip: connected.name.length > 35 ? connected.name : null,
        name: RenderUtils.truncateAppGroupName(connected.name, 35, [12, 12, 10]),
        caps: connected.caps,
        controls: (
          <div className="CommandPanelSubTitle-Controls">
            {!isNaN(nextIndex) && focused[connectedType] && Object.keys(focused[connectedType]).length !== 1
              ? [
                  <div className="CommandPanelSubTitle-Control" key="previous">
                    <Icon name="back" styleClass="Nav" onClick={this.handleGoToPrev} />
                  </div>,
                  <div className="CommandPanelSubTitle-Control" key="next">
                    <Icon name="next" styleClass="Nav" onClick={this.handleGoToNext} />
                  </div>,
                ]
              : null}
            <div className="MapSearchBar-Controls MapSearchBar-Close">
              <Icon name="collapse" onClick={this.handleCloseConnected} styleClass="Collapse" />
            </div>
          </div>
        ),
      };
      const focusedGroup = {
        tooltip: focused.name.length > 37 ? focused.name : null,
        caps: focused.caps,
        name: RenderUtils.truncateAppGroupName(focused.name, 35, [15, 12, 10]),
        type: 'focused',
      };

      let groups = [connectedGroup, focusedGroup];

      if (connectedType === 'providing') {
        groups = [focusedGroup, connectedGroup];
      }

      const ovalClassesTop = cx('oval', {
        'CommandPanel-AppGroup-Focused': connectedType === 'providing',
        'CommandPanel-AppGroup-Connected': connectedType === 'consuming',
      });

      const ovalClassesBottom = cx('oval', {
        'CommandPanel-AppGroup-Focused': connectedType === 'consuming',
        'CommandPanel-AppGroup-Connected': connectedType === 'providing',
      });

      connectedGroups = [
        <div className="CommandPanelTitle">
          {intl('Common.SourceToTarget', {source: intl('Common.AppGroup'), target: intl('Common.AppGroup')})}
        </div>,
        <div className="CommandPanelTraffic">
          <div className="CommandPanelArrowPane">
            <div className={ovalClassesTop} />
            <Icon name="arrow-right" styleClass="arrowDown" />
            <div className={ovalClassesBottom} />
          </div>
          <div className="CommandPanelContent">
            {groups.map((group, index) => {
              const groupMapIsAvailable =
                group.caps.workloads.includes('read') &&
                group.caps.rulesets.includes('read') &&
                group.type !== 'focused';

              return (
                <div key={group.tooltip || group.name} className="CommandPanelSubTitle-AppGroup">
                  <Tooltip
                    content={group.tooltip}
                    location="bottomright"
                    position={index ? 'bottomtraffic' : 'toptraffic'}
                    width={372}
                  >
                    <div className={index ? 'CommandPanelBottomTraffic' : 'CommandPanelTopTraffic'}>
                      <div
                        className={`CommandPanelSubTitle${groupMapIsAvailable ? ' CommandPanelTitle-Link' : ''}`}
                        onClick={
                          groupMapIsAvailable ? _.partial(this.handleExternalLink, Boolean(group.controls)) : null
                        }
                        data-tid="nav-to-group-details"
                      >
                        {group.name}
                      </div>
                    </div>
                  </Tooltip>
                  {group.controls}
                </div>
              );
            })}
          </div>
        </div>,
      ];
    }

    const policyVersion = MapPageStore.getPolicyVersion();

    return (
      <div
        className={`CommandPanelTitleBar CommandAppGroupTitle ${
          policyVersion === 'draft' ? 'CommandPanelDraft' : 'CommandPanelReported'
        }`}
      >
        <div className="CommandPanelContentBar">{connectedGroups || focusedGroup}</div>
      </div>
    );
  },
});
