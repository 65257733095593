/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
// FILE NOT USED ANYWHERE IN THE THOR UI AT THE MOMENT
import intl from 'intl';
import Button from './Button';
import {saveAs} from 'file-saver';
import React, {PropTypes} from 'react';
import {flattenObject} from '../utils/GeneralUtils';

export default React.createClass({
  propTypes: {
    name: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
  },

  generateCsv() {
    const flattenedRows = [];
    let headers = new Set();

    this.props.data.forEach(item => {
      const flattenedItem = flattenObject(item);
      const itemHeaders = Object.keys(flattenedItem);

      itemHeaders.forEach(header => {
        header.add(header);
      });

      flattenedRows.push(flattenedItem);
    });

    headers = Array.from(headers).sort();

    const rows = [headers];

    flattenedRows.forEach(item => {
      const row = [];

      headers.forEach(header => {
        const value = item[header];

        if (typeof value === 'string') {
          row.push(value);
        } else {
          let string = JSON.stringify(value);

          if (string && string.includes(',')) {
            string = `"${string.replaceAll('"', "'")}"`;
          }

          row.push(string);
        }
      });
      rows.push(row);
    });

    return rows;
  },

  handleClick() {
    const csv = this.generateCsv().join('\n');
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});

    saveAs(blob, `${this.props.name}.csv`);
  },

  render() {
    return (
      <Button
        text={intl('Common.ExportToCsv')}
        icon="download"
        onClick={this.handleClick}
        type="secondary"
        tid="download-csv"
        disabled={!this.props.data || !this.props.data.length}
      />
    );
  },
});
