/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from 'intl';
import React, {PropTypes} from 'react';
import {findDOMNode} from 'react-dom';
import actionCreators from '../../actions/actionCreators';
import Icon from '../../components/Icon';
import RadioGroup from '../../components/RadioGroup';
import Radio from '../../components/Radio';

export default React.createClass({
  propTypes: {
    appMapVersion: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      hidden: true,
      version: this.props.appMapVersion,
    };
  },

  componentDidMount() {
    document.body.classList.add(this.props.appMapVersion);
  },

  componentWillReceiveProps(nextProps) {
    if (this.state.version !== nextProps.appMapVersion) {
      this.setState({version: nextProps.appMapVersion});
    }

    document.body.classList.remove(this.props.appMapVersion);
    document.body.classList.add(nextProps.appMapVersion);
  },

  componentWillUnmount() {
    document.body.classList.remove(this.props.appMapVersion);
  },

  handleSelectVersion(nextversion) {
    actionCreators.setAppMapVersion(nextversion);
    this.setState({version: nextversion});
  },

  handleOpenPanel() {
    actionCreators.setTrafficFilterHidden(true);
    this.setState({hidden: !this.state.hidden});
  },

  handleRadioToggle(evt) {
    if (!this.state.hidden) {
      findDOMNode(this).focus();
    }

    if (evt.target.value !== this.state.version) {
      this.handleSelectVersion(evt.target.value);
    }
  },

  handleHidePanel(evt) {
    const relatedTarget = evt.relatedTarget || evt.nativeEvent.explicitOriginalTarget || document.activeElement;

    if (findDOMNode(this).contains(relatedTarget)) {
      findDOMNode(this).focus();

      return;
    }

    this.setState({hidden: true});
  },

  render() {
    const {appMapVersion, policyVersion} = this.props;
    const {hidden, version} = this.state;

    const appMapTypeClasses = cx('AppMapTypePanel', {
      'AppMapTypePanel-draft': policyVersion === 'draft',
      'AppMapTypePanel-active': policyVersion === 'reported',
    });

    const buttonClasses = cx('AppMapTypeButton', {
      'AppMapTypeButton-draft': policyVersion === 'draft',
      'AppMapTypeButton-active': policyVersion === 'reported',
      'AppMapType-policy': appMapVersion === 'policy',
      'AppMapType--vulnerability': !hidden,
    });

    const appMapTypePanelClasses = cx('AppMapTypePanel-body', {
      hidden,
    });

    return (
      <div className={appMapTypeClasses} tabIndex="-1" onBlur={this.handleHidePanel}>
        <div className={buttonClasses} onClick={this.handleOpenPanel} data-tid="app-maptype-panel-button">
          {appMapVersion === 'policy' ? intl('Common.PolicyData') : intl('Common.VulnerabilityData')}&nbsp;
          <Icon name="dropdown" />
        </div>
        <div className={appMapTypePanelClasses}>
          <RadioGroup value={version}>
            <Radio
              value="policy"
              onChange={this.handleRadioToggle}
              checked={version === 'policy'}
              label={intl('Common.PolicyData')}
              tid="policy"
            />
            <div className="PolicyVersionPanel-details">{intl('Map.PolicyVersionPanel.PolicyViewDetails')}</div>
            <Radio
              value="vulnerability"
              onChange={this.handleRadioToggle}
              checked={version === 'vulnerability'}
              label={intl('Common.VulnerabilityData')}
              tid="vulnerability"
            />
            <div className="PolicyVersionPanel-details">{intl('Map.PolicyVersionPanel.VulnerabilityViewDetails')}</div>
          </RadioGroup>
        </div>
      </div>
    );
  },
});
