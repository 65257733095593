/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import {SpinnerOverlay, Navbar, Banner} from '..';

DisabledBanner.propTypes = {
  spinner: PropTypes.bool,
  title: PropTypes.string.isRequired,
  header: PropTypes.string,
  content: PropTypes.element,
};

export default function DisabledBanner(props) {
  const {title, spinner, content, header} = props;

  return (
    <div className="RBSelectAppGroup">
      <Navbar title={title} />
      <div className="Graph-no-data">
        {spinner ? <SpinnerOverlay /> : <Banner type="notice" header={header} content={content} />}
      </div>
    </div>
  );
}
