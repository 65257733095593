/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import {UserMixin} from '../mixins';
import Constants from '../constants';
import actionCreators from '../actions/actionCreators';
import {RestApiUtils} from '../utils';
import {ServiceForm} from '../components/Forms';
import {SpinnerOverlay} from '../components';

export default React.createClass({
  propTypes: {
    onCreate: PropTypes.func,
    providedName: PropTypes.string,
    providedService: PropTypes.object,
    returnService: PropTypes.bool,
  },

  mixins: [UserMixin],

  getDefaultProps() {
    return {
      onCreate: _.noop,
      providedName: '',
      returnService: false,
    };
  },

  getInitialState() {
    return {
      os: 'all',
    };
  },

  componentDidMount() {
    let service = {};

    if (this.props.providedName) {
      service = {name: this.props.providedName};
    }

    if (this.props.providedService) {
      service = this.props.providedService;
    }

    this.setState({service});
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  handleSave(model) {
    if (!this.hasChanged() && !this.props.returnService) {
      actionCreators.closeDialog();

      return;
    }

    if (this.props.returnService) {
      actionCreators.closeDialog();
      this.props.onCreate(model, this.props.providedService);

      return;
    }

    RestApiUtils.services.create(model).then(response => {
      this.ignoreChanges = true;

      RestApiUtils.secPolicies.dependencies().then(() => {
        this.props.onCreate(response.body);
        actionCreators.closeDialog();
      });
    });
  },

  hasChanged() {
    return this.refs.form ? this.refs.form.hasChanged() : false;
  },

  render() {
    return (
      <div className="ServiceCreateDialog">
        <Dialog ref="dialog" title={intl('Services.Create')}>
          <div className="ServiceEdit">
            {this.state.status === Constants.STATUS_BUSY ? <SpinnerOverlay /> : null}
            <div className="ServiceEdit-details">
              <ServiceForm
                model={this.state.service}
                onSubmit={this.handleSave}
                onCancel={this.handleClose}
                type="dialog"
                ref="form"
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  },
});
