/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Link, State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import Constants from '../../constants';
import {Label, Grid, Navbar, SpinnerOverlay, Pagination} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin} from '../../mixins';
import {
  SessionStore,
  GeneralStore,
  LabelStore,
  JoinedVirtualServerStore,
  VirtualServerStore,
  WorkloadStore,
  VirtualServiceStore,
  ContainerWorkloadStore,
  PairingProfileStore,
  DvsStore,
  LoadBalancerStore,
} from '../../stores';
import {
  GraphDataUtils,
  GridDataUtils,
  GroupDataUtils,
  RenderUtils,
  RestApiUtils,
  WorkloadUtils,
  ServiceUtils,
} from '../../utils';
import {GroupTabs, AppGroupTabs} from '.';
import GroupMemberPanel from './GroupMemberSelect';

const MAX_RESULTS_PER_PAGE = 50;

function getStateFromStores() {
  const {groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

  const pairingProfiles = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, PairingProfileStore.getAll());
  let containerWorkloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, ContainerWorkloadStore.getAll());
  let virtualServices = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServiceStore.getAll().draft);
  let virtualServers = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, JoinedVirtualServerStore.getAll());
  const discovered = DvsStore.getAll();

  if (virtualServers.length) {
    _.each(virtualServers, server => {
      let discoveredServer;

      if (server.discovered_virtual_server) {
        discoveredServer = _.find(discovered, dvs => dvs.href === server.discovered_virtual_server.href);
      }

      if (discoveredServer) {
        server.vipPort = discoveredServer.vip_port;
        server.slb = LoadBalancerStore.getSpecified(discoveredServer.slb.href);
      }
    });
  }

  // Find the workloads in a discovery group defined by the traffic
  if (group && group.discovered) {
    containerWorkloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const containerWorkload = ContainerWorkloadStore.getSpecified(href);

        if (containerWorkload) {
          result.push(containerWorkload);
        }
      },
      [],
    );

    virtualServices = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualService = VirtualServiceStore.getSpecified(href);

        if (virtualService) {
          result.push(virtualService);
        }
      },
      [],
    );

    virtualServers = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualServer = JoinedVirtualServerStore.getSpecified(href);

        if (virtualServer) {
          result.push(virtualServer);
        }
      },
      [],
    );
  }

  return {
    group,
    virtualServices,
    virtualServers,
    containerWorkloads,
    pairingProfiles,
    status: [LabelStore.getStatus(), VirtualServerStore.getStatus(), VirtualServiceStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    UserMixin,
    StoreMixin(
      [LabelStore, ContainerWorkloadStore, PairingProfileStore, VirtualServiceStore, WorkloadStore],
      getStateFromStores,
    ),
  ],

  getInitialState() {
    const {groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);
    const sorting = GeneralStore.getSorting('groupVirtualServers');
    const filter = GeneralStore.getFilter('groupVirtualServers');
    const type = GroupDataUtils.getType(this.getPathname());
    const vulnerabilitiesEnabled = SessionStore.areVulnerabilitiesEnabled() && type === 'appgroups';

    return {
      type,
      groupHref,
      groupLabelHrefs,
      sorting:
        sorting || vulnerabilitiesEnabled
          ? [{key: 'vulnerability_summary', direction: true}]
          : [{key: 'name', direction: false}],
      filter: filter || 'all',
      expandedRow: null,
      currentPage: 1,
      groupExists: true,
      vulnerabilitiesEnabled,
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    RestApiUtils.virtualServers.getCollection();
    RestApiUtils.discoveredVirtualServers.getCollection();
    RestApiUtils.labels.getCollection();
    RestApiUtils.slbs.getCollection();

    this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();

    const {groupHref, type, vulnerabilitiesEnabled} = this.state;
    const {group, caps} = await GroupDataUtils.getGroup(
      this.state.group,
      groupHref,
      type,
      vulnerabilitiesEnabled,
      true,
    );

    this.setState({groupExists: !_.isEmpty(group), caps});

    if (group && group.discovered) {
      this.setState(() => {
        /* Set the proper count for "Discovered" group since the API doesn't return a count header. */
        const count = {...this.state.count, matched: group.nodesHrefs.length};

        return {count};
      });
    }
  },

  componentWillReceiveProps() {
    const type = GroupDataUtils.getType(this.getPathname());

    if (this.getParams().id !== this.state.groupHref || type !== this.state.type) {
      const {groupHref, groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

      GroupDataUtils.getGroup(group, groupHref, type, this.state.vulnerabilitiesEnabled, true);

      this.setState({
        group,
        groupHref,
        groupLabelHrefs,
        selection: [],
      });
    }
  },

  handleExpandCollapse(data) {
    if (this.state.expandedRow && this.state.expandedRow === data) {
      this.setState({expandedRow: null});
    } else {
      this.setState({expandedRow: data});
    }
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
    });
  },

  handleRowClick(row) {
    const url = row.href.split('/');
    const id = url[url.length - 1];

    this.transitionTo('virtualServer', {id, pversion: 'draft'});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('groupVirtualServers', sorting);
    this.setState({sorting});
  },

  render() {
    const {type} = this.state;
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    if (!this.state.groupExists) {
      // if we can't find the group, it must be because it's a discovered group
      this.replaceWith(type === 'appgroups' ? listPage.route : 'map');
    } else if (!this.state.group) {
      return <SpinnerOverlay />;
    }

    const columns = [
      {
        key: 'update_type',
        label: intl('Provision.Status'),
        style: 'provision',
        sortable: true,
        format(value) {
          return GridDataUtils.formatUpdatedType(value);
        },
      },
      {
        key: 'name',
        label: intl('Common.Name'),
        sortable: true,
        format(value, row) {
          return (
            <Link
              to="virtualServerTab"
              className="Grid-link"
              params={{id: GridDataUtils.getIdFromHref(row.href), pversion: 'draft', tab: 'summary'}}
            >
              {WorkloadUtils.friendlyName(row)}
            </Link>
          );
        },
        sortValue(value, row) {
          return WorkloadUtils.friendlyName(row).toLowerCase();
        },
      },
      {
        key: 'mode',
        label: intl('Common.State'),
        sortable: true,
        style: 'wrap',
        format(value) {
          return value ? ServiceUtils.getFriendlyMode(value) : intl('VirtualServers.Unassociated');
        },
      },
      {
        key: 'vsmode',
        label: intl('Common.Mode'),
        sortable: true,
        style: 'wrap',
        format(value, row) {
          return row.dvs && row.dvs.mode ? row.dvs.mode.toUpperCase() : null;
        },
      },
      {
        key: 'vipPort',
        label: intl('Common.VIPPort'),
        sortable: true,
        style: 'wrap',
        format(value, row) {
          return row && row.vipPort
            ? row.vipPort.vip + ' ' + row.vipPort.port + ' ' + ServiceUtils.lookupProtocol(row.vipPort.protocol)
            : null;
        },
      },
      {
        key: 'slb',
        label: intl('VirtualServers.List.SLB'),
        sortable: true,
        style: 'wrap',
        format(value, row) {
          return row && row.slb && row.slb.name;
        },
      },
      {
        key: 'role',
        label: intl('Common.Role'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format(value, row) {
          const role = row && row.expandedLabels.role;
          const roleVal = role ? <Label type="role" text={role} /> : null;

          return roleVal;
        },
        sortValue: (value, row) => {
          const role = row.expandedLabels.role;

          return role ? role.value : null;
        },
        sortable: true,
      },
    ];

    let title;

    if (this.state.group) {
      title = RenderUtils.truncateAppGroupName(
        _.sortBy(this.state.group.labels, 'key')
          .map(label => label.value)
          .join(' | '),
        45,
        [30, 15, 10],
      );
    }

    const gridData = this.state.virtualServers;

    const {group, groupHref} = this.state;
    const mapRoute = GroupDataUtils.getMapRoute(group, groupHref, this.mapLevel, type);
    const tabs = GroupDataUtils.getTabs(this.state);
    const appGroups = this.state.type === 'appgroups';

    return (
      <div className="GroupVirtualServices ListPage" data-tid="page-appcontainer-virtual-services">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar title={title} type="detail" up={appGroups || !mapRoute ? listPage : mapRoute} />
        <div className="GroupBar">
          {appGroups ? (
            <AppGroupTabs active="members" mapRoute={mapRoute} />
          ) : (
            <GroupTabs active="virtualServices" tabs={tabs} group={group} />
          )}
        </div>
        {appGroups ? <GroupMemberPanel active="virtualServers" items={tabs} /> : null}
        <ToolBar>
          <ToolGroup />
          {gridData.length ? (
            <ToolGroup tid="pagination">
              <Pagination
                page={this.state.currentPage}
                totalRows={gridData.length}
                pageLength={MAX_RESULTS_PER_PAGE}
                isFiltered
              />
            </ToolGroup>
          ) : null}
        </ToolBar>

        <Grid
          columns={columns}
          data={gridData}
          sorting={this.state.sorting}
          sortable
          idField="href"
          onSort={this.handleSort}
          onRowClick={this.handleRowClick}
          resultsPerPage={MAX_RESULTS_PER_PAGE}
          currentPage={this.state.currentPage}
        />
      </div>
    );
  },
});
