/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    selectSpecifiedServices: PropTypes.func.isRequired,
    selectAllServices: PropTypes.func.isRequired,
  },

  getActions() {
    return [
      {
        text: intl('PolicyGenerator.Options.SpecifiedServices.Title'),
        tid: 'cancel',
        type: 'secondary',
        onClick: () => {
          this.handleClose();
          this.props.selectSpecifiedServices();
        },
      },
      {
        text: intl('Common.AllServices'),
        tid: 'continue',
        autoFocus: true,
        onClick: () => {
          this.handleClose();
          this.props.selectAllServices();
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const title = intl('PolicyGenerator.LargeServices');
    const message = (
      <div className="PolicyGenerator-large-services">
        <div>{intl('PolicyGenerator.LargeServicesWarning')}</div>
        <div>{intl('PolicyGenerator.LargeServicesContinue')}</div>
      </div>
    );

    return (
      <Dialog ref="dialog" type="detail" title={title} actions={this.getActions()} onClose={this.props.onCancel}>
        {message}
      </Dialog>
    );
  },
});
