/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {TrafficStore} from '../../stores';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin} from '../../mixins';
import {Navbar, ProgressBar, Button, Banner} from '../../components';
import {IpListGrid} from '../../components/PolicyGenerator';
import {PolicyGeneratorUtils, GridDataUtils, GroupDataUtils, RenderUtils} from '../../utils';
import {AppGroupTabs} from '../Groups';

function getStateFromStores() {
  const traffic = TrafficStore.getAllRoleTraffics();
  const state = this.getPolicyGeneratorValues();
  const {appGroup, ruleset} = state;
  const rulesetId = ruleset && ruleset.href.split('/').pop();
  const id = this.getParams() && this.getParams().id;

  if (id && (!appGroup || id !== appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  return appGroup ? PolicyGeneratorUtils.parseIpListTraffic(traffic, appGroup, rulesetId) : {};
}

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin, StoreMixin(TrafficStore, getStateFromStores)],

  getInitialState() {
    return {
      sorting: [{key: 'name', direction: false}],
      selectedIpLists: [],
    };
  },

  componentDidMount() {
    if (!this.state.appGroup) {
      this.transitionTo('policygenerator');

      return;
    }

    this.getData(this.state, 'ipList');
  },

  async componentWillReceiveProps() {
    const id = this.getParams() && this.getParams().id;
    const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

    if (id && (!this.state.appGroup || id !== this.state.appGroup.href) && appGroup) {
      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

      this.setState({ruleset, showProgress: true, appGroup}, () => {
        //reload the data for the new app group
        this.getData(this.state, 'ipList');
      });
    }
  },

  componentWillUnmount() {
    const {ipListConfig, ipLists, selectedIpLists} = this.state;

    if (ipLists && ipListConfig) {
      const lists = ipLists.filter(list => selectedIpLists.includes(list.href));

      // If the type is specified services and there are any missing connections for the selected IP Lists
      // We need to reload the the traffic with higher max ports
      if (
        ipListConfig.type === 'microsegmentation' &&
        lists.reduce((result, list) => result + list.missingConnections, 0)
      ) {
        actionCreators.updateTrafficForAll();
      }
    }
  },

  handleRowClick(row) {
    if (row.connectionsWithRules !== Number(row.connections.split(' ')[0])) {
      this.handleRowSelect(row.key);
    }
  },

  handleRowSelect(selection) {
    const selected = GridDataUtils.selectToggle(this.state.selectedIpLists, selection);

    this.setSelectedIpLists(selected);
    this.setState({selectedIpLists: selected});
  },

  handleSort(key, direction) {
    this.setState({sorting: [{key, direction}]});
  },

  handleBack() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupPolicyGenerator', {id});
    } else {
      this.transitionTo('policygenerator');
    }
  },

  handleNext() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupIpListConfigure', {id});
    } else {
      this.transitionTo('ipListConfigure');
    }
  },

  handleTransmissionFilterChange(filters) {
    this.setIpListTransmissionFilters(filters);
    this.setState({ipListTransmissionFilters: filters}, () => this.getAppGroupTraffic(this.state, 'ipList'));
  },

  render() {
    const {appGroup, ipLists, selectedIpLists, ipListTransmissionFilters, loadingSpinner, modal, type} = this.state;

    if (!appGroup) {
      return null;
    }

    let title = intl('PolicyGenerator.IpList.IpListChoose');
    let appGroupTabs = null;

    if (this.getParams() && this.getParams().id) {
      const mapRoute = GroupDataUtils.getMapRoute(appGroup, this.getParams().id, this.mapLevel, 'appgroups');

      title = RenderUtils.truncateAppGroupName(appGroup.name, 45, [30, 15, 10]);
      appGroupTabs = (
        <div className="GroupBar">
          <AppGroupTabs active="policyGenerator" mapRoute={mapRoute} />
        </div>
      );
    }

    let ipListTable = null;

    if (appGroup && ipLists) {
      ipListTable = (
        <IpListGrid
          ipLists={ipLists}
          title={appGroup.name}
          subtitle={intl('PolicyGenerator.IpList.NumIpLists', {count: ipLists.length})}
          selection={selectedIpLists}
          onRowSelect={this.handleRowSelect}
          onRowClick={this.handleRowClick}
          sorting={this.state.sorting}
          onSort={this.handleSort}
          onTransmissionFilterChange={this.handleTransmissionFilterChange}
          transmissionFilters={ipListTransmissionFilters}
          type={type}
        />
      );
    }

    if (loadingSpinner && !modal) {
      let message;

      switch (loadingSpinner) {
        case 'rules':
          message = intl('PolicyGenerator.Spinner.CalculatingFlowData');
          break;
        case 'vulnerabilities':
          message = intl('PolicyGenerator.Spinner.CalculatingVulnerabilityData');
          break;
        default:
          message = intl('PolicyGenerator.Spinner.FindingConnections');
      }

      return (
        <div className="RBConfigure RBConfigure--Extra--Choose RBConfigure--Extra">
          <Banner type="progresscentered" header={intl('PolicyGenerator.CalculationInProgress')} message={message} />
          <Navbar title={title} />
        </div>
      );
    }

    return (
      <div className="RBConfigure RBConfigure--Extra--Choose RBConfigure--Extra">
        <Navbar title={title} />
        {appGroupTabs}
        <div className="RBConfigure-Content">
          <ProgressBar
            steps={[
              intl('PolicyGenerator.IpList.IpListChoose'),
              intl('PolicyGenerator.IpList.ChooseIpList'),
              intl('PolicyGenerator.IpList.ConfigureIpList'),
              intl('PolicyGenerator.PreviewRules'),
            ]}
            active={1}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
            <Button
              text={intl('Common.Next')}
              disabled={!ipLists.length || !selectedIpLists.length}
              onClick={this.handleNext}
              tid="next"
            />
          </div>
          {ipListTable}
        </div>
      </div>
    );
  },
});
