/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import qs from 'qs';
import _ from 'lodash';
import JSONBig from 'json-bigint-keep-object-prototype-methods';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

export default async function ({
  url, // Target url
  headers = {}, // HTTP headers
  method = 'GET', // HTTP method
  query = {}, // List of key/value for constructing query parameters
  data, // Data to send, will be transformed according to method and type
  // If method is PUT or POST will be passed as 'body' property to request
  // If not - will be added to query parameters
  type = 'json', // Data type for PUT and POST methods. If 'json' - data will be stingified, if 'form' - encoded
  redirect = 'follow', // Redirect mode: follow, error, or manual. Can't be polyfilled on top of XHR
  mode = 'same-origin', // Mode of the request: cors, no-cors, cors-with-forced-preflight, same-origin, or navigate
  credentials = 'same-origin', // Whether the user agent should send cookies from the other domain in the case of cors.
  // Values: omit, same-origin or include
  timeout, // How long to wait for response before promise will be rejected, in ms
}) {
  const options = {
    mode,
    method,
    redirect,
    credentials,
    headers: new Headers(headers),
  };

  if (data) {
    if (method === 'PUT' || (method === 'POST' && data)) {
      // For PUT/POST methods we must pass even empty object as data-binary param
      if (type === 'json') {
        // Send data as JSON
        options.body = JSONBigIntNative.stringify(data);

        if (!options.headers.get('Content-Type')) {
          options.headers.append('Content-Type', 'application/json');
        }
      } else if (type === 'form') {
        // Send data as encoded form (key=value&key=value)
        options.body = _.map(data, (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');

        if (!options.headers.get('Content-Type')) {
          options.headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
        }
      }
    } else {
      query = {...query, data};
    }
  }

  const queryString = qs.stringify(query, {encode: true, arrayFormat: 'brackets'});

  if (queryString) {
    url += '?' + queryString;
  }

  const promise = fetch(url, options)
    .catch(error => {
      if (error instanceof TypeError && error.message === 'Failed to fetch') {
        // 'TypeError. Failed to fetch' means that server can't be reach
        // or (if mode is cors) requested asset is not on the same origin and the server isn't returning the CORS headers
        // (you can overcome cors case by setting the mode of the request to no-cors)
        throw new Error('Connection has failed. Make sure that you are connected to Internet and try again.');
      }

      throw error;
    })
    .then(response => {
      // Opaque response type meanse that no-cors mode was set
      // and it's ok to get the asset despite the server not supporting CORS,
      // however, the response type will be opaque for these responses,
      // meaning you won't be able to examine the response, resulting in no idea of the status or the content of the response

      if (response.type !== 'opaque' && response.status >= 400 && response.status < 600) {
        const error = new Error(`Cannot ${method} ${url} (${response.status} ${response.statusText})`);

        error.response = response;
        error.status = response.status;
        error.method = options.method;
        error.url = url;

        throw error;
      }

      return response;
    });

  if (!timeout) {
    return promise;
  }

  // Fetch still has no .abort() method, emulate it
  // https://github.com/whatwg/fetch/issues/27
  return new Promise((resolve, reject) => {
    let aborted = false;

    promise
      .then(response => {
        if (!aborted) {
          resolve(response);
        }
      })
      .catch(error => {
        if (!aborted) {
          reject(error);
        }
      });

    if (timeout) {
      setTimeout(() => {
        aborted = true;
        reject(new Error('TIMEOUT'));
      }, timeout);
    }
  });
}
