/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import Navbar from '../components/Navbar';

export default function PageNotFound() {
  return (
    <div className="il-static-page">
      <Navbar search={false} />
      <section>
        <div className="il-logo-border">
          <img width="154" height="40" src="/static/images/logo.svg" alt="Illumio" />
        </div>
        <h1>{intl('Static.FourOhFour.CouldNotFindPage')}</h1>
        <div>
          {intl(
            'Static.FourOhFour.LetUsKnow',
            {
              email: <a href="mailto:support@illumio.com">support@illumio.com</a>,
            },
            {jsx: true},
          )}
        </div>
      </section>
    </div>
  );
}
