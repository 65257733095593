/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import StoreUtils from '../utils/StoreUtils';
import _ from 'lodash';

let labels = [];
let count = {};
const labelMap = {};

let isLoaded = false;

function setLabels(data) {
  labels = data;
  data.forEach(i => (labelMap[i.href] = i));
}

function setCount(data) {
  count = data;
}

export default createApiStore(['LABEL_', 'LABELS_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.LABELS_GET_COLLECTION_SUCCESS:
        setLabels(action.data);
        setCount(action.count);
        isLoaded = true;
        break;

      case Constants.LABELS_GET_INSTANCE_SUCCESS:
        labelMap[action.data.href] = action.data;
        StoreUtils.setObject(action.data, labels);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getCount() {
    return count;
  },

  getSpecified(href) {
    return labelMap[href];
  },

  getAll() {
    return labels;
  },

  getByType(type) {
    return _.filter(labels, label => label.key === type);
  },

  isLoaded() {
    return isLoaded;
  },
});
