/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from 'intl';
import {Form} from '../FormComponents';
import {looseEqual} from '../../utils/GeneralUtils';

export default React.createClass({
  propTypes: {
    type: PropTypes.string,
    model: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      model: {},
      errorModel: {},
      initialModel: {},
      disableType: false,
    };
  },

  componentWillMount() {
    this.formFields = [
      {
        type: 'fieldset',
        name: 'general',
        fields: [
          {
            type: 'field',
            label: intl('Common.Name'),
            name: 'name',
            required: true,
            controls: [
              {
                type: 'text',
                maxlength: 255,
                name: 'value',
                tid: 'name',
                placeholder: intl('Labels.NamePlaceholder'),
                validation: model => {
                  if (
                    [
                      intl('Common.AllApplications'),
                      intl('Common.AllEnvironments'),
                      intl('Common.AllLocations'),
                    ].includes(model.value.trim())
                  ) {
                    return {errorString: intl('Labels.InvalidLabel')};
                  }

                  return true;
                },
              },
            ],
          },
          {
            type: 'field',
            label: intl('Common.Type'),
            name: 'type',
            required: true,
            disabled: () => this.state.disableType,
            controls: [
              {
                type: 'select',
                name: 'key',
                tid: 'type',
                placeholder: intl('Labels.Create.Placeholder.LabelType'),
                options: [
                  {value: 'role', label: intl('Common.Role'), sublabel: intl('Common.RoleDescription')},
                  {value: 'app', label: intl('Common.Application'), sublabel: intl('Common.ApplicationDescription')},
                  {value: 'env', label: intl('Common.Environment'), sublabel: intl('Common.EnvironmentDescription')},
                  {value: 'loc', label: intl('Common.Location'), sublabel: intl('Common.LocationDescription')},
                ],
              },
            ],
          },
        ],
      },
    ];
  },

  componentDidMount() {
    this.setInitialModel(this.props.model);
  },

  componentWillReceiveProps(nextProps) {
    if (this.areModelsDifferent(this.state.initialModel, this.getFriendlyModel(nextProps.model))) {
      this.setInitialModel(nextProps.model);
    }
  },

  getFriendlyModel(unfriendlyModel) {
    return unfriendlyModel || {};
  },

  setInitialModel(initialModel) {
    const model = this.getFriendlyModel(initialModel);
    const disableType = Boolean(model.href);

    this.setState({model, initialModel: model, disableType});
  },

  handleChange(modelUpdates) {
    this.setState(previousState => ({model: {...previousState.model, ...modelUpdates}}));
  },

  handleErrorChange(errorModelUpdates) {
    this.setState(previousState => ({errorModel: {...previousState.errorModel, ...errorModelUpdates}}));
  },

  handleSubmit() {
    const model = {
      value: this.state.model.value,
    };

    if (!this.state.initialModel.href) {
      model.key = this.state.model.key;
    }

    this.props.onSubmit(model);
  },

  areModelsDifferent(original, compare) {
    return !looseEqual(original.value, compare.value) || !looseEqual(original.key, compare.key);
  },

  hasChanged() {
    return this.areModelsDifferent(this.state.initialModel, this.state.model);
  },

  render() {
    return (
      <Form
        type={this.props.type}
        fields={this.formFields}
        model={this.state.model}
        errorModel={this.state.errorModel}
        onChange={this.handleChange}
        onErrorChange={this.handleErrorChange}
        onSubmit={this.handleSubmit}
        onCancel={this.props.onCancel}
      />
    );
  },
});
