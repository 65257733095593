/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import cx from 'classnames';
import {Grid, Banner} from '..';
import intl from 'intl';
import {GridDataUtils, ProviderConsumerUtils} from '../../utils';

ConfigureExtraConnectionsGrid.propTypes = {
  trafficData: PropTypes.array,
  onRowToggle: PropTypes.func,
  exclusions: PropTypes.array,
  config: PropTypes.any,
  firstAppGroup: PropTypes.bool,
  appGroupName: PropTypes.string,
  numAppGroups: PropTypes.number,
  bottomSpacing: PropTypes.bool,
  sorting: PropTypes.array.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default function ConfigureExtraConnectionsGrid(props) {
  const formattedTrafficData = props.trafficData.map(traffic => {
    const provider = traffic.target;
    const consumer = traffic.source;
    const key = traffic.key;
    const rulesetInclusion = {
      included: props.exclusions && !props.exclusions.includes(key),
      connections: traffic.connections,
      sessions: traffic.sessions,
      roles: {provider, consumer},
      appGroup: props.appGroupName,
      portProtocol: traffic.services && traffic.services[Object.keys(traffic.services)[0]],
      vulnerabilities: traffic.vulnerabilities,
      key,
    };

    return {
      rulesetInclusion,
      sessions: rulesetInclusion,
      connections: rulesetInclusion,
      services: traffic.services,
      serviceCount: traffic.connections,
      missingServices: traffic.missingConnections,
      type: traffic.type,
      provider,
      consumer,
      key,
    };
  });

  const sortingItems = {
    rulesetInclusion: {
      key: 'rulesetInclusion',
      label: intl('PolicyGenerator.Grid.RulesetInclusion'),
      sort: 'included',
    },
    connections: {
      key: 'connections',
      label: intl('Common.Connections'),
      sort: 'connections',
    },
    sessions: {
      key: 'sessions',
      label: intl('Common.Flows'),
      sort: 'sessions',
    },
  };

  const {key, label, sort} = sortingItems[props.sorting[0].key] || sortingItems.rulesetInclusion;

  const columns = [
    {
      key,
      style: 'inc-exc',
      label,
      format: inclusionData =>
        GridDataUtils.formatRulesetInclusion(
          inclusionData,
          props.title,
          props.onRowToggle,
          props.config,
          'extra',
          props.numAppGroups < 2 && props.trafficData.length < 2 && props.completeData,
          props.providerConsumerOrder,
        ),
      sortable: true,
      sortingItems: Object.values(sortingItems),
      sortValue: inclusionData => inclusionData[sort],
    },
    ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
      {
        key: 'provider',
        style: 'provider',
        label: intl('Common.Provider'),
        format: (value, row) => GridDataUtils.formatAppGroupEntities(value, false, row.type),
      },
      {
        key: 'services',
        style: 'providing-services',
        label: intl('Port.ProtocolProcess'),
        sortable: true,
        format: (services, row) => GridDataUtils.formatAppGroupTrafficService(services, row, 'extra', row.type),
        sortValue: GridDataUtils.sortValueAppGroupTrafficService,
      },
      {
        key: 'consumer_to_provider_arrow',
        style: 'consumerToProviderArrow-pg',
        format: (services, row, arrowDirection) =>
          GridDataUtils.formatAppGroupTrafficService(services, row, 'extra', row.type, arrowDirection),
      },
      {
        key: 'consumer',
        style: 'policygenerator-role',
        label: intl('Common.Consumer'),
        format: (value, row) => GridDataUtils.formatAppGroupEntities(value, 'extra', row.type),
      },
      props.providerConsumerOrder,
    ),
  ];

  const classes = cx({
    'PolicyGeneratorGrid-grid': true,
    'PolicyGeneratorGrid-extra-grid-spacing': props.bottomSpacing,
    'PolicyGeneratorGrid-extra-grid-top': props.showProgressBar === 'top',
  });

  return (
    <div className={classes}>
      {props.firstAppGroup && props.showProgressBar === 'top' ? (
        <div>
          <div className="PolicyGeneratorGrid-grid-header">
            <Grid
              idField="key"
              data={formattedTrafficData}
              columns={columns}
              rowClass={GridDataUtils.formatStatusRowClass}
              onRowSelectToggle={props.onRowSelect}
              sortable={true}
              sorting={props.sorting}
              onSort={props.onSort}
              emptyContent={
                props.rulesetId ? <Banner content={intl('PolicyGenerator.Grid.CoveredByOtherRuleset')} /> : null
              }
            />
          </div>
          <div className="PolicyGeneratorGrid-grid-top" />
        </div>
      ) : null}
      <Grid
        idField="key"
        data={formattedTrafficData}
        columns={columns}
        rowClass={GridDataUtils.formatStatusRowClass}
        renderHeader={props.firstAppGroup}
        onRowSelectToggle={props.onRowSelect}
        sortable={true}
        sorting={props.sorting}
        onSort={props.onSort}
        emptyContent={props.rulesetId ? <Banner content={intl('PolicyGenerator.Grid.CoveredByOtherRuleset')} /> : null}
      />
    </div>
  );
}
