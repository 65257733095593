/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import update from 'react-addons-update';
import intl from 'intl';
import Button from '../Button.jsx';
import Spinner from '../Spinner.jsx';
import actionCreators from '../../actions/actionCreators';
import PolicyStateSelect from '../Workload/PolicyStateSelect.jsx';
import VSPolicyStateSelect from '../VSPolicyStateSelect.jsx';
import {GraphStore, MapPageStore, TrafficFilterStore} from '../../stores';
import {RestApiUtils, GraphDataUtils, GridDataUtils} from '../../utils';

const policyStates = ['unmanaged', 'visibility', 'selective', 'enforced'];

// Returns string key for the policyState
const getPolicyState = mode => {
  switch (mode) {
    case 'idle':
      return 'idle';
    case 'visibility_only':
      return 'visibility';
    case 'full':
      return 'enforced';
    case 'selective':
      return 'selective';
    default:
    // no-default
  }
};

const enforcementModeAPIMap = {
  selective: 'selective',
  visibility: 'visibility_only',
  enforced: 'full',
  idle: 'idle',
};

export default React.createClass({
  getInitialState() {
    return {
      selectedState: {value: this.props.data.currentState},
    };
  },

  handleCancel() {
    this.props.onClose();
  },

  handleChange(value) {
    this.setState({
      selectedState: value,
    });
  },

  setStateAsync(newState) {
    return new Promise(resolve => {
      this.setState(newState, resolve);
    });
  },

  async handleSubmit() {
    const {type, subType, href, labels, labelsObject} = this.props.data;

    await this.setStateAsync({loading: true});

    const payload = {
      enforcement_mode: enforcementModeAPIMap[this.state.selectedState?.data?.mode || this.state.selectedState?.value],
    };

    try {
      switch (type) {
        case 'workload':
          if (subType === 'container') {
            await RestApiUtils.containerWorkload.update(GridDataUtils.getIdFromHref(href), payload, true);
          } else {
            await RestApiUtils.workload.update(GridDataUtils.getIdFromHref(href), payload, true);
          }

          break;
        case 'virtualServer':
          const virtualServerId = GridDataUtils.getIdFromHref(href);

          await RestApiUtils.virtualServer.update(virtualServerId, payload);
          break;
        case 'group':
        case 'role':
          // Get node hrefs from the Graph Store for discovery bubbles
          if (this.props.data.href.includes('discovery_')) {
            const group = GraphStore.getCluster(this.props.data.href);
            const workloadHrefs = group && group.nodesHrefs;

            if (workloadHrefs && workloadHrefs.length) {
              // Update 5 workloads at a time
              for (const workloadChunk of _.chunk(workloadHrefs, 5)) {
                await Promise.all(
                  workloadChunk.map(href =>
                    RestApiUtils.workload.update(GridDataUtils.getIdFromHref(href), payload, true),
                  ),
                );
              }
            }

            break;
          }

          const completeLabels =
            MapPageStore.getMapType() === 'app' || (type === 'role' && MapPageStore.getMapType() === 'loc')
              ? labels
              : RestApiUtils.ruleSets.getCompleteScope(
                  ['app', 'env', 'loc'].reduce((result, key) => {
                    if (labelsObject && labelsObject[key]) {
                      result[key] = labelsObject[key].href;
                    }

                    return result;
                  }, {}),
                );
          const results = await Promise.all([
            RestApiUtils.workloads.getCollection({labels: JSON.stringify([completeLabels])}, true),
            RestApiUtils.virtualServers.getCollection('draft', {labels: JSON.stringify([labels])}, true),
          ]);

          actionCreators.forceListPageRefresh('virtualServerList', true);

          const filteredPolicyStates = TrafficFilterStore.getPolicyStates();

          // Remove all unmanaged and filtered workloads
          const workloads = results[0].body.filter(
            workload => workload.agent.status && filteredPolicyStates[getPolicyState(workload.enforcement_mode)],
          );
          const virtualServers = results[1].body;
          const policyStateData = this.state.selectedState.data;

          if (workloads.length) {
            // Update 5 workloads at a time
            for (const workloadChunk of _.chunk(workloads, 5)) {
              await Promise.all(
                workloadChunk.map(workload =>
                  RestApiUtils.workload.update(GridDataUtils.getIdFromHref(workload.href), payload, true),
                ),
              );
            }
          }

          // Update 5 virtual servers at a time
          if (virtualServers.length) {
            const policyState = update(policyStateData.mode, {
              $apply(mode) {
                return mode !== 'enforced' ? {enforcement_mode: 'unmanaged'} : {enforcement_mode: mode};
              },
            });

            for (const virtualServerChunk of _.chunk(virtualServers, 5)) {
              await Promise.all(
                virtualServerChunk.map(virtualServer =>
                  RestApiUtils.virtualServer.update(
                    GridDataUtils.getIdFromHref(virtualServer.href),
                    policyState,
                    null,
                    null,
                    true,
                  ),
                ),
              );
            }
          }
      }

      this.handleReloadGraphData();
    } catch {
      await this.setStateAsync({loading: false});
    }
  },

  async handleReloadGraphData() {
    // Wait 2 seconds before rebuilding the summary graphs
    if (MapPageStore.getMapType() === 'app') {
      await new Promise(resolve => setTimeout(resolve, 2000));
      GraphDataUtils.getTraffic('appGroup', {type: 'rebuild'});
    } else {
      await new Promise(resolve => setTimeout(resolve, 2000));
      GraphDataUtils.getTraffic('location', {type: 'rebuild'});
    }

    await GraphDataUtils.loadTraffic('rebuild');

    this.setState({loading: false});

    this.props.onClose();
  },

  render() {
    if (this.state.loading) {
      return (
        <div className="SetPolicyState-Spinner">
          <Spinner size="small" />
        </div>
      );
    }

    let policyStateSelect = null;

    if (this.props.data.type === 'virtualServer') {
      policyStateSelect = <VSPolicyStateSelect data={this.state.selectedState} onChange={this.handleChange} />;
    } else {
      policyStateSelect = <PolicyStateSelect data={this.state.selectedState} onChange={this.handleChange} />;
    }

    const disableSave = !policyStates.includes(this.state.selectedState.value);

    return (
      <div className={this.props.groupPanel ? 'SetGroupPolicyState' : null}>
        <div className="SetPolicyState">
          {policyStateSelect}
          <div className="MapActionBar-FormPanel-Buttons">
            <Button text={intl('Common.Cancel')} type="secondary" onClick={this.handleCancel} tid="cancel" />
            <Button text={intl('Common.Save')} disabled={disableSave} onClick={this.handleSubmit} tid="save" />
          </div>
        </div>
      </div>
    );
  },
});
