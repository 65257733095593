/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import {GraphTransformStore, GraphStore, MapPageStore} from '../stores';
import GraphTransformUtils from './GraphTransformUtils';

export default {
  d3Zoom: d3.behavior.zoom(),
  graphContainer: null,

  update(transform, selection) {
    this.graphContainer = selection.select('.il-graph-container');

    if (transform) {
      this.graphContainer.attr('transform', 'translate(' + transform.translate + ') scale(' + transform.scale + ')');
    }
  },

  initializeZoom(wheelZooming, wheelZoomingEnd, prevTransform, selection) {
    let scale;
    let translate;

    this.d3Zoom
      .scale(prevTransform.scale)
      .translate(prevTransform.translate)
      .on('zoom', () => {
        scale = d3.event.scale;

        if (
          GraphTransformStore.getInteractionType() === 'move' ||
          (GraphTransformStore.getInteractionType() === 'select' && !d3.select('#extent')[0][0])
        ) {
          translate = d3.event.translate;
        } else {
          // if the marque brush rect is mounted. Do this.
          translate = GraphTransformStore.getTransform().translate;
        }

        this.graphContainer.attr('transform', 'translate(' + translate + ') scale(' + scale + ')');
        wheelZooming();
      })
      .on('zoomend', () => {
        if (translate && scale) {
          wheelZoomingEnd(translate, scale);
          translate = undefined;
          scale = undefined;
        }
      });

    selection.call(this.d3Zoom).on('dblclick.zoom', null);
  },

  updateZoom(prevTransform) {
    // only use clusters for appGroup/full map, use locations for everything else
    const currentMapLevel = MapPageStore.getMapLevel();
    const graphData =
      currentMapLevel === 'focusedAppGroup' || currentMapLevel === 'connectedAppGroup' || currentMapLevel === 'full'
        ? GraphStore.getClusters()
        : GraphStore.getLocations();
    const graphLevel =
      currentMapLevel === 'focusedAppGroup' || currentMapLevel === 'connectedAppGroup' || currentMapLevel === 'full'
        ? currentMapLevel
        : 'location';
    const fitScaleFactor = currentMapLevel === 'focusedAppGroup' || currentMapLevel === 'connectedAppGroup' ? 0.3 : 0.5;
    const scaleLimit = GraphTransformUtils.getGraphZoomTofitScale(graphData, graphLevel, fitScaleFactor) || 0;

    this.d3Zoom
      // limit the zoom out value, which should not be less than zoomToFit value
      .scaleExtent([scaleLimit, Infinity])
      .scale(prevTransform.scale)
      .translate(prevTransform.translate);
  },
};
