/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Link, State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import Constants from '../../constants';
import {Button, ConfirmationDialog, Grid, Navbar, Select, SpinnerOverlay} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin} from '../../mixins';
import GroupMemberPanel from './GroupMemberSelect';
import {GroupTabs, AppGroupTabs} from '.';
import {GridDataUtils, RestApiUtils, RenderUtils, GroupDataUtils, WorkloadUtils, GraphDataUtils} from '../../utils';
import {
  SessionStore,
  GeneralStore,
  LabelStore,
  PairingProfileStore,
  ContainerWorkloadStore,
  VirtualServerStore,
  VirtualServiceStore,
} from '../../stores';

function getStateFromStores() {
  const {groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

  const virtualServers = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServerStore.getAll());
  const pairingProfiles = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, PairingProfileStore.getAll());
  let containerWorkloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, ContainerWorkloadStore.getAll());
  let virtualServices = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServiceStore.getAll().draft);

  // Find the workloads in a discovery group defined by the traffic
  if (group && group.discovered) {
    containerWorkloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const containerWorkload = ContainerWorkloadStore.getSpecified(href);

        if (containerWorkload) {
          result.push(containerWorkload);
        }
      },
      [],
    );

    virtualServices = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualService = VirtualServiceStore.getSpecified(href);

        if (virtualService) {
          result.push(virtualService);
        }
      },
      [],
    );
  }

  return {
    group,
    virtualServices,
    virtualServers,
    containerWorkloads,
    pairingProfiles,
    count: ContainerWorkloadStore.getCount(),
    status: [LabelStore.getStatus(), PairingProfileStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    UserMixin,
    StoreMixin([LabelStore, VirtualServiceStore, ContainerWorkloadStore, PairingProfileStore], getStateFromStores),
  ],

  getInitialState() {
    const {groupHref, groupLabelHrefs} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);
    const selectionObject = GeneralStore.getSelection('groupPairingProfiles');
    const sorting = GeneralStore.getSorting('groupPairingProfiles');
    const filter = GeneralStore.getFilter('groupPairingProfiles');
    const type = GroupDataUtils.getType(this.getPathname());
    let selection = [];

    if (selectionObject && selectionObject.selection && selectionObject.id === this.getParams().id) {
      selection = selectionObject.selection;
    }

    return {
      type,
      groupHref,
      groupLabelHrefs,
      selection,
      sorting: sorting || [{key: 'name', direction: false}],
      filter: filter || 'all',
      groupExists: true,
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    RestApiUtils.labels.getCollection();

    this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();

    const {groupHref, type, vulnerabilitiesEnabled} = this.state;
    const {group, caps} = await GroupDataUtils.getGroup(
      this.state.group,
      groupHref,
      type,
      vulnerabilitiesEnabled,
      false,
    );

    this.setState({groupExists: !_.isEmpty(group), caps});

    if (group && group.discovered) {
      this.setState(() => {
        /* Set the proper count for "Discovered" group since the API doesn't return a count header. */
        const count = {...this.state.count, matched: group.nodesHrefs.length};

        return {count};
      });
    }
  },

  componentDidUpdate() {
    const type = GroupDataUtils.getType(this.getPathname());

    if (this.getParams().id !== this.state.groupHref || type !== this.state.type) {
      const {groupHref, groupLabelHrefs, group} = GroupDataUtils.getGroupLabelHrefs(this.getParams().id);

      GroupDataUtils.getGroup(group, groupHref, type, this.state.vulnerabilitiesEnabled);

      this.setState({
        group,
        groupHref,
        groupLabelHrefs,
        selection: [],
      });
    }
  },

  getSelectedPairingProfiles() {
    return _.compact(_.map(this.state.selection, href => PairingProfileStore.getSpecified(href)));
  },

  handleAdd() {
    this.transitionTo('pairingProfiles.create');
  },

  handleFilterChange(evt) {
    actionCreators.updateGeneralSelection('groupPairingProfiles', []);
    actionCreators.updateGeneralFilter('groupPairingProfiles', evt.value);
    this.setState({filter: evt.value, selection: []});
  },

  handleForceRemove() {
    const pairingProfiles = _.map(this.state.selection, href => ({href}));

    RestApiUtils.pairingProfiles.delete(pairingProfiles);
    actionCreators.updateGeneralSelection('groupPairingProfiles', []);

    if (this.state.selection.length === this.state.pairingProfiles.length) {
      this.transitionTo(this.state.type === 'groups' ? 'groupWorkloads' : 'appGroupWorkloads', {
        id: this.getParams().id,
      });
    }

    this.setState({selection: []});
  },

  handleRemove() {
    const pairingProfiles = this.getSelectedPairingProfiles();

    if (pairingProfiles.length) {
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('PairingProfiles.Delete', {count: pairingProfiles.length})}
          message={intl('PairingProfiles.DeleteConfirm', {count: pairingProfiles.length})}
          onConfirm={this.handleForceRemove}
        />,
      );
    }
  },

  handleRowClick(row) {
    const url = row.href.split('/');
    const id = url[url.length - 1];

    this.transitionTo('pairingProfiles.item', {id});
  },

  handleSelectToggle(selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('groupPairingProfiles', {
      id: this.getParams().id,
      selection: newSelection,
    });
    this.setState({selection: newSelection});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('groupPairingProfiles', sorting);
    this.setState({sorting});
  },

  render() {
    const {type, caps} = this.state;
    const workloadManagerDisabled = !caps?.workloads?.includes('write');
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    if (!this.state.groupExists && !this.state.group) {
      this.replaceWith(type === 'appgroups' ? listPage.route : 'map');
    } else if (!this.state.group) {
      return <SpinnerOverlay />;
    }

    const columns = [
      {
        key: 'name',
        label: intl('Common.Name'),
        sortable: true,
        format(value, row) {
          return (
            <Link to="pairingProfiles.item" className="Grid-link" params={{id: GridDataUtils.getIdFromHref(row.href)}}>
              {value}
            </Link>
          );
        },
        sortValue: GridDataUtils.sortStringValue,
      },
      {
        key: 'role',
        label: intl('Common.Role'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValueOld,
        sortValue: GridDataUtils.labelSortValue,
        sortable: true,
      },
      {
        key: 'app',
        label: intl('Common.Application'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValueOld,
        sortValue: GridDataUtils.labelSortValue,
        sortable: true,
      },
      {
        key: 'env',
        label: intl('Common.Environment'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValueOld,
        sortValue: GridDataUtils.labelSortValue,
        sortable: true,
      },
      {
        key: 'loc',
        label: intl('Common.Location'),
        formatHeader: GridDataUtils.formatLabelLabel,
        format: GridDataUtils.formatLabelValueOld,
        sortValue: GridDataUtils.labelSortValue,
        sortable: true,
      },
      {
        key: 'last_pairing_at',
        type: 'date',
        style: 'date',
        label: intl('PairingProfiles.LastUse'),
        sortable: true,
      },
    ];

    const selectOptions = [
      {value: 'all', label: intl('Common.All')},
      {value: 'use', label: intl('Groups.PairingProfiles.UseLimited')},
      {value: 'time', label: intl('Groups.PairingProfiles.TimeLimited')},
      {value: 'usetime', label: intl('Groups.PairingProfiles.TimeAndUseLimited')},
    ];

    let title;

    if (this.state.group) {
      title = RenderUtils.truncateAppGroupName(
        _.sortBy(this.state.group.labels, 'key')
          .map(label => label.value)
          .join(' | '),
        45,
        [30, 15, 10],
      );
    }

    let gridData = this.state.pairingProfiles || [];

    switch (this.state.filter) {
      case 'use':
        gridData = _.filter(gridData, item => item.allowed_uses_per_key !== 'unlimited');
        break;
      case 'time':
        gridData = _.filter(gridData, item => item.key_lifespan !== 'unlimited');
        break;
      case 'usetime':
        gridData = _.filter(
          gridData,
          item => item.allowed_uses_per_key !== 'unlimited' && item.key_lifespan !== 'unlimited',
        );
        break;
    }

    const {group, groupHref} = this.state;
    const mapRoute = GroupDataUtils.getMapRoute(group, groupHref, this.mapLevel, type);
    const tabs = GroupDataUtils.getTabs(this.state);
    const appGroups = this.state.type === 'appgroups';

    return (
      <div className="GroupWorkloads ListPage" data-tid="page-appcontainer-workloads">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar title={title} type="detail" up={appGroups || !mapRoute ? listPage : mapRoute} />
        <div className="GroupBar">
          {appGroups ? (
            <AppGroupTabs active="members" mapRoute={mapRoute} />
          ) : (
            <GroupTabs active="pairingProfiles" tabs={tabs} group={group} />
          )}
        </div>
        {appGroups ? <GroupMemberPanel active="pairingProfiles" items={tabs} /> : null}

        <ToolBar>
          <ToolGroup>
            <Button
              autoFocus={true}
              text={intl('Common.Add')}
              onClick={this.handleAdd}
              tid="add"
              disabled={workloadManagerDisabled}
            />
            <Button
              text={intl('Common.Remove')}
              onClick={this.handleRemove}
              type="secondary"
              disabled={this.state.selection.length === 0 || workloadManagerDisabled}
              tid="remove"
            />
            <div className="ListPage-Filter">
              <Select options={selectOptions} selected={this.state.filter} onSelect={this.handleFilterChange} />
            </div>
            <div>
              <span className="ListPage-Count-Total" data-tid="elem-count-total">
                {`${gridData.length} ${intl('PairingProfiles.Profiles')}`}
              </span>
              {this.state.selection.length > 0 &&
                intl(
                  'Common.SelectedCount',
                  {className: 'Count', count: this.state.selection.length},
                  {
                    html: true,
                    htmlProps: {
                      'className': 'ListPage-Count-Selection',
                      'data-tid': 'elem-count-selection',
                    },
                  },
                )}
            </div>
          </ToolGroup>
        </ToolBar>

        <Grid
          columns={columns}
          data={gridData}
          sorting={this.state.sorting}
          selection={this.state.selection}
          sortable={true}
          selectable={!workloadManagerDisabled}
          idField="href"
          onSort={this.handleSort}
          onRowSelectToggle={this.handleSelectToggle}
          onRowClick={this.handleRowClick}
        />
      </div>
    );
  },
});
