/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';

export default {
  tid(defaultTID, optionalTID) {
    const tids = [];

    tids.push(defaultTID);

    if (Array.isArray(optionalTID)) {
      _.forEach(optionalTID, tid => {
        if (tid) {
          tids.push(defaultTID + '-' + tid);
        }
      });
    } else if (optionalTID) {
      tids.push(defaultTID + '-' + optionalTID);
    }

    return tids;
  },

  tidString(tids) {
    return _.compact(tids).join(' ');
  },
};
