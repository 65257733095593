/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import PortUtils from './PortUtils';
import IpUtils from './IpUtils';
import {SegmentationTemplateUtils} from '.';

export default {
  dataReference: {
    formatEditedState(dataReference) {
      return SegmentationTemplateUtils.isEntityEdited(dataReference) ? dataReference : `${dataReference}\r`;
    },
  },
  // Label Validations
  labels: {
    validate(label) {
      return this.validateType(label.key) && this.validateName(label.value);
    },

    validateType(type) {
      return Boolean(type);
    },

    validateName(name) {
      return Boolean(name);
    },

    isNameUpdated(oldLabel, newLabel) {
      return oldLabel.value !== newLabel.value;
    },

    isTypeUpdated(oldLabel, newLabel) {
      return oldLabel.key !== newLabel.key;
    },
  },

  // Workload Validations

  // Rule Validations

  // IP List Validations
  ipLists: {
    validateValues(ipList) {
      const update = this.getUpdate(ipList);

      if ((update && update.ip_ranges && update.ip_ranges.length) || (update && update.fqdns && update.fqdns.length)) {
        return update;
      }

      return 'invalid';
    },

    getUpdate(ipList) {
      if (!ipList) {
        return;
      }

      const result = {
        name: _.trim(ipList.name),
        description: _.trim(ipList.description),
      };

      const ipRanges = [];
      const fqdns = [];

      if (ipList.ip_ranges) {
        const validData = ipList.ip_ranges.filter(
          range => !range.error && !range.removed && (range.from_ip || range.fqdn),
        );

        validData.forEach(range => {
          if (range.from_ip) {
            const updateRange = {
              from_ip: range.from_ip,
            };

            if (range.to_ip) {
              updateRange.to_ip = range.to_ip;
            }

            if (range.description) {
              updateRange.description = range.description;
            }

            if (range.exclusion) {
              updateRange.exclusion = range.exclusion;
            }

            ipRanges.push(updateRange);
          } else if (range.fqdn) {
            const fqdn = {fqdn: range.fqdn};

            if (range.description) {
              fqdn.description = range.description;
            }

            fqdns.push(fqdn);
          }
        });
      }

      result.ip_ranges = ipRanges;

      result.fqdns = fqdns;

      return result;
    },

    isEmpty(ipList) {
      return (
        !ipList ||
        ((!ipList.name || ipList.name.match(/New IP List/)) &&
          !ipList.description &&
          (!ipList.ip_ranges || ipList.ip_ranges.length === 0))
      );
    },

    hasChanged(old, updates, ignoreName) {
      let rangeChange = false;

      if (this.isEmpty(old) && this.isEmpty(updates)) {
        return false;
      }

      if (this.isEmpty(old) && !this.isEmpty(updates)) {
        return true;
      }

      if ((!ignoreName && old.name !== updates.name) || (old.description || '') !== (updates.description || '')) {
        return true;
      }

      if (old.ip_ranges.length !== updates.ip_ranges.length) {
        return true;
      }

      old.ip_ranges.forEach((oldRange, index) => {
        if (!IpUtils.areIpRangesEqual(oldRange, updates.ip_ranges[index])) {
          rangeChange = true;
        }
      });

      //there are different numbers of fqdns
      if (old.fqdns.length !== updates.fqdns.length) {
        return true;
      }

      old.fqdns.forEach((oldValue, index) => {
        if (oldValue.fqdn !== updates.fqdns[index].fqdn) {
          rangeChange = true;
        }
      });

      return rangeChange;
    },
  },

  // Services Validations
  services: {
    validateValues(service) {
      if (!service.name) {
        return 'invalid';
      }

      const update = this.getUpdate(service);

      if (
        update &&
        ((service.os === 'windows' && update.windows_services && update.windows_services.length) ||
          (service.os === 'all' && update.service_ports && update.service_ports.length))
      ) {
        return update;
      }

      return 'invalid';
    },

    getUpdate(localService) {
      if (!localService) {
        return null;
      }

      const updates = {};
      const service = _.cloneDeep(localService);

      updates.name = service.name ? service.name.trim() : '';
      updates.description = service.description ? service.description.trim() : '';

      if (localService.os === 'windows') {
        if (service.windows_services) {
          updates.windows_services = _.filter(service.windows_services, range => {
            if (range.error || range.removed || range.duplicate) {
              return false;
            }

            if (range.protocol === 47 /*GRE*/ || range.protocol === 94 /*IPIP*/) {
              return true;
            }

            return range.process_name || range.service_name || range.port !== undefined;
          }).map(range => {
            const updateRange = {};

            if (range.port) {
              updateRange.port = range.port;
            }

            if (range.to_port) {
              updateRange.to_port = range.to_port;
            }

            if (range.protocol) {
              updateRange.protocol = range.protocol;
            }

            if (range.process_name) {
              updateRange.process_name = range.process_name;
            }

            if (range.service_name) {
              updateRange.service_name = range.service_name;
            }

            if (range.icmp_type) {
              updateRange.icmp_type = range.icmp_type;
            }

            if (range.icmp_code) {
              updateRange.icmp_code = range.icmp_code;
            }

            return updateRange;
          });
        }
      } else if (service.service_ports) {
        updates.service_ports = _.filter(
          service.service_ports,
          range =>
            !range.error &&
            !range.removed &&
            !range.duplicate &&
            (range.port !== undefined || range.protocol !== undefined),
        ).map(range => {
          const updateRange = {};

          if (range.port) {
            updateRange.port = range.port;
          }

          if (range.protocol) {
            updateRange.protocol = range.protocol;
          }

          if (range.to_port) {
            updateRange.to_port = range.to_port;
          }

          if (range.icmp_type) {
            updateRange.icmp_type = range.icmp_type;
          }

          if (range.icmp_code) {
            updateRange.icmp_code = range.icmp_code;
          }

          return updateRange;
        });
      }

      return updates;
    },

    isEmpty(service) {
      return (
        !service ||
        ((!service.name || service.name.match(new RegExp(intl('Services.Create')))) && // TODO: Do not rely on name (langs)
          !service.description &&
          (!service.service_ports || service.service_ports.length === 0))
      );
    },

    hasChanged(old, updates, ignoreName) {
      let rangeChange = false;

      if (this.isEmpty(old) && this.isEmpty(updates)) {
        return false;
      }

      if (this.isEmpty(old) && !this.isEmpty(updates)) {
        return true;
      }

      if ((!ignoreName && old.name !== updates.name) || (old.description || '') !== (updates.description || '')) {
        return true;
      }

      if (
        (old.service_ports && updates.service_ports && old.service_ports.length !== updates.service_ports.length) ||
        (old.windows_services &&
          updates.windows_services &&
          old.windows_services.length !== updates.windows_services.length) ||
        (!old.windows_services && updates.windows_services) ||
        (!old.service_ports && updates.service_ports)
      ) {
        return true;
      }

      if (old.service_ports && updates.service_ports) {
        old.service_ports.forEach((oldRange, index) => {
          if (!this.arePortRangesEqual(oldRange, updates.service_ports[index])) {
            rangeChange = true;
          }
        });
      }

      if (old.windows_services && updates.windows_services) {
        old.windows_services.forEach((oldRange, index) => {
          if (!this.arePortRangesEqual(oldRange, updates.windows_services[index])) {
            rangeChange = true;
          }
        });
      }

      return rangeChange;
    },

    isDuplicate(range, servicePorts) {
      let duplicate = false;
      const services = _.filter(servicePorts, port => range.protocol === port.protocol && !port.removed);

      services.forEach(service => {
        if (PortUtils.arePortRangesOverlapping(range, service)) {
          duplicate = true;
        }
      });

      return duplicate;
    },

    arePortRangesEqual(oldRange, newRange) {
      return PortUtils.arePortRangesEqual(oldRange, newRange);
    },
  },

  users: {
    //External users can have valid usernames other than just emails
    //Valid usernames:
    //1) Start with any letter or number
    //2) Can be followed by any combination of letters, numbers and special characters including: . @ % \ / _ + -
    isUsernameValid(username) {
      return username.match(/^[\dA-Za-z]+[\w%+./@\\-]+$/);
    },
    //Local user username must be a strict email match
    isEmailInvalid(email) {
      return !/^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]+$/.test(email);
    },
  },

  // Login Validations
};
