/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import GraphTransformStore from '../stores/GraphTransformStore';

export default {
  size: 20,
  hoverPadding: 4,
  corner: 2,
  padding: {top: 5, left: 5},
  paddingRadius: 3,
  textSize: 12,
  policySize: 30,
  policyCorner: 4, // How much policy state rect's corners are rounded by
  duration: 750, //remove the transition duration for zoom to fit
  showLabelSize: 40, // the size to show role label
  containerWorkloadTestOffset: 4,

  enter(selection) {
    this.position(selection, 0);
    this.addTitle(selection);
  },

  update(selection) {
    const hoverSize = this.size + this.hoverPadding;
    const hoverPosition = -this.size / 2 - this.hoverPadding / 2;
    const scale = GraphTransformStore.getTransform().scale;
    const semanticScale = Math.pow(GraphTransformStore.getTransform().scale, 0.7);
    const enforcementPadding =
      selection.datum().policyState === 'enforced' || selection.datum().policyState === 'selective' ? 6 : 0;

    selection.call(this.selectNode);
    selection.call(this.hoverNode);

    selection
      .select('.il-node-policyState')
      .transition()
      .duration(this.duration)
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', this.policySize / 2 + this.paddingRadius)
      .attr('stroke-width', 3)
      .attr('stroke', '#435e69')
      .attr('stroke-dasharray', d => {
        if (d.policyState === 'selective') {
          return [(Math.PI * this.policySize) / 15];
        }
      })
      .attr('transform', d => (d.type === 'virtualService' ? 'rotate(45)' : ''));

    selection
      .select('.il-node-rect')
      .transition()
      .duration(this.duration)
      .attr('x', d => (d.hovered === 'hovered' ? hoverPosition : -this.size / 2))
      .attr('y', d => (d.hovered === 'hovered' ? hoverPosition : -this.size / 2))
      .attr('width', d => (d.hovered === 'hovered' ? hoverSize : this.size))
      .attr('height', d => (d.hovered === 'hovered' ? hoverSize : this.size))
      .attr('rx', this.corner)
      .attr('ry', this.corner);

    selection
      .select('.il-node-unmanaged')
      .attr('x', d => (d.hovered === 'hovered' ? hoverPosition : -this.size / 2))
      .attr('y', d => (d.hovered === 'hovered' ? hoverPosition : -this.size / 2))
      .attr('width', d => (d.hovered === 'hovered' ? hoverSize : this.size))
      .attr('height', d => (d.hovered === 'hovered' ? hoverSize : this.size))
      .attr('rx', this.corner)
      .attr('ry', this.corner)
      .attr('fill', d => (d.hovered === 'hovered' ? 'url(/#unmanagedIconHover)' : 'url(/#unmanagedIcon)'));

    selection
      .select('.il-node-idle')
      .attr('x', -this.size / 2)
      .attr('y', -this.size / 2)
      .attr('width', this.size)
      .attr('height', this.size)
      .attr('rx', this.corner)
      .attr('ry', this.corner)
      .attr('fill', 'url(/#idleIcon)');

    selection
      .select('.il-node-vs')
      .attr('x', -this.size / 2)
      .attr('y', -this.size / 2)
      .attr('width', this.size)
      .attr('height', this.size)
      .attr('rx', this.corner)
      .attr('ry', this.corner)
      .attr('transform', 'rotate(45)')
      .transition()
      .duration(this.duration);

    selection
      .select('.il-node-container-workload')
      .transition()
      .duration(this.duration)
      .attr('points', d => {
        const x = d.x / (16 * this.size);
        const y = d.y / (16 * this.size);
        const r = d.hovered === 'hovered' ? (this.size + 4 * 2) / 2 : (this.size + 4) / 2;
        const sqrt3over2 = Math.sqrt(3) / 2;

        return [
          [x, y + r],
          [x - r * sqrt3over2, y + r / 2],
          [x - r * sqrt3over2, y - r / 2],
          [x, y - r],
          [x + r * sqrt3over2, y - r / 2],
          [x + r * sqrt3over2, y + r / 2],
          [x, y + r],
        ];
      });

    selection
      .select('.il-container-workload-policyState')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', this.policySize / 2 + this.paddingRadius)
      .attr('fill', '#fff')
      .attr('stroke-width', 3)
      .attr('stroke', '#435e69')
      .attr('stroke-dasharray', d => {
        if (d.policyState === 'selective') {
          return [(Math.PI * this.policySize) / 15];
        }
      });

    selection
      .select('.il-node-text')
      .attr('font-size', () =>
        50 * scale < this.showLabelSize ? this.textSize / scale - 2 : this.textSize / scale + 2,
      )
      .attr('dy', '0.6em')
      .transition()
      .duration(this.duration)
      .attr('y', this.policySize / 2 + this.padding.top * 2)
      .attr('display', () => (100 * scale < this.showLabelSize ? 'none' : 'block'))
      .style('pointer-events', 'fill')
      // and then style the secondary name
      .select('.il-node-text-secondary')
      .attr('x', 0)
      .attr('dy', this.textSize / scale + this.padding.top)
      .attr('display', () => (100 * scale < this.showLabelSize * 2 ? 'none' : 'block'));

    selection
      .select('.il-node-text-label')
      .attr('font-size', () =>
        50 * scale < this.showLabelSize ? this.textSize / semanticScale - 2 : this.textSize / semanticScale + 2,
      )
      .attr('dy', '0.6em')
      .attr('x', -this.policySize / 2)
      .transition()
      .duration(this.duration)
      .attr('y', this.policySize / 2 + enforcementPadding + (this.padding.top * 2) / semanticScale)
      .attr('display', () => (100 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style('pointer-events', 'fill')
      // and then style the secondary name
      .select('.il-node-text-secondary')
      .attr('x', -this.policySize / 2)
      .attr('dy', this.textSize / semanticScale + this.padding.top / semanticScale)
      .attr('display', () => (100 * semanticScale < this.showLabelSize * 2 ? 'none' : 'block'));

    selection
      .select('.il-node-text-vulnerability')
      .attr('font-size', () =>
        50 * semanticScale < this.showLabelSize ? this.textSize / semanticScale - 2 : this.textSize / semanticScale + 2,
      )
      .attr('x', -this.policySize / 2 - this.padding.top / semanticScale)
      .attr('dy', '0.6em')
      .transition()
      .duration(this.duration)
      .attr('y', this.policySize / 2 + Number(enforcementPadding) + (this.padding.top * 2) / semanticScale)
      .attr('display', () => (100 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style('pointer-events', 'fill')
      // and then style the secondary name
      .select('.il-node-text-secondary')
      .attr('x', 0)
      .attr('dy', this.textSize / semanticScale + this.padding.top / semanticScale)
      .attr('display', () => (100 * scale < this.showLabelSize * 2 ? 'none' : 'block'));

    selection
      .select('.il-node-vulnerablity')
      .attr('dx', '.35em')
      .attr('dy', '.35em')
      .attr('font-size', this.textSize / semanticScale)
      .attr('display', () => (100 * semanticScale < this.showLabelSize ? 'none' : 'block'))
      .style({'pointer-events': 'fill', 'font-weight': 400 / semanticScale});

    selection
      .select('.il-node-internet')
      .attr('font-family', 'illumio-icons')
      .attr('text-anchor', 'middle')
      .attr('dy', '.46em')
      .attr('fill', '#3b4881') // @blue
      .attr('y', -this.policySize / 2.5)
      .attr('x', this.policySize / 2.5)
      .style('font-size', parseInt(2.5 * this.textSize + 4, 10) + 'px')
      .text('\uE620');

    selection
      .select('.il-node-syncing')
      .attr('font-family', 'illumio-icons')
      .attr('text-anchor', 'end')
      .attr('x', -this.policySize / 2 - this.padding.top / semanticScale)
      .attr('dy', '0.6em')
      .attr('font-size', this.textSize / semanticScale + 10)
      .attr('stroke-width', 1)
      .attr('y', this.policySize / 2 + Number(enforcementPadding) + (this.padding.top * 2) / semanticScale)
      .attr('display', () => (200 * semanticScale < this.showLabelSize * 2 ? 'none' : 'block'))
      .style('pointer-events', 'fill')
      .text('\uE660');

    this.position(selection, this.duration);
    this.addTitle(selection);
  },

  position(selection, duration) {
    if (duration) {
      selection = selection.transition().duration(d => (d.drag ? 0 : duration));
    }

    selection.attr('transform', d => 'translate(' + d.x.toFixed(2) + ',' + d.y.toFixed(2) + ')');
  },

  addTitle(selection) {
    selection.select('.il-node-title').text(d => {
      let title = d.name;

      if (d.secondaryName) {
        title += '\n' + d.secondaryName;
      }

      return title;
    });
  },

  selectNode(selection) {
    const policyState = selection.select('.il-node-policyState');
    const nodeRect = selection.select('.il-node-rect');

    // If the policy state rect exists, clean node rect filter then apply filter on policyState
    // If not, apply filter on the node rect
    if (!policyState.empty()) {
      nodeRect.attr('filter', 'none');
      policyState.attr('filter', d => (d.selected ? 'url(#selectFilter)' : 'none'));
    } else {
      nodeRect.attr('filter', d => (d.selected ? 'url(#selectFilter)' : 'none'));
    }
  },

  hoverNode(selection) {
    const selectedNode = selection.select('.il-node-policyState').empty()
      ? selection.select('.il-node-rect')
      : selection.select('.il-node-policyState');

    selectedNode.attr('filter', d => {
      if (d.selected || d.selfHovered) {
        return 'url(#selectFilter)';
      }

      return 'none';
    });

    selection.select('.il-node-rect').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-node-policyState').style('stroke-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-node-unmanaged').style('stroke-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-node-text').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-node-idle').style('stroke-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));

    selection.select('.il-node-container-workload').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));
  },

  dragNode(selection, drag, dragend) {
    selection.call(
      d3.behavior
        .drag()
        .on('dragstart', () => {
          d3.event.sourceEvent.stopPropagation();
          document.dispatchEvent(new Event('mousedown')); // for triggering menu-drop down close
        })
        .on('drag', () => {
          drag(d3.event.x, d3.event.y);
        })
        .on('dragend', () => {
          d3.event.sourceEvent.stopPropagation();
          dragend();
        }),
    );
  },
};
