/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from 'intl';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';
import {RadioGroup, AttributeList} from '../components';

const serviceRadioGroupData = [
  {key: 'port', value: intl('PolicyGenerator.PortServices')},
  {key: 'new', value: intl('Explorer.CreateNewService')},
];

export default React.createClass({
  propTypes: {
    onSave: PropTypes.func,
    settings: PropTypes.object,
  },

  getInitialState() {
    return {settings: this.props.settings};
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          actionCreators.closeDialog();
        },
      },
      {
        text: intl('Common.Save'),
        tid: 'save',
        type: 'primary',
        ref: 'confirmButton',
        disabled: this.state.settings.serviceType === this.props.settings.serviceType,
        onClick: () => {
          actionCreators.closeDialog();
          this.props.onSave(this.state.settings);
        },
      },
    ];
  },

  handleServiceTypeSelect(evt) {
    const {settings} = this.state;

    this.setState({settings: {...settings, serviceType: evt.target.value}});
  },

  render() {
    const attributeListData = [
      {
        key: intl('Rulesets.ServiceCreation'),
        value: [
          <div className="RBPreview-Construction-Description">{intl('Rulesets.ServiceSubstitution')}</div>,
          <RadioGroup
            data={serviceRadioGroupData}
            value={this.state.settings.serviceType}
            format="vertical"
            onChange={this.handleServiceTypeSelect}
          />,
        ],
      },
    ];

    return (
      <Dialog className="ProposedRulesetSettings" title={intl('Common.Settings')} actions={this.getActions()}>
        {/*<Notification type="instruction" message={intl('Rulesets.SettingsNotification')} />*/}
        {/*<div className="RBPreview-Construction-SubGroup">*/}
        {/*<div className="RBPreview-Construction-Description">{intl('PolicyGenerator.ServiceSubstitution')}</div>*/}
        <AttributeList data={attributeListData} />
      </Dialog>
    );
  },
});
