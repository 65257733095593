/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React, {PropTypes} from 'react';
import Dialog from '../components/Dialog.jsx';
import {UserMixin} from '../mixins';
import actionCreators from '../actions/actionCreators';
import {GraphDataUtils, RestApiUtils} from '../utils';
import {DomainForm} from '../components/Forms';
import {Spinner} from '../components';
import {MapPageStore, TrafficFilterStore} from '../stores';

export default React.createClass({
  propTypes: {
    providedName: PropTypes.string,
  },

  mixins: [UserMixin],

  getDefaultProps() {
    return {
      providedName: '',
    };
  },

  getInitialState() {
    let ipList = {};

    if (this.props.providedName) {
      ipList = {
        name: this.props.providedName,
      };
    }

    return {ipList};
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  async handleSave(model) {
    this.setState({spinner: true});

    try {
      // For Full map, don't clear all the traffic because we will reload everthing anyway
      await RestApiUtils.ipLists.create(model, null, MapPageStore.getMapLevel() === 'full');

      RestApiUtils.secPolicies.dependencies();
      setTimeout(() => GraphDataUtils.loadLevelTraffic('rebuild', TrafficFilterStore.getTransmissionFilters()), 1000);
      actionCreators.closeActionItem();
      actionCreators.closeDialog();
    } catch (error) {
      console.error(error);

      this.setState({spinner: false});
    }
  },

  render() {
    return (
      <div className="DomainCreateDialog">
        <Dialog ref="dialog" title={intl('Map.Fqdn.AddPolicy')}>
          <div className="DomainCreate">
            {this.state.spinner ? (
              <div className="DomainSpinner">
                <Spinner />
              </div>
            ) : (
              <DomainForm
                model={this.state.ipList}
                onSubmit={this.handleSave}
                onCancel={this.handleClose}
                type="dialog"
                ref="form"
              />
            )}
          </div>
        </Dialog>
      </div>
    );
  },
});
