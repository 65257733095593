/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import cx from 'classnames';
import intl from 'intl';
import Icon from './Icon.jsx';
import AttributeList from './AttributeList.jsx';
import Label from './Label.jsx';
import LabelUtils from '../utils/LabelUtils.js';
import WorkloadStore from '../stores/WorkloadStore.js';

export default React.createClass({
  propTypes: {
    entity: PropTypes.object.isRequired,
    endpoint: PropTypes.oneOf(['provider', 'consumer']),
    type: PropTypes.oneOf(['blocked', 'allowed', 'none']),
    onIpAddress: PropTypes.func,
  },

  getDefaultProps() {
    return {
      type: 'none',
    };
  },

  calculateLabels(entityHref, labelData = []) {
    const workload = WorkloadStore.getSpecified(entityHref);

    if (workload) {
      const labelObject = LabelUtils.buildLabelTypeObject(workload.labels);

      if (labelObject.role) {
        labelData.push({
          tid: 'labelrole',
          value: <Label type="role" text={labelObject.role.value} />,
        });
      }

      if (labelObject.app) {
        labelData.push({
          tid: 'labelapp',
          value: <Label type="app" text={labelObject.app.value} />,
        });
      }

      if (labelObject.env) {
        labelData.push({
          tid: 'labelenv',
          value: <Label type="env" text={labelObject.env.value} />,
        });
      }

      if (labelObject.loc) {
        labelData.push({
          tid: 'labelloc',
          value: <Label type="loc" text={labelObject.loc.value} />,
        });
      }
    }

    return labelData;
  },

  render() {
    const {type, entity, endpoint, onIpAddress} = this.props;

    let labelData = [];
    let name = intl('Common.Internet');
    let policyState = null;

    if (entity.workloads) {
      name = entity.workloads[0].name;
      policyState = _.startCase(entity.workloads[0].mode);

      if (entity.workloads[0].agent && entity.workloads[0].agent.config && policyState === 'Illuminated') {
        policyState = entity.workloads[0].agent.config.log_traffic ? intl('Common.Test') : intl('Common.Build');
      }
    } else if (entity.virtual_servers) {
      name = entity.virtual_servers[0].name;
      policyState = _.startCase(entity.virtual_servers[0].mode);
    } else if (entity.ip_lists) {
      name = entity.ip_lists[0].name;
    }

    let traffic = null;
    let indicator = null;
    let note = null;

    if (type === 'blocked') {
      traffic = intl('Common.Blocked');
      indicator = <Icon name="blocked" styleClass="BlockedTrafficEntity-indicator-blocked" />;
      note = intl(endpoint === 'consumer' ? 'BlockedTraffic.BlockedOnConsumer' : 'BlockedTraffic.BlockedOnProvider');
      labelData = entity.workloads ? this.calculateLabels(entity.workloads[0].href) : [];
    } else if (type === 'allowed') {
      traffic = intl('Common.PotentiallyBlocked');
      indicator = <Icon name="p-blocked" styleClass="BlockedTrafficEntity-indicator-warning" />;
      note = intl(
        endpoint === 'consumer'
          ? 'BlockedTraffic.PotentiallyBlockedOnConsumer'
          : 'BlockedTraffic.PotentiallyBlockedOnProvider',
      );
      labelData = entity.workloads ? this.calculateLabels(entity.workloads[0].href) : [];
    }

    const linkClasses = cx({
      'BlockedTraffic-ipaddress': true,
      'BlockedTraffic-link': !entity.workloads && !entity.virtual_servers,
    });

    const data = [
      {key: intl('BlockedTraffic.Entity.EntityName'), value: name},
      {
        key: intl('Common.IPAddress'),
        value: (
          <div className={linkClasses} onClick={evt => onIpAddress(entity, evt)}>
            {entity.ip_address}
          </div>
        ),
      },
    ];

    if (policyState) {
      data.push({key: intl('Policy.State'), value: policyState});
    }

    if (traffic) {
      data.push({key: intl('Common.Traffic'), value: traffic});
    }

    if (labelData.length) {
      data.push({key: intl('Common.Labels'), value: <AttributeList data={labelData} />});
    }

    const classes = cx('BlockedTrafficEntity', {
      'BlockedTrafficEntity--blocked': type === 'blocked',
      'BlockedTrafficEntity--allowed': type === 'allowed',
      'BlockedTrafficEntity--none': type === 'none',
    });

    const tids = cx('elem-blocked-traffic-entity', {
      'elem-blocked-traffic-entity-blocked': type === 'blocked',
      'elem-blocked-traffic-entity-allowed': type === 'allowed',
      'elem-blocked-traffic-entity-none': type === 'none',
    });

    return (
      <div>
        <div className="BlockedTrafficEntity-endpoint">
          {intl(endpoint === 'consumer' ? 'Common.Consumer' : 'Common.Provider')}
        </div>
        <div className={classes} data-tid={tids}>
          <div className="BlockedTrafficEntity-indicator">{indicator}</div>
          <AttributeList data={data} />
        </div>
        {type !== 'none' ? <div className="BlockedTrafficEntity-note">{note}</div> : null}
      </div>
    );
  },
});
