/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {SegmentationTemplateStore, ServiceStore, IpListStore} from '../stores';

const entityEditedPropertyLegacy = `// ${intl('SegmentationTemplates.Edited')}`;
const entityEditedProperty = '\r';

function getService(ingressServices) {
  return ingressServices.map(service => {
    if (service.name === 'All Services') {
      return ServiceStore.getServiceAll();
    }

    return {href: SegmentationTemplateStore.getSegmentationTemplateServices()[service.key].href};
  });
}

function getEntities(entityArr, dataSet) {
  return entityArr.map(entity => getEntity(entity, dataSet));
}

function getEntity(entity) {
  if (entity.label) {
    const labels = SegmentationTemplateStore.getSegmentationTemplateLabels();
    const label = labels[entity.label.value];

    return {label: {href: label.href}};
  }

  if (entity.actors) {
    // FIXME: should this be "entity.actors"?
    return {actors: 'ams'};
  }

  if (entity.label_group) {
    const labelGroup = SegmentationTemplateStore.getSegmentationTemplateLabelGroups()[entity.label_group.name];

    return {label_group: {href: labelGroup.href}};
  }

  if (entity.ip_list) {
    if (entity.ip_list.name === intl('IPLists.Any')) {
      return {ip_list: {href: IpListStore.getAnyIpList().href}};
    }

    const ipList = SegmentationTemplateStore.getSegmentationTemplateIpLists()[entity.ip_list.name];

    return {ip_list: {href: ipList.href}};
  }
}

function configureExternalDataReferenceForDelete(templateData, externalDataReference) {
  const key = externalDataReference.split(' -- ')[0];
  const installedTemplates = externalDataReference.split(' -- ')[1].split(' & ');

  if (installedTemplates.length === 1) {
    return false;
  }

  _.remove(installedTemplates, installedTemplate => installedTemplate === templateData);

  const templateVersion = parseInt(templateData.split(' - Version ')[1], 10);

  if (templateVersion > 1) {
    const possibleInstalledVersions = [];

    for (let i = 1; i < parseInt(templateData.split(' - Version ')[1], 10); i++) {
      possibleInstalledVersions.push(templateData.split(' - Version ')[0] + ' - Version ' + i);
    }

    possibleInstalledVersions.forEach(version =>
      _.remove(installedTemplates, installedTemplate => installedTemplate === version),
    );
  }

  return key + ' -- ' + installedTemplates.join(' & ');
}

export default {
  getContent(template) {
    const content = [];

    if (template.rule_sets && template.rule_sets.length) {
      content.push(intl('SegmentationTemplates.RulesetCount', {rulesetCount: template.rule_sets.length}));
    }

    if (template.services && template.services.length) {
      content.push(intl('SegmentationTemplates.ServiceCount', {serviceCount: template.services.length}));
    }

    if (template.labels && template.labels.length) {
      content.push(intl('SegmentationTemplates.LabelCount', {labelCount: template.labels.length}));
    }

    if (template.label_groups && template.label_groups.length) {
      content.push(intl('SegmentationTemplates.LabelGroupCount', {labelGroupCount: template.label_groups.length}));
    }

    if (template.ip_lists && template.ip_lists.length) {
      content.push(intl('SegmentationTemplates.IpListCount', {ipListCount: template.ip_lists.length}));
    }

    return content.join(', ');
  },

  getInstallText(status) {
    if (status === 'notInstalled') {
      return intl('SegmentationTemplates.Install');
    }

    if (status === 'installed' || status === 'uninstalling' || status === 'invalidVersion') {
      return intl('Common.Installed');
    }

    if (status === 'updateAvailable') {
      return intl('SegmentationTemplates.Update');
    }

    if (status === 'installing') {
      return intl('SegmentationTemplates.Installing');
    }
  },

  prepareEntitiesForDelete(entitiesInTemplate, installedEntities, templateData, entityKey, id) {
    const remove = [];
    const update = [];

    entitiesInTemplate.forEach(entity => {
      const preExistingEntity = installedEntities[entity[entityKey]];

      if (preExistingEntity) {
        const extDataRef = configureExternalDataReferenceForDelete(
          templateData,
          preExistingEntity.external_data_reference,
        );

        if (extDataRef) {
          update.push({id: _.last(preExistingEntity.href.split('/')), data: {external_data_reference: extDataRef}});
        } else if (id) {
          remove.push({id: _.last(preExistingEntity.href.split('/')), data: preExistingEntity});
        } else {
          remove.push({href: preExistingEntity.href, data: preExistingEntity});
        }
      }
    });

    return {remove, update};
  },

  /**
   * Checks the external_data_reference property, returns true in these cases:
   *     "1000053 // edited -- Some Reference",
   *     "1000034 -- Some Reference\r",
   *     "1000043 // edited -- Some Reference\r",
   *     where "// edited" is the legacy way of indicating an edited item, and "\r" is the new way
   * @param externalDataReference
   * @returns {boolean}
   */
  isEntityEdited(externalDataReference) {
    const editedConditions = [entityEditedPropertyLegacy, entityEditedProperty];

    return editedConditions.some(condition => _.includes(externalDataReference, condition));
  },

  /**
   * Returns the external data reference key without the edited string
   * @param unformattedKey - "1000043 // edited" or "1000043"
   * @returns {string}     - "1000043"
   */
  getEntityKey(unformattedKey) {
    return _.includes(unformattedKey, entityEditedPropertyLegacy)
      ? unformattedKey.split(` ${entityEditedPropertyLegacy}`)[0]
      : unformattedKey;
  },

  configureExternalDataReference(currentReference, updatedReference, overwrite) {
    if (!currentReference || !currentReference.length || updatedReference === currentReference) {
      return updatedReference;
    }

    let key = currentReference.split(' -- ')[0];

    if (overwrite && this.isEntityEdited(currentReference)) {
      key = this.getEntityKey(key);
    }

    let currentReferences = currentReference.split(' -- ')[1].split(' & ');

    if (_.includes(currentReferences, updatedReference.split(' -- ')[1])) {
      return currentReference;
    }

    const currentTemplateName = updatedReference.split(' -- ')[1].split(' - Version ')[0];

    currentReferences = currentReferences.map(reference => {
      if (reference.split(' - Version ')[0] === currentTemplateName) {
        return updatedReference.split(' -- ')[1];
      }

      return reference;
    });

    if (!_.includes(currentReferences, updatedReference.split(' -- ')[1])) {
      currentReferences.push(updatedReference.split(' -- ')[1]);
    }

    return key + ' -- ' + currentReferences.join(' & ');
  },

  getValidRules(rules) {
    return rules.map(rule => ({
      enabled: true,
      consuming_security_principals: rule.consuming_security_principals ? rule.consuming_security_principals : [],
      sec_connect: rule.sec_connect ? rule.sec_connect : false,
      unscoped_consumers: rule.unscoped_consumers ? rule.unscoped_consumers : false,
      consumers: getEntities(rule.consumers),
      providers: getEntities(rule.providers),
      ingress_services: getService(rule.ingress_services),
      resolve_labels_as: rule.resolve_labels_as,
    }));
  },

  getValidScopes(ruleSet) {
    const labels = SegmentationTemplateStore.getSegmentationTemplateLabels();
    const scopes = [];

    ruleSet.scopes.forEach(scope => {
      const scopeWithHrefs = scope.map(label => ({label: {href: labels[label.label.value].href}}));

      scopes.push(scopeWithHrefs);
    });

    return scopes;
  },

  getLabel(scope, type) {
    if (scope.length) {
      const item = scope.find(
        label => (label.label && label.label.key === type) || (label.label_group && label.label_group.key === type),
      );

      return item && ((item.label && item.label.value) || (item.label_group && item.label_group.name));
    }
  },

  getPngIcons() {
    return ['exchange', 'active_directory', 'linux', 'mongodb', 'postgresql', 'sharepoint', 'universal', 'openshift'];
  },
};
