/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {GridDataUtils, UserUtils, RestApiUtils} from '.';
import OrgStore from '../stores/OrgStore';
import {getSessionUri, getInstanceUri} from '../lib/api';

// These are all the rows of an Abilities Table. They are different PCE elements that are / are not accessible based on your role.
export const ABILITY_ROWS = {
  rules: intl('Rulesets.AndRules'),
  workloads: intl('Common.Workloads'),
  map: intl('Common.IlluminationMap'),
  appMap: intl('Common.AppGroupMap'),
  roles: intl('RBAC.ScopesAndRoles'),
  users: intl('Users.UsersAndGroups'),
  services: intl('Common.Services'),
  ipLists: intl('Common.IPLists'),
  userGroups: intl('Common.UserGroups'),
  labelGroups: intl('Labels.Groups'),
  virtualServices: intl('Common.VirtualServices'),
  virtServers: intl('Common.VirtualServers'),
  labels: intl('Common.Labels'),
  pairingProfiles: intl('PairingProfiles.Profiles'),
  loadBalancers: intl('Menu.LoadBalancers'),
  blockedTraffic: intl('BlockedTraffic.Name'),
  secSettings: intl('Common.SecuritySettings'),
  appGroupConfig: intl('Common.AppGroupsSettings'),
  myProfile: intl('Users.MyProfile'),
  apiKeys: intl('Users.APIKeys.MyAPIKeys'),
  ssoConfig: intl('Users.SSOConfig.Name'),
};

// These are possible combinations of Abilities Table column values
// This is used in ABILITIES_TABLES_FOR_ROLES to assign column values to each row
export const ABILITY_COMBOS = {
  view: {
    view: intl('Common.View'),
  },
  viewModify: {
    view: intl('Common.View'),
    modify: intl('Common.Modify'),
  },
  viewDelete: {
    view: intl('Common.View'),
    delete: intl('Common.Delete'),
  },
  viewProvision: {
    view: intl('Common.View'),
    provision: intl('Common.Provision'),
  },
  viewModifyProvision: {
    view: intl('Common.View'),
    modify: intl('Common.Modify'),
    provision: intl('Common.Provision'),
  },
  allExceptProvision: {
    view: intl('Common.View'),
    add: intl('Common.Add'),
    modify: intl('Common.Modify'),
    delete: intl('Common.Delete'),
  },
  all: {
    view: intl('Common.View'),
    add: intl('Common.Add'),
    modify: intl('Common.Modify'),
    provision: intl('Common.Provision'),
    delete: intl('Common.Delete'),
  },
  none: {
    none: intl('Common.None'),
  },
};

// This is every abilities table for every possible role
// Each row in an abilities table is set using the ABILITY_COMBOS constant
const ABILITIES_TABLES_FOR_ROLES = {
  owner: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['workloads', 'roles', 'users', 'labels', 'pairingProfiles', 'loadBalancers', 'apiKeys'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    if (['map', 'appMap'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
    }

    if (['appGroupConfig', 'myProfile', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    if (rowName === 'secSettings') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModifyProvision});
    }

    if (rowName === 'blockedTraffic') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewDelete});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.all});
  }, {}),
  admin: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['workloads', 'labels', 'pairingProfiles', 'loadBalancers', 'apiKeys'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    if (['map', 'appMap', 'users', 'appGroupConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
    }

    if (['ssoConfig', 'roles'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.none});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    if (rowName === 'secSettings') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModifyProvision});
    }

    if (rowName === 'blockedTraffic') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewDelete});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.all});
  }, {}),
  read_only: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['roles', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.none});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
  }, {}),
  ruleset_manager: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['roles', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.none});
    }

    if (['apiKeys', 'rules'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
  }, {}),
  limited_ruleset_manager: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['roles', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.none});
    }

    if (['apiKeys', 'rules'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
  }, {}),
  ruleset_provisioner: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['roles', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.none});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    if (rowName === 'rules') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewProvision});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
  }, {}),
  global_object_provisioner: Object.keys(ABILITY_ROWS).reduce((res, rowName) => {
    if (['services', 'ipLists', 'labelGroups', 'secSettings'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewProvision});
    }

    if (['roles', 'ssoConfig'].includes(rowName)) {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.none});
    }

    if (rowName === 'myProfile') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.viewModify});
    }

    if (rowName === 'apiKeys') {
      return Object.assign(res, {[rowName]: ABILITY_COMBOS.allExceptProvision});
    }

    return Object.assign(res, {[rowName]: ABILITY_COMBOS.view});
  }, {}),
};

//These are util functions for the RBAC Access Wizard
export const isAllScope = scope => scope.app.type === 'all' && scope.env.type === 'all' && scope.loc.type === 'all';

// Checks if all selected roles (roles[role] === true) are allowed to have all all all scope
export const isAllAllAllOk = roles =>
  Object.keys(roles).every(role => !roles[role] || UserUtils.isAllScopeAllowed(role));

//Checks if the scope on the Wizard page is in a valid state to submit
//the param scope is a scope that is pre loaded to the wizard from another page; pickedScope is a scope that the user choses on the wizard page
//a scope is valid if it is preloaded, or if the picked scope has app, env and loc and the picked scope is not all all all
export const isScopeValid = (scope, pickedScope, roles) => {
  if (scope) {
    return isAllAllAllOk(roles) || scope.length;
  }

  if (pickedScope && pickedScope.app && pickedScope.env && pickedScope.loc) {
    return isAllAllAllOk(roles) || !isAllScope(pickedScope);
  }

  return false;
};

//This function checks if the principals on the Wizard page are in a valid state to submit
//initPrincipals are the principals that were initially loaded into the page,
//principals are the current state of the principals
export const arePrincipalsValid = (principals, initPrincipals) => {
  const currPrincipals = principals && principals.length ? principals : [];
  const oldPrincipals = initPrincipals && initPrincipals.length ? initPrincipals : [];

  //If a principal is loaded into the page, it is valid
  if (oldPrincipals.length) {
    return true;
  }

  //If there were no initial principals, but there are principals now, the state is valid
  return currPrincipals.length > 0;
};

// This function takes in a list of roles
// Then it returns a single abilities table which is a union of all the abilities tables for the input roles
export const getEffectiveAbilitiesTable = roles => {
  const effectiveTable = {};

  Object.keys(ABILITY_ROWS).forEach(rowName => {
    effectiveTable[rowName] = roles.reduce(
      (res, role) => Object.assign(res, ABILITIES_TABLES_FOR_ROLES[role][rowName]),
      {},
    );
  });

  return effectiveTable;
};

//Get the text for scope for the summary section of the Wizard page
export const getScopeText = (scope, pickedScope, roles) => {
  if (scope) {
    // case 1: scope was preloaded into wizard page
    if (!isAllAllAllOk(roles) && !scope.length) {
      return intl('RBAC.CantHaveAllScope');
    }

    return GridDataUtils.formatScopeToText(scope);
  }

  if (pickedScope && pickedScope.app && pickedScope.env && pickedScope.loc) {
    // case 2: scope was picked on wizard page

    if (!isAllAllAllOk(roles) && isAllScope(pickedScope)) {
      return intl('RBAC.CantHaveAllScope');
    }

    return `${pickedScope.app.value} | ${pickedScope.env.value} | ${pickedScope.loc.value}`;
  }

  if (pickedScope.app || pickedScope.env || pickedScope.loc) {
    // case 3: scope was picked but is incomplete
    return intl('RBAC.NotValidScope');
  }

  // case 4: scope has not been picked yet
  return intl('RBAC.PleaseSelectScope');
};

//Get the text for principals for the summary section of the Wizard page
//This will be in the form: X Local Users, X External User, X External Groups
export const getPrincipalsText = (principals, initPrincipals) => {
  if (arePrincipalsValid(principals, initPrincipals)) {
    if (principals.length === 1) {
      return principals[0].display_name;
    }

    const typeCounts = {
      extGroup: 0,
      extUser: 0,
      locUser: 0,
    };

    const localUsers = OrgStore.getLocalUsers().map(localUser => localUser.username);

    principals.forEach(principal => {
      if (principal.type === 'user') {
        if (localUsers.includes(principal.name)) {
          typeCounts.locUser = typeCounts.locUser + 1;
        } else {
          typeCounts.extUser = typeCounts.extUser + 1;
        }
      } else {
        typeCounts.extGroup = typeCounts.extGroup + 1;
      }
    });

    const locUserText = intl('RBAC.PrincipalsTypeCount.LocalUsers', {count: typeCounts.locUser});
    const extUserText = intl('RBAC.PrincipalsTypeCount.ExternalUsers', {count: typeCounts.extUser});
    const extGroupText = intl('RBAC.PrincipalsTypeCount.ExternalGroups', {count: typeCounts.extGroup});

    return [locUserText, extUserText, extGroupText].filter(text => text !== '').join(', ');
  }

  if (initPrincipals && initPrincipals.length) {
    return intl('RBAC.PleaseChangePrincipal');
  }

  return intl('RBAC.PleaseSelectPrincipal');
};

//This function takes roles, principals, and scopes from the wiard page and checks to see if valid permission changes will be made
export const willPermissionsBeRemovedOrAdded = (currRoles, initRoles, currPrincipals, initPrincipals, scope) => {
  const removedRoles = initRoles.filter(roleKey => !currRoles.includes(roleKey));
  const addedRoles = currRoles.filter(roleKey => !initRoles.includes(roleKey));
  const removedPrincipals = initPrincipals.filter(principal => !currPrincipals.includes(principal));
  const addedPrincipals = currPrincipals.filter(principal => !initPrincipals.includes(principal));
  const overlapPrincipals = currPrincipals.filter(principal => initPrincipals.includes(principal));

  //There are two cases to check for permissions for potential removals.
  //1) All combinations of removedPrincipals and initRoles
  //2) All combinations of removedRoles and overlapPrincipals
  //If a permission exists with the given principal, scope and role then that permission will be removed.
  const somePermissionsWillBeRemovedBasedOnPrincipal = removedPrincipals.some(principal =>
    initRoles.some(
      role => OrgStore.getPermissionFromScopePrincipalRole(scope, principal, UserUtils.getRoleHref(role)) !== undefined,
    ),
  );
  const somePermissionsWillBeRemovedBasedOnRole = overlapPrincipals.some(principal =>
    removedRoles.some(
      role => OrgStore.getPermissionFromScopePrincipalRole(scope, principal, UserUtils.getRoleHref(role)) !== undefined,
    ),
  );

  //There are two cases to check for permissions that might be added.
  //1) All combinations of addedPrincipals and currRoles
  //2) All combinations of addedRoles and overlapPrincipals
  //If a permission does not exist with the given principal, scope, and role, then a permission will be added.
  const somePermissionsWillBeAddedBasedOnPrincipal = addedPrincipals.some(principal =>
    currRoles.some(
      role => OrgStore.getPermissionFromScopePrincipalRole(scope, principal, UserUtils.getRoleHref(role)) === undefined,
    ),
  );
  const somePermissionsWillBeAddedBasedOnRole = overlapPrincipals.some(principal =>
    addedRoles.some(
      role => OrgStore.getPermissionFromScopePrincipalRole(scope, principal, UserUtils.getRoleHref(role)) === undefined,
    ),
  );

  return (
    somePermissionsWillBeRemovedBasedOnPrincipal ||
    somePermissionsWillBeRemovedBasedOnRole ||
    somePermissionsWillBeAddedBasedOnPrincipal ||
    somePermissionsWillBeAddedBasedOnRole
  );
};

//This function checks for redundant preloaded permissions (i.e. if someone has owner and admin this is redundant)
//Then the function will delete the redundant permissions and return data to the Wizard page which will reload with the new permissions
export const deleteOverlappingPermissions = query => {
  let transition = false;

  if (query.roles && query.roles.length) {
    let scope;

    if (query.scope) {
      if (query.scope === 'all') {
        scope = [];
      } else {
        const scopeIds = query.scope.split('-');

        scope = scopeIds.map(scopeId => ({href: getSessionUri(getInstanceUri('labels'), {label_id: scopeId})}));
      }
    }

    const roles = [...query.roles];

    if (query.scope === 'all') {
      if (query.roles.includes('owner')) {
        ['admin', 'global_object_provisioner', 'ruleset_provisioner', 'read_only'].forEach(delRole => {
          if (query.roles.includes(delRole)) {
            transition = true;

            const delIndex = roles.indexOf(delRole);

            if (delIndex) {
              roles.splice(delIndex, 1);
            }

            query.principals.split(',').forEach(principal => {
              const principalHref = getSessionUri(getInstanceUri('org_auth_security_principals'), {
                auth_security_principal_id: principal,
              });
              const delPerm = OrgStore.getPermissionFromScopePrincipalRole(
                scope,
                principalHref,
                UserUtils.getRoleHref(delRole),
              );

              if (delPerm) {
                RestApiUtils.permission.delete(GridDataUtils.getIdFromHref(delPerm.href));
              }
            });
          }
        });
      } else if (query.roles.includes('admin')) {
        ['global_object_provisioner', 'ruleset_provisioner', 'read_only'].forEach(delRole => {
          if (query.roles.includes(delRole)) {
            transition = true;

            const delIndex = roles.indexOf(delRole);

            if (delIndex) {
              roles.splice(delIndex, 1);
            }

            query.principals.split(',').forEach(principal => {
              const principalHref = getSessionUri(getInstanceUri('org_auth_security_principals'), {
                auth_security_principal_id: principal,
              });
              const delPerm = OrgStore.getPermissionFromScopePrincipalRole(
                scope,
                principalHref,
                UserUtils.getRoleHref(delRole),
              );

              if (delPerm) {
                RestApiUtils.permission.delete(GridDataUtils.getIdFromHref(delPerm.href));
              }
            });
          }
        });
      }
    } else if (query.roles.includes('ruleset_manager') && query.roles.includes('limited_ruleset_manager')) {
      transition = true;

      const delIndex = roles.indexOf('limited_ruleset_manager');

      if (delIndex) {
        roles.splice(delIndex, 1);
      }

      query.principals.split(',').forEach(principal => {
        const principalHref = getSessionUri(getInstanceUri('org_auth_security_principals'), {
          auth_security_principal_id: principal,
        });
        const limitedRulesetManagerPerm = OrgStore.getPermissionFromScopePrincipalRole(
          scope,
          principalHref,
          UserUtils.getRoleHref('limited_ruleset_manager'),
        );

        if (limitedRulesetManagerPerm) {
          RestApiUtils.permission.delete(GridDataUtils.getIdFromHref(limitedRulesetManagerPerm.href));
        }
      });
    }

    return {transition, roles};
  }

  return {transition, roles: {}};
};

export default {
  isScopeValid,
  isAllAllAllOk,
  arePrincipalsValid,
  ABILITY_ROWS,
  getEffectiveAbilitiesTable,
  willPermissionsBeRemovedOrAdded,
  getScopeText,
  getPrincipalsText,
  deleteOverlappingPermissions,
  isAllScope,
};
