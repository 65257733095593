/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import Icon from '../Icon.jsx';
import Tooltip from '../Tooltip.jsx';
import Vulnerability from '../Vulnerability.jsx';
import {Link, State} from 'react-router';
import {RestApiUtils, ServiceUtils} from '../../utils';
import {MapPageStore, TrafficFilterStore, GraphStore, TrafficStore} from '../../stores';
import actionCreators from '../../actions/actionCreators';
import SetPolicyState from './SetPolicyStatePanel';
import {getEnforcementIntlByState} from 'intl/dynamic';
import EditPolicyStateConfirmModal from '../../modals/EditPolicyStateConfirmModal';
import cx from 'classnames';

const enforcementOrder = {
  unmanaged: 0,
  idle: 1,
  visibility: 2,
  selective: 3,
  enforced: 4,
  unknown: 5,
};

export default React.createClass({
  mixins: [State],

  getInitialState() {
    return {
      editPolicyState: false,
      doNotShow: false,
    };
  },

  componentDidMount() {
    this.getRuleset();
  },

  componentDidUpdate(prevProps) {
    if (!TrafficStore.getRingFenceRules(this.props.data?.href)) {
      this.getRingFenceRules();
    }

    if (this.props.data.href !== prevProps.data.href) {
      // only if it's not the same cluster get new ruleset and load capabilities data.
      this.getRuleset();

      this.setState({
        editPolicyState: false,
      });
    }
  },

  getRingFenceRules() {
    const {data} = this.props;
    const labelsObject = {labels: Object.values(data.labels).map(label => ({href: label.href}))};

    _.defer(() =>
      RestApiUtils.ruleCoverage.get(
        [
          {
            source: labelsObject,
            destination: labelsObject,
            services: [{port: -1, protocol: -1}],
            resolve_labels_as: {source: ['workloads'], destination: ['workloads']},
          },
        ],
        labelsObject.labels,
        'ringfence',
        data.href,
        'draft',
        true,
      ),
    );
  },

  getRuleset() {
    const {data, type} = this.props;
    let labels = [];

    if (data.labels) {
      labels = _.reduce(
        data.labels,
        (result, label) => {
          result[label.key] = label.href;

          return result;
        },
        {},
      );
    }

    if (type === 'group') {
      _.defer(() => RestApiUtils.ruleSets.getGroupCollection(labels, 'rule_set_services_labels_and_names'));
    }
  },

  handleEditPolicyState() {
    if (!localStorage.getItem('edit_workload_policy_state_only')) {
      actionCreators.openDialog(<EditPolicyStateConfirmModal onConfirm={this.handleConfirmEditPolicyState} />);
    } else {
      this.handleConfirmEditPolicyState();
    }
  },

  handleConfirmEditPolicyState(data) {
    if (data) {
      localStorage.setItem('edit_workload_policy_state_only', 'true');
    }

    this.setState({editPolicyState: !this.state.editPolicyState});
  },

  handleClosePolicyState() {
    this.setState({editPolicyState: false});
  },

  handleShowConnectedGroups() {
    if (this.getParams().id && this.props.data.connectedClusterNum) {
      actionCreators.clickActionItem({
        type: 'findConnectedGroupAction',
      });
    }
  },

  handleShowConsumingGroups() {
    if (this.getParams().id && this.props.data.consuming) {
      actionCreators.clickActionItem({
        type: 'findConsumingGroupAction',
      });
    }
  },

  handleShowProvidingGroups() {
    if (this.getParams().id && this.props.data.providing) {
      actionCreators.clickActionItem({
        type: 'findProvidingGroupAction',
      });
    }
  },

  selectVulnerability(vulnerability) {
    _.defer(() => {
      actionCreators.selectVulnerability({
        type: 'vulnerability',
        formData: vulnerability,
      });
    });
  },

  render() {
    const {data} = this.props;
    const id = this.getParams().id;
    const groupWorkloadsIsWritable = data.caps.workloads.includes('write');

    const eachEntity = _.map(data.entities, (entityNum, key) => {
      const entityName = key !== 'undefined' ? key : intl('Common.RoleUnassigned');

      return (
        <tr
          className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
          key={key}
          data-tid="map-sub-info-panel-row"
        >
          <td className="MapSubInfoPanel-Row-Label">
            <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-info-panel-row-label">
              {entityName}
            </div>
          </td>
          <td className="MapSubInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            {entityNum}
          </td>
        </tr>
      );
    });

    const entityHeader = (
      <tr className="MapInfoPanel-Header">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
          {intl('Map.Workloads.Role', {count: data.roleNum})}
        </th>
      </tr>
    );

    let entities = (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
        <td colSpan="2">
          <div className="MapInfoPanel-Row--Scroll">
            <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
              <tbody>
                {entityHeader}
                {eachEntity}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    );

    // All the different possible policy values are:
    // bulding, testing, enforced, none
    // And flow visibility are: flow_summary, flow_drops, flow_off
    let policyState = null;
    let enforcementModes = null;
    let enforcementObj = {};
    const filteredPolicyState = TrafficFilterStore.getHiddenPolicyStates();

    if (this.props.type === 'appGroup') {
      enforcementObj = data?.searchData?.find(cluster => cluster.href === data.href)?.mode || {};
    } else {
      enforcementObj = GraphStore.getClusters().find(cluster => cluster.href === data.href)?.data?.mode || {};
    }

    const enforcementKeys = Object.keys(enforcementObj)
      .filter(key => enforcementObj[key] && !filteredPolicyState.includes(key))
      .sort((a, b) => enforcementOrder[a] - enforcementOrder[b]);

    if (enforcementKeys.length > 1) {
      enforcementModes = enforcementKeys.map(key => (
        <tr
          className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
          data-tid="map-sub-info-panel-row"
        >
          <th
            className="MapSubInfoPanel-Row-Align-top"
            data-tid={`map-info-panel-row-label-${getEnforcementIntlByState(key)}`}
          >
            <div className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Label-Enforcement">
              {getEnforcementIntlByState(key)}
            </div>
          </th>
          <td className="MapSubInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
            <Link
              className="CommandPanel-Link"
              to={MapPageStore.getMapType() === 'app' ? 'appGroupWorkloads' : 'groupWorkloads'}
              params={{id: data.href, filter: key}}
              data-tid="map-info-panel-workloads"
            >
              {enforcementObj[key]}
            </Link>
          </td>
        </tr>
      ));
    }

    let containerEnforcementModes = null;
    let containerEnforcementObj = null;
    let containerEnforcementKeys = [];

    if (data.containerWorkloadsNum) {
      if (this.props.type === 'appGroup') {
        containerEnforcementObj = data?.searchData?.find(cluster => cluster.href === data.href)?.containerMode || {};
      } else {
        containerEnforcementObj =
          GraphStore.getClusters().find(cluster => cluster.href === data.href)?.data?.containerMode || {};
      }

      containerEnforcementKeys = Object.keys(containerEnforcementObj)
        .filter(key => containerEnforcementObj[key] && !filteredPolicyState.includes(key))
        .sort((a, b) => enforcementOrder[a] - enforcementOrder[b]);

      if (containerEnforcementKeys.length > 1) {
        containerEnforcementModes = containerEnforcementKeys.map(key => (
          <tr className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
            <th
              colSpan="2"
              className="MapSubInfoPanel-Row-Align-top"
              data-tid={`map-info-panel-row-label-${getEnforcementIntlByState(key)}`}
            >
              <div className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Label-Enforcement">
                {getEnforcementIntlByState(key)}
              </div>
            </th>
            <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
              <Link
                className="CommandPanel-Link"
                to={MapPageStore.getMapType() === 'app' ? 'appGroupContainerWorkloads' : 'groupContainerWorkloads'}
                params={{id: data.href, filter: key}}
                data-tid="map-info-panel-workloads"
              >
                {containerEnforcementObj[key]}
              </Link>
            </td>
          </tr>
        ));
      }
    }

    if (data.policyState) {
      const policyStateData = {
        type: 'group',
        href: data.href,
        currentState: data.policyState,
        labelsObject: data.labels,
        labels: Object.values(data.labels).reduce((items, curItems) => {
          if (!curItems) {
            return items;
          }

          if (curItems.href) {
            items.push(curItems.href);
          }

          return items;
        }, []),
      };

      const editPolicyState = (
        <td className="MapSubInfoPanel-Row-Value" data-tid="map-info-panel-row-value-enforcement">
          <SetPolicyState onClose={this.handleClosePolicyState} data={policyStateData} groupPanel />
        </td>
      );

      const disablePolicyEdit = !groupWorkloadsIsWritable;

      //If a discovered cluster doesnt have any managed workloads, disable policy edit.
      const discoveredPolicyEdit = data.href.includes('discovery') ? data.nodes.some(d => !d.unmanaged) : true;

      let displayPolicyState = (
        <td className="MapSubInfoPanel-Row-Value" data-tid="map-info-panel-row-value-enforcement">
          {enforcementKeys.length === 1 ? getEnforcementIntlByState(enforcementKeys[0]) : null}
          {discoveredPolicyEdit &&
          data.policyState &&
          (data.workloadsNum || data.virtualServersNum) &&
          data.entityNum < 500 &&
          !disablePolicyEdit ? (
            <span className="Icon-Edit">
              <Icon onClick={this.handleEditPolicyState} name="edit" tid="edit-policy-state" />
            </span>
          ) : null}
        </td>
      );

      if (data.policyState === 'unmanaged') {
        displayPolicyState = (
          <td className="MapSubInfoPanel-Row-Value" data-tid="map-info-panel-row-value-enforcement">
            <Icon name="unmanaged" styleClass="Unmanaged" position="before" />
            {intl('Workloads.Status.NotInstalled')}
          </td>
        );
      }

      policyState = (
        <tr
          className={`MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer ${
            discoveredPolicyEdit &&
            data.policyState &&
            (data.workloadsNum || data.virtualServersNum) &&
            data.entityNum < 500 &&
            !disablePolicyEdit &&
            data.policyState !== 'unmanaged'
              ? 'MapSubInfoPanel-Row--Enforcement'
              : null
          }`}
          data-tid="map-sub-info-panel-row"
        >
          <th className="MapSubInfoPanel-Row-Align-top" data-tid="map-info-panel-row-label-enforcement">
            <div className={this.state.editPolicyState ? 'MapSubInfoPanel-Row-Label-Top' : 'MapSubInfoPanel-Row-Label'}>
              {data.policyState === 'unmanaged' ? intl('Common.Connectivity') : intl('Common.Enforcement')}
            </div>
          </th>
          {this.state.editPolicyState ? editPolicyState : displayPolicyState}
        </tr>
      );
    }

    if (data.vulnerabilities) {
      entities = data.vulnerabilities.map(vulnerability => (
        <tr
          className="MapSubInfoPanel-Row"
          onClick={_.partial(this.selectVulnerability, {...vulnerability})}
          data-tid="vulnerability-sub-info-panel-row"
        >
          <td
            className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width-Vulnerability"
            data-tid="map-info-panel-row-value-vulnerability-name"
          >
            <span className="MapSubInfoPanel-Row" data-tid="map-sub-info-panel-row">
              <Tooltip content={vulnerability.details.name} position="location-group" width={372} location="fixed">
                {vulnerability.details.name}
              </Tooltip>
            </span>
          </td>
          <td className="MapSubTrafficPanel-Row-Port-Protocol">
            {vulnerability.port || vulnerability.protocol
              ? `${vulnerability.port ? vulnerability.port : ''} ${
                  vulnerability.protocol ? ServiceUtils.lookupProtocol(vulnerability.protocol) : ''
                }`
              : intl('Common.NA')}
          </td>
          <td className="MapSubInfoPanel-Row-Value-Vulnerability">
            <Vulnerability vulnerability={{...vulnerability}} opacity />
            <Icon name="next" size="xxlarge" position="after" tid="nav-to-vulnerability" />
          </td>
        </tr>
      ));

      entities = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel MapSubInfoPanel-Row-Auto-Layout" data-tid="map-sub-info-panel">
                <tbody>
                  <tr className="MapSubInfoPanel-Header" data-tid="map-info-panel-row-header">
                    <th colSpan="2" className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                      {`${data.vulnerabilities.length} ${intl('Common.Vulnerabilities')}`}
                    </th>
                  </tr>
                  {entities}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    let workloads;

    if (data.workloadsNum) {
      workloads = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
              <tbody>
                <tr className="MapInfoPanel-Header">
                  <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
                    {intl('Common.Workloads')}
                  </th>
                </tr>
                {data.workloadsNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label">
                        {intl('Map.Count')}
                      </div>
                    </td>
                    <td className="MapSubInfoPanel-Row-Value" data-tid="map-sub-info-panel-row-value">
                      <Link
                        className="CommandPanel-Link"
                        to={this.props.type === 'appGroup' ? 'appGroupWorkloads' : 'groupWorkloads'}
                        params={{id: data.href}}
                        data-tid="map-info-panel-workloads"
                      >
                        {data.workloadsNum}
                      </Link>
                    </td>
                  </tr>
                ) : null}
                {policyState}
                {enforcementModes}
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    let containerWorkloads;

    if (data.containerWorkloadsNum) {
      containerWorkloads = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
              <tbody>
                <tr className="MapInfoPanel-Header">
                  <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
                    {intl('Common.ContainerWorkloads')}
                  </th>
                </tr>
                {data.containerWorkloadsNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label">
                        {intl('Map.Count')}
                      </div>
                    </td>
                    <td className="MapInfoPanel-Row-Value" colSpan="2" data-tid="map-sub-info-panel-row-value">
                      <Link
                        className="CommandPanel-Link"
                        to={this.props.type === 'appGroup' ? 'appGroupContainerWorkloads' : 'groupContainerWorkloads'}
                        params={{id: data.href}}
                        data-tid="map-info-panel-container-workloads"
                      >
                        {data.containerWorkloadsNum}
                      </Link>
                    </td>
                  </tr>
                ) : null}
                {data.containerWorkloadsNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-info-panel-row-label-enforcement">
                        {intl('Common.Enforcement')}
                      </div>
                    </td>
                    <td
                      className="MapInfoPanel-Row-Value"
                      colSpan="2"
                      data-tid="map-sub-info-panel-row-value-enforcement"
                    >
                      {containerEnforcementKeys.length === 1
                        ? getEnforcementIntlByState(containerEnforcementKeys[0])
                        : null}
                    </td>
                  </tr>
                ) : null}
                {containerEnforcementKeys.length > 1 ? containerEnforcementModes : null}
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    let virtualServices;

    if (data.virtualServicesNum || data.virtualServersNum) {
      virtualServices = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-entities-row">
          <td colSpan="2" className="MapInfoPanel-Row-Value">
            <table className="MapSubInfoPanel" data-tid="map-sub-info-panel">
              <tbody>
                <tr className="MapInfoPanel-Header">
                  <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-header">
                    {intl('Common.VirtualServices')}
                  </th>
                </tr>
                {data.virtualServicesNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label">
                        {data.virtualServersNum ? intl('Common.VirtualServices') : intl('Map.Count')}
                      </div>
                    </td>
                    <td className="MapSubInfoPanel-Row-Value" data-tid="map-sub-info-panel-row-value">
                      <Link
                        className="CommandPanel-Link"
                        to={this.props.type === 'appGroup' ? 'appGroupVirtualServices' : 'groupVirtualServices'}
                        params={{id: data.href}}
                        data-tid="map-info-panel-virtual-services"
                      >
                        {data.virtualServicesNum}
                      </Link>
                    </td>
                  </tr>
                ) : null}
                {data.virtualServersNum ? (
                  <tr
                    className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                    data-tid="map-sub-info-panel-row"
                  >
                    <td className="MapSubInfoPanel-Row-Label">
                      <div className="MapSubInfoPanel-Row-Overflow" data-tid="map-sub-info-panel-row-label">
                        {intl('Common.VirtualServers')}
                      </div>
                    </td>
                    <td className="MapSubInfoPanel-Row-Value" data-tid="map-sub-info-panel-row-value">
                      <Link
                        className="CommandPanel-Link"
                        to={this.props.type === 'appGroup' ? 'appGroupVirtualServices' : 'groupVirtualServices'}
                        params={{id: data.href}}
                        data-tid="map-info-panel-virtual-servers"
                      >
                        {data.virtualServersNum}
                      </Link>
                    </td>
                  </tr>
                ) : null}
                <tr
                  className="MapSubInfoPanel-Row MapSubInfoPanel-NoAction MapSubInfoPanel-Row--NoHover MapSubInfoPanel-Row--NoPointer"
                  data-tid="map-sub-info-panel-row"
                >
                  <th className="MapSubInfoPanel-Row" data-tid="map-info-panel-row-label">
                    <div className="MapSubInfoPanel-Row-Label">{intl('Common.Enforcement')}</div>
                  </th>
                  <td className="MapSubInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
                    <Link
                      className="CommandPanel-Link"
                      to={this.props.type === 'appGroup' ? 'appGroupVirtualServices' : 'groupVirtualServices'}
                      params={{id: data.href}}
                      data-tid="map-info-panel-bound-workloads"
                    >
                      {intl('Map.ViewBoundWorkloads')}
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      );
    }

    let connectedGroups;

    const hoverClass = cx('MapInfoPanel-Row', {
      'MapInfoPanel-Row--HoverClass': !id || (id && MapPageStore.getMapLevel() !== 'full' && data.connectedClusterNum),
    });

    const hoverConsumingClass = cx('MapInfoPanel-Row', {
      'MapInfoPanel-Row--HoverClass': !id || (id && this.props.type === 'appGroup' && data.consuming),
    });

    const hoverProvidingClass = cx('MapInfoPanel-Row', {
      'MapInfoPanel-Row--HoverClass': !id || (id && this.props.type === 'appGroup' && data.providing),
    });

    if (MapPageStore.getMapLevel() !== 'full') {
      connectedGroups = (
        <tr className={hoverClass} data-tid="map-info-panel-row" onClick={this.handleShowConnectedGroups}>
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {!id || id === data.href ? intl('Map.ConnectedGroups') : intl('Map.OtherConnectedGroups')}
          </th>
          <td
            className={`${
              id && data.connectedClusterNum ? 'MapInfoPanel-Row-Value-Enforcement' : 'MapInfoPanel-Row-Value'
            } MapInfoPanel-Row-Value-Overflow`}
            data-tid="map-info-panel-row-value"
          >
            {data.connectedClusterNum}
            {id && data.connectedClusterNum ? (
              <span className="Icon-Next">
                <Icon name="next" size="xxlarge" tid="nav-to-connected-groups" />
              </span>
            ) : null}
          </td>
        </tr>
      );
    }

    if (this.props.type === 'appGroup') {
      connectedGroups = [
        <tr className={hoverConsumingClass} data-tid="map-info-panel-row" onClick={this.handleShowConsumingGroups}>
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Map.ConsumingAppGroups')}
          </th>
          <td
            className={`MapInfoPanel-Row-Value-Overflow ${
              id && data.consuming ? 'MapInfoPanel-Row-Value-Enforcement' : 'MapInfoPanel-Row-Value'
            }`}
            data-tid="map-info-panel-row-value"
          >
            {data.consuming}
            {id && data.consuming ? (
              <span className="Icon-Next">
                <Icon name="next" size="xxlarge" tid="nav-to-consuming-groups" />
              </span>
            ) : null}
          </td>
        </tr>,
        <tr className={hoverProvidingClass} data-tid="map-info-panel-row" onClick={this.handleShowProvidingGroups}>
          <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Map.ProvidingAppGroups')}
          </th>
          <td
            className={`MapInfoPanel-Row-Value-Overflow ${
              id && data.providing ? 'MapInfoPanel-Row-Value-Enforcement' : 'MapInfoPanel-Row-Value'
            }`}
            data-tid="map-info-panel-row-value"
          >
            {data.providing}
            {id && data.providing ? (
              <span className="Icon-Next">
                <Icon name="next" size="xxlarge" tid="nav-to-providing-groups" />
              </span>
            ) : null}
          </td>
        </tr>,
      ];
    }

    return (
      <table className="MapInfoPanel">
        <tbody>
          {entities}
          {workloads}
          {containerWorkloads}
          {virtualServices}
          {connectedGroups}
        </tbody>
      </table>
    );
  },
});
