/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {TrafficStore} from '../../stores';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin} from '../../mixins';
import {Navbar, ProgressBar, Button, Banner, AlertDialog} from '../../components';
import {ConsumingAppGroupGrid} from '../../components/PolicyGenerator';
import {PolicyGeneratorUtils, GridDataUtils, GroupDataUtils, RenderUtils} from '../../utils';
import {AppGroupTabs} from '../Groups';

function getStateFromStores() {
  const traffic = TrafficStore.getAllRoleTraffics();
  const state = this.state || this.getPolicyGeneratorValues();
  const {appGroup, ruleset} = state;
  const rulesetId = ruleset && ruleset.href.split('/').pop();
  const id = this.getParams() && this.getParams().id;

  if (id && (!appGroup || id !== appGroup.href) && TrafficStore.getNodeForPolicyGenerator(id)) {
    this.setAppGroup(TrafficStore.getNodeForPolicyGenerator(id));
  }

  return appGroup ? PolicyGeneratorUtils.parseExtraAppGroupTraffic(traffic, appGroup, rulesetId) : {};
}

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin, StoreMixin(TrafficStore, getStateFromStores)],

  getInitialState() {
    return {sorting: [{key: 'name', direction: false}]};
  },

  componentDidMount() {
    if (!this.state.appGroup) {
      this.transitionTo('policygenerator');

      return;
    }

    this.getData(this.state, 'extra');
  },

  async componentWillReceiveProps() {
    const id = this.getParams() && this.getParams().id;
    const appGroup = TrafficStore.getNodeForPolicyGenerator(id);

    if (id && (!this.state.appGroup || id !== this.state.appGroup.href) && appGroup) {
      this.setAppGroup(appGroup);

      const ruleset = await this.getPolicyGeneratorRuleset(appGroup);

      this.setState({ruleset, showProgress: true, appGroup}, () => {
        //reload the data for the new app group
        this.getData(this.state, 'extra');
      });
    }
  },

  componentWillUnmount() {
    const {extraConfig, connectedAppGroups, selectedConnectedAppGroups, appGroup} = this.state;

    if (!appGroup) {
      return;
    }

    const appGroups = connectedAppGroups.filter(appGroup => selectedConnectedAppGroups.includes(appGroup.href));

    // If the type is specified services and there are any missing connections for the selected Connected App Groups
    // We need to reload the the traffic with higher max ports
    if (
      extraConfig &&
      extraConfig.type === 'microsegmentation' &&
      appGroups.reduce((result, appGroup) => result + appGroup.missingConnections, 0)
    ) {
      actionCreators.updateTrafficForAll();
    }
  },

  handleRowClick(row) {
    if (
      row.name !== intl('PolicyGenerator.MissingLabels') &&
      row.connectionsWithRules !== Number(row.connections.split(' ')[0])
    ) {
      this.handleRowSelect(row.href);
    }
  },

  handleRowSelect(selection) {
    const selected = GridDataUtils.selectToggle(this.state.selectedConnectedAppGroups, selection);
    const totalCount = selected.reduce((result, href) => (result += this.state.connectionCounts[href]), 0);

    // Always allow the user to choose one extra scope group even if it's over the limit
    if (selected.length < 2 || totalCount < 7500) {
      this.setSelectedConnectedAppGroups(selected);
      this.setState({selectedConnectedAppGroups: selected});
    } else {
      actionCreators.openDialog(
        <AlertDialog
          message={
            Array.isArray(selection)
              ? intl('PolicyGenerator.TooManyConnectionsSelectFewer')
              : intl('PolicyGenerator.TooManyConnections')
          }
        />,
      );
    }
  },

  handleSort(key, direction) {
    this.setState({sorting: [{key, direction}]});
  },

  handleBack() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupPolicyGenerator', {id});
    } else {
      this.transitionTo('policygenerator');
    }
  },

  handleNext() {
    const id = this.getParams() && this.getParams().id;

    if (id) {
      this.transitionTo('appGroupExtraScopeConfigure', {id});
    } else {
      this.transitionTo('extraScopeConfigure');
    }
  },

  handleTransmissionFilterChange(filters) {
    this.setExtraTransmissionFilters(filters);
    this.setState({extraTransmissionFilters: filters}, () => this.getAppGroupTraffic(this.state, 'extra'));
  },

  render() {
    const {
      appGroup,
      connectedAppGroups,
      selectedConnectedAppGroups,
      extraTransmissionFilters,
      loadingSpinner,
      modal,
      type,
    } = this.state;

    if (!appGroup) {
      return null;
    }

    let title = intl('PolicyGenerator.ExtraScope.ExtraScopeAppGroupChoose');
    let appGroupTabs = null;

    if (this.getParams() && this.getParams().id) {
      const mapRoute = GroupDataUtils.getMapRoute(appGroup, this.getParams().id, this.mapLevel, 'appgroups');

      title = RenderUtils.truncateAppGroupName(appGroup.name, 45, [30, 15, 10]);
      appGroupTabs = (
        <div className="GroupBar">
          <AppGroupTabs active="policyGenerator" mapRoute={mapRoute} />
        </div>
      );
    }

    let connectedAppGroupTable = null;
    const count = connectedAppGroups.some(
      consuming => consuming.appGroup.name === intl('PolicyGenerator.MissingLabels'),
    )
      ? connectedAppGroups.length - 1
      : connectedAppGroups.length;

    if (appGroup && connectedAppGroups) {
      connectedAppGroupTable = (
        <ConsumingAppGroupGrid
          appGroups={connectedAppGroups}
          title={appGroup.name}
          subtitle={intl('PolicyGenerator.ExtraScope.NumConsumingAppGroup', {count})}
          selection={this.state.selectedConnectedAppGroups}
          onRowSelect={this.handleRowSelect}
          onRowClick={this.handleRowClick}
          sorting={this.state.sorting}
          onSort={this.handleSort}
          type={type}
          noRules={this.state.noRules}
          onTransmissionFilterChange={this.handleTransmissionFilterChange}
          transmissionFilters={extraTransmissionFilters}
        />
      );
    }

    if (loadingSpinner && !modal && loadingSpinner !== 'rules') {
      let message;

      switch (loadingSpinner) {
        case 'rules':
          message = intl('PolicyGenerator.Spinner.CalculatingFlowData');
          break;
        case 'vulnerabilities':
          message = intl('PolicyGenerator.Spinner.CalculatingVulnerabilityData');
          break;
        default:
          message = intl('PolicyGenerator.Spinner.FindingConnections');
      }

      return (
        <div className="RBConfigure RBConfigure--Extra--Choose RBConfigure--Extra">
          <Banner type="progresscentered" header={intl('PolicyGenerator.CalculationInProgress')} message={message} />
          <Navbar title={title} />
        </div>
      );
    }

    return (
      <div className="RBConfigure RBConfigure--Extra--Choose RBConfigure--Extra">
        <Navbar title={title} />
        {appGroupTabs}
        <div className="RBConfigure-Content">
          <ProgressBar
            steps={[
              intl('PolicyGenerator.SelectAppGroup'),
              [intl('PolicyGenerator.ExtraScope.ChooseExtraScope'), <br />, intl('Common.AppGroups')],
              intl('PolicyGenerator.ExtraScope.ConfigureExtraScope'),
              intl('PolicyGenerator.PreviewRules'),
            ]}
            active={1}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
            <Button
              text={intl('Common.Next')}
              disabled={!selectedConnectedAppGroups.length}
              onClick={this.handleNext}
              tid="next"
            />
          </div>
          {connectedAppGroupTable}
        </div>
      </div>
    );
  },
});
