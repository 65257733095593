/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getSessionUri, getInstanceUri} from '../lib/api';
import {createApiStore} from '../lib/store';
import StoreUtils from '../utils/StoreUtils';
import ServiceUtils from '../utils/ServiceUtils';
import Constants from '../constants';
import intl from 'intl';

const services = {};
let count = {};
const isLoaded = {};
const defaultServices = {};
let servicesObject = {};

function setServices(data, version, query) {
  StoreUtils.setObjects(services, isLoaded, data, version || 'draft');

  if (!version || version === 'draft') {
    servicesObject = ServiceUtils.getServicesObject(data);
    isLoaded.draft = !(query && query.max_results < 10_000);

    services.draft = services.draft.map(service => ({
      ...service,
      serviceCount: ServiceUtils.getServiceCount(service),
      serviceSpecificity: ServiceUtils.getServiceSpecificity(service),
      windowsServiceSpecificity: ServiceUtils.getWindowsServiceSpecificity(service),
    }));
  }
}

function setService(data, version) {
  StoreUtils.setObjectByVersion(data, version || 'draft', services);

  if (version === 'active') {
    const pversion = StoreUtils.getPolicyVersionFromHref(data.href);

    StoreUtils.setObjectByVersion(data, pversion || 'draft', services);
  }

  isLoaded[version] = false;
}

function setCount(data) {
  count = data;
}

function setDefaultServices(data) {
  defaultServices[data[0].name] = data[0];
}

export default createApiStore(['SERVICES_', 'SERVICE_'], {
  dispatchHandler(action) {
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    switch (action.type) {
      case Constants.SERVICES_GET_COLLECTION_SUCCESS:
        if (action.options.noStore) {
          return true;
        }

        if (
          action.data &&
          action.data.length &&
          action.options.query &&
          action.options.query.name &&
          (action.options.query.name === 'All Services' || action.options.query.name === 'ICMP')
        ) {
          setDefaultServices(action.data, version);
        }

        setServices(action.data, version, action.options.query);
        setCount(action.count);
        break;

      case Constants.SERVICES_GET_INSTANCE_SUCCESS:
        setService(action.data, version);
        break;

      case Constants.SERVICES_CREATE_SUCCESS:
        if (services?.draft) {
          services.draft.push(action.data);
          servicesObject = ServiceUtils.getServicesObject(services.draft);
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  // Get the service "All Services" by finding service with any port, any protocol
  getServiceAll(withName) {
    const allServices = getSessionUri(getInstanceUri('services'), {
      service_id: 'all_services',
      pversion: 'draft',
    });

    if (withName) {
      return {
        name: intl('Common.AllServices'),
        href: allServices,
      };
    }

    return {href: allServices};
  },

  getAll(version) {
    return services[version || 'draft'] || [];
  },

  getCount() {
    return count;
  },

  getSpecified(href) {
    return StoreUtils.findObjectByHref(href, services);
  },

  isLoaded(version) {
    return isLoaded[version || 'draft'] || false;
  },

  getAllServicesService(version) {
    return _.find(
      this.getAll(version || 'draft'),
      service =>
        service.service_ports && service.service_ports.length === 1 && service.service_ports[0].protocol === -1,
    );
  },

  getSpecifiedDefaultService(serviceName) {
    return defaultServices[serviceName];
  },

  getServiceFromObject(key) {
    return servicesObject[key];
  },
});
