/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
/*eslint-disable no-unused-vars*/
import d3 from 'd3';
/*eslint-enable no-unused-vars*/
import _ from 'lodash';
import intl from 'intl';
import {SessionStore} from '../../stores';

export default {
  calculateNextLabel,
  calculateHighlighted,
  calculateHighlightedVizLink,
  calculateHighlightedPCDimension,
  calculateClickedTickPath,
  calculateHoveredLink,
};

export function calculateNextLabel(clickedLabel, value) {
  let nextLabel;

  if (value === 'Workloads') {
    nextLabel = 'workload';
  } else if (value === 'Virtual Services') {
    nextLabel = 'virtual_service';
  } else if (value === 'Virtual Servers') {
    nextLabel = 'virtual_server';
  } else if (value === 'IP List' || value === 'IP Range') {
    nextLabel = 'ip_list';
  } else if (value === intl('Common.IPAddress') || clickedLabel === 'ip_list' || clickedLabel === 'domain') {
    nextLabel = 'ip';
  } else if (clickedLabel === 'ip') {
    nextLabel = null;
  } else if (clickedLabel === 'workload') {
    nextLabel = 'ip';
  } else if (clickedLabel === 'loc' || clickedLabel === 'env' || clickedLabel === 'app') {
    nextLabel = 'group';
  } else if (clickedLabel === 'group') {
    nextLabel = SessionStore.isEdge() ? 'workload' : 'role';
  } else if (clickedLabel === 'role') {
    nextLabel = 'workload';
  } else if (clickedLabel === 'all') {
    if (value === 'Labels') {
      nextLabel = 'app';
    } else if (value === 'FQDNs') {
      nextLabel = 'domain';
    }
  }

  return nextLabel;
}

export function calculateHighlighted(hovered, link, dimensions) {
  hovered[link.key] = true;
  _.forOwn(dimensions, dimension => {
    if (!hovered[dimension]) {
      hovered[dimension] = {};
    }

    hovered[dimension][link.data[dimension]] = true;
  });

  return hovered;
}

export function calculateHoveredLink(hoveredLink, data) {
  if (hoveredLink === null) {
    return;
  }

  const src = 'src_' + data.sourceType;
  const dst = 'dst_' + data.targetType;

  return (
    hoveredLink[src] === data.source &&
    hoveredLink[dst] === data.target &&
    hoveredLink.port === data.port &&
    hoveredLink.protocol === data.protocol &&
    hoveredLink.processName === data.processName
  );
}

export function calculateHighlightedVizLink(highlightedLinks, link) {
  return (
    highlightedLinks &&
    highlightedLinks.source &&
    highlightedLinks.source[link.source] &&
    highlightedLinks.target[link.target] &&
    (!highlightedLinks.port || highlightedLinks.port[link.port]) &&
    (!highlightedLinks.processName || highlightedLinks.processName[link.processName]) &&
    (!highlightedLinks.outboundProcessName || highlightedLinks.outboundProcessName[link.outboundProcessName])
  );
}
export function calculateHighlightedPCDimension(highlightedLink, data, dimension) {
  let tblHoveredSrc = false;
  let tblHoveredDst = false;
  let tblHoveredPort = false;
  let tblHoveredProcessName = false;

  highlightedLink.forOwn(linkKey => {
    if (linkKey.startsWith('src')) {
      tblHoveredSrc = tblHoveredSrc || (highlightedLink[linkKey] === data && dimension === 'source');
    } else if (linkKey.startsWith('dst')) {
      tblHoveredDst = tblHoveredDst || (highlightedLink[linkKey] === data && dimension === 'target');
    } else if (linkKey === 'port') {
      tblHoveredPort = tblHoveredPort || (highlightedLink[linkKey] === data && dimension === 'port');
    } else if (linkKey === 'processName') {
      tblHoveredProcessName =
        tblHoveredProcessName || (highlightedLink[linkKey] === data && dimension === 'processName');
    }
  });

  return tblHoveredSrc || tblHoveredDst || tblHoveredPort || tblHoveredProcessName;
}

export function calculateClickedTickPath(oldClickedTickPath, clickedTick, dimension) {
  const clickedTickPath = {...oldClickedTickPath};

  if ((!_.isEmpty(clickedTickPath) && _.isEmpty(clickedTickPath[dimension])) || _.isEmpty(clickedTickPath)) {
    clickedTickPath[dimension] = [];
  }

  if (!_.isEmpty(clickedTick)) {
    clickedTickPath[dimension].push(clickedTick[dimension]);
  }

  return clickedTickPath;
}
