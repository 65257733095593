/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import {Icon} from '..';
import intl from 'intl';
import {HelpCards} from '.';
import SessionStore from '../../stores/SessionStore';

HelpPopup.propTypes = {
  canExpand: PropTypes.bool,
  poppedOut: PropTypes.bool,
  canClose: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

//This component renders the content of the HelpPopup
export default function HelpPopup(props) {
  return (
    <div className="HelpPopup" data-tid="help-popup">
      <div className="HelpPopup-title-bar">
        <div className="HelpPopup-title">
          <div className="HelpPopup-grab">{!props.poppedOut && <Icon styleClass="grabHelp" name="grab-handle" />}</div>
          {intl('Common.Help')}
        </div>
        {props.canExpand && !props.poppedOut && (
          <div onClick={props.onExpand} className="HelpPopup-icon">
            <Icon styleClass="HelpPopup" name="pop-out" />
          </div>
        )}
        {props.canExpand && props.poppedOut && (
          <div onClick={props.onExpand} className="HelpPopup-icon">
            <Icon styleClass="HelpPopup" name="pop-in" />
          </div>
        )}
        {props.canClose && (
          <div onClick={props.onClose} className="HelpPopup-icon">
            <Icon styleClass="HelpPopup" name="close" />
          </div>
        )}
      </div>
      <HelpCards data={props.data} />
      <div className="HelpPopup-footer">
        <a target="_blank" href={`${SessionStore.getSupportLink()}/index.html`}>
          {intl('Help.Menu.VisitSupportPortal')}
          <div className="HelpPopup-linkout">
            <Icon styleClass="HelpPopup" name="external-link" />
          </div>
        </a>
      </div>
    </div>
  );
}
