/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';

export default {
  usesOptions: [
    {value: 'unlimited', label: intl('PairingProfiles.UnlimitedUses')},
    {value: '1', label: intl('PairingProfiles.OneUse')},
  ],

  getUsesPerKeyText(value) {
    const result = {};

    switch (value) {
      case 'unlimited':
        result.title = intl('Common.Unlimited');
        result.content = intl('PairingProfiles.UnlimitedWorkloadCanPaired');
        break;
      case '1':
        result.title = intl('PairingProfiles.SinglePairing');
        result.content = intl('PairingProfiles.OneWorkloadCanPaired');
        break;
    }

    return result;
  },

  lifespanOptions: () => [
    {value: 'unlimited', label: intl('Common.Unlimited')},
    {value: 'custom', label: intl('PairingProfiles.CustomTimeRange')},
  ],

  lifespanTimeOptions: () => [
    {value: 'day', label: intl('Common.Days'), timeValue: 86_400},
    {value: 'hour', label: intl('Common.Hours'), timeValue: 3600},
    {value: 'minute', label: intl('Common.Minutes'), timeValue: 60},
  ],

  getKeyLifespanText(value) {
    const result = {};

    if (value === 'unlimited') {
      result.title = intl('Common.Unlimited');
      result.content = intl('PairingProfiles.UnlimitedTime');
    } else if (_.isFinite(value)) {
      result.title = this.formatTimeLeft(value, true);
      result.content = intl('PairingProfiles.WhenKeyGenerated');
    }

    return result;
  },

  getLockWorkloadStateText(value) {
    const result = {};

    if (value) {
      result.title = intl('Common.Locked');
      result.content = intl('PairingProfiles.Workloads.LockPolicyAssignmentDesc');
    } else {
      result.title = intl('Common.Unlocked');
      result.content = intl('PairingProfiles.Workloads.AllowPolicyAssignmentDesc');
    }

    return result;
  },

  getLockedLabelsText(labelsLength, labels) {
    const result = {};

    switch (labelsLength) {
      case 0:
        result.title = intl('PairingProfiles.Workloads.AllowCustomLabels');
        result.content = intl('PairingProfiles.Workloads.AllowCustomLabelsDesc');
        break;
      case 4:
        result.title = intl('PairingProfiles.Workloads.LockLabelAssignment');
        result.content = intl('PairingProfiles.Workloads.LockLabelAssignmentDesc');
        break;
      default:
        if (labelsLength > 0) {
          result.title = intl('PairingProfiles.Workloads.LockSomeLabels');
          result.content = intl('PairingProfiles.Workloads.LockSomeLabelsDesc', {labels: labels.join(', ')}); // Only used on details page
        }

        break;
    }

    return result;
  },

  formatTimeLeft(timeLeft, showMinutes, showSeconds) {
    timeLeft = parseInt(timeLeft, 10);

    const days = Math.floor(timeLeft / 86_400);
    const hours = Math.floor((timeLeft - days * 86_400) / 3600);
    const minutes = Math.floor((timeLeft - days * 86_400 - hours * 3600) / 60);
    const seconds = timeLeft - days * 86_400 - hours * 3600 - minutes * 60;

    const timeParts = [];

    if (days > 0) {
      timeParts.push(intl('Common.DaysNumber', {count: days}));
    }

    if (hours > 0) {
      timeParts.push(intl('Common.HoursNumber', {count: hours}));
    }

    if (showMinutes && minutes > 0) {
      timeParts.push(intl('Common.MinutesNumber', {count: minutes}));
    }

    if (showSeconds && seconds > 0) {
      timeParts.push(intl('Common.SecondsNumber', {count: seconds}));
    }

    return timeParts.join(', ');
  },

  getTimeType(value) {
    if (parseInt(value, 10) !== value) {
      return;
    }

    if (value % 86_400 === 0) {
      return 'day';
    }

    if (value % 3600 === 0) {
      return 'hour';
    }

    if (value % 60 === 0) {
      return 'minute';
    }

    return 'second';
  },

  getTimeTypeValue(type, value) {
    switch (type) {
      case 'day':
        return value / 86_400;
      case 'hour':
        return value / 3600;
      case 'minute':
        return value / 60;
      case 'second':
        return value;
    }
  },

  getTimeValue(type, value) {
    const parsedValue = parseInt(value, 10);

    if (!_.isFinite(parsedValue) || parsedValue !== Number(value)) {
      return;
    }

    switch (type) {
      case 'day':
        return parsedValue * 86_400;
      case 'hour':
        return parsedValue * 3600;
      case 'minute':
        return parsedValue * 60;
      case 'second':
        return parsedValue;
    }
  },
};
