/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {Navbar, Button, AlertDialog} from '../../components';
import {Form, Fieldset, Field} from '../../components/Form';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RestApiUtils, GraphDataUtils} from '../../utils';
import OrgStore from '../../stores/OrgStore';
import SessionStore from '../../stores/SessionStore';
import Session from '../../lib/session';
import {StoreMixin, UserMixin} from '../../mixins';
import {Navigation} from 'react-router';

function getStateFromStores() {
  return {
    status: [OrgStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [Navigation, UserMixin, StoreMixin([OrgStore], getStateFromStores)],

  getInitialState() {
    const org = OrgStore.getOrg();

    return {
      org,
      double: !org.app_groups_default || org.app_groups_default.length === 2,
    };
  },

  componentWillMount() {
    if (!this.isUserOwner()) {
      this.replaceWith('appGroups');
    }
  },

  componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    RestApiUtils.orgs.getInstance(Session.getOrgId());
  },

  handleAppGroupTypeChange(evt) {
    this.setState({double: evt.target.value === 'double'});
  },

  handleCancel() {
    this.transitionTo('appGroups');
  },

  handleSave() {
    // Start the process during the Alert, to allow this to be done at higher scale.
    const type = this.state.double ? ['app', 'env'] : ['app', 'env', 'loc'];

    actionCreators.updateForAll();
    RestApiUtils.org.update(Session.getOrgId(), {app_groups_default: type});
    actionCreators.openDialog(
      <AlertDialog
        title={intl('AppGroups.ChangingAppGroupsType')}
        message={intl('AppGroups.ChangingAppGroupsWarning')}
        onClose={this.handleConfirm}
      />,
    );
  },

  handleConfirm() {
    GraphDataUtils.getTraffic('appGroup', {type: 'rebuild', route: 'appGroups'});
    this.transitionTo('appGroups');
  },

  render() {
    const appGroupTypes = [
      {
        key: 'double',
        value: intl('AppGroups.AppEnv'),
        focus: true,
        detail: intl('AppGroups.AppEnvDesc'),
        detailFormat: 'bottom',
      },
      {
        key: 'triple',
        value: intl('AppGroups.AppEnvLoc'),
        detail: intl('AppGroups.AppEnvLocDesc'),
        detailFormat: 'bottom',
      },
    ];

    return (
      <div className="AppGroupType" data-tid="page-app-group-type-edit">
        <Navbar />
        <ToolBar>
          <ToolGroup>
            <Button text={intl('Common.Save')} disabled={this.isUserReadOnly()} onClick={this.handleSave} tid="save" />
            <Button
              text={intl('Common.Cancel')}
              disabled={this.isUserReadOnly()}
              type="secondary"
              onClick={this.handleCancel}
              tid="cancel"
            />
          </ToolGroup>
        </ToolBar>
        <div className="AppGroupTypeEdit-form" data-tid="page-app-group-form">
          <Form>
            <Fieldset
              title={intl('Common.Description')}
              tid="app-group-types"
              subtitle={intl('AppGroups.AppGroupTypeDescription')}
            >
              <Field
                type="radiogroup"
                data={appGroupTypes}
                onChange={this.handleAppGroupTypeChange}
                label={intl('AppGroups.AppGroupType')}
                value={this.state.double ? 'double' : 'triple'}
              />
            </Fieldset>
          </Form>
        </div>
      </div>
    );
  },
});
