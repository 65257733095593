/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from 'intl';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    name: PropTypes.string.isRequired,
    onSave: PropTypes.func,
  },

  getInitialState() {
    return {filterName: this.props.name};
  },

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          actionCreators.closeDialog();
        },
      },
      {
        text: intl('Common.OK'),
        tid: 'ok',
        type: 'primary',
        ref: 'confirmButton',
        disabled: !this.state.filterName,
        onClick: () => {
          actionCreators.closeDialog();
          this.props.onSave(this.state.filterName);
        },
      },
    ];
  },

  handleChange(evt) {
    this.setState({filterName: evt.target.value});
  },

  render() {
    return (
      <Dialog className="SaveFilter" title={intl('Explorer.SaveFilter')} actions={this.getActions()}>
        <div className="SaveFilterModal-Text">{intl('Explorer.EnterName')}</div>
        <input
          type="text"
          ref="filterNameInput"
          onChange={this.handleChange}
          className="FormInput"
          value={this.state.filterName}
        />
      </Dialog>
    );
  },
});
