/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React, {PropTypes} from 'react';
import Slider from 'react-rangeslider';

export default React.createClass({
  propTypes: {
    time: PropTypes.shape({
      hour: PropTypes.number.isRequired,
      minute: PropTypes.number.isRequired,
    }).isRequired,
    date: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['from', 'to']).isRequired,
  },

  getInitialState() {
    const {hour, minute} = this.props.time;
    const hourStr = hour.toString().padStart(2, '0');
    const minuteStr = minute.toString().padStart(2, '0');

    return {
      hourTextValue: hourStr,
      minuteTextValue: minuteStr,
    };
  },

  componentWillReceiveProps(nextProps) {
    const {
      time: {hour: nextHour, minute: nextMinute},
      type: nextType,
    } = nextProps;
    const {
      type,
      time: {minute, hour},
    } = this.props;

    if (nextType !== type || nextHour !== hour || nextMinute !== minute) {
      const hourStr = nextHour.toString().padStart(2, '0');
      const minuteStr = nextMinute.toString().padStart(2, '0');

      this.setState({
        hourTextValue: hourStr,
        minuteTextValue: minuteStr,
      });
    }
  },

  handleHourRangeChange(val) {
    const date = new Date();
    const nowHour = date.getHours();
    const nowMin = date.getMinutes();
    const nowyear = date.getFullYear();
    const nowdate = date.getDate();

    let hour = Number(val);
    let flag = false;
    let inputMin = Number(this.state.minuteTextValue);

    if (hour >= nowHour && this.props.date.getFullYear() === nowyear && this.props.date.getDate() === nowdate) {
      if (inputMin > nowMin) {
        flag = true;
        inputMin = nowMin;
        hour = nowHour;
      } else {
        hour = nowHour;
      }
    }

    this.setState(prevState => ({
      hourTextValue: hour.toString().padStart(2, '0'),
      minuteTextValue:
        flag && prevState.minuteTextValue > nowMin ? nowMin.toString().padStart(2, '0') : prevState.minuteTextValue,
    }));
    this.props.onChange({...this.props.time, hour, minute: inputMin}, this.props.type);
  },

  handleHourTextChange(evt) {
    let inputMin = Number(this.state.minuteTextValue);
    const evtValue = evt.target.value;
    const date = new Date();
    const nowHour = date.getHours();
    const nowMin = date.getMinutes();
    const nowyear = date.getFullYear();
    const nowdate = date.getDate();
    let intValue = Number(evtValue);
    let flag = false;

    if (isNaN(intValue)) {
      return;
    }

    if (evtValue.length > 2 && intValue > 23) {
      intValue = Number(evtValue.split('').pop());
    }

    if (intValue >= nowHour && this.props.date.getFullYear() === nowyear && this.props.date.getDate() === nowdate) {
      if (inputMin > nowMin) {
        flag = true;
        inputMin = nowMin;
        intValue = nowHour;
      } else {
        intValue = nowHour;
      }
    }

    if (intValue < 0) {
      this.setState({hourTextValue: '0'});
      this.props.onChange({...this.props.time, hour: 0}, this.props.type);
    } else {
      this.setState(prevState => ({
        hourTextValue: intValue,
        minuteTextValue:
          flag && prevState.minuteTextValue > nowMin ? nowMin.toString().padStart(2, '0') : prevState.minuteTextValue,
      }));
      this.props.onChange({...this.props.time, hour: intValue, minute: inputMin}, this.props.type);
    }
  },

  handleHourKeyDown(evt) {
    const hour = this.props.time.hour;
    const up = evt.key === 'ArrowUp';
    const down = evt.key === 'ArrowDown';

    if (evt.key === 'Enter') {
      this.setState({hourTextValue: this.state.hourTextValue.padStart(2, '0')});
    } else if ((up && hour < 23) || (down && hour > 0)) {
      const hourChange = up ? 1 : -1;

      this.setState({hourTextValue: (hour + hourChange).toString().padStart(2, '0')});
      this.props.onChange({...this.props.time, hour: hour + hourChange}, this.props.type);
    }
  },

  handleHourBlur() {
    this.setState({hourTextValue: this.state.hourTextValue.toString().padStart(2, '0')});
  },

  handleMinuteRangeChange(val) {
    const inputHour = Number(this.state.hourTextValue);
    const date = new Date();
    const nowHour = date.getHours();
    const nowMin = date.getMinutes();
    const nowyear = date.getFullYear();
    const nowdate = date.getDate();

    const stepSize = 5;
    const modValue = val % stepSize;
    const roundedValue = modValue < 3 ? val - modValue : val + (5 - modValue);
    // don't allow user to set minute to 60
    let minute = roundedValue === 60 ? 59 : roundedValue;

    if (
      this.props.date.getFullYear() === nowyear &&
      this.props.date.getDate() === nowdate &&
      inputHour === nowHour &&
      minute > nowMin
    ) {
      minute = nowMin;
    }

    this.setState({minuteTextValue: minute.toString().padStart(2, '0')});
    this.props.onChange({...this.props.time, minute}, this.props.type);
  },

  handleMinuteBlur() {
    this.setState({minuteTextValue: this.state.minuteTextValue.toString().padStart(2, '0')});
  },

  handleMinuteTextChange(evt) {
    const inputHour = Number(this.state.hourTextValue);
    const date = new Date();
    const nowHour = date.getHours();
    const nowMin = date.getMinutes();
    const nowyear = date.getFullYear();
    const nowdate = date.getDate();
    const evtValue = evt.target.value;
    let intValue = Number(evtValue);

    if (isNaN(intValue)) {
      return;
    }

    if (evtValue.length > 2 && intValue > 59) {
      intValue = Number(evtValue.split('').pop());
    }

    if (
      this.props.date.getFullYear() === nowyear &&
      this.props.date.getDate() === nowdate &&
      inputHour === nowHour &&
      intValue > nowMin
    ) {
      intValue = nowMin;
    }

    if (intValue < 0) {
      this.setState({minuteTextValue: '0'});
      this.props.onChange({...this.props.time, minute: 0}, this.props.type);
    } else {
      this.setState({minuteTextValue: intValue});
      this.props.onChange({...this.props.time, minute: intValue}, this.props.type);
    }
  },

  handleMinuteKeyDown(evt) {
    const minute = this.props.time.minute;
    const up = evt.key === 'ArrowUp';
    const down = evt.key === 'ArrowDown';

    if (evt.key === 'Enter') {
      this.setState({minuteTextValue: this.state.minuteTextValue.padStart(2, '0')});
    } else if ((up && minute < 59) || (down && minute > 0)) {
      const minuteChange = up ? 1 : -1;

      this.setState({minuteTextValue: (minute + minuteChange).toString().padStart(2, '0')});
      this.props.onChange({...this.props.time, minute: minute + minuteChange}, this.props.type);
    }
  },

  render() {
    const {hour, minute} = this.props.time;

    return (
      <div className="DTPickerClock">
        <div className="DTPickerClock-TextField">
          <input
            type="text"
            value={this.state.hourTextValue.toString().padStart(2, '0')}
            onChange={this.handleHourTextChange}
            onKeyDown={this.handleHourKeyDown}
            onBlur={this.handleHourBlur}
          />
          <span>:</span>
          <input
            type="text"
            value={this.state.minuteTextValue.toString().padStart(2, '0')}
            onChange={this.handleMinuteTextChange}
            onKeyDown={this.handleMinuteKeyDown}
            onBlur={this.handleMinuteBlur}
          />
        </div>
        <div className="DTPickerClock-RangeField">
          <span>{intl('Common.Hours')}</span>
          <Slider
            min={0}
            max={23}
            tooltip={false}
            value={hour}
            onClick={this.handleClick}
            onChange={this.handleHourRangeChange}
          />
        </div>
        <div className="DTPickerClock-RangeField">
          <span>{intl('Common.Minutes')}</span>
          <Slider
            min={0}
            max={59}
            tooltip={false}
            value={minute}
            onClick={this.handleClick}
            onChange={this.handleMinuteRangeChange}
          />
        </div>
      </div>
    );
  },
});
