/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {Button, Icon} from '..';
import React, {PropTypes} from 'react';
import {DateTimePickerCal, DateTimePickerInput, DateTimePickerClock} from '.';
import {diffInDays} from 'intl/utils';

// Everything is ok with the selection of from and to
const APPLY_DISABLED_FALSE = 0;
// All previous invalid date selection
const APPLY_DISABLED_INVALID_TIME = 1;
// Limit in the time span
const APPLY_DISABLED_LIMITED = 2;

export default React.createClass({
  propTypes: {
    name: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    getDateFromValue: PropTypes.func.isRequired,
    fromSingleValues: PropTypes.object.isRequired,
    toSingleValues: PropTypes.object.isRequired,
    autoLoadCustom: PropTypes.bool, // Used in explorer. If only option is custom value, auto load it and don't show dropdown.
    preSelectFrom: PropTypes.bool, // Used in explorer. This means that the from field will have an initial value.
    limitCustomRangeToMaxDays: PropTypes.number, // Used in explorer to limit the number of max days of the custom range.
  },

  getInitialState() {
    return {
      activeCustom: this.props.preSelectFrom ? 'from' : '',
      date: {
        from: new Date(),
        to: new Date(),
      },
      time: {
        from: {hour: 0, minute: 0},
        to: {hour: 0, minute: 0},
      },
      hasCustom: {
        from: this.props.preSelectFrom,
        to: false,
      },
      // Object to hold the selected value in DateTimePickerInput
      items: {
        from: this.props.preSelectFrom
          ? {
              [intl('DateTimeInput.CustomTime')]: 'custom',
            }
          : {},
        to: {},
      },
    };
  },

  getTimestamps(from, to) {
    // Array to hold Date timestamps of from and to
    const types = ['from', 'to'];
    const timestamps = [];

    [from, to].forEach((value, idx) => {
      // Let DateTimePicker evaluate 'anytime', 'now' and 'custom'
      // for everything else, delegate to the parent component
      switch (value) {
        case 'anytime':
          timestamps[idx] = null;
          break;
        case 'now':
          timestamps[idx] = new Date();
          break;
        case 'custom':
          const date = new Date(this.state.date[types[idx]]);
          const time = this.state.time[types[idx]];

          date.setHours(time.hour, time.minute);
          timestamps[idx] = date;
          break;
        default:
          // This function should return a Date instance
          timestamps[idx] = this.props.getDateFromValue(value);
      }
    });

    return {from: timestamps[0], to: timestamps[1]};
  },

  applyDisabled(limitCustomRangeToMaxDays = null) {
    const {items} = this.state;
    const [fromValue, toValue] = [Object.values(items.from)[0], Object.values(items.to)[0]];

    // Both from and to are required
    if (!fromValue || !toValue) {
      return APPLY_DISABLED_INVALID_TIME;
    }

    // If both from and to time are the same ('anytime', 'now' or 'last')
    // (they can both be 'custom')
    if (fromValue !== 'custom' && fromValue === toValue) {
      return APPLY_DISABLED_INVALID_TIME;
    }

    // Get the Date object from the string value of the times
    const {from, to} = this.getTimestamps(fromValue, toValue);

    // If from or to are not 'anytime', convert them to getTime()
    // getTime() returns the seconds passed since UTC Epoch Time
    const fromTime = fromValue !== 'anytime' && from.getTime();
    const toTime = toValue !== 'anytime' && to.getTime();

    // Get current time in seconds passed
    const currentTime = Date.now();

    // If fromTime is in the future
    const fromInFuture = fromTime > currentTime;

    // If toTime is 'anytime' and
    // and fromTime is in the future
    if (!toTime) {
      return fromInFuture ? APPLY_DISABLED_INVALID_TIME : APPLY_DISABLED_FALSE;
    }

    // If toTime is in the future
    const toInFuture = toTime > currentTime;

    // If fromTime is 'anytime'
    // and toTime is in the future
    if (!fromTime) {
      return toInFuture ? APPLY_DISABLED_INVALID_TIME : APPLY_DISABLED_FALSE;
    }

    // If either fromTime or toTime is in the future
    if (fromInFuture || toInFuture) {
      return APPLY_DISABLED_INVALID_TIME;
    }

    // if limited to max days is set, perform the check
    if (limitCustomRangeToMaxDays && fromTime && toTime) {
      const diffDays = diffInDays(new Date(toTime), new Date(fromTime));

      if (diffDays > limitCustomRangeToMaxDays) {
        return APPLY_DISABLED_LIMITED;
      }
    }

    // If the from timestamp is after the to timestamp
    return fromTime > toTime ? APPLY_DISABLED_INVALID_TIME : APPLY_DISABLED_FALSE;
  },

  handleDateChange(value, type) {
    if (!value) {
      return;
    }

    const dateWithTime = new Date(value);
    const nowdate = new Date();
    const {activeCustom, time} = this.state;
    let hour = time[activeCustom].hour;
    let minute = time[activeCustom].minute;

    if (
      dateWithTime.getFullYear() === nowdate.getFullYear() &&
      dateWithTime.getDate() === nowdate.getDate() &&
      time[activeCustom].hour >= nowdate.getHours()
    ) {
      if (time[activeCustom].minute >= nowdate.getMinutes()) {
        hour = nowdate.getHours();
        minute = nowdate.getMinutes();
      } else {
        hour = nowdate.getHours();
      }
    }

    const date = {
      ...this.state.date,
      [type]: dateWithTime,
    };

    const newTime = {
      ...this.state.time,
      [type]: {hour, minute},
    };

    this.setState({date, time: newTime});
  },

  handleCustom(type, clearCustom) {
    if (clearCustom) {
      // If a value other than custom is selected
      // clear the date and time
      this.handleDateChange(new Date(), type);
      this.handleClockChange({hour: 0, minute: 0}, type);

      const hasCustom = {
        ...this.state.hasCustom,
        [type]: false,
      };

      this.setState({activeCustom: '', hasCustom});
    } else {
      // else show the calendar
      const hasCustom = {
        ...this.state.hasCustom,
        [type]: true,
      };

      this.setState({activeCustom: type, hasCustom});
    }
  },

  handleDTPickerInputClick(evt, type) {
    const {activeCustom, hasCustom} = this.state;

    // When clicking on an another input, while a custom calendar is active,
    // Either switch to that, or show calendar for the other input
    if (type !== activeCustom) {
      if (hasCustom[type]) {
        this.setState({activeCustom: type});
      } else {
        this.setState({activeCustom: ''});
      }
    }
  },

  handleClockChange(value, type) {
    const time = {
      ...this.state.time,
      [type]: value,
    };

    this.setState({time});
  },

  handleDTChange(value, type) {
    const items = {
      ...this.state.items,
      [type]: value,
    };

    this.setState({items});
  },

  handleCancel() {
    this.props.onRemove(this.props.name);
  },

  handleApply() {
    const {items} = this.state;
    const value = this.getTimestamps(Object.values(items.from)[0], Object.values(items.to)[0]);

    this.props.onAdd(this.props.name, value, items);
  },

  render() {
    const {fromSingleValues, toSingleValues, autoLoadCustom, limitCustomRangeToMaxDays} = this.props;
    const {activeCustom, hasCustom, items} = this.state;
    let fromCustomValue;
    let toCustomValue;

    if (hasCustom.from) {
      fromCustomValue = new Date(this.state.date.from);
      fromCustomValue.setHours(this.state.time.from.hour, this.state.time.from.minute);
    }

    if (hasCustom.to) {
      toCustomValue = new Date(this.state.date.to);
      toCustomValue.setHours(this.state.time.to.hour, this.state.time.to.minute);
    }

    const applyDisabled = this.applyDisabled(limitCustomRangeToMaxDays);

    return (
      <div className="DTPicker">
        <div className="DTPicker-Section DTPicker-Section--Inputs">
          <div className="DTPicker-Section DTPicker-Section--From">
            <div className="DTPicker-Section DTPicker-Section--FromTo">
              <div className="DTPicker-Section-Label">{intl('DateTimeInput.From')}</div>
              <div className="DTPicker-Section-Input">
                <DateTimePickerInput
                  type="from"
                  items={this.state.items.from}
                  autoLoadCustom={autoLoadCustom}
                  onChange={this.handleDTChange}
                  onCustom={this.handleCustom}
                  customValue={fromCustomValue}
                  active={activeCustom === 'from'}
                  onClick={this.handleDTPickerInputClick}
                  singleValues={fromSingleValues}
                />
              </div>
            </div>
          </div>
          <div className="DTPicker-Section DTPicker-Section--To">
            <div className="DTPicker-Section DTPicker-Section--FromTo">
              <div className="DTPicker-Section-Label">{intl('DateTimeInput.To')}</div>
              <div className="DTPicker-Section-Input">
                <DateTimePickerInput
                  type="to"
                  items={this.state.items.to}
                  autoLoadCustom={autoLoadCustom}
                  onChange={this.handleDTChange}
                  onCustom={this.handleCustom}
                  customValue={toCustomValue}
                  active={activeCustom === 'to'}
                  onClick={this.handleDTPickerInputClick}
                  singleValues={toSingleValues}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="DTPicker-Section">
          {!_.isEmpty(items.from) && !_.isEmpty(items.to) && applyDisabled !== APPLY_DISABLED_FALSE ? (
            <div className="DTPicker-Actions-Error">
              <Icon name="error" size="medium" />
              {applyDisabled === APPLY_DISABLED_INVALID_TIME
                ? intl('DateTimeInput.InvalidTimeRange')
                : intl('DateTimeInput.LimitedTimeRange', {count: limitCustomRangeToMaxDays})}
            </div>
          ) : null}
        </div>

        {activeCustom ? (
          <div className="DTPicker-Section DTPicker-Section--Calendar">
            <div className="DTPicker-Section-Cal">
              <div className="DTPicker-Section-Bluebanner" />
              <div className="DTPicker-Section-NextPrev">
                <Icon name="back" size="large" />
                <Icon name="next" size="large" />
              </div>
              <DateTimePickerCal
                date={this.state.date[activeCustom]}
                onDateChange={this.handleDateChange}
                type={activeCustom}
              />
            </div>
            <div className="DTPicker-Section-Time">
              <div className="DTPicker-Section-Bluebanner">
                <span>{intl('Explorer.Time')}</span>
              </div>
              <DateTimePickerClock
                time={this.state.time[activeCustom]}
                date={this.state.date[activeCustom]}
                onChange={this.handleClockChange}
                type={activeCustom}
              />
            </div>
          </div>
        ) : null}
        <div className="DTPicker-Actions">
          <div className="DTPicker-Actions-Buttons">
            <div className="DTPicker-Actions-Button">
              <Button
                type="linky"
                text={intl('Common.Cancel')}
                onClick={this.handleCancel}
                content="text-only"
                tid="DateTimeInput"
              />
            </div>
            <div className="DTPicker-Actions-Button">
              <Button
                text={intl('Common.Apply')}
                onClick={this.handleApply}
                disabled={applyDisabled !== APPLY_DISABLED_FALSE}
                tid="DateTimeInput"
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
});
